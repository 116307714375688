import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { Box, theme } from "Application";
import { FullWidthCentFlexContainer } from "Components/FullWidthCentFlexCont";
import { InnerWrapper } from "Components/InnerWrapper";
import { BannerImageFull } from "Components/BannerImageFull/BannerImageFull";

import Banner1 from "Assets/LivingBanner1.jpg";
import Banner2 from "Assets/LivingBanner2.jpg";
import Banner3 from "Assets/LivingBanner3.jpg";
import QuoteImage from "Assets/LivingEllipse.png";
import LivingNature from "Assets/Living_Nature.jpg";
import LivingFlavours from "Assets/Living_Flavours.jpg";
import LivingCulture from "Assets/Living_Culture.jpg";
import LivingSports from "Assets/Living_Sports.jpg";
import LivingNatureMobile from "Assets/LivingNature.png";
import LivingFlavoursMobile from "Assets/LivingFlavours.png";
import LivingCultureMobile from "Assets/LivingCulture.png";
import LivingSportsMobile from "Assets/LivingSports.png";

import { TitleLeftTextRightBlock } from "Components/TitleLeftTextRightBlock/TitleLeftTextRightBlock";
import { ShorelineButton } from "Components/ShorelineButton";
import { LivingViewModel } from "./LivingViewModel";
import { QuoteBlock } from "Components/QuoteBlock/QuoteBlock";
import styled from "@emotion/styled";
import { container } from "tsyringe";
import { DomainStore } from "Stores/Domain/DomainStore";
import { H1, H3, P } from "Components/Primitives/TextElements/TextElements";
import { PropertyListItem } from "Views/NewHomes/Subviews/PropertyListItem";
import { useInView } from "react-intersection-observer";
import { BaseCard } from "Components/BaseCard";

export const LivingView: React.FC = observer(() => {
    const [viewModel] = useState<LivingViewModel>(new LivingViewModel());
    const domainStore = container.resolve(DomainStore);

    const [index, setIndex] = useState(0);
    const [currentOpacity, setCurrentOpacity] = useState(1);

    const { ref: RefNature, inView: NatureInView } = useInView({ threshold: 0.25 });
    const RefNatureScrollTo = useRef<null | HTMLDivElement>(null);
    const { ref: RefFlavours, inView: FlavoursInView } = useInView({ threshold: 0.25 });
    const RefFlavoursScrollTo = useRef<null | HTMLDivElement>(null);
    const { ref: RefCulture, inView: CultureInView } = useInView({ threshold: 0.25 });
    const RefCultureScrollTo = useRef<null | HTMLDivElement>(null);
    const { ref: RefSports, inView: SportsInView } = useInView({ threshold: 0.5 });
    const RefSportsScrollTo = useRef<null | HTMLDivElement>(null);

    useEffect(() => {
        if (NatureInView == true) {
            setIndex(0);
        }
    }, [NatureInView]);

    useEffect(() => {
        if (FlavoursInView == true) {
            setIndex(1);
        }
    }, [FlavoursInView]);

    useEffect(() => {
        if (CultureInView == true) {
            setIndex(2);
        }
    }, [CultureInView]);

    useEffect(() => {
        if (SportsInView == true) {
            setIndex(3);
        }
    }, [SportsInView]);

    const ViewOptions = [
        {
            backgroundColor: "#2B6298",
            bodyText: `In a prime position on Kent’s Heritage Coast, which recently placed 4th in Lonely Planet’s top 10 ‘must-visit’ destinations worldwide, Folkestone is surrounded by acres of coastal wilderness for roaming, foraging, and fossil-hunting.\n
Located just a few metres from the renowned Lower Leas Coastal Park, Shoreline Crescent offers easy access to this award-winning attraction.`,
            imageUrl: LivingNature,
            title: "Surrounded by Nature",
            fontColor: theme.palette.common.secondary,
        },
        {
            backgroundColor: "#CC6B2C",
            bodyText:
                "Trendsetting street food and pop-ups in The Goods Yard; Eclectic restaurants in the old town and charming harbourside pubs; Artisan coffee spots; Relaxed beachside dining; And, a lighthouse champagne bar; Folkestone is a haven for food and drink lovers.",
            imageUrl: LivingFlavours,
            title: "A Kaleidoscope of Flavours",
            fontColor: theme.palette.common.secondary,
        },
        {
            backgroundColor: "#ECDFC9",
            bodyText: `Buzzing with creative energy, Folkestone hosts the Creative Folkestone Triennial, the “UK’s coolest art festival”, according to Time Out.\n
There’s always something going on in The Creative Quarter which acts as an incubator for artists, makers and creators across design, music, film and the digital arts.\n
Quarterhouse is the town’s cultural hub with a regular programme of theatre, music, film, comedy and ‘everything in between’.`,
            imageUrl: LivingCulture,
            title: "Culture & Creativity",
        },
        {
            backgroundColor: "#E0A752",
            bodyText: `Folkestone offers a range of year-round indoor and outdoor activities. On the water, there’s sailing, paddleboarding, kayaking and sea swimming.\n
Off the water, there’s the world’s first multistorey skatepark with indoor climbing wall and boxing gym. There are two sports centres, one with swimming pool, and gyms and studios that provide yoga and fitness classes. The new athletics track at Three Hills completes their indoor and outdoor hockey and cricket facility.`,
            imageUrl: LivingSports,
            title: "Sports & Outdoors",
        },
    ];

    const navtoNature = () => {
        RefNatureScrollTo.current?.scrollIntoView({ behavior: "instant", block: "center" });
    };

    const navtoFlavours = () => {
        RefFlavoursScrollTo.current?.scrollIntoView({ behavior: "instant", block: "end" });
    };

    const navtoCulture = () => {
        RefCultureScrollTo.current?.scrollIntoView({ behavior: "instant", block: "end" });
    };

    const navtoSports = () => {
        RefSportsScrollTo.current?.scrollIntoView({ behavior: "instant", block: "end" });
    };

    // useEffect(() => {
    //     getView();
    // }, [domainStore.scrollPosition]);

    const getView = () => {
        if (domainStore.scrollPosition <= 17) {
            if (domainStore.scrollPosition >= 15) {
                setCurrentOpacity(1 - (domainStore.scrollPosition - 15) / 2);
            }
            setIndex(0);
        } else if (domainStore.scrollPosition > 17 && domainStore.scrollPosition < 27) {
            if (domainStore.scrollPosition > 17 && domainStore.scrollPosition <= 19) {
                setCurrentOpacity((domainStore.scrollPosition - 17) / 2);
            }
            if (domainStore.scrollPosition >= 25) {
                setCurrentOpacity(1 - (domainStore.scrollPosition - 25) / 2);
            }
            setIndex(1);
        } else if (domainStore.scrollPosition >= 27 && domainStore.scrollPosition < 37) {
            if (domainStore.scrollPosition > 27 && domainStore.scrollPosition <= 29) {
                setCurrentOpacity((domainStore.scrollPosition - 27) / 2);
            }
            if (domainStore.scrollPosition >= 35) {
                setCurrentOpacity(1 - (domainStore.scrollPosition - 35) / 2);
            }
            setIndex(2);
        } else if (domainStore.scrollPosition >= 37 && domainStore.scrollPosition < 47) {
            if (domainStore.scrollPosition > 37 && domainStore.scrollPosition <= 39) {
                setCurrentOpacity((domainStore.scrollPosition - 37) / 2);
            }
            setIndex(3);
        }
    };

    return (
        <>
            <FullWidthCentFlexContainer>
                <InnerWrapper height={viewModel.isMobile ? "100%" : "100%"} style={{ backgroundColor: ViewOptions[index].backgroundColor }}>
                    <BannerImageFull imageUrl={Banner1} />
                    <div ref={RefNature}>
                        <TitleLeftTextRightBlock
                            surTitle="FOLKESTONE"
                            title={`“One of the best places to \n live in 2023”  - The Times`}
                            textBlock={`Folkestone is a vibrant coastal community with creativity at its heart. Eclectic independent shops, cafés and restaurants, a thriving arts scene, world-class sports facilities, and proximity to the sea and rolling countryside make Folkestone “one of the best places to live” in the UK. `}
                            buttons={
                                <>
                                    <ShorelineButton displayName="DISCOVER MORE AT FOLKE.LIFE" value="https://folke.life/" command={viewModel.navTo} />
                                </>
                            }
                            isMobile={viewModel.isMobile}
                            backgroundColour="white"
                            hasLargerBodyText={true}
                        />
                    </div>
                </InnerWrapper>
            </FullWidthCentFlexContainer>

            <FullWidthCentFlexContainer>
                <InnerWrapper maxWidth="1400px" m={viewModel.isMobile ? "0px 20px" : "0px 40px"}>
                    <LivingBox>
                        <H1 color="primary" pb="40px" style={{ fontStyle: "48px", fontWeight: 400 }}>
                            In and around Folkestone
                        </H1>
                        <Box grid dc="1fr 1fr">
                            <BaseCard
                                imgUrl={viewModel.isMobile ? LivingNatureMobile : LivingNature}
                                title="Surrounded by Nature"
                                description="In a prime position on Kent’s Heritage Coast, which recently placed 4th in Lonely Planet’s top 10 ‘must-visit’ destinations worldwide, Folkestone is surrounded by acres of coastal wilderness for roaming, foraging, and fossil-hunting."
                            />
                            <BaseCard
                                imgUrl={viewModel.isMobile ? LivingFlavoursMobile : LivingFlavours}
                                title="A Kaleidoscope of Flavours"
                                description="Trendsetting street food and pop-ups in The Goods Yard; Eclectic restaurants in the old town and charming harbourside pubs; Artisan coffee spots; Relaxed beachside dining; And, a lighthouse champagne bar; Folkestone is a haven for food and drink lovers."
                            />
                            <BaseCard
                                imgUrl={viewModel.isMobile ? LivingCultureMobile : LivingCulture}
                                title="Culture & Creativity"
                                description="Buzzing with creative energy, Folkestone hosts the Creative Folkestone Triennial, the “UK’s coolest art festival”, according to Time Out.
There’s always something going on in The Creative Quarter which acts as an incubator for artists, makers and creators across design, music, film and the digital arts."
                            />
                            <BaseCard
                                imgUrl={viewModel.isMobile ? LivingSportsMobile : LivingSports}
                                title="Sports & Outdoors"
                                description="Folkestone offers a range of year-round indoor and outdoor activities. On the water, there’s sailing, paddleboarding, kayaking and sea swimming.
Off the water, there’s the world’s first multistorey skatepark with indoor climbing wall and boxing gym. "
                            />
                        </Box>
                    </LivingBox>
                    {/* {!viewModel.isMobile && (
                        <>
                            <ScrollBox showIf={!viewModel.isMobile}>
                                <Box flexBox maxWidth="1400px" style={{ justifyContent: "space-between", flexDirection: "column" }}>
                                    <Box grid dc="1fr 1fr">
                                        <MenuBox>
                                            <H3Styled textColour={ViewOptions[index].fontColor} className={index === 0 ? "selected" : ""} onClick={navtoNature}>
                                                Surrounded by Nature
                                            </H3Styled>
                                            <H3Styled textColour={ViewOptions[index].fontColor} color="primary" className={index === 1 ? "selected" : ""} onClick={navtoFlavours}>
                                                A Kaleidoscope of Flavours
                                            </H3Styled>
                                            <H3Styled textColour={ViewOptions[index].fontColor} color="primary" className={index === 2 ? "selected" : ""} onClick={navtoCulture}>
                                                Culture & Creativity
                                            </H3Styled>
                                            <H3Styled textColour={ViewOptions[index].fontColor} color="primary" className={index === 3 ? "selected" : ""} onClick={navtoSports}>
                                                Sports & Outdoors
                                            </H3Styled>
                                        </MenuBox>

                                        <BodyP textColour={ViewOptions[index].fontColor}>{ViewOptions[index].bodyText}</BodyP>
                                    </Box>
                                    <Box grid dc="1fr 2fr">
                                        <Box />

                                        <img src={ViewOptions[index].imageUrl} width="100%" height="auto" style={{ objectFit: "cover", display: "block", maxHeight: "60vh" }} />
                                    </Box>
                                </Box>
                            </ScrollBox>
                        </>
                    )} */}
                    {/* ****DO NOT REMOVE - These are the divs that are scrolled to**** */}
                    {/* {!viewModel.isMobile && (
                        <>
                            <div style={{ height: "100vh" }} ref={RefNature}>
                                <div ref={RefNatureScrollTo} />
                            </div>

                            <div style={{ height: "100vh" }} ref={RefFlavours}>
                                <div ref={RefFlavoursScrollTo} style={{ height: "100vh" }} />
                            </div>
                            <div style={{ height: "100vh" }} ref={RefCulture}>
                                <div ref={RefCultureScrollTo} style={{ height: "100vh" }} />
                            </div>
                            <div style={{ height: "100vh" }} ref={RefSports}>
                                <div ref={RefSportsScrollTo} style={{ height: "100vh" }} />
                            </div>
                        </>
                    )} */}
                    {/* {viewModel.isMobile && (
                        <>
                            {ViewOptions.map((option, index) => {
                                return (
                                    <Box p="30px 20px" key={index} style={{ backgroundColor: option.backgroundColor }}>
                                        <H3StyledMobile textColour={option.fontColor} pb="22px">
                                            {option.title}
                                        </H3StyledMobile>
                                        <PStyledMobile textColour={option.fontColor} pb="28px">
                                            {option.bodyText}
                                        </PStyledMobile>

                                        <img src={option.imageUrl} width="100%" height="100%" />
                                    </Box>
                                );
                            })}
                        </>
                    )} */}
                </InnerWrapper>
            </FullWidthCentFlexContainer>

            <FullWidthCentFlexContainer>
                <InnerWrapper>
                    <BannerImageFull imageUrl={Banner2} />

                    <TitleLeftTextRightBlock
                        title="Travel & Connectivity"
                        textBlock={`Folkestone to London St. Pancras is less than 1 hour by train and the Eurotunnel station at Folkestone provides car and coach services to Calais. Gatwick Airport is 1hr 15 mins by car, London City is 1 hour 30 mins, and Lydd Airport is 35 mins away.\n
Shoreline Crescent has true fibre optic broadband, delivered right to the building. That’s up to x10 faster than conventional fibre optic broadband.`}
                        backgroundColour="white"
                        textColour={"primary"}
                        isMobile={viewModel.isMobile}
                    />

                    <QuoteBlock
                        quoteAuthor="CONDE NAST TRAVELLER"
                        quoteText={`“Folkestone is one of the best places in\n Europe for remote working.” `}
                        imageUrl={QuoteImage}
                        isMobile={viewModel.isMobile}
                        imageLeft={true}
                        isTablet={viewModel.isTablet}
                    />

                    <TitleLeftTextRightBlock
                        title="Schools"
                        textBlock={`Folkestone has many primary and secondary schools rated ‘Good’ and ‘Outstanding’ by Ofsted.\n
Additionally, the town boasts both boys and girls grammar schools, along with a good selection of private schools nearby. Dover College and The King’s School, Canterbury are just 8 and 19 miles away, respectively.`}
                        backgroundColour="white"
                        textColour={"primary"}
                        isMobile={viewModel.isMobile}
                    />

                    <BannerImageFull imageUrl={Banner3} />
                </InnerWrapper>
            </FullWidthCentFlexContainer>
        </>
    );
});

const ScrollBox = styled(Box)`
    height: 98vh;
    width: 100%;
    position: sticky;
    left: 0;
    top: 48px;
    padding: 40px 0px;
    display: flex;
    justify-content: center;

    /* max-height: 100vh;
    max-width: 100vw;
    transform: translate(-50%, -50%); */
`;

const InnerWrapperScroll = styled(InnerWrapper)`
    height: 94vh;
    width: 100%;
    position: sticky;
    left: 0;
    top: 48px;

    /* max-height: 100vh;
    max-width: 100vw;
    transform: translate(-50%, -50%); */
`;

const MenuBox = styled(Box)`
    .selected {
        text-decoration: underline;
        text-underline-offset: 6px;
    }
`;

const H3ScrollMenuItem = styled(H3)`
    cursor: pointer;
`;

interface IStyling {
    textColour?: string;
}

const BodyP = styled(P)<IStyling>`
    color: ${(props) => (props.textColour ? props.textColour : "#212745")};
    font-family: sabon;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    white-space: pre-line;
`;

const H3StyledMobile = styled(H3)<IStyling>`
    font-family: sohne;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 48px; /* 160% */
    color: ${(props) => (props.textColour ? props.textColour : "#212745")};
`;

const H3Styled = styled(H3)<IStyling>`
    font-family: sohne;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 48px;
    color: ${(props) => (props.textColour ? props.textColour : "#212745")};
    cursor: pointer;
`;

const PStyledMobile = styled(P)<IStyling>`
    color: ${(props) => (props.textColour ? props.textColour : "#212745")};
    font-family: sabon;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;

const LivingBox = styled(Box)`
    padding: 67px 0px 40px 0px;
`;
