import React from "react";
import { observer } from "mobx-react-lite";
import { Box } from "Application";
import { P } from "Components/Primitives/TextElements/TextElements";

export interface IBannerImageFull {
    imageUrl: string;
    caption?: string;
}

export const BannerImageFullCover: React.FC<IBannerImageFull> = observer((props) => {
    return (
        <>
            <Box style={{ lineHeight: "0px" }} height="100%">
                <img src={props.imageUrl} height="100%" width="100%" style={{ objectFit: "cover" }} />
                {props.caption && (
                    <P pb="30px" pt="11px" style={{ fontSize: "12px" }}>
                        {props.caption}
                    </P>
                )}
            </Box>
        </>
    );
});
