import { makeObservable, observable, runInAction } from "mobx";
import { ViewModelBase } from "@shoothill/core";
import { APIClient, IKeyState } from "Application";
import { DownloadModel, DownloadModelValidator } from "./DownloadModel";
import { DownloadBrochureAddendumEndpoint } from "./Endpoints/DownloadBrochureAddendum";

export class DownloadViewModel extends ViewModelBase<DownloadModel> {
    public apiClient = new APIClient();
    public brochureToDownload: brochureTypes = brochureTypes.Duplex;
    public downloadView: DownloadViews = DownloadViews.Downloading;

    constructor() {
        super(new DownloadModel());
        this.setValidator(new DownloadModelValidator());
        makeObservable(this, { brochureToDownload: observable, downloadView: observable });
    }

    public downloadBrochure = (type: string) => {
        let brochureType = type.toLocaleLowerCase();
        switch (brochureType) {
            case "duplex":
                this.openInNewTab(process.env.PUBLIC_URL + "/pdfBrochures/2023-09-08 Shoreline Crescent_Duplexes_240x330mm_v10.pdf");
                this.downloadView = DownloadViews.Downloaded;
                // this.brochureToDownload = brochureTypes.Duplex;
                // this.getBrochure(this.brochureToDownload);
                break;
            case "east_apartment":
                this.openInNewTab(process.env.PUBLIC_URL + "/pdfBrochures/2023-09-08 Shoreline Crescent_East Apartments_240x330mm_v9.pdf");
                this.downloadView = DownloadViews.Downloaded;
                // this.brochureToDownload = brochureTypes.EastApartment;
                // this.getBrochure(this.brochureToDownload);
                break;
            case "penthouse":
                this.openInNewTab(process.env.PUBLIC_URL + "/pdfBrochures/2023-09-08 Shoreline Crescent_Penthouses_240x330mm_v11.pdf");
                this.downloadView = DownloadViews.Downloaded;
                // this.brochureToDownload = brochureTypes.Penthouse;
                // this.getBrochure(this.brochureToDownload);
                break;
            case "beachhouse":
                this.openInNewTab(process.env.PUBLIC_URL + "/pdfBrochures/2023-09-08 Shoreline Crescent_Townhouses_240x330mm_v10.pdf");
                this.downloadView = DownloadViews.Downloaded;
                // this.brochureToDownload = brochureTypes.Townhouse;
                // this.getBrochure(this.brochureToDownload);
                break;
            case "west_apartment":
                this.openInNewTab(process.env.PUBLIC_URL + "/pdfBrochures/2023-09-18 Shoreline Crescent_West Apartments_240x330mm_v11.pdf");
                this.downloadView = DownloadViews.Downloaded;
                // this.brochureToDownload = brochureTypes.WestApartment;
                // this.getBrochure(this.brochureToDownload);

                break;
            default:
                this.downloadView = DownloadViews.PathNotFound;
        }
    };

    openInNewTab = (url: string) => {
        window.open(url, "_blank", "noreferrer");
    };

    public async getBrochure(params: any): Promise<void> {
        if (params !== undefined) {
            const brochure = new DownloadBrochureAddendumEndpoint(params);
            await this.apiClient.sendAsync(brochure);

            if (this.apiClient.IsRequestSuccessful) {
                runInAction(() => {
                    let response = this.apiClient.Response();
                    this.downloadView = DownloadViews.Downloaded;
                    // this.isProcessing = false;
                });
            } // TODO Else show error message
            else {
                runInAction(() => {
                    // this.isProcessing = false;
                });
            }
        }
    }
}

export enum brochureTypes {
    "FullBrochure" = 0,
    "Duplex" = 1,
    "EastApartment" = 2,
    "Penthouse" = 3,
    "Townhouse" = 4,
    "WestApartment" = 5,
}

export enum DownloadViews {
    "Downloading" = 1,
    "Downloaded" = 2,
    "PathNotFound" = 3,
}
