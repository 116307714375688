import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { Validator } from "Application/Validation";

export class OurVisionModel extends ModelBase<OurVisionModel> {
    public id: string = "";

    constructor() {
        super();
        makeObservable(this, {
            id: observable,
        });
    }
}

export class OurVisionModelValidator extends Validator<OurVisionModel> {
    constructor() {
        super();
        //this.ruleFor("id").notEmpty();
    }
}
