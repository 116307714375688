import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { Box, theme } from "Application";
import { FullWidthCentFlexContainer } from "Components/FullWidthCentFlexCont";
import { InnerWrapper } from "Components/InnerWrapper";
import { H2, H4, P } from "Components/Primitives/TextElements/TextElements";
import { BannerImageFull } from "Components/BannerImageFull/BannerImageFull";
import Banner from "Assets/HomeCarouselVidFallback.jpg";
import { SemiCircle } from "Components/SemiCircle";
import { TitleLeftTextRightBlock } from "Components/TitleLeftTextRightBlock/TitleLeftTextRightBlock";
import { AssistedMoveViewModel } from "./AssistedMoveViewModel";
import { Navigate, Route, Routes } from "react-router-dom";
import { HomeView } from "Views/Home/HomeView";
import { TitleWithTextBlock } from "Components/TitleLeftTextRightBlock/TitleWithTextBlock";

export const AssistedMoveView: React.FC = observer(() => {
    const [viewModel] = useState(new AssistedMoveViewModel());

    interface IStep {
        circleColor?: string;
        title?: string;
        body?: string;
    }

    const Step: React.FC<IStep> = (props) => {
        return (
            <>
                <Box>
                    <SemiCircle $bgColor={props.circleColor} />
                </Box>
                <Box pb="60px">
                    <H4 color="secondary" pb="15px">
                        {props.title}
                    </H4>
                    <P color="secondary" style={{ whiteSpace: "pre-wrap", fontSize: "18px" }}>
                        {props.body}
                    </P>
                </Box>
            </>
        );
    };

    return (
        <>
            <TitleLeftTextRightBlock
                title="Assisted Move"
                textBlock={`Discover the seamless relocation experience at Shoreline Crescent with our Assisted Move program, where we take care of your estate agent fees.
`}
                isMobile={viewModel.isMobile}
                hasLargeTitle={true}
                hasLargerBodyText={true}
                hasThreeButtons={true}
                isTablet={viewModel.isTablet}
                textColour="primary"
                backgroundColour="white"
            />
            <FullWidthCentFlexContainer style={{ backgroundColor: "rgb(31, 39, 71)" }} p={viewModel.isDesktop ? "40px" : "20px"}>
                <Box>
                    <FullWidthCentFlexContainer style={{ backgroundColor: "rgb(31, 39, 71)" }}>
                        <InnerWrapper height={"100%"} maxWidth="1400px" style={{ backgroundColor: "rgb(31, 39, 71)" }}>
                            <Box grid dc="1fr auto" p={viewModel.isMobile || viewModel.isTablet ? "60px 0px" : "100px 0px"} columnGap={"100px"}>
                                <Step
                                    circleColor="#eaa346"
                                    title="Step One"
                                    body="Choose your new home and find out if your current property is eligible for the scheme with a member of the sales team."
                                />
                                <Step
                                    circleColor="#1d6a9f"
                                    title="Step Two"
                                    body={
                                        "An asking price for your current home will be agreed after three independent valuations.\n\nWe agree a valuation and marketing plan for your existing property and will market your existing home for up to 6 weeks.  A £500 holding fee is paid to Folkestone Harbour Seafront Development team (This is fully refundable should your property not sell).  We will take your new home off the market."
                                    }
                                />
                                <Step
                                    circleColor="#de6b26
"
                                    title="Step Three"
                                    body="We will work with you to instruct an estate agent to handle the sale of your current home."
                                />
                                <Step
                                    circleColor="#423e49
"
                                    title="Step Four"
                                    body="We will cover the estate agents’ costs."
                                />
                                <Step
                                    circleColor="#e4d8c0
"
                                    title="Step Five"
                                    body="Once a sale is agreed, it will enable you to proceed with the purchase of your new Shoreline home."
                                />
                            </Box>
                        </InnerWrapper>
                    </FullWidthCentFlexContainer>
                </Box>
            </FullWidthCentFlexContainer>

            <BannerImageFull imageUrl={Banner} />
        </>
    );
});
