import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Box, CheckBox, EditSelect, ICommand, Input, Label, RelayCommand, theme } from "Application";
import { DownloadBrochureModalViewModel } from "./DownloadBrochureModalViewModel";
import { Modal } from "@fluentui/react";
import styled from "@emotion/styled";
import { H3, H4, P } from "Components/Primitives/TextElements/TextElements";
import { Link } from "react-router-dom";
import { ShorelineButton } from "Components/ShorelineButton";
import loadingSpinner from "Assets/LoadingSpinner.svg";

import X from "Assets/x.svg";
import { HorizontalGradLineDarkLightDark } from "Components/HorizontalGradLineDarkLightDark";
import { currencyFormat } from "Utils/CurrencyFormatting";
import { container } from "tsyringe";
import { DomainStore } from "Stores/Domain/DomainStore";

interface IDownloadBrochureModal {
    open: boolean;
    closeModal: () => void;
}

export const DownloadBrochureModal: React.FC<IDownloadBrochureModal> = observer((props) => {
    const [viewModel] = useState<DownloadBrochureModalViewModel>(new DownloadBrochureModalViewModel());
    const domainStore = container.resolve(DomainStore);

    const closeModal: ICommand = new RelayCommand(() => {
        props.closeModal();
        viewModel.closeFormReset();
    });

    useEffect(() => {
        if (viewModel.isSumbitted == true) {
            props.closeModal();
            viewModel.downloadButtonText = "DOWNLOAD";
        }
    }, [viewModel.isSumbitted]);

    return (
        <>
            <Modal
                isOpen={props.open}
                allowTouchBodyScroll={true}
                onDismissed={() => {
                    viewModel.viewFormOrSubmitted = "Form";
                }}
                styles={{ main: { backgroundColor: theme.palette.common.tertiary }, scrollableContent: { maxHeight: "200vh" } }}
            >
                <ModalWrapper>
                    {viewModel.viewFormOrSubmitted == "Form" ? (
                        <Box>
                            <HeaderBox>
                                <img src={X} style={{ cursor: "pointer" }} onClick={props.closeModal} />
                                <H4 color="primary">Download our brochure</H4>
                            </HeaderBox>

                            <form>
                                <FieldWrapper grid dc="1fr 1fr" mc="1fr 1fr">
                                    <Input
                                        command={viewModel.updateFirstName}
                                        onBlur={() => viewModel.isFieldValid("firstName")}
                                        type="text"
                                        displayName="First name*"
                                        placeholder="First name"
                                        labelColor="primary"
                                        value={() => viewModel.getValue("firstName")}
                                        validationMessage={() => viewModel.getError("firstName")}
                                    ></Input>

                                    <Input
                                        command={viewModel.updateLastName}
                                        type="text"
                                        displayName="Last name*"
                                        placeholder="Last name"
                                        labelColor="primary"
                                        value={() => viewModel.getValue("surname")}
                                        validationMessage={() => viewModel.getError("surname")}
                                    ></Input>
                                </FieldWrapper>

                                <FieldWrapper>
                                    <Input
                                        command={viewModel.updateAddressLine1}
                                        type="text"
                                        displayName="Address line 1*"
                                        placeholder="Address line 1"
                                        labelColor="primary"
                                        value={() => viewModel.getValue("addressLine1")}
                                        validationMessage={() => viewModel.getError("addressLine1")}
                                    ></Input>
                                </FieldWrapper>

                                <FieldWrapper>
                                    <Input
                                        command={viewModel.updateAddressLine2}
                                        type="text"
                                        displayName="Address line 2"
                                        placeholder="Address line 2"
                                        labelColor="primary"
                                        value={() => viewModel.getValue("addressLine2")}
                                        /* validationMessage={() => viewModel.getError("addressLine2")} */
                                    ></Input>
                                </FieldWrapper>

                                <FieldWrapper grid dc="1fr 1fr" mc="1fr 1fr">
                                    <Input
                                        command={viewModel.updateCity}
                                        type="text"
                                        displayName="City / Town*"
                                        placeholder="City / Town"
                                        labelColor="primary"
                                        value={() => viewModel.getValue("city")}
                                        validationMessage={() => viewModel.getError("city")}
                                    ></Input>

                                    <Input
                                        command={viewModel.updatePostcode}
                                        type="text"
                                        displayName="Post code*"
                                        placeholder="Post code"
                                        labelColor="primary"
                                        value={() => viewModel.getValue("postcode")}
                                        validationMessage={() => viewModel.getError("postcode")}
                                    ></Input>
                                </FieldWrapper>

                                <FieldWrapper>
                                    <Input
                                        command={viewModel.updateEmail}
                                        type="email"
                                        displayName="Email address*"
                                        placeholder="Email address"
                                        labelColor="primary"
                                        value={() => viewModel.getValue("contactEmail")}
                                        validationMessage={() => viewModel.getError("contactEmail")}
                                    ></Input>
                                </FieldWrapper>

                                <FieldWrapper>
                                    <Input
                                        command={viewModel.updatePhoneNumber}
                                        type="number"
                                        displayName="Phone number*"
                                        placeholder="Phone number"
                                        labelColor="primary"
                                        value={() => viewModel.getValue("primaryContactNumber")}
                                        // maskedOptions={MaskedOptions.MobilePhoneNumber()}
                                        validationMessage={() => viewModel.getError("primaryContactNumber")}
                                    ></Input>
                                </FieldWrapper>

                                <Box className={"fullWidth"}>
                                    <Label pb="10px">Preferred method of contact*</Label>
                                </Box>
                                <FieldWrapper grid dc="1fr 1fr" mc="1fr 1fr">
                                    <CheckBox
                                        className="inputConsent"
                                        displayName="&nbsp;&nbsp;Email"
                                        command={viewModel.updatePrefersEmails}
                                        labelColor="primary"
                                        value={() => viewModel.getValue("preferredContactMethodEmail")}
                                        validationMessage={() => viewModel.getError("preferredContactMethodEmail")}
                                    ></CheckBox>
                                    <CheckBox
                                        className="inputConsent"
                                        displayName="&nbsp;&nbsp;Phone"
                                        command={viewModel.updatePrefersTelCalls}
                                        labelColor="primary"
                                        value={() => viewModel.getValue("preferredContactMethodPhone")}
                                        validationMessage={() => viewModel.getError("preferredContactMethodPhone")}
                                    ></CheckBox>
                                </FieldWrapper>

                                {viewModel.getPreferredContactMethodValidationMessage !== "" && (
                                    <Box className={"fullWidth"}>
                                        <Label fontStyle="error" color="error">
                                            {viewModel.getPreferredContactMethodValidationMessage}
                                        </Label>
                                    </Box>
                                )}

                                <FieldWrapper>
                                    <EditSelect
                                        styles={{
                                            // Dropdown menu z-index fix
                                            menuPortal: (provided: any) => ({ ...provided, zIndex: 1000000 }),
                                            control: (base: any) => ({
                                                ...base,
                                                // override border radius to match the box
                                                borderRadius: 5,
                                                border: "none",
                                            }),
                                        }}
                                        displayName="Brochure*"
                                        command={viewModel.updateBrochureToDownload}
                                        options={viewModel.getBrochureOptions}
                                        value={() => viewModel.getValue("brochureType")}
                                        menuPosition="fixed"
                                    />
                                </FieldWrapper>

                                <FieldWrapper>
                                    <Input
                                        command={viewModel.updateBudgetFrom}
                                        type="text"
                                        displayName="Budget from*"
                                        placeholder="Budget from"
                                        labelColor="primary"
                                        value={() => currencyFormat(viewModel.getValue("budgetFrom"))}
                                        validationMessage={() => viewModel.getError("budgetFrom")}
                                        prefix={<p>£</p>}
                                    ></Input>
                                </FieldWrapper>
                                <FieldWrapper>
                                    <Input
                                        command={viewModel.updateBudgetTo}
                                        type="text"
                                        displayName="Budget to*"
                                        placeholder="Budget to"
                                        labelColor="primary"
                                        value={() => currencyFormat(viewModel.getValue("budgetTo"))}
                                        validationMessage={() => viewModel.getError("budgetTo")}
                                        prefix={<p>£</p>}
                                    ></Input>
                                </FieldWrapper>

                                <FieldWrapper>
                                    <P color="primary" pb="20px">
                                        <CheckBox
                                            className="inputConsent"
                                            labelColor="primary"
                                            displayName="&nbsp;&nbsp;Yes, I want to know about updates from Shoreline"
                                            command={viewModel.updateWantsUpdates}
                                            value={() => viewModel.getValue("acceptsMarketing")}
                                        ></CheckBox>
                                    </P>
                                </FieldWrapper>

                                <FieldWrapper>
                                    <P color="primary" pb="20px">
                                        <CheckBox
                                            className="inputConsent"
                                            labelColor="primary"
                                            displayName="&nbsp;&nbsp;I agree to the "
                                            command={viewModel.updateAgreesPrivacyPolicy}
                                            value={() => viewModel.getValue("agreePrivacyPolicy")}
                                            validationMessage={() => viewModel.getError("agreePrivacyPolicy")}
                                            hrefLink={
                                                <Link className="privacy-policy-link" to="/privacy-policy">
                                                    privacy policy
                                                </Link>
                                            }
                                        ></CheckBox>
                                    </P>
                                    <Label fontStyle="error" color="error">
                                        {viewModel.getError("agreePrivacyPolicy")}
                                    </Label>
                                </FieldWrapper>

                                <FieldWrapper>
                                    <Box maxWidth="228px" mb="30px">
                                        <ShorelineButton command={viewModel.onSubmit} displayName={viewModel.downloadButtonText}></ShorelineButton>
                                    </Box>
                                </FieldWrapper>
                            </form>
                        </Box>
                    ) : (
                        <Box maxWidth="300px">
                            {viewModel.hasDownloaded ? (
                                <>
                                    <P pb="40px" pt="40px" color="primary" style={{ textAlign: "center" }}>
                                        Thank you for your interest.
                                    </P>
                                    {/* <P color="primary" pb="20px" style={{ textAlign: "center" }}>
                                        Our Sales and Marketing Suite will be closed from 5pm on 23rd December and will re-open from 10am on 4th January. All messages and requests
                                        received during this time will be addressed promptly upon our return.
                                    </P>
                                    <P color="primary" pb="20px" style={{ textAlign: "center" }}>
                                        Wishing you a Merry Christmas and Happy New Year from Shoreline Crescent!
                                    </P> */}
                                </>
                            ) : (
                                <P pb="60px" pt="60px" color="primary" style={{ textAlign: "center" }}>
                                    Your brochure will begin downloading soon. <br /> Thank you for your interest.
                                </P>
                            )}
                            {viewModel.isProcessing == true && (
                                <Box>
                                    <P color="primary" pb="15px">
                                        Downloading brochure...
                                    </P>
                                    <Box style={{ display: "flex", justifyContent: "center" }} pb="20px">
                                        <img src={loadingSpinner} />
                                    </Box>
                                </Box>
                            )}
                            <ShorelineButton pb="30px" command={closeModal} displayName="CLOSE"></ShorelineButton>
                        </Box>
                    )}
                </ModalWrapper>
            </Modal>
        </>
    );
});

const ModalWrapper = styled(Box)`
    padding: 20px;
    background-color: #ecdfc9;
    /* label {
        color: white;
    } */
    a {
        color: ${theme.palette.common.primary};
    }

    .css-f7ku2w-FieldLabel {
        color: #1f2747 !important;
    }
`;

const FieldWrapper = styled(Box)`
    padding-bottom: 15px;
    z-index: 100000;

    .editselect__control {
        border-radius: 4px;
        border: none;
    }
`;

export const HeaderBox = styled(Box)`
    display: grid;
    grid-template-columns: auto auto;
    margin-bottom: 20px;
`;
