import React from "react";
import { observer } from "mobx-react-lite";
import { Box, ICommand, RelayCommand } from "Application";
import { FullWidthCentFlexContainer } from "Components/FullWidthCentFlexCont";
import { P } from "Components/Primitives/TextElements/TextElements";
import { ShorelineButton } from "Components/ShorelineButton";
import { useNavigate } from "react-router";
import styled from "@emotion/styled";

export const FormSentView: React.FC = observer(() => {
    const navigate = useNavigate();

    const navToHomePage: ICommand = new RelayCommand(() => {
        navigate("/");
    });

    return (
        <>
            <FullWidthCentFlexContainer height="100vh">
                <CenteredBox>
                    <P color="primary" pb="30px" style={{ fontSize: "30px", lineHeight: "1.5" }}>
                        Thank you for registering your interest.
                    </P>
                    <P color="primary" pb="35px" style={{ lineHeight: "1.5" }}>
                        Our sales team will be in touch soon.
                    </P>

                    {/* <BoxOutline>
                        <P color="primary" pb="20px">
                            Our Sales and Marketing Suite will be closed from 5pm on 23rd December and will re-open from 10am on 4th January. All messages and requests received
                            during this time will be addressed promptly upon our return.
                        </P>
                        <P color="primary" pb="20px">
                            Wishing you a Merry Christmas and Happy New Year from Shoreline Crescent!
                        </P>
                    </BoxOutline> */}
                    <ShorelineButton displayName="BACK TO HOMEPAGE" command={navToHomePage} style={{ width: "auto" }} />
                </CenteredBox>
            </FullWidthCentFlexContainer>
        </>
    );
});

const CenteredBox = styled(Box)`
    text-align: center;
`;

export const BoxOutline = styled(Box)`
    border: 1px solid #1f2747;
    margin: 20px 20px 30px 20px;
    padding: 20px;
`;
