import { action, computed, makeObservable, observable, runInAction } from "mobx";
import { CoreStoreInstance, FieldType, ViewModelBase } from "@shoothill/core";
import { APIClient, ICommand, ICommandAsync, IKeyState, RelayCommand, RelayCommandAsync } from "Application";
import { ConfirmPasswordModel, ConfirmPaswordValidator } from "./ConfirmPasswordModel";
import { ConfirmPasswordEndpoint } from "./Endpoints/ConfirmPasswordEndpoint";
import { AppUrls } from "AppUrls";

export class ConfirmPasswordViewModel extends ViewModelBase<ConfirmPasswordModel> {
    public apiClient = new APIClient();
    public errorMessage: string = "";

    public redirectToCallback: (() => void) | undefined = undefined;

    constructor(redirectTo: () => void) {
        super(new ConfirmPasswordModel());
        this.redirectToCallback = redirectTo;
        this.setValidator(new ConfirmPaswordValidator());
        makeObservable(this, { errorMessage: observable, setErrorMessage: action, clear: action, getErrorMessage: computed });
    }

    public clear() {
        this.errorMessage = "";
        this.model.clear();
    }

    public setErrorMessage = (value: string) => {
        this.errorMessage = value;
    };

    public updatePasswordCommand: ICommandAsync = new RelayCommandAsync(async (value: string, keyState: IKeyState) => {
        await this.updateField("password", value, keyState);
    });

    public updateConfirmPasswordCommand: ICommandAsync = new RelayCommandAsync(async (value: string, keyState: IKeyState) => {
        await this.updateField("confirmPassword", value, keyState);
    });

    private async updateField(fieldName: keyof FieldType<ConfirmPasswordModel>, value: any, keyState?: IKeyState) {
        this.setValue(fieldName, value);
    }

    public updateToken = async (token: string) => {
        await this.updateField("token", token);
    };

    public get getErrorMessage() {
        return this.errorMessage;
    }

    public submitPasswords: ICommandAsync = new RelayCommandAsync(async (value: string, keyState: IKeyState) => {
        CoreStoreInstance.HideInfoBar();
        if (this.isModelValid() === true && this.apiClient.IsBusy === false) {
            this.errorMessage = "";
            // then we are good to register
            const register = new ConfirmPasswordEndpoint();
            await this.apiClient.sendAsync(register, this.model);

            runInAction(() => {
                if (this.apiClient.IsRequestSuccessful === true) {
                    // TODO CMR this.PanelView = "ResetRequestSent";
                    let response = this.apiClient.Response<boolean>();

                    if (this.model.isForgotPassword === false) {
                        // we should be logged in so need to log the user in and go to their page.
                        // TODO JIM!
                    } else {
                        this.history.push(AppUrls.Client.Home);
                    }

                    CoreStoreInstance.HideInfoBar();
                } else {
                    if (this.apiClient.HaveValidationMessage === true) {
                        this.errorMessage = this.apiClient.ValidationMessage;
                    } else {
                        this.errorMessage = "There has been a problem storing the password.  Please try again later.  If problems persist, contact Shorline.";
                    }
                }
            });
        }
    });
}
