import { Logger } from "index";
import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { SignInModel } from "../SignInModel";

export class PostLoginRequest {
    public emailAddress: string = "";
    public password: string = "";
    public rememberMe: boolean = false;

    constructor() {}
}

export class PostLoginResponse {
    public jwt: string = "";
    constructor() {}
}

export class SignInEndpoint extends Endpoint<PostLoginRequest, PostLoginResponse> {
    constructor() {
        super();
        this.verb(Http.Patch);
        this.path("/api/Account/Login");
    }

    public async HandleRequestAsync(model: SignInModel): Promise<any> {
        let request = new PostLoginRequest();
        request.emailAddress = model.emailAddress;
        request.password = model.password;
        request.rememberMe = false;
        return request;
    }

    public async HandleResponseAsync(response: PostLoginResponse): Promise<any> {
        Logger.logInformation("Response", response);

        //Return your model
        return response;
    }
}
