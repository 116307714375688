import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { Box, ICommand, Label, RelayCommand } from "Application";

import Polygon1 from "Assets/RightBlueArrow.svg";
import Polygon2 from "Assets/LeftBlueArrow.svg";

import { FloorplanImageData } from "./TempApartmentFloorplanData";
import { NextButton, PrevButton } from "Components/NextPrevButton";
import { GradLabel } from "Components/GradHeadings";
import { HorizontalGradLineDarkLightDark } from "Components/HorizontalGradLineDarkLightDark";
import { ShorelineButton } from "Components/ShorelineButton";
import { motion } from "framer-motion";
import { styled } from "@fluentui/react";
import { PropertyMediaModel } from "Views/NewHomes/Subviews/PropertyMediaModel";

interface ICarouselImage {
    imgUrl?: string;
    model: PropertyMediaModel[];
}

export const CarouselForImagesDimensionsModal: React.FC<ICarouselImage> = observer((props) => {
    const [FloorplanArray, setPicArray] = useState(props.model);

    const [IndexOne, setIndexOne] = useState(0);
    const [IndexTwo, setIndexTwo] = useState(1);
    const [IndexThree, setIndexThree] = useState(FloorplanArray.length - 1);

    const [iscarouselModalOpen, setIsCarouselModalOpen] = useState(false);

    const doSomething: ICommand = new RelayCommand((value: string) => {});

    function NextImage() {
        setIndexOne(IndexOne + 1 == FloorplanArray.length ? 0 : IndexOne + 1);
        setIndexTwo(IndexTwo + 1 == FloorplanArray.length ? 0 : IndexTwo + 1);
        setIndexThree(IndexThree + 1 == FloorplanArray.length ? 0 : IndexThree + 1);
    }

    function PreviousImage() {
        setIndexOne(IndexOne == 0 ? FloorplanArray.length - 1 : IndexOne - 1);
        setIndexTwo(IndexTwo == 0 ? FloorplanArray.length - 1 : IndexTwo - 1);
        setIndexThree(IndexThree == 0 ? FloorplanArray.length - 1 : IndexThree - 1);
    }

    return (
        <>
            <Box grid dc={"repeat(1, 1fr)"} minHeight={"329px"} pb={"30px"}>
                {/* Slide 1 */}
                <Box style={{ alignSelf: "start" }}>
                    <Box height={"300px"}>
                        {/* <motion.div onClick={() => setIsCarouselModalOpen(!iscarouselModalOpen)} animate={iscarouselModalOpen ? { scale: 2 } : { scale: 1 }}> */}
                        <img src={FloorplanArray[IndexOne] && FloorplanArray[IndexOne].imageUrl} width="100%" height={"100%"} style={{ objectFit: "cover" }}></img>
                        {/* </motion.div> */}
                    </Box>
                </Box>
                {/* Slide 2 */}
                <Box display={"flex"} style={{ justifyContent: "center" }}>
                    {/* <Box height={"295px"}>
                        <img src={FloorplanArray[IndexTwo] && FloorplanArray[IndexTwo].imageUrl} width="100%" height={"100%"} style={{ objectFit: "cover" }}></img>
                    </Box> */}
                    <Box display={"flex"} pt="10px">
                        <PrevButton pr={"4px"} mr={"15px"} onClick={NextImage}>
                            <img src={Polygon2}></img>
                        </PrevButton>
                        <NextButton pl={"4px"} onClick={PreviousImage}>
                            <img src={Polygon1}></img>
                        </NextButton>
                    </Box>
                </Box>
            </Box>
        </>
    );
});
