import { action, computed, makeObservable, observable, observe, runInAction } from "mobx";
import { ViewModelBase } from "@shoothill/core";
import { APIClient, ICommand, IKeyState, RelayCommand } from "Application";
import { NewHomesModel, NewHomesModelValidator } from "./NewHomesModel";

import { PropertyListItemViewModel } from "./Subviews/PropertyListItemViewModel";
import { PropertyListItemModel } from "./Subviews/PropertyListItemModel";
import { Logger } from "index";
import { GETPropertiesByDevelopmentIdEndpoint } from "./Endpoints/GetPropertiesByDevelopmentIdEndpoint";
import { PropertyWithRelatedResponse } from "./Endpoints/PropertyResponseModel";
import { Globals } from "../../Globals";
import { container } from "tsyringe";
import { DomainStore } from "Stores/Domain/DomainStore";
import { brochureTypes } from "Views/BrochureAddendums/DownloadViewModel";

export enum BuildingType {
    WestWing = "WestWing",
    EastWing = "EastWing",
    Townhouse = "TownHouse",
    Duplex = "Duplex",
    Penthouse = "Penthouse",
    NoneSelected = "None",
}

export enum loadingStatus {
    Loading = "Loading",
    YesDisplay = "YesDisplay",
    NoPropertiesAvailable = "NoPropertiesAvailable",
}
export class NewHomesViewModel extends ViewModelBase<NewHomesModel> {
    public apiClient = new APIClient();
    public domainStore = container.resolve(DomainStore);

    public propertyListItemViewModels = observable<PropertyListItemViewModel>([]);

    public isLoading: loadingStatus = loadingStatus.Loading;

    public hasLoaded: boolean = false;

    public numberOfColumns: number = 0;
    public numberOfColumnsFr: string = "1fr";

    public oneColumn: string = "1fr";
    public twoColumns: string = "1fr 1fr";
    public threeColumns: string = "1fr 1fr 1fr";
    public fourColumns: string = "1fr 1fr 1fr 1fr";
    public fiveColumns: string = "1fr 1fr 1fr 1fr 1fr";

    public areThereAnyEastWingApartments: loadingStatus = loadingStatus.Loading;
    public areThereAnyWestWingApartments: loadingStatus = loadingStatus.Loading;
    public areThereAnyTownHouses: loadingStatus = loadingStatus.Loading;
    public areThereAnyDuplexes: loadingStatus = loadingStatus.Loading;
    public areThereAnyPenthouses: loadingStatus = loadingStatus.Loading;

    constructor(model = new NewHomesModel()) {
        super(model);
        this.setValidator(new NewHomesModelValidator());
        makeObservable(this, {
            sortByPrice: computed,
            numberOfColumns: observable,
            numberOfColumnsFr: observable,
            isLoading: observable,
            hasLoaded: observable,
            areThereAnyTownHouses: observable,
            areThereAnyEastWingApartments: observable,
            areThereAnyWestWingApartments: observable,
            areThereAnyDuplexes: observable,
            areThereAnyPenthouses: observable,
            loadPropertiesForHardCodedDevelopmentId: action,
            clear: action,
        });
    }

    public clear() {
        this.model.clear();
        this.propertyListItemViewModels.clear();
    }

    public dispose = (): void => {
        this.propertyModelsObserverDispose();
    };

    public loadPropertiesForHardCodedDevelopmentId = async () => {
        if (this.apiClient.IsBusy === false) {
            const endpoint: GETPropertiesByDevelopmentIdEndpoint = new GETPropertiesByDevelopmentIdEndpoint(Globals.SHORELINEDEVELOPMENTID.toLocaleLowerCase());

            await this.apiClient.sendAsync(endpoint, this.model);

            runInAction(() => {
                if (this.apiClient.IsRequestSuccessful === true) {
                    let response: PropertyWithRelatedResponse[] = this.apiClient.Response<PropertyWithRelatedResponse[]>();

                    runInAction(() => {
                        for (const property of response) {
                            let listItem = new PropertyListItemModel();
                            listItem.fromPropertyWithRelated(property);
                            this.model.properties.push(listItem);
                        }
                    });
                    this.hasLoaded = true;

                    // Only turn on buttons if house type/wing is available

                    this.propertyListItemViewModels.map((property) => {
                        if (property.getValue("buildingType") == "Apartment" && property.getValue("actualStatus") !== 7 && property.getValue("block") == "East") {
                            this.areThereAnyEastWingApartments = loadingStatus.YesDisplay;
                        }

                        if (property.getValue("buildingType") == "Apartment" && property.getValue("actualStatus") !== 7 && property.getValue("block") == "West") {
                            this.areThereAnyWestWingApartments = loadingStatus.YesDisplay;
                        }

                        if (property.getValue("buildingType") == "Town House" && property.getValue("actualStatus") !== 7) {
                            this.areThereAnyTownHouses = loadingStatus.YesDisplay;
                        }

                        if (property.getValue("buildingType") == "Duplex" && property.getValue("actualStatus") !== 7) {
                            this.areThereAnyDuplexes = loadingStatus.YesDisplay;
                        }

                        if (property.getValue("buildingType") == "Penthouse" && property.getValue("actualStatus") !== 7) {
                            this.areThereAnyPenthouses = loadingStatus.YesDisplay;
                        }
                    });

                    if (this.areThereAnyEastWingApartments == loadingStatus.YesDisplay) {
                        {
                            this.numberOfColumns += 1;
                        }
                    }

                    if (this.areThereAnyWestWingApartments == loadingStatus.YesDisplay) {
                        {
                            this.numberOfColumns += 1;
                        }
                    }

                    if (this.areThereAnyTownHouses == loadingStatus.YesDisplay) {
                        {
                            this.numberOfColumns += 1;
                        }
                    }

                    if (this.areThereAnyDuplexes == loadingStatus.YesDisplay) {
                        {
                            this.numberOfColumns += 1;
                        }
                    }

                    if (this.areThereAnyPenthouses == loadingStatus.YesDisplay) {
                        {
                            this.numberOfColumns += 1;
                        }
                    }

                    switch (this.numberOfColumns) {
                        case 1:
                            this.numberOfColumnsFr = this.oneColumn;
                            break;
                        case 2:
                            this.numberOfColumnsFr = this.twoColumns;
                            break;
                        case 3:
                            this.numberOfColumnsFr = this.threeColumns;
                            break;
                        case 4:
                            this.numberOfColumnsFr = this.fourColumns;
                            break;
                        case 5:
                            this.numberOfColumnsFr = this.fiveColumns;
                            break;
                        default:
                        // code block
                    }

                    if (this.numberOfColumns > 0) {
                        this.isLoading = loadingStatus.YesDisplay;
                    } else if ((this.numberOfColumns = 0)) {
                        this.isLoading == loadingStatus.NoPropertiesAvailable;
                    }
                } else {
                }
            });
        }
    };

    private propertyModelsObserverDispose = observe(this.model.properties, (childModelChanges: any) => {
        Logger.logDebug(`PropertyListViewModel.childModelsObserverDispose`);
        for (const addedChildModel of childModelChanges.added) {
            this.propertyListItemViewModels.push(new PropertyListItemViewModel(addedChildModel));
        }

        for (const removedChildModel of childModelChanges.removed) {
            const childViewModelToRemove = this.propertyListItemViewModels.find((vm) => vm.model.KEY === removedChildModel.KEY);

            if (childViewModelToRemove) {
                this.propertyListItemViewModels.remove(childViewModelToRemove);
            }
        }
    });

    public addPropertyCommand = new RelayCommand(() => {
        this.model.properties.push(new PropertyListItemModel());
    });

    public doSomething: ICommand = new RelayCommand((value: string) => {
        console.log("clicked");
    });

    public sendBrochureForm: ICommand = new RelayCommand((value: brochureTypes) => {
        this.domainStore.brochureType = value;
        this.domainStore.openBrochureModal();
    });

    public downloadBrochure: ICommand = new RelayCommand((value: string) => {
        switch (value) {
            case "duplex":
                this.openInNewTab(process.env.PUBLIC_URL + "/pdfBrochures/2023-09-08 Shoreline Crescent_Duplexes_240x330mm_v10.pdf");
                break;
            case "EastWing":
                this.openInNewTab(process.env.PUBLIC_URL + "/pdfBrochures/2023-09-08 Shoreline Crescent_East Apartments_240x330mm_v9.pdf");
                break;
            case "penthouse":
                this.openInNewTab(process.env.PUBLIC_URL + "/pdfBrochures/2023-09-08 Shoreline Crescent_Penthouses_240x330mm_v11.pdf");

                break;
            case "TownHouse":
                this.openInNewTab(process.env.PUBLIC_URL + "/pdfBrochures/2023-09-08 Shoreline Crescent_Townhouses_240x330mm_v10.pdf");

                break;
            case "Duplex":
                this.openInNewTab(process.env.PUBLIC_URL + "/pdfBrochures/2023-09-18 Shoreline Crescent_West Apartments_240x330mm_v11.pdf");

                break;
            default:
        }
    });

    openInNewTab = (url: string) => {
        window.open(url, "_blank", "noreferrer");
    };

    public get sortByPrice() {
        let tempArray;

        tempArray = this.propertyListItemViewModels.slice().sort(function (a, b) {
            return a.model.basePurchasePrice - b.model.basePurchasePrice;
        });
        console.log(tempArray);
        return tempArray;
    }

    // Filtering by buildingtype

    public get getEastWing() {
        if (this.model.eastWingViewModels.length > 0) {
            return this.model.eastWingViewModels;
        } else {
            this.propertyListItemViewModels.slice().map((property) => {
                if (property.getValue("buildingType") == "Apartment" || property.getValue("buildingType") == "Flat/apartment") {
                    if (property.getStatus(property.getValue("actualStatus")) !== "unreleased") {
                        this.model.eastWingViewModels.push(property);
                    }
                }
            });

            return this.model.eastWingViewModels;
        }
    }

    public get getWestWing() {
        if (this.model.westWingViewModels.length > 0) {
            return this.model.westWingViewModels;
        } else {
            this.propertyListItemViewModels.slice().map((property) => {
                if (property.getValue("buildingType") == "Apartment" || (property.getValue("buildingType") == "Flat/apartment" && property.getValue("block") == "West")) {
                    if (property.getStatus(property.getValue("actualStatus")) !== "unreleased") {
                        this.model.westWingViewModels.push(property);
                    }
                }
            });

            return this.model.westWingViewModels;
        }
    }

    public get getTownhouses() {
        if (this.model.townHouseViewModels.length > 0) {
            return this.model.townHouseViewModels;
        } else {
            this.propertyListItemViewModels.slice().map((property) => {
                if (property.getValue("buildingType") == "Town House") {
                    if (property.getStatus(property.getValue("actualStatus")) !== "unreleased") {
                        this.model.townHouseViewModels.push(property);
                    }
                }
            });

            return this.model.townHouseViewModels;
        }
    }

    public get getDuplexes() {
        if (this.model.duplexViewModels.length > 0) {
            return this.model.duplexViewModels;
        } else {
            this.propertyListItemViewModels.slice().map((property) => {
                if (property.getValue("buildingType") == "Duplex") {
                    if (property.getStatus(property.getValue("actualStatus")) !== "unreleased") {
                        this.model.duplexViewModels.push(property);
                    }
                }
            });

            return this.model.duplexViewModels;
        }
    }

    public get getPenthouses() {
        if (this.model.penthouseViewModels.length > 0) {
            return this.model.penthouseViewModels;
        } else {
            this.propertyListItemViewModels.slice().map((property) => {
                if (property.getValue("buildingType") == "Penthouse") {
                    if (property.getStatus(property.getValue("actualStatus")) !== "unreleased") {
                        this.model.penthouseViewModels.push(property);
                    }
                }
            });

            return this.model.penthouseViewModels;
        }
    }
}
