import { makeObservable, observable, runInAction } from "mobx";
import { CoreStoreInstance, ViewModelBase } from "@shoothill/core";
import { APIClient, ICommand, IKeyState, RelayCommand } from "Application";
import { SignInModel, SignInModelValidator } from "./SignInModel";
import { PostLoginResponse, SignInEndpoint } from "./Endpoints/SignIn";
import { AccountStore } from "Stores/Domain";
import { container } from "tsyringe";

export class SignInViewModel extends ViewModelBase<SignInModel> {
    public apiClient = new APIClient();
    accountStore = container.resolve(AccountStore);

    onCompleteCallback: () => void;

    constructor(onComplete: () => void) {
        super(new SignInModel());
        this.setValidator(new SignInModelValidator());
        makeObservable(this, {});

        this.onCompleteCallback = onComplete;
    }

    public updateEmail: ICommand = new RelayCommand((value: string) => {
        this.model.emailAddress = value;
    });

    public updatePassword: ICommand = new RelayCommand((value: string) => {
        this.model.password = value;
    });

    public signIn: ICommand = new RelayCommand(async () => {
        if (this.isModelValid()) {
            const login = new SignInEndpoint();
            await this.apiClient.sendAsync(login, this.model);

            if (this.apiClient.IsRequestSuccessful === true) {
                // TODO CMR this.PanelView = "ResetRequestSent";
                let response = this.apiClient.Response<PostLoginResponse>();
                this.accountStore.getLoginState(response.jwt);

                this.onCompleteCallback();
            } else {
                this.setValue("password", "");
                this.setError("emailAddress", "The email address or password is incorrect.  Please try again");
            }
        }
        CoreStoreInstance.HideInfoBar();
    });
}
