import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Box, ICommand, Label, RelayCommand } from "Application";

import LeftArrow from "Assets/LeftBlueArrow.svg";
import RightArrow from "Assets/RightBlueArrow.svg";

import Placeholder from "Assets/shorelineCrescentPlaceholder.svg";

import { NextButton, PrevButton } from "Components/NextPrevButton";
import { motion, useAnimationControls } from "framer-motion";
import { styled } from "@fluentui/react";
import { PropertyMediaModel } from "Views/NewHomes/Subviews/PropertyMediaModel";
import { ImageModal } from "Components/ImageModal/ImageModal";

interface ICarouselImage {
    imgUrl?: string;
    model: PropertyMediaModel[];
    isMobile: boolean;
}

export const CarouselForImagesDimensions: React.FC<ICarouselImage> = observer((props) => {
    const [FloorplanArray, setPicArray] = useState(
        props.model.map((pictures) => {
            return { imageUrl: pictures.imageUrl, originalImageUrl: pictures.originalImageUrl };
        }),
    );

    const [IndexOne, setIndexOne] = useState(0);
    const [IndexTwo, setIndexTwo] = useState(1);
    const [IndexThree, setIndexThree] = useState(2);

    const [iscarouselModalOpen, setIsCarouselModalOpen] = useState(false);

    const onCloseModal = () => setIsCarouselModalOpen(false);

    const doSomething: ICommand = new RelayCommand((value: string) => {});

    function NextImage() {
        setIndexOne(IndexOne + 1 == FloorplanArray.length ? 0 : IndexOne + 1);
        setIndexTwo(IndexTwo + 1 == FloorplanArray.length ? 0 : IndexTwo + 1);
        setIndexThree(IndexThree + 1 == FloorplanArray.length ? 0 : IndexThree + 1);
    }

    function PreviousImage() {
        setIndexOne(IndexOne == 0 ? FloorplanArray.length - 1 : IndexOne - 1);
        setIndexTwo(IndexTwo == 0 ? FloorplanArray.length - 1 : IndexTwo - 1);
        setIndexThree(IndexThree == 0 ? FloorplanArray.length - 1 : IndexThree - 1);
    }

    const controls = useAnimationControls();

    useEffect(() => {
        // controls.set({ opacity: 0 });
        // controls.start({ opacity: 1 });
    }, [IndexOne]);

    if (FloorplanArray.length == 2) {
        setPicArray([...FloorplanArray, { imageUrl: Placeholder, originalImageUrl: Placeholder }]);
        console.log(FloorplanArray);
    }

    if (FloorplanArray.length == 1) {
        setPicArray([...FloorplanArray, { imageUrl: Placeholder, originalImageUrl: Placeholder }, { imageUrl: Placeholder, originalImageUrl: Placeholder }]);
        console.log(FloorplanArray);
    }

    return (
        <>
            <ImageModal
                NextButton={NextImage}
                PrevButton={PreviousImage}
                onCloseModal={onCloseModal}
                open={iscarouselModalOpen}
                imgUrl={FloorplanArray[IndexOne].originalImageUrl}
                imgUrlSmaller={FloorplanArray[IndexOne].imageUrl}
            ></ImageModal>
            <Box grid dc={"1fr 50% 1fr"} pb={"40px"} columnGap="4px" height={"289px"} style={{ position: "relative" }}>
                {/* Slide 3 */}
                <Box hidden={props.isMobile} style={{ alignSelf: "start" }} onClick={PreviousImage}>
                    <Box height={"289px"}>
                        <img
                            src={FloorplanArray[IndexThree] && FloorplanArray[IndexThree].imageUrl}
                            width="100%"
                            height={"100%"}
                            style={{ objectFit: "cover", opacity: "0.3" }}
                        ></img>
                    </Box>
                </Box>{" "}
                {/* Slide 1 */}
                <Box style={{ alignSelf: "start" }}>
                    <Box height={"289px"}>
                        <motion.div
                            onClick={() => setIsCarouselModalOpen(!iscarouselModalOpen)}
                            animate={iscarouselModalOpen ? { scale: 1.2 } : { scale: 1 }}
                            style={{ height: "100%", overflow: FloorplanArray[IndexOne].originalImageUrl?.slice(-3) == "pdf" ? "hidden" : "" }}
                        >
                            {FloorplanArray[IndexOne].originalImageUrl?.slice(-3) == "pdf" ? (
                                <motion.img animate={controls} src={FloorplanArray[IndexOne] && FloorplanArray[IndexOne].imageUrl} width="100%" height={"700px"}></motion.img>
                            ) : (
                                <motion.img
                                    animate={controls}
                                    src={FloorplanArray[IndexOne] && FloorplanArray[IndexOne].imageUrl}
                                    width="100%"
                                    height={"100%"}
                                    style={{ objectFit: "cover", aspectRatio: "917 / 1257" }}
                                ></motion.img>
                            )}
                        </motion.div>
                    </Box>
                </Box>
                {/* Slide 2 */}
                <Box display={"flex"} flexDirection={"column"} style={{ justifyContent: "space-between" }}>
                    <Box hidden={props.isMobile} height={"289px"} onClick={NextImage}>
                        <img src={FloorplanArray[IndexTwo] && FloorplanArray[IndexTwo].imageUrl} width="100%" height={"100%"} style={{ objectFit: "cover", opacity: "0.3" }}></img>
                    </Box>
                    <Box display={"flex"} justifyContent={props.isMobile ? "center" : "left"}></Box>
                </Box>
                <PrevButton pr={"4px"} mr={"15px"} onClick={PreviousImage} style={{ position: "absolute", top: 120, left: 20 }}>
                    <img src={LeftArrow}></img>
                </PrevButton>
                <NextButton pl={"4px"} onClick={NextImage} style={{ position: "absolute", top: 120, right: 20 }}>
                    <img src={RightArrow}></img>
                </NextButton>
            </Box>
        </>
    );
});
