export function RoundUp(numberToConvert: string): string {
    let retVal: string = "";

    let stringToNumber = Number(numberToConvert);

    if (Number.isInteger(stringToNumber) == true) {
        retVal = numberToConvert;
    } else {
        let tempNumber = stringToNumber.toFixed(1);

        retVal = String(tempNumber);
    }

    return retVal;
}
