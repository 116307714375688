import styled from "@emotion/styled";
import { theme } from "Application";
import { Box } from "Components";
import { P } from "Components/Primitives/TextElements/TextElements";

export const ContactFormBox = styled(Box)`
    align-items: start;
    color: #1f2747;
    padding-bottom: 40px;

    .css-f7ku2w-FieldLabel {
        color: #1f2747 !important;
    }

    .fullWidth {
        grid-column: 1 / 3;
    }
    .underline {
        text-decoration: underline;
    }
    .privacy-policy-link {
        text-decoration: underline;
        color: black;
    }
`;

export const OurDetailsBox = styled(Box)`
    background-color: ${theme.palette.common.tertiary};

    border-radius: 5px;
`;

export const OurDetailsBoxInner = styled(Box)`
    padding: 30px 20px 30px 20px;

    a {
        text-decoration: none;
        color: white;
    }
`;

export const ContactDetailsBox = styled(Box)`
    display: flex;
    justify-content: center;
    text-align: center;

    a {
        color: #1f2747;
    }
`;

export const MarketP = styled(P)`
    letter-spacing: 1.2px;
`;
