import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Box } from "Application";
import AnimateHeight from "react-animate-height";
import SignInView from "./SignIn/SignInView";
import RegisterView from "./SignUp/RegisterView";
import { AccountStore } from "../../Stores/Domain";
import { container } from "tsyringe";
import { GlobalHistory } from "../../index";
import { LoginHomeViewModel } from "./LoginHomeViewModel";
import { ForgotPasswordView } from "./SignIn/ForgotPassword/ForgotPasswordView";
import { Route, Routes } from "react-router-dom";
import styled from "@emotion/styled";

export const Wrapper = styled.form`
    root {
        height: 100vh;
        width: 100vh;
    }
    content {
        padding: pxToRem(20);
        margin: auto;
    }
    leftContent {
        margin: auto;
        max-width: pxToRem(325);
    }
    maxHeight {
        height: 100vh;
    }
    tab {
        border: 1px solid #dcdcdc !important;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
    }
    selected {
        background-color: white;
        color: black;
    }
    notSelected {
        background-color: #7f7f7f;
        color: white;
    }
`;

/**
 * Login page view.
 */

const LoginHomeView: React.FunctionComponent = observer(() => {
    // #region Code Behind
    const accountStore = container.resolve(AccountStore);
    const [viewModel] = useState(() => new LoginHomeViewModel());

    useEffect(() => {
        if (accountStore.IsLoggedIn) {
            GlobalHistory.push("/");
        }
    }, []);

    const doSubmit = (e: any) => {
        e.preventDefault();
    };

    // #endregion Code Behind

    const renderForm = () => (
        <Wrapper className={"root"} onSubmit={doSubmit}>
            <Box grid className={"maxHeight"} columnGap={30} rowGap={20} mc={"1fr"} tc={"1fr"} dc={"1fr"}>
                <Box grid rowGap={"20px"} dc={"1fr"} className={"leftContent"} minHeight={"600px"} alignContent={"flex-start"}>
                    <AnimateHeight id="login-panel" duration={500} height={"auto"}>
                        <Routes>
                            <Route path="login" element={<SignInView loginHomeViewModel={viewModel} />} />
                            <Route path="register/*" element={<RegisterView loginHomeViewModel={viewModel} />} />
                            {/*           <Route path="account/confirmemail/:token/*" element={<ConfirmEmailView loginHomeViewModel={viewModel} />} /> */}
                            <Route path="newuser/:token/*" element={<RegisterView loginHomeViewModel={viewModel} />} />
                            <Route path="forgotpassword/*" element={<ForgotPasswordView loginHomeViewModel={viewModel} />} />
                        </Routes>
                    </AnimateHeight>
                </Box>
            </Box>
        </Wrapper>
    );

    return <React.Fragment>{renderForm()}</React.Fragment>;
});
export default LoginHomeView;
