import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { observer } from "mobx-react-lite";
import { Box, ICommand, RelayCommand } from "Application";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
// import { Modal } from "@fluentui/react";
import styled from "@emotion/styled";
import { NextButton, PrevButton } from "Components/NextPrevButton";
import CloseButton from "Assets/CloseButtonMk2.png";
import ReactImageMagnify from "react-image-magnify";
import LeftArrow from "Assets/LeftSandyArrow.svg";
import RightArrow from "Assets/RightSandyArrow.svg";
import { Document, Page, pdfjs } from "react-pdf";
import type { PDFDocumentProxy } from "pdfjs-dist";
import { P } from "Components/Primitives/TextElements/TextElements";
import { ShorelineButton } from "Components/ShorelineButton";

// import CloseButton from "Assets/CloseButton.svg";

interface IModalProps {
    imgUrl?: string | undefined;
    imgUrlSmaller?: string | undefined;
    NextButton?: () => void;
    PrevButton?: () => void;
    onCloseModal?: any;
    open: boolean;
}

export const ImageModal: React.FC<IModalProps> = observer((props) => {
    const [isZoomedIn, setIsZoomedIn] = useState(false);

    const imgElement = React.useRef<HTMLImageElement>(null);

    const [imageWidth, setImageWidth] = useState(0);
    const [imageHeight, setImageHeight] = useState(0);

    const doSomething: ICommand = new RelayCommand((value: string) => {});

    // PDF view stuff

    const [numPages, setNumPages] = useState<number>();
    const [pageNumber, setPageNumber] = useState<number>(1);

    pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

    function onDocumentLoadSuccess({ numPages: nextNumPages }: PDFDocumentProxy): void {
        setNumPages(nextNumPages);
    }

    const options = {
        cMapUrl: "/cmaps/",
        standardFontDataUrl: "/standard_fonts/",
    };

    // ******************

    useEffect(() => {
        setIsZoomedIn(false);
    }, [props.onCloseModal]);

    return (
        <>
            <ModalWrapper>
                <Modal
                    styles={{
                        modalContainer: { backgroundColor: "rgba(0, 0, 0, 0.7)" },
                        modal: { maxWidth: "700px", background: "none" },
                        closeButton: { right: "19px", top: "19px" },
                    }}
                    open={props.open}
                    onClose={props.onCloseModal}
                    center
                    closeIcon={<img src={CloseButton} height="40px" />}
                    animationDuration={0}
                >
                    {isZoomedIn == false && (
                        <>
                            {props.imgUrl?.slice(-3) == "pdf" ? (
                                // <div>
                                //     <Document file={props.imgUrl} onLoadSuccess={onDocumentLoadSuccess} options={options}>
                                //         <Page pageNumber={pageNumber} />
                                //     </Document>
                                //     <p>
                                //         Page {pageNumber} of {numPages}
                                //     </p>
                                // </div>

                                <Box style={{ backgroundColor: "white", lineHeight: 0 }}>
                                    <img
                                        src={props.imgUrlSmaller}
                                        // width={imgElement.current != null ? imgElement.current.clientHeight / 1.14 : "100%"}
                                        height="100%"
                                        width="100%"
                                        ref={imgElement}
                                        onLoad={() => {
                                            imgElement.current != null && setImageHeight(imgElement.current.clientHeight);
                                            imgElement.current != null && setImageWidth(imgElement.current.clientWidth);
                                        }}
                                        style={{ maxHeight: "70vh", aspectRatio: "917 / 1257" }}
                                        // onClick={() => setIsZoomedIn(true)}
                                    ></img>
                                    <Box style={{ position: "absolute", top: 40, left: 40 }}>
                                        <a href={props.imgUrl} target="_blank">
                                            <ShorelineButton command={doSomething} displayName="DOWNLOAD PDF" />
                                        </a>
                                    </Box>
                                </Box>
                            ) : (
                                <img
                                    src={props.imgUrl}
                                    width="100%"
                                    ref={imgElement}
                                    onLoad={() => {
                                        imgElement.current != null && setImageHeight(imgElement.current.clientHeight);
                                        imgElement.current != null && setImageWidth(imgElement.current.clientWidth);
                                    }}
                                    style={{ cursor: "zoom-in", maxHeight: "70vh" }}
                                    onClick={() => setIsZoomedIn(true)}
                                ></img>
                            )}
                        </>
                    )}
                    {props.imgUrl && isZoomedIn == true && (
                        <div onClick={() => setIsZoomedIn(false)} style={{ cursor: "zoom-out" }}>
                            <ReactImageMagnify
                                {...{
                                    smallImage: {
                                        alt: "",
                                        isFluidWidth: true,
                                        src: props.imgUrl,
                                        sizes: "(max-height: 70vh)",
                                    },
                                    largeImage: {
                                        src: props.imgUrl,
                                        width: imageWidth * 3,
                                        height: imageHeight * 3,
                                    },
                                    enlargedImagePosition: "over",
                                }}
                            />
                        </div>
                    )}
                    <Box display={"flex"} justifyContent="center" pt="30px">
                        <PrevButton pr={"4px"} mr={"15px"} onClick={props.PrevButton}>
                            <img src={LeftArrow}></img>
                        </PrevButton>
                        <NextButton pl={"4px"} onClick={props.NextButton}>
                            <img src={RightArrow}></img>
                        </NextButton>
                    </Box>
                    {/* <button onClick={props.onCloseModal}>CloseModal</button> */}
                </Modal>
            </ModalWrapper>
        </>
    );
});

const ModalWrapper = styled.div`
    .customModal {
        background: #b2dbbf;
        max-width: 500px;
        width: 100%;
    }
    .customOverlay {
        background: rgba(36, 123, 160, 0.7);
    }
`;
