import { Logger } from "index";
import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { UserFavourite } from "Views/BuyerLoginDashboard/UserFavourite";
import { GetUserFavouritesResponse } from "./GetUserFavourites";

export class UserFavouriteRequest {
    public userId: string = "";
    public propertyId: string = "";
    public isDeleted: boolean = false;

    public constructor() {}
}

export class UpsertUserFavouriteEndpoint extends Endpoint<UserFavouriteRequest, GetUserFavouritesResponse> {
    constructor() {
        super();
        this.verb(Http.Post);
        this.path("/api/user/userfavouriteUpsert");
    }

    public async HandleRequestAsync(request: UserFavouriteRequest): Promise<any> {
        return request;
    }

    public async HandleResponseAsync(response: GetUserFavouritesResponse): Promise<UserFavourite[]> {
        Logger.logInformation("Response", response);
        return response.favourites;
    }
}
