/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useEffect, useState } from "react";
import { Link, Link as RouterLink, NavLink } from "react-router-dom";
import { AppUrls } from "AppUrls";
import { Main } from "./FooterView.styles";
import { Box, CheckBox, Input, Show } from "Components";
import useMediaQuery from "../../Application/Hooks/useMediaQuery";
import { ICommand, Label, RelayCommand, theme } from "Application";
import { InnerWrapper } from "Components/InnerWrapper";

import { FullWidthCentFlexContainer } from "Components/FullWidthCentFlexCont";

import Logo from "Assets/ShorelineFooterLogo.svg";
import FooterLogoBanner from "Assets/ShorelineFooterLogoBanner.svg";
import FooterLogoBannerMob from "Assets/ShorelineFooterLogoBannerMob.svg";

import { FooterViewModel } from "./FooterViewModel";
import { observer } from "mobx-react-lite";
import { CoreStoreInstance } from "@shoothill/core";
import { H2, H5, P } from "Components/Primitives/TextElements/TextElements";

export const Footer: React.FC = observer(() => {
    const [viewModel] = useState<FooterViewModel>(new FooterViewModel());

    const doSomething: ICommand = new RelayCommand((value: string) => {});
    const isMobile = useMediaQuery("(max-width: 767px)");

    useEffect(() => {
        if (viewModel.isModelValid(true, true) === true) {
            CoreStoreInstance.HideInfoBar();
        }
    }, [viewModel.isModelValid(true, true)]);

    const mainText = (
        <Box textAlign={isMobile ? "center" : "right"} paddingTop={isMobile ? "25px" : "0"} style={{ cursor: "pointer" }}>
            <Box grid dc={"1fr"} rowGap={"20px"}>
                <NavLink to={AppUrls.Client.Home}>
                    <Label fontStyle={"h4"} color={"white"}>
                        Home
                    </Label>
                </NavLink>
                <NavLink to="/faq">
                    <Label fontStyle={"h4"} color={"white"}>
                        How does it work?
                    </Label>
                </NavLink>
                <NavLink to="/contact">
                    <Label fontStyle={"h4"} color={"white"}>
                        Contact
                    </Label>
                </NavLink>
                <NavLink to={AppUrls.Client.Account.Login}>
                    <Label fontStyle={"h4"} color={"white"}>
                        Login
                    </Label>
                </NavLink>
            </Box>
        </Box>
    );

    const copyright = (
        <Box paddingTop={isMobile ? "10px" : "20px"} textAlign={isMobile ? "center" : undefined}>
            <Label color="white" fontStyle={isMobile ? "h1" : "h2"}>
                <strong>&copy; {new Date().getFullYear()} Shoothill Ltd</strong>
            </Label>
        </Box>
    );

    return (
        <Main>
            <FullWidthCentFlexContainer style={{ backgroundColor: theme.palette.common.primary }}>
                <InnerWrapper p={viewModel.isMobile ? "50px 20px 20px 20px" : "35px 40px"} flexBox>
                    <img src={Logo} height={viewModel.isMobile ? "25ps" : "47px"}></img>
                    <H2 color="orange" style={{ fontSize: viewModel.isMobile ? "24px" : "48px" }}>
                        Shoreline Crescent
                    </H2>
                </InnerWrapper>
            </FullWidthCentFlexContainer>

            <FullWidthCentFlexContainer style={{ backgroundColor: theme.palette.common.primary }}>
                <InnerWrapper grid dc="2fr 2fr 1fr 1fr" tc="2fr 2fr 1fr 1fr" p={viewModel.isMobile ? "50px 20px 30px 20px" : "35px 40px 10px 40px"} columnGap={"0"}>
                    <Box>
                        <a href="tel:01303 760 940">
                            <P color="orange" pb={"5px"} style={{ textDecoration: "underline" }}>
                                01303 760 940
                            </P>
                        </a>
                        <a href="mailto:sales@folkestoneseafront.com">
                            <P color="orange" pb={"20px"} style={{ cursor: "pointer" }}>
                                sales@folkestoneseafront.com
                            </P>
                        </a>

                        {/* Pre 04/03/24: Thursday - Monday 10am to 5pm */}
                        <P p="15px 0px" color="orange">
                            Opening hours: 7 days a week, 10am to 5pm
                        </P>

                        <P pt="10px" color="orange">
                            For press enquiries:
                        </P>
                        <a href="mailto:press@folkestoneseafront.com">
                            <P color="orange" pb={"30px"} style={{ cursor: "pointer" }}>
                                press@folkestoneseafront.com
                            </P>
                        </a>
                    </Box>

                    <Box>
                        <P color="orange">
                            Shoreline Sales & Marketing Suite, <br></br>1 Shoreline East, <br></br>Shoreline Crescent, <br></br>Folkestone, Kent, <br></br>CT20 1FD
                        </P>
                    </Box>
                    <Box grid mc="1fr 1fr">
                        <Box>
                            <Box>
                                <a href="https://www.instagram.com/shoreline_folkestone/" target="_blank">
                                    <P color="orange">Instagram</P>
                                </a>
                            </Box>
                            <Box>
                                <a href="https://www.facebook.com/shorelinecrescent" target="_blank">
                                    <P color="orange">Facebook</P>
                                </a>
                            </Box>
                        </Box>

                        <Box showIf={viewModel.isMobile} style={{ columnGap: "50px" }}>
                            <Link to="/privacy-policy">
                                <P color="orange" style={{ cursor: "pointer", textAlign: "left" }}>
                                    Privacy Policy
                                </P>
                            </Link>
                            <Link to="/cookie-policy">
                                <P color="orange" style={{ cursor: "pointer", textAlign: "left" }}>
                                    Cookie Policy
                                </P>
                            </Link>
                        </Box>
                    </Box>

                    <Box showIf={!viewModel.isMobile} style={{ columnGap: "50px" }}>
                        <Link to="/privacy-policy">
                            <P color="orange" style={{ cursor: "pointer", textAlign: "right" }}>
                                Privacy Policy
                            </P>
                        </Link>
                        <Link to="/cookie-policy">
                            <P color="orange" style={{ cursor: "pointer", textAlign: "right" }}>
                                Cookie Policy
                            </P>
                        </Link>
                    </Box>
                </InnerWrapper>
            </FullWidthCentFlexContainer>

            <FullWidthCentFlexContainer style={{ backgroundColor: theme.palette.common.primary }}>
                <InnerWrapper>
                    <Box grid p={viewModel.isMobile ? "0px 20px" : "0px 40px"} tc="1fr 1fr">
                        <H5 color="orange" style={{ textAlign: "left", fontSize: "11px" }} pb={"25px"}>
                            All CGIs presented on this website are indicative only.
                        </H5>
                        <H5 color="orange" style={{ textAlign: viewModel.isMobile ? "left" : "right", fontSize: "11px" }} pb={"25px"}>
                            &copy; Folkestone Harbour & Seafront Development Company {new Date().getFullYear()}.
                        </H5>
                    </Box>
                    <img src={viewModel.isMobile ? FooterLogoBannerMob : FooterLogoBanner} width="100%" />
                </InnerWrapper>
            </FullWidthCentFlexContainer>
        </Main>
    );
});
