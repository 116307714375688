import styled from "@emotion/styled";
import { Box } from "Components";
import { theme } from "Application";

interface LineSizing {
    widthOfLine?: string;
    heightOfLine?: string;
}

/**
 * HorizontalGradLineDarkLightDark
 * params: widthOfLine (optional), heightOfLine (optional)
 */
export const HorizontalGradLineDarkLightDark = styled(Box)<LineSizing>`
    height: ${(props) => (props.heightOfLine ? props.heightOfLine : "1.5px")};
    width: ${(props) => (props.widthOfLine ? props.widthOfLine : "100px")};

    background: ${theme.palette.common.secondary};
`;
