import { action, makeObservable, observable, runInAction } from "mobx";
import { KeyValuePair, ViewModelBase } from "@shoothill/core";
import { APIClient, ICommand, IKeyState, RelayCommand } from "Application";
import { DownloadBrochureModalModel, DownloadBrochureModalModelValidator } from "./DownloadBrochureModalModel";
import { PostcontactFormEndpoint } from "Views/Contact/Endpoints/PostContactFormEndPoint";
import { Logger } from "index";
import { DownloadBrochureEndpoint } from "../Endpoints/DownloadBrochureEndpoint";
import { container } from "tsyringe";
import { DomainStore } from "Stores/Domain/DomainStore";
import { brochureTypes } from "Views/BrochureAddendums/DownloadViewModel";
import { getBrochureType } from "Utils/GetBrochureType";

export class DownloadBrochureModalViewModel extends ViewModelBase<DownloadBrochureModalModel> {
    public apiClient = new APIClient();
    public domainStore = container.resolve(DomainStore);

    public downloadButtonText: string = "DOWNLOAD";
    public isSumbitted: boolean = false;
    public hbpResponseSuccessful: boolean = false;
    public messageSent: boolean = false;

    public viewFormOrSubmitted: string = "Form";
    public isProcessing: boolean = false;
    public hasDownloaded: boolean = false;

    constructor() {
        super(new DownloadBrochureModalModel());
        this.setValidator(new DownloadBrochureModalModelValidator());
        makeObservable(this, {
            downloadButtonText: observable,
            isSumbitted: observable,
            hbpResponseSuccessful: observable,
            setHbpResponseSuccessful: action,
            messageSent: observable,
            viewFormOrSubmitted: observable,
            isProcessing: observable,
            hasDownloaded: observable,
        });
    }

    public setHbpResponseSuccessful = (value: boolean) => (this.hbpResponseSuccessful = value);

    public updateFirstName: ICommand = new RelayCommand((value: string) => {
        this.model.firstName = value;
    });

    public updateLastName: ICommand = new RelayCommand((value: string) => {
        this.model.surname = value;
    });

    public updateAddressLine1: ICommand = new RelayCommand((value: string) => {
        this.model.addressLine1 = value;
    });

    public updateAddressLine2: ICommand = new RelayCommand((value: string) => {
        this.model.addressLine2 = value;
    });

    public updateCity: ICommand = new RelayCommand((value: string) => {
        this.model.city = value;
    });

    public updatePostcode: ICommand = new RelayCommand((value: string) => {
        this.model.postcode = value;
    });

    public updateEmail: ICommand = new RelayCommand((value: string) => {
        this.model.contactEmail = value;
    });

    public updatePhoneNumber: ICommand = new RelayCommand((value: string) => {
        this.model.primaryContactNumber = value;
    });

    public updatePrefersEmails: ICommand = new RelayCommand((value: string) => {
        this.model.preferredContactMethodEmail = !this.model.preferredContactMethodEmail;
    });

    public updatePrefersTelCalls: ICommand = new RelayCommand((value: string) => {
        this.model.preferredContactMethodPhone = !this.model.preferredContactMethodPhone;
    });

    public updateBudgetFrom: ICommand = new RelayCommand((value: string) => {
        this.model.budgetFrom = value;
    });

    public updateBudgetTo: ICommand = new RelayCommand((value: string) => {
        this.model.budgetTo = value;
    });

    public updateWantsUpdates: ICommand = new RelayCommand((value: string) => {
        this.model.acceptsMarketing = !this.model.acceptsMarketing;
    });

    public updateAgreesPrivacyPolicy: ICommand = new RelayCommand((value: string) => {
        this.model.agreePrivacyPolicy = !this.model.agreePrivacyPolicy;
    });

    public updateMessage: ICommand = new RelayCommand((value: string) => {
        this.model.notes = value;
    });

    public get getBrochureOptions(): KeyValuePair<any>[] {
        // CHANGE THIS ONCE OTHER PROPERTY TYPES ARE ADDED!!
        let retVal: KeyValuePair<any>[] = [
            { key: 0, text: "Host Brochure" },
            { key: 2, text: "East Apartment" },
            { key: 4, text: "Beach House" },
        ];

        return retVal;
    }

    public updateBrochureToDownload: ICommand = new RelayCommand((value: KeyValuePair) => {
        this.model.brochureType = value.key;
    });

    public onSubmit: ICommand = new RelayCommand((closeModal: any) => {
        this.model.firstValidate = false;

        if (this.isModelValid(true, true) === true) {
            this.downloadButtonText = "DOWNLOADING...";
            // this.model.brochureType = this.domainStore.brochureType;
            this.PostContactFormAsync().then(() => {
                this.model = new DownloadBrochureModalModel();
            });
            console.log("submitted");
            this.messageSent = true;
            this.viewFormOrSubmitted = "Submitted";
        }
    });

    public async PostContactFormAsync(): Promise<void> {
        this.model.notes = getBrochureType(this.model.brochureType);
        this.isProcessing = true;
        this.hasDownloaded = false;
        const postContactForm = new DownloadBrochureEndpoint();
        await this.apiClient.sendAsync(postContactForm, this.model);
        if (this.apiClient.IsRequestSuccessful) {
            runInAction(() => {
                this.isProcessing = false;
                this.hasDownloaded = true;
            });
        } else {
            this.isProcessing = false;
        }

        this.viewFormOrSubmitted = "Submitted";
        Logger.logInformation("Posted contact form");
    }

    public closeFormReset = () => {
        this.downloadButtonText = "DOWNLOAD";
        this.model = new DownloadBrochureModalModel();
    };

    public get getPreferredContactMethodValidationMessage(): string {
        let retVal = "";
        if (this.getError("preferredContactMethodEmail") || this.getError("preferredContactMethodPhone")) {
            retVal = "Please select a preferred contact method";
        }
        return retVal;
    }
}
