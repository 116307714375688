import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { Box, theme } from "Application";
import { FullWidthCentFlexContainer } from "Components/FullWidthCentFlexCont";
import { InnerWrapper } from "Components/InnerWrapper";
import { H2, H3, H4, P } from "Components/Primitives/TextElements/TextElements";
import { BannerImageFull } from "Components/BannerImageFull/BannerImageFull";
import Banner from "Assets/HomeCarouselVidFallback.jpg";
import { TitleLeftTextRightBlock } from "Components/TitleLeftTextRightBlock/TitleLeftTextRightBlock";
import { PartExchangeViewModel } from "./PartExchangeViewModel";
import { ShorelineButton } from "Components/ShorelineButton";
import { AppUrls } from "AppUrls";
import { TitleWithTextBlock } from "Components/TitleLeftTextRightBlock/TitleWithTextBlock";
import styled from "@emotion/styled";

export const PartExchangeView: React.FC = observer(() => {
    const [viewModel] = useState(new PartExchangeViewModel());

    return (
        <>
            <TitleLeftTextRightBlock
                title="Part Exchange"
                textBlock={`Welcome to Shoreline Crescent: Your Gateway to a New Home with Part Exchange.\n\n Shoreline Crescent is here to make your home buying dreams a reality with our hassle-free Part Exchange program.`}
                isMobile={viewModel.isMobile}
                hasLargeTitle={true}
                hasLargerBodyText={true}
                hasThreeButtons={true}
                isTablet={viewModel.isTablet}
                backgroundColour="#E0A752"
                textColour="primary"
                // paddingForBlock="0 0 40px 0"
            />
            <Box flexBox p={viewModel.isDesktop ? "40px" : "20px"} style={{ justifyContent: "center" }}>
                <Box>
                    <TitleWithTextBlock
                        title="What is Part Exchange?"
                        textBlock={`Part Exchange is a game-changer in the world of property transactions. It allows you to secure your new dream home without the stress of selling your current one. Here's how it works:\n
You select your ideal home at Shoreline Crescent, and we arrange for three independent estate agents to value your property.  Our Part Exchange Homes Manager may also want to visit your home before making an offer.\n
We will make you a verbal offer (Subject to a survey), based on the independent valuations, which we will then follow up in writing. \n
Once you accept our offer, the sale is confirmed.  You will need to instruct your Solicitors to arrange the sale of your new home.  For more information, please contact our Sales Team.`}
                        isMobile={viewModel.isMobile}
                        // hasLargeTitle={true}
                        // hasLargerBodyText={true}
                        isTablet={viewModel.isTablet}
                        paddingForBlock="0 0 40px 0"
                    />
                    <TitleWithTextBlock
                        title="Why Choose Part Exchange with Shoreline Crescent?"
                        textBlock={`Convenience: Part Exchange eliminates the need for dealing with estate agents, viewings, and unpredictable chains. We streamline the process for you.\n
Financial Certainty: You'll have a clear and guaranteed price for your current home from the outset, allowing you to confidently budget for your new property.\n
No Hidden Costs: We cover the valuation costs and estate agent fees, so there are no unexpected expenses.\n
Efficiency: With Part Exchange, you can move into your new home faster, avoiding the delays that often come with traditional sales.
`}
                        isMobile={viewModel.isMobile}
                        isTablet={viewModel.isTablet}
                        textColour="primary"
                        paddingForBlock="0 0 40px 0"
                    />
                    <TitleWithTextBlock
                        title="Our Selection of Homes"
                        textBlock={`Shoreline Crescent offers a diverse range of stunning new homes with picturesque views and a welcoming community, Shoreline Crescent is more than just a place to live; it's a lifestyle you'll love.`}
                        isMobile={viewModel.isMobile}
                        isTablet={viewModel.isTablet}
                        buttons={
                            <>
                                <ShorelineButton displayName="VIEW AVAILABLE PROPERTIES" value={AppUrls.Client.NewHomes} command={viewModel.navTo} />
                            </>
                        }
                        paddingForBlock="0 0 40px 0"
                    />
                    <TitleWithTextBlock
                        title="How to Get Started"
                        textBlock={`Simply get in touch with our team to discuss your requirements, browse our available properties, and let us guide you through the process step by step. We're here to make your transition into your new home as smooth as possible.`}
                        isMobile={viewModel.isMobile}
                        isTablet={viewModel.isTablet}
                        paddingForBlock="0 0 40px 0"
                    />

                    <TitleWithTextBlock
                        title="Contact Us"
                        textBlock={`Contact Shoreline Crescent today to learn more about our Part Exchange program and discover the perfect property for you.`}
                        isMobile={viewModel.isMobile}
                        isTablet={viewModel.isTablet}
                        textColour="primary"
                        JSXElement={
                            <>
                                <AStyled href="mailto:sales@folkestoneseafront.com">
                                    <P className={viewModel.isMobile ? "linkTextMob" : "linkText"} p="10px 0px">
                                        sales@folkestoneseafront.com
                                    </P>
                                </AStyled>
                                <AStyled href="tel:01303 760 940">
                                    <P className={viewModel.isMobile ? "linkTextMob" : "linkText"} p="10px 0px">
                                        01303 760 940
                                    </P>
                                </AStyled>
                            </>
                        }
                        paddingForBlock="0 0 40px 0"
                    />
                </Box>
            </Box>
        </>
    );
});

const AStyled = styled.a`
    text-decoration-color: ${theme.palette.common.primary};
`;
