import React from "react";
import { observer } from "mobx-react-lite";
import { Box, ICommand } from "Application";
import { H3 } from "Components/Primitives/TextElements/TextElements";
import { VerticalGradLineNoMargin } from "Components/VerticalGradLine";
import { BuildingType, NewHomesViewModel, loadingStatus } from "Views/NewHomes/NewHomesViewModel";
import { NewHomesButton, Triangle, TriangleMobile } from "../NewHomeStyling";

import LoadingSpinner from "Assets/LoadingSpinner.svg";
import { ShorelineButton } from "Components/ShorelineButton";
import { brochureTypes } from "Views/BrochureAddendums/DownloadViewModel";

interface IBuildingTypeColumn {
    title: string;
    imgUrl: string;
    buttonValue: string;
    viewModel: NewHomesViewModel;
    togglePropertyLists: ICommand<any>;
    buildingTypeSelected: string;
    backgroundColour?: string;
    brochureToDownload?: brochureTypes;
}

export const BuildingTypeColumn: React.FC<IBuildingTypeColumn> = observer((props) => {
    return (
        <>
            <Box>
                <Box style={{ outline: props.buttonValue === props.buildingTypeSelected ? "2px solid #CC6B2C" : "" }}>
                    <Box grid dc="1fr" columnGap="0px" rowGap="0px">
                        <Box style={{ backgroundColor: props.backgroundColour ? props.backgroundColour : "#FCEEDB" }}>
                            <Box flexBox gap={0} style={{ justifyContent: "space-between", flexDirection: "column" }}>
                                <H3 color="primary" p="30px 20px 0px 20px" style={{ fontSize: props.viewModel.isDesktop ? "24px" : "20px" }}>
                                    {props.title}
                                </H3>
                                <Box p={props.viewModel.isMobile ? "10px" : "20px"}>
                                    <ShorelineButton value={props.brochureToDownload} displayName={"DOWNLOAD BROCHURE"} command={props.viewModel.sendBrochureForm} />
                                </Box>
                            </Box>
                            <Box height={"209px"}>
                                <img src={props.imgUrl} height="100%" width="100%" style={{ objectFit: "cover" }}></img>
                            </Box>
                        </Box>

                        <VerticalGradLineNoMargin hidden={props.viewModel.isMobile ? true : false}></VerticalGradLineNoMargin>
                    </Box>
                    <Box>
                        {props.viewModel.areThereAnyEastWingApartments == loadingStatus.YesDisplay ? (
                            <NewHomesButton
                                className={props.buildingTypeSelected === props.buttonValue ? "selected" : ""}
                                command={props.togglePropertyLists}
                                displayName="VIEW AVAILABLE PROPERTIES"
                                value={props.buttonValue}
                                style={{ wordWrap: "normal" }}
                            />
                        ) : props.viewModel.areThereAnyEastWingApartments == loadingStatus.NoPropertiesAvailable ? (
                            <H3 color="quarternary" pb="20px">
                                COMING SOON
                            </H3>
                        ) : (
                            <Box style={{ display: "flex", justifyContent: "center" }}>
                                <img src={LoadingSpinner}></img>
                            </Box>
                        )}
                    </Box>
                </Box>
                <Box width={"100%"} display={"flex"} justifyContent={"center"}>
                    <Triangle showIf={props.buildingTypeSelected === props.buttonValue && !props.viewModel.isMobile}></Triangle>
                </Box>

                {/* <Box showIf={props.buildingTypeSelected === props.buttonValue && props.viewModel.isMobile} width={"100%"} display={"flex"} justifyContent={"center"}>
                    <TriangleMobile></TriangleMobile>
                </Box> */}
            </Box>
        </>
    );
});
