import styled from "@emotion/styled";
import { Box, Button } from "Components";
import HeaderImage from "Assets/HeaderGraphic.png";
import { theme } from "Application";
import { HeaderViewModel } from "./HeaderViewModel";

export const LogoStyling = styled.h6`
    font-size: 12px;
    font-family: "open sans";
    font-weight: 600;
    letter-spacing: 2px;

    background-color: #f3ec78;
    background-image: linear-gradient(90deg, #7d5232 0%, #bc834f 25%, #f0aa66 47%, #eba664 56%, #db9a5c 66%, #c18651 78%, #9d6a40 91%, #7d5232 100%);
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
`;

export interface IHeaderWrapperProps {
    viewModel: HeaderViewModel;
}

export const HeaderWrapper = styled(Box)<IHeaderWrapperProps>`
    /* background: url(${HeaderImage});
    background-repeat: no-repeat;
    background-size: cover 100%;
    background-position: -250px 0px;
    box-shadow: 0px 3px 6px #00000029; */
    /* box-shadow: 5px 10px red; */
    z-index: 10;
    width: 100%;
    /* height: 48px; */
    display: flex;
    flex-direction: ${(props) => (props.viewModel.isMobile ? "column" : "row")};
    align-items: center;
    justify-content: center;
    background-color: ${theme.palette.common.secondary};
`;

export const HeaderBar = styled.div<IHeaderWrapperProps>`
    position: relative;
    top: 0;
    left: 0;
    width: 100%;

    /* padding-left: ${(props) => (props.viewModel.isMobile || props.viewModel.isTablet ? "" : "20px")};
    padding-right: ${(props) => (props.viewModel.isMobile || props.viewModel.isTablet ? "" : "20px")}; */

    height: 48px;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;

    //padding: 0 24px;
`;

export interface IMenuBoxProps {
    isDesktop?: boolean;
}

export const MenuBox = styled(Box)<IMenuBoxProps>`
    background: ${(props) => (props.isDesktop ? "" : "#212241")};

    align-items: center;
`;

export const HomePageButton = styled(Button)`
    text-align: center;
    /* background-color: #eda765; */
    background: linear-gradient(90deg, #7d5232 0%, #bc834f 25%, #f0aa66 47%, #eba664 56%, #db9a5c 66%, #c18651 78%, #9d6a40 91%, #7d5232 100%) 0% 0% no-repeat padding-box;

    height: 50px;
    font-family: "open sans";
    font-weight: 600;
    box-shadow: 0px 3px 6px #00000029;
    &.selected {
        background-color: #566f6a;
    }
`;

export const SubHeadingBox = styled(Box)`
    display: flex;
`;

export const BuyerLoginButton = styled.button`
    border-radius: 0px;
    border: none;
    padding: 10px;
    color: #ecdfc9;
    font-weight: 300;
    background: #212241;
    height: 100%;
    cursor: pointer;
    font-family: "sohne";
    min-height: 48px;
    letter-spacing: 0.10625rem;
`;

export interface IButtonProps {
    isDesktop?: boolean;
}
export const RegisterYourInterestButton = styled(Button)<IButtonProps>`
    border-radius: 0px;
    border: none;
    padding: 10px;
    min-height: 48px;
    margin-top: ${(props) => (props.isDesktop ? "0px" : "30px")};
    color: ${(props) => (!props.isDesktop ? theme.palette.common.primary : theme.palette.common.secondary)};
    font-weight: 300;
    background: ${(props) => (!props.isDesktop ? theme.palette.common.secondary : theme.palette.common.primary)};
    height: 48px;
    cursor: pointer;
    font-family: "sohne";
`;

export const MobileMenuBox = styled(Box)`
    background-color: ${theme.palette.common.primary};
    height: 100%;
    position: absolute;
    width: 100%;
`;
