import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { Box, ICommand, RelayCommand } from "Application";
import { HomeViewModel } from "Views/Home/HomeViewModel";
import { PolicyPara, PolicyWrapper } from "./Policy.Styles";
import { InnerWrapper } from "Components/InnerWrapper";
import { FullWidthCentFlexContainer } from "Components/FullWidthCentFlexCont";
import { HorizontalGradLineDarkLightDark } from "Components/HorizontalGradLineDarkLightDark";
import { ShorelineButton } from "Components/ShorelineButton";
import { Link } from "react-router-dom";
import { H2 } from "Components/Primitives/TextElements/TextElements";

export const CookiePolicyView: React.FC = observer(() => {
    const [viewModel] = useState<HomeViewModel>(new HomeViewModel());
    const doSomething: ICommand = new RelayCommand((value: string) => {});

    return (
        <>
            <PolicyWrapper>
                <FullWidthCentFlexContainer>
                    <InnerWrapper maxWidth="1000px" ml="20px" mr="20px">
                        <Box grid dc="1fr auto" pt="40px" pb="40px">
                            <Box>
                                <H2 pb="20px">Cookie policy</H2>
                            </Box>
                            <Box>
                                <Link to="/">
                                    <ShorelineButton command={doSomething} displayName="RETURN TO HOME PAGE"></ShorelineButton>
                                </Link>
                            </Box>
                        </Box>
                        <PolicyPara>
                            A cookie is a small file which asks permission to be placed on your computer’s hard drive. Once you agree, the file is added and the cookie helps
                            analyse web traffic or lets you know when you visit a particular site.
                        </PolicyPara>
                        <PolicyPara>
                            We use traffic log cookies to identify which pages are being used. This helps us analyse data about web page traffic and improve our website in order to
                            tailor it to customer needs. We only use this information for statistical analysis purposes and then the data is removed from the system.
                        </PolicyPara>
                        <PolicyPara>A cookie in no way gives us access to your computer or any information about you, other than the data you choose to share with us.</PolicyPara>
                        <PolicyPara>
                            This website uses Google Analytics, a web analytics service provided by Google, Inc. (Google). This helps us further analyse visits to our website and
                            make it more useful for users. Google Analytics uses cookies. The information generated by the cookie about your use of the website including your IP
                            address will be transmitted to and stored by Google on servers in the United States. Google will use this information for the purpose of evaluating your
                            use of the website, compiling reports on website activity for website operators and providing other services relating to website activity and internet
                            usage. Google may also transfer this information to third parties where required to do so by law, or where such third parties process the information on
                            Google’s behalf. Google will not associate your IP address with any other data held by Google. By using this website, you consent to the processing of
                            data about you by Google in the manner and for the purposes set out above.
                        </PolicyPara>
                        <PolicyPara>
                            You can choose to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser setting to decline
                            cookies if you prefer. This may prevent you from taking full advantage of the website.
                        </PolicyPara>
                        <Box maxWidth="250px" pb="60px" pt="20px">
                            <Link to="/">
                                <ShorelineButton command={doSomething} displayName="RETURN TO HOME PAGE"></ShorelineButton>
                            </Link>
                        </Box>
                    </InnerWrapper>
                </FullWidthCentFlexContainer>
            </PolicyWrapper>
        </>
    );
});
