import styled from "@emotion/styled";
import { Button } from "./Primitives";

export const ShorelineButton = styled(Button)`
    text-align: center;
    /* background-color: #eda765; */
    background: #1f2747;
    color: #ecdfc9;
    width: 100%;
    height: 50px;
    font-family: "sohne";
    font-weight: 300;
    /* box-shadow: 0px 3px 6px #00000029; */
    border-radius: 0px;
    &.disabled {
        background: linear-gradient(90deg, #7d5232 0%, #bc834f 25%, #f0aa66 47%, #eba664 56%, #db9a5c 66%, #c18651 78%, #9d6a40 91%, #7d5232 100%) 0% 0% no-repeat padding-box;
    }

    cursor: pointer;
    /* &.selected {
        background-color: #566f6a;
    } */
`;
