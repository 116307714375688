import React from "react";
import { observer } from "mobx-react-lite";
import { Box } from "Application";
import { RoundedGreenBox, StampDutyInput, StampDutySelect } from "../SelectedPropertyStyles";
import { ShorelineButton } from "Components/ShorelineButton";
import { H3, P } from "Components/Primitives/TextElements/TextElements";
import { PropertyListItemViewModel } from "Views/NewHomes/Subviews/PropertyListItemViewModel";

interface IStampDutyCalc {
    viewModel: PropertyListItemViewModel;
}

export const StampDutyCalculatorBox: React.FC<IStampDutyCalc> = observer((props) => {
    return (
        <>
            <RoundedGreenBox>
                <H3 color="quarternary" p="30px 0px">
                    Stamp duty calculator
                </H3>
                <Box p="20px 20px" mb="40px" style={{ backgroundColor: "#D68956" }}>
                    <StampDutyInput
                        displayName="Purchase price (£)"
                        labelColor={"white"}
                        type={"number"}
                        command={props.viewModel.updateHousePrice}
                        // value={() =>
                        //     new Intl.NumberFormat("en-GB", { style: "currency", currency: "GBP", maximumFractionDigits: 0 }).format(props.viewModel.housePrice)
                        // }
                        value={() => props.viewModel.housePrice}
                        placeholder="e.g. 500,000"
                        prefix={<P>£</P>}
                    ></StampDutyInput>
                    <StampDutySelect
                        options={props.viewModel.getBuyerOptions}
                        command={props.viewModel.updateBuyerOption}
                        displayName="Buyer position"
                        value={() => "First name"}
                    ></StampDutySelect>
                    <ShorelineButton command={props.viewModel.getStampDuty} displayName={"CALCULATE"} mt="20px"></ShorelineButton>
                    {props.viewModel.stampDutyDisplayToggle == true && (
                        <Box>
                            <P color="white" pt="20px">
                                Your stamp duty fee would be...
                            </P>
                            <P color="white" pt="20px" style={{ fontSize: "20px", fontWeight: 600 }}>
                                {new Intl.NumberFormat("en-GB", { style: "currency", currency: "GBP", maximumFractionDigits: 0 }).format(props.viewModel.stampDutyAmount)}
                            </P>
                        </Box>
                    )}
                </Box>
            </RoundedGreenBox>
        </>
    );
});
