import { makeObservable, observable, runInAction } from "mobx";
import { ViewModelBase } from "@shoothill/core";
import { APIClient, ICommand, IKeyState, RelayCommand } from "Application";
import { AboutModel, AboutModelValidator } from "./AboutModel";
import { useNavigate } from "react-router";

export class AboutViewModel extends ViewModelBase<AboutModel> {
    public apiClient = new APIClient();

    public navigate = useNavigate();

    public navTo: ICommand = new RelayCommand((value: string) => {
        if (value === "https://www.folkestoneseafront.com/about/") {
            window.open("https://www.folkestoneseafront.com/about/");
        } else if (value === "https://folke.life/") {
            window.open("https://folke.life/");
        } else {
            this.navigate(value);
        }
    });

    constructor() {
        super(new AboutModel());
        this.setValidator(new AboutModelValidator());
        makeObservable(this, {});
    }
    doSomething: ICommand = new RelayCommand((value: string) => {});
}
