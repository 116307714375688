import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { ConfirmPasswordViewModel } from "./ConfirmPasswordViewModel";
import { Box, ErrorExclamationView, Input } from "Application";
import { ShorelineButton } from "Components/ShorelineButton";
import { redirect, useLocation, useMatch, useParams } from "react-router";
import { AppUrls } from "AppUrls";
import { ConfirmPasswordWrapper, FormOuterWrapper } from "./ConfirmPassword.style";
import { P } from "Components/Primitives/TextElements/TextElements";
import { RedAsterisk } from "Components/RedAsterisk";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

import Shoreline from "Assets/ShorelineRoundLogo.png";
import ConfirmPasswordImage from "Assets/ConfirmPasswordImage.png";
import eyeSlash from "Assets/eye-slash-regular.svg";
import eyeOpen from "Assets/eye-regular.svg";
import Help from "Assets/Help.svg";
import { CoreStoreInstance } from "@shoothill/core";

export const ConfirmPasswordView: React.FC = observer(() => {
    const ScrollDivRef = useRef<HTMLInputElement>(null);

    const redirectTo = (): void => {
        redirect(AppUrls.Client.Home);
        //history.push(AppUrls.Client.Home);
    };

    const [viewModel] = useState(() => new ConfirmPasswordViewModel(redirectTo));

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const params = useParams();
    let token = params.token || "";

    const matches = useMatch(AppUrls.Client.ConfirmPassword);
    const matchedPath = matches == null ? useMatch(AppUrls.Client.ForgotPassword) : matches;

    useEffect(() => {
        let foundMatch: boolean = false;
        if (matchedPath !== null) {
            switch (matchedPath?.pattern.path) {
                case AppUrls.Client.ForgotPassword:
                case AppUrls.Client.Account.ForgotPassword: {
                    foundMatch = true;
                    viewModel.setValue("isForgotPassword", true);
                    break;
                }
                case AppUrls.Client.ConfirmPassword: {
                    foundMatch = true;
                    viewModel.setValue("isForgotPassword", false);
                    break;
                }
                default: {
                    break;
                }
            }
        }

        if (token.length === 0 || foundMatch == false) {
            redirect(AppUrls.Client.Home);
        } else {
            viewModel.updateToken(token);
        }

        return () => {
            viewModel.clear();
        };
    }, []);

    useEffect(() => {}, []);

    useEffect(() => {
        if (viewModel.getErrorMessage.length > 0) {
            CoreStoreInstance.ShowInfoBar(viewModel.getErrorMessage, "error");
            ScrollDivRef.current?.scrollTo(0, 0);
        } else {
            CoreStoreInstance.HideInfoBar();
        }
    }, [viewModel.getErrorMessage]);

    const hasTokenError: boolean = viewModel.model.token !== undefined && viewModel.getError("token") !== undefined && viewModel.getError("token").length > 0;

    return (
        <>
            <ConfirmPasswordWrapper isMobile={viewModel.isMobile} isTablet={viewModel.isTablet}>
                <FormOuterWrapper>
                    <Box grid p="20px" alignContent={"center"} justifyContent="center" dc="1fr" rowGap={"30px"} width="100%" maxWidth="302px">
                        {hasTokenError && <ErrorExclamationView displayName={"Token"} haveError={hasTokenError} validationMessage={() => viewModel.getError("token")} />}
                        <Box>
                            <img src={Shoreline} width="175px" />
                        </Box>
                        {viewModel.getValue("isForgotPassword") == false && (
                            <P style={{ textAlign: "initial" }}>Before you can log into your Shoreline account please create a secure memorable password to protect it.</P>
                        )}
                        {viewModel.getValue("isForgotPassword") == true && (
                            <P style={{ textAlign: "initial" }}>
                                Before you can get access to your Shoreline account again, please create a new secure memorable password to protect it.
                            </P>
                        )}
                        <Input
                            displayName={viewModel.getValue("isForgotPassword") ? "New password" : "Create password"}
                            command={viewModel.updatePasswordCommand}
                            autoFill={false}
                            type={showPassword ? "text" : "password"}
                            value={() => viewModel.getValue("password")}
                            validationMessage={() => viewModel.getError("password")}
                            cy={"password"}
                            displayNameSuffix={
                                <>
                                    {RedAsterisk}
                                    <img
                                        src={Help}
                                        data-tooltip-id="my-tooltip"
                                        data-tooltip-content="Passwords must be between 8 and 15 characters long, must contain at least one number and one special character"
                                    ></img>
                                </>
                            }
                            suffix={
                                <Box grid dc="1fr" height="15px" pr="10px" onClick={() => setShowPassword(!showPassword)}>
                                    <img src={showPassword ? eyeSlash : eyeOpen} height="100%" />
                                </Box>
                            }
                        />
                        <Tooltip id="my-tooltip" className="toolTip" />
                        <Input
                            displayName="Confirm password"
                            command={viewModel.updateConfirmPasswordCommand}
                            autoFill={false}
                            type={showConfirmPassword ? "text" : "password"}
                            value={() => viewModel.getValue("confirmPassword")}
                            validationMessage={() => viewModel.getError("confirmPassword")}
                            cy={"confirmPassword"}
                            displayNameSuffix={RedAsterisk}
                            suffix={
                                <Box grid dc="1fr" height="15px" pr="10px" onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                                    <img src={showConfirmPassword ? eyeSlash : eyeOpen} height="100%" />
                                </Box>
                            }
                        />

                        <ShorelineButton
                            displayName={viewModel.getValue("isForgotPassword") ? "RESET PASSWORD" : "CREATE PASSWORD"}
                            command={viewModel.submitPasswords}
                            paletteColor="primary"
                            type="submit"
                            cy={"submit"}
                            isDisabled={viewModel.apiClient.IsBusy}
                        />
                    </Box>
                </FormOuterWrapper>
                <Box>
                    <img src={ConfirmPasswordImage} style={{ objectFit: "cover", height: "100%", width: "100%" }} />
                </Box>
            </ConfirmPasswordWrapper>
        </>
    );
});
