import { action, makeObservable, observable, runInAction } from "mobx";
import { ViewModelBase } from "@shoothill/core";
import { APIClient, ICommand, IKeyState, RelayCommand } from "Application";
import { HomeModel, HomeModelValidator } from "./HomeModel";
import { useNavigate } from "react-router";

export class HomeViewModel extends ViewModelBase<HomeModel> {
    public apiClient = new APIClient();
    public navigate = useNavigate();

    public isBrochureModalOpen: boolean = false;
    public isVideoMuted: boolean = true;
    public isNotificationModalOpen: boolean = true;

    public navTo: ICommand = new RelayCommand((value: string) => {
        this.navigate(value);
    });

    public openBrochureModal: ICommand = new RelayCommand((value: string) => {
        this.isBrochureModalOpen = true;
    });

    public closeBrochureModal = () => {
        this.isBrochureModalOpen = false;
    };

    public closeNotificationModal = () => {
        this.isNotificationModalOpen = false;
    };

    public doSomething: ICommand = new RelayCommand((value: string) => {});

    public toggleMute: ICommand = new RelayCommand(() => {
        this.isVideoMuted = !this.isVideoMuted;
    });

    constructor() {
        super(new HomeModel());
        this.setValidator(new HomeModelValidator());
        makeObservable(this, {
            isBrochureModalOpen: observable,
            isVideoMuted: observable,
            isNotificationModalOpen: observable,
            // openBrochureModal: action,
            closeBrochureModal: action,
        });
    }
}
