import { Logger } from "index";
import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import _ from "lodash";
import { ContactModel } from "Views/Contact/ContactModel";

class DownloadBrochureAddendumRequest {
    public brochureToDownload: number = 0;
}

class DownloadBrochureAddendumResponse {}

export class DownloadBrochureAddendumEndpoint extends Endpoint<DownloadBrochureAddendumRequest, DownloadBrochureAddendumResponse> {
    public brochureToDownload;
    constructor(brochureToDownload: number) {
        super();
        this.brochureToDownload = brochureToDownload;
        this.verb(Http.Post);
        this.path("/api/download/getbrochurepdffromblob");
        this.responseIsBlob(true);
    }

    public async HandleRequestAsync(): Promise<any> {
        let request = new DownloadBrochureAddendumRequest();
        request.brochureToDownload = this.brochureToDownload;

        return request;
    }

    public async HandleResponseAsync(response: any): Promise<any> {
        // window.open(window.URL.createObjectURL(response), "_blank");

        const contentDisposition = response.headers["content-disposition"];
        const filename = contentDisposition.split("filename=")[1];

        // Create a link element for the file and use the filename provided.
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(new Blob([response]));
        link.target = "_blank";
        link.setAttribute("download", filename);

        document.body.appendChild(link);

        // Download.
        link.click();

        // Having clicked the link, delete the element otherwise it will
        // remain attached to the document.
        document.body.removeChild(link);

        return Promise.resolve();
    }
}
