import { makeObservable, observable, runInAction } from "mobx";
import { ViewModelBase } from "@shoothill/core";
import { APIClient, ICommand, IKeyState, RelayCommand } from "Application";
import { FooterModel, FooterModelValidator } from "./FooterModel";

export class FooterViewModel extends ViewModelBase<FooterModel> {
    public apiClient = new APIClient();

    constructor() {
        super(new FooterModel());
        this.setValidator(new FooterModelValidator());
        makeObservable(this, {});
    }

    public updateFirstName: ICommand = new RelayCommand((value: string) => {
        this.model.firstName = value;
    });

    public updateLastName: ICommand = new RelayCommand((value: string) => {
        this.model.lastName = value;
    });

    public updateEmail: ICommand = new RelayCommand((value: string) => {
        this.model.contactEmail = value;
    });

    public updateAgreesPrivacyPolicy: ICommand = new RelayCommand((value: string) => {
        this.model.agreePrivacyPolicy = !this.model.agreePrivacyPolicy;
    });

    public onSubmit: ICommand = new RelayCommand(() => {
        this.model.firstValidate = false;

        if (this.isModelValid(true, true) === true) {
            // this.PostContactFormAsync().then(() => {
            //     this.model = new ContactModel();
            // });
            console.log("submitted");
        }
    });
}
