import { makeObservable, observable, runInAction } from "mobx";
import { ViewModelBase } from "@shoothill/core";
import { APIClient, ICommand, IKeyState, RelayCommand } from "Application";
import { BuyerLoginModel, BuyerLoginModelValidator } from "./BuyerLoginModel";

export class BuyerLoginViewModel extends ViewModelBase<BuyerLoginModel> {
    public apiClient = new APIClient();

    constructor() {
        super(new BuyerLoginModel());
        this.setValidator(new BuyerLoginModelValidator());
        makeObservable(this, {});
    }
}
