import { Logger } from "index";
import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { UserModel } from "Application/Models";
import { Guid } from "Application/Helpers";
import { SignUpOrUpdateModel } from "Components/BuyerLogin/SignUpOrUpdate/SignUpOrUpdateModel";

class IsEmailInUseRequest {
    public userId: Guid | null = null;
    public emailAddress: string = "";
}

class IsEmailInUseResponse {
    public isEmailInUse: boolean = false;
}

export class IsEmailInUseEndpoint extends Endpoint<IsEmailInUseRequest, IsEmailInUseResponse> {
    constructor() {
        super();
        this.verb(Http.Post);
        this.path("/api/User/IsEmailInUse");
    }

    public async HandleRequestAsync(model: UserModel | SignUpOrUpdateModel): Promise<any> {
        let request = new IsEmailInUseRequest();
        request.userId = model.id;
        request.emailAddress = model.emailAddress;
        return request;
    }

    public async HandleResponseAsync(response: IsEmailInUseResponse): Promise<boolean> {
        Logger.logInformation("Response", response);

        //Return your model
        return response.isEmailInUse;
    }
}
