import { action, makeObservable, observable, runInAction } from "mobx";
import { CoreStoreInstance, KeyValuePair, ViewModelBase } from "@shoothill/core";
import { APIClient, ICommand, IKeyState, RelayCommand } from "Application";
import { SignUpOrUpdateModel, SignUpOrUpdateModelValidator } from "./SignUpOrUpdateModel";
import { RegisterEndpointPOST, RegisterResponse } from "Views/Login/SignUp/Endpoints/RegisterEndpointPOST";
import { GetUserRelatedEndpoint, UserRelatedResponse } from "Endpoints/StaticData/GetUserRelated";
import { InterestedIn, SourceOfEnquiry, CurrentPosition } from "Models/StaticData";
import { IObservableArray, computed } from "mobx";
import { IsEmailInUseEndpoint } from "Endpoints/User/IsEmailInUse";

export class SignUpOrUpdateViewModel extends ViewModelBase<SignUpOrUpdateModel> {
    public apiClient = new APIClient();

    public interestedTypes: IObservableArray<InterestedIn> = observable([]);
    public sourceOfEnquiry: IObservableArray<SourceOfEnquiry> = observable([]);
    public currentPosition: IObservableArray<CurrentPosition> = observable([]);
    public errorMessage: string = "";

    constructor() {
        super(new SignUpOrUpdateModel());
        this.setValidator(new SignUpOrUpdateModelValidator());
        makeObservable(this, {
            currentPosition: observable,
            interestedTypes: observable,
            sourceOfEnquiry: observable,
            errorMessage: observable,
            clear: action,
            getInterestedInTypes: computed,
            getErrorMessage: computed,
        });
    }

    public get getErrorMessage(): string {
        return this.errorMessage;
    }

    public clear() {
        this.interestedTypes.clear();
        this.sourceOfEnquiry.clear();
        this.currentPosition.clear();
        this.errorMessage = "";
        this.model.clear();
    }

    public load = async () => {
        if (this.apiClient.IsBusy === false && (this.interestedTypes.length === 0 || this.sourceOfEnquiry.length === 0 || this.currentPosition.length === 0)) {
            let endpoint: GetUserRelatedEndpoint = new GetUserRelatedEndpoint();

            await this.apiClient.sendAsync(endpoint);
            if (this.apiClient.IsRequestSuccessful) {
                let response: UserRelatedResponse = this.apiClient.Response<UserRelatedResponse[]>();

                runInAction(() => {
                    this.interestedTypes.replace(response.interestedIn);
                    this.sourceOfEnquiry.replace(response.sourceOfEnquiry);
                    this.currentPosition.replace(response.currentPositions);
                });
            } else {
                this.errorMessage = this.apiClient.ValidationMessage;
            }
        }
    };

    public updateFirstName: ICommand = new RelayCommand((value: string) => {
        this.model.firstName = value;
    });

    public updateLastName: ICommand = new RelayCommand((value: string) => {
        this.model.lastName = value;
    });

    public updateEmail: ICommand = new RelayCommand((value: string) => {
        this.model.emailAddress = value;
    });

    public updatePhoneNumber: ICommand = new RelayCommand((value: string) => {
        this.model.phoneNumber = value;
    });

    public updateAddressLine1: ICommand = new RelayCommand((value: string) => {
        this.model.addressLine1 = value;
    });

    public updateAddressLine2: ICommand = new RelayCommand((value: string) => {
        this.model.addressLine2 = value;
    });

    public updateTownCity: ICommand = new RelayCommand((value: string) => {
        this.model.city = value;
    });

    public updatePostcode: ICommand = new RelayCommand((value: string) => {
        this.model.postCode = value;
    });

    public updateAcceptsEmail: ICommand = new RelayCommand(() => {
        this.model.marketingEmail = !this.model.marketingEmail;
    });

    public updateAcceptsText: ICommand = new RelayCommand(() => {
        this.model.marketingText = !this.model.marketingText;
    });

    public updateAcceptsPost: ICommand = new RelayCommand(() => {
        this.model.marketingPost = !this.model.marketingPost;
    });

    //     public updateCurrentPassword: ICommand = new RelayCommand(() => {

    //     });

    //     public updateNewPassword: ICommand = new RelayCommand(() => {

    //     });

    public get getInterestedInTypes() {
        let retVal: KeyValuePair[] = [];

        if (this.interestedTypes.length > 0) {
            retVal = this.interestedTypes.map((item: InterestedIn) => {
                return {
                    key: item.id,
                    text: item.interest,
                };
            });
        }

        return retVal;
    }

    public updateInterestedIn: ICommand = new RelayCommand((value: KeyValuePair) => {
        this.model.interestedInId = parseInt(value.key);
    });

    public get getCurrentPositionTypes() {
        let retVal: KeyValuePair[] = [];

        if (this.currentPosition.length > 0) {
            retVal = this.currentPosition.map((item: CurrentPosition) => {
                return {
                    key: item.id,
                    text: item.position,
                };
            });
        }

        return retVal;
    }

    public updateCurrentPosition: ICommand = new RelayCommand((value: KeyValuePair) => {
        this.model.currentPositionId = parseInt(value.key);
    });

    public get getSourceOfEnquiryTypes() {
        let retVal: KeyValuePair[] = [];

        if (this.sourceOfEnquiry.length > 0) {
            retVal = this.sourceOfEnquiry.map((item: SourceOfEnquiry) => {
                return {
                    key: item.id,
                    text: item.enquiry,
                };
            });
        }

        return retVal;
    }

    public updateSourceOfEnquiry: ICommand = new RelayCommand((value: KeyValuePair) => {
        this.model.sourceOfEnquiryId = parseInt(value.key);
    });

    public submitUpdatedContactDetails: ICommand = new RelayCommand(async () => {
        this.model.firstValidate = false;

        if (this.isModelValid() === true && this.apiClient.IsBusy === false) {
            this.errorMessage = "";
            const isEmailInUse = new IsEmailInUseEndpoint();
            await this.apiClient.sendAsync(isEmailInUse, this.model);

            if (this.apiClient.IsRequestSuccessful === true) {
                let inUse: boolean = this.apiClient.Response<boolean>();

                if (inUse === true) {
                    this.model.setError("emailAddress", "Email Address is already registered.");
                } else {
                    // then we are good to register
                    const register = new RegisterEndpointPOST();
                    await this.apiClient.sendAsync(register, this.model);

                    runInAction(() => {
                        if (this.apiClient.IsRequestSuccessful === true) {
                            let response = this.apiClient.Response<RegisterResponse>();
                        } else {
                            this.errorMessage = this.apiClient.ValidationMessage;
                        }
                    });
                }
            } else {
                this.errorMessage = this.apiClient.ValidationMessage;
            }
        } else {
            console.log("Not submitted");
        }
        CoreStoreInstance.HideInfoBar();
    });
}
