import styled from "@emotion/styled";
import { Box } from "Components";

export const MainWrapper = styled(Box)`
    height: 100vh;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
`;
