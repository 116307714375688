import React from "react";
import { observer } from "mobx-react-lite";
import { Box } from "Application";
import { H3, P } from "./Primitives/TextElements/TextElements";
import { fontSize } from "styled-system";

export interface IBaseCard {
    imgUrl: string;
    title: string;
    description: string;
}

export const BaseCard: React.FC<IBaseCard> = observer((props) => {
    return (
        <>
            <Box>
                <img src={props.imgUrl} width="100%" height={"570px"} style={{ objectFit: "cover" }} />
                <H3 color="primary" style={{ fontSize: "30px" }} pt="26px" pb="16px">
                    {props.title}
                </H3>
                <P color="primary" pb="50px">
                    {props.description}
                </P>
            </Box>
        </>
    );
});
