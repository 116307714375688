import { Logger } from "index";
import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { ConfirmPasswordModel } from "../ConfirmPasswordModel";

class ConfirmPasswordRequest {
    public token: string = "";
    public newPassword: string = "";
    public isForgotPassword = true;
}

class ConfirmPasswordRequestResponse {
    public isSuccess: boolean = false;
}

export class ConfirmPasswordEndpoint extends Endpoint<ConfirmPasswordRequest, ConfirmPasswordRequestResponse> {
    constructor() {
        super();
        this.verb(Http.Post);
        this.path("/api/Account/resetpassword");
    }

    public async HandleRequestAsync(model: ConfirmPasswordModel): Promise<any> {
        let request = new ConfirmPasswordRequest();
        request.newPassword = model.password;
        request.token = model.token;
        request.isForgotPassword = model.isForgotPassword;
        return request;
    }

    public async HandleResponseAsync(response: ConfirmPasswordRequestResponse): Promise<boolean> {
        Logger.logInformation("Response", response);
        //Return your model

        return response.isSuccess;
    }
}
