import { Box, Button, CheckBox, EditDate, EditSelect, Input, Label, RadioButton, theme } from "Application";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { container } from "tsyringe";
import { DeleteIcon } from "../../Assets";
import { LookupStore } from "../../Stores/Domain/LookupStore";
import { NullCommand } from "../Commands";
import { MaskedOptions, remToPx } from "../Helpers";
import { TestViewModel } from "./TestViewModel";

export const StyleSheetView: React.FC = observer(() => {
    const [viewModel] = useState(() => new TestViewModel());
    const lookupStore = container.resolve(LookupStore);
    // @ts-ignore
    return (
        <Box p={5}>
            <Box display={"flex"} alignContent={"center"} justifyContent={"center"}>
                <Label style={{ fontSize: "50px", textDecoration: "underline" }} fontStyle={"h1"}>
                    Style Sheet
                </Label>
            </Box>
            <Box>
                <Label fontStyle={"h2"} style={{ textDecoration: "underline" }}>
                    Font Styles
                </Label>
                {viewModel.getFontStyles().map((fontStyle: string, index) => {
                    return (
                        <Box key={index}>
                            <Label mt={1} fontStyle={fontStyle as any}>
                                {fontStyle} Heading, {remToPx(theme.fontStyles[fontStyle].fontSize)}{" "}
                            </Label>
                        </Box>
                    );
                })}
            </Box>

            <Label mt={6} fontStyle={"h2"} color={"black"} style={{ textDecoration: "underline" }}>
                Common Colours
            </Label>
            <Box grid dc={"repeat(6, 1fr)"}>
                {viewModel.getCommonColours().map((palette: string, index) => {
                    return (
                        <Box key={index}>
                            <Label>{palette}</Label>
                            <Box mt={1} height="20px" width="150px" backgroundColor={theme.palette.common[palette]}>
                                <Label color={"white"}>{theme.palette.common[palette]}</Label>
                            </Box>
                        </Box>
                    );
                })}
            </Box>

            <Label mt={6} fontStyle={"h2"} style={{ textDecoration: "underline" }}>
                Palette
            </Label>
            <Box grid dc={"repeat(6, 1fr)"}>
                {viewModel.getPaletteColours().map((palette: string, index) => {
                    return (
                        <Box key={index}>
                            <Label>{palette}</Label>
                            <Box mt={1} height="25px" width="150px" backgroundColor={theme.palette[palette].lighter}>
                                <label style={{ color: theme.palette[palette].text }}>Lighter {theme.palette[palette].lighter}</label>
                            </Box>
                            <Box mt={1} height="25px" width="150px" backgroundColor={theme.palette[palette].light}>
                                <label style={{ color: theme.palette[palette].text }}>Light {theme.palette[palette].light}</label>
                            </Box>
                            <Box mt={1} height="25px" width="150px" backgroundColor={theme.palette[palette].main}>
                                <label style={{ color: theme.palette[palette].text }}>Main {theme.palette[palette].main}</label>
                            </Box>
                            <Box mt={1} height="25px" width="150px" backgroundColor={theme.palette[palette].dark}>
                                <label style={{ color: theme.palette[palette].text }}>Dark {theme.palette[palette].dark}</label>
                            </Box>
                            <Box mt={1} height="25px" width="150px" backgroundColor={theme.palette[palette].darker}>
                                <label style={{ color: theme.palette[palette].text }}>Darker {theme.palette[palette].darker}</label>
                            </Box>
                        </Box>
                    );
                })}
            </Box>

            <Box pt={100}>
                <Label pt={2} color={"black"} fontStyle="h1">
                    Buttons
                </Label>
                <Box p={2} backgroundColor={"default"} display={"flex"} alignContent={"center"} justifyContent={"space-between"}>
                    <Label fontStyle="h2">Title</Label>
                    <Button paletteColor={"primary"} command={new NullCommand()} displayName={"Add Something"} />
                </Box>
                <Box pt={1}>
                    <Label>Button width is determined by the text within it in most cases. 30px either side of the button text.</Label>
                    <br />
                    <Label>However in the case of two buttons next to each other they default to the widest button width.</Label>
                </Box>
                <Box mt={2}>
                    <Button mr={2} paletteColor={"primary"} command={new NullCommand()} displayName={"Save"} />
                    <Button mr={2} paletteColor={"primary"} command={new NullCommand()} displayName={"Save Changes"} />
                    <Button mr={2} paletteColor={"default"} command={new NullCommand()} displayName={"Cancel"} />
                    <Button paletteColor={"delete"} command={new NullCommand()} displayName={"Delete"} />
                </Box>
                <Box pt={5}>
                    <Label>But there are always exceptions</Label>
                </Box>
                <Box grid mt={5} columnGap={"20px"} dc={"162px 162px 162px"}>
                    <Button paletteColor={"primary"} command={new NullCommand()} displayName={"Save"} />
                    <Button paletteColor={"default"} command={new NullCommand()} displayName={"Cancel"} />
                    <Button paletteColor={"delete"} command={new NullCommand()} displayName={"Delete"} />
                </Box>
            </Box>
            <Box>
                <Label pt={2} color={"black"} fontStyle="h1">
                    Alignment Check
                </Label>
                <Box flexBox>
                    <Input displayName={"With Label"} command={new NullCommand()} value={() => "Dummy Value"} />
                    <Input command={new NullCommand()} value={() => "Without label"} />
                    <EditSelect displayName={"With Label"} command={new NullCommand()} options={lookupStore.dummyData()} value={() => "Dummy Value"} />
                    <EditSelect command={new NullCommand()} options={lookupStore.dummyData()} value={() => "Dummy Value"} />
                    <RadioButton command={new NullCommand()} options={lookupStore.YesNoOptions()} value={() => "yes"} />
                    <EditDate displayName={"With Label"} command={new NullCommand()} value={() => new Date()} />
                    <EditDate command={new NullCommand()} value={() => new Date()} />
                    <CheckBox displayName={"With Label"} command={new NullCommand()} value={() => true} />
                    <CheckBox command={new NullCommand()} value={() => true} />
                </Box>
            </Box>
            <Box pt={100}>
                <Label pt={2} color={"black"} fontStyle="h1">
                    Form elements
                </Label>
                <Label fontStyle={"h4"}>(All fields are forced to be in an errored state)</Label>
                <Box pt={3} grid dc={"1fr"}>
                    <Input
                        displayName="Input"
                        command={viewModel.updateFirstNameCommand}
                        placeholder="First Name"
                        value={() => viewModel.getValue("firstName")}
                        validationMessage={() => "Validation Error Message Sample"}
                    />
                    <Input
                        displayName="Prefix Icon"
                        value={() => viewModel.getValue("lastName")}
                        command={viewModel.updateLastNameCommand}
                        placeholder={"Enter text..."}
                        prefix={<DeleteIcon />}
                        validationMessage={() => "Validation Error Message Sample"}
                    />
                    <Input
                        displayName="Suffix Icon"
                        value={() => viewModel.getValue("lastName")}
                        command={viewModel.updateLastNameCommand}
                        placeholder={"Enter text..."}
                        prefix={<DeleteIcon />}
                        validationMessage={() => "Validation Error Message Sample"}
                    />

                    <Input
                        displayName="Both Prefix and Suffix Icons"
                        value={() => viewModel.getValue("lastName")}
                        command={viewModel.updateLastNameCommand}
                        placeholder={"Enter text..."}
                        prefix={<DeleteIcon />}
                        suffix={<DeleteIcon />}
                        validationMessage={() => "Validation Error Message Sample"}
                    />
                    <Input
                        multiline={true}
                        displayName="Multiline"
                        value={() => viewModel.getValue("lastName")}
                        command={viewModel.updateLastNameCommand}
                        placeholder={"Enter text..."}
                        prefix={<DeleteIcon />}
                        rows={10}
                        validationMessage={() => "Validation Error Message Sample"}
                    />
                    <Input
                        displayName="Integers Only Mask"
                        command={viewModel.updateAgeCommand}
                        placeholder="Whole numbers only"
                        value={() => viewModel.getValue("age")}
                        validationMessage={() => "Validation Error Message Sample"}
                        maskedOptions={MaskedOptions.NumbersOnly()}
                    />

                    <Input
                        displayName="Decimals Mask"
                        command={viewModel.updateAmountCommand}
                        placeholder="Whole numbers only"
                        value={() => viewModel.getValue("amount")}
                        validationMessage={() => "Validation Error Message Sample"}
                        maskedOptions={MaskedOptions.Decimals()}
                    />

                    <Input
                        displayName="Currency Mask"
                        command={viewModel.updateAmountCommand}
                        placeholder="Amount in pence"
                        value={() => viewModel.getValue("amount")}
                        validationMessage={() => "Validation Error Message Sample"}
                        maskedOptions={MaskedOptions.Currency()}
                    />
                    <Input
                        displayName="Phone Number Mask"
                        command={viewModel.updatePhoneNumberCommand}
                        value={() => viewModel.getValue("phoneNumber")}
                        validationMessage={() => "Validation Error Message Sample"}
                        maskedOptions={MaskedOptions.InternationalPhoneNumber()}
                    />
                    <Input
                        displayName="Email Mask"
                        command={viewModel.updateEmailAddressCommand}
                        value={() => viewModel.getValue("emailAddress")}
                        validationMessage={() => "Validation Error Message Sample"}
                        maskedOptions={MaskedOptions.Email()}
                    />
                    <Input
                        displayName="AlphaNumeric"
                        command={viewModel.updateFirstNameCommand}
                        value={() => viewModel.getValue("emailAddress")}
                        validationMessage={() => "Validation Error Message Sample"}
                        maskedOptions={MaskedOptions.AlphaNumeric()}
                    />
                    <EditSelect
                        displayName={"Select"}
                        command={viewModel.updateTeamCommand}
                        options={viewModel.getTeams}
                        value={() => viewModel.getValue("team")}
                        validationMessage={() => "Validation Error Message Sample"}
                    />
                    <EditSelect
                        displayName={"Multi Select"}
                        command={viewModel.updateTeamsCommand}
                        options={viewModel.getTeams}
                        value={() => viewModel.getValue("teams")}
                        isMulti={true}
                        validationMessage={() => "Validation Error Message Sample"}
                    />
                    <EditDate
                        displayName={"Date with time"}
                        value={() => viewModel.getValue("dob")}
                        command={viewModel.updateDOBCommand}
                        dateFormat={"DD/MM/YYYY HH:mm"}
                        validationMessage={() => "Validation Error Message Sample"}
                    />
                    <EditDate
                        displayName={"Date without time"}
                        value={() => viewModel.getValue("dob")}
                        command={viewModel.updateDOBCommand}
                        dateFormat={"DD/MM/YYYY"}
                        includeTime={false}
                        validationMessage={() => "Validation Error Message Sample"}
                    />
                    <EditDate
                        displayName={"Time only"}
                        value={() => viewModel.getValue("dob")}
                        command={viewModel.updateDOBCommand}
                        timePicker={true}
                        dateFormat={"HH:mm"}
                        validationMessage={() => "Validation Error Message Sample"}
                    />
                </Box>
            </Box>
        </Box>
    );
});
