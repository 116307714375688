import { HeaderViewModel } from "./HeaderViewModel";
import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useLocation, useNavigate } from "react-router";
import { Box, Label } from "../../Components";
import styled from "@emotion/styled";
import { motion } from "framer-motion";
import { theme } from "../../Styles";
import { AppUrls } from "AppUrls";
import { container } from "tsyringe";
import { DomainStore } from "Stores/Domain/DomainStore";
import { H4 } from "Components/Primitives/TextElements/TextElements";
import { set } from "lodash";

const domainStore = container.resolve(DomainStore);
interface MenuItemProps {
    text: string;
    path: string;
    canExecute?: () => boolean;
    viewModel: HeaderViewModel;
    hasSubMenu?: boolean;
    subMenuItems?: subMenuItem[];
}
export const MenuItem: React.FC<MenuItemProps> = observer((props) => {
    let location = useLocation();
    let navigate = useNavigate();
    const [showSubMenu, setShowSubMenu] = useState(false);

    useEffect(() => {
        setShowSubMenu(false);
        props.viewModel.setActiveFromLocation(location.pathname, props.path, props.text);
    }, [location]);

    const onClick = () => {
        if (props.path == AppUrls.Client.Folklife) {
            window.open("https://folke.life/");
        } else if (props.path == AppUrls.Client.HarbourArm) {
            window.open("https://folkestoneharbourarm.co.uk/");
        } else if (props.path == AppUrls.Client.Buying) {
            setShowSubMenu(!showSubMenu);
        } else {
            setShowSubMenu(false);
            domainStore.setSideBarClosed(!domainStore.isSideBarClosed);
            props.viewModel.setActiveMenuItem(props.text);
            navigate(props.path);
        }
    };

    const onClickSubMenu = (path: string) => {
        domainStore.setSideBarClosed(!domainStore.isSideBarClosed);
        props.viewModel.setActiveMenuItem(props.text);
        navigate(path);
        setShowSubMenu(!showSubMenu);
    };

    return props.canExecute!() ? (
        <>
            <Box width={props.viewModel.isDesktop ? "auto" : "100%"}>
                <Box
                    className={"menuitem"}
                    onClick={onClick}
                    style={{ position: "relative", textAlign: props.viewModel.isMobile ? "left" : "center", minWidth: "70px", cursor: "pointer" }}
                >
                    <span style={{ position: "relative", zIndex: 10 }}>
                        <HeaderItem
                            isDesktop={props.viewModel.isDesktop}
                            p={props.viewModel.isMobile ? "5px" : ""}
                            className={props.viewModel.activeMenuItem === props.text ? "selected-item" : ""}
                            style={{ cursor: "pointer" }}
                            color={props.viewModel.isDesktop ? "primary" : "secondary"}
                        >
                            {props.text}
                        </HeaderItem>
                    </span>
                    {/* {props.viewModel.activeMenuItem === props.text && <Underline className={"menuitem"} layoutId={"underline"} />} */}
                    {/* {props.viewModel.activeMenuItem === props.text && (
                    <HoverBox
                        className={"menuitem"}
                        layoutId="hoverbox"
                        whileHover={{
                            backgroundColor: [
                                theme.palette.secondary.main,
                                theme.palette.secondary.dark,
                                theme.palette.secondary.main,
                                theme.palette.secondary.light,
                                theme.palette.secondary.main,
                            ],
                            transition: { duration: 2, repeat: Infinity },
                        }}
                    />
                )} */}
                </Box>
                <SubHeader isDesktop={props.viewModel.isDesktop} hidden={props.hasSubMenu === false || showSubMenu === false}>
                    {props.subMenuItems?.map((item) => {
                        return (
                            <SubHeadingH4 padding="10px 10px" color="primary" isDesktop={props.viewModel.isDesktop} onClick={() => onClickSubMenu(item.path)}>
                                {item.text}
                            </SubHeadingH4>
                        );
                    })}
                </SubHeader>
            </Box>
        </>
    ) : null;
});
MenuItem.defaultProps = {
    canExecute: () => true,
};

const HoverBox = styled(Box)`
    position: absolute;
    top: -25px;
    background-color: ${theme.palette.secondary.main};
    height: 64px;
    width: 100%;
`;

const Underline = styled(Box)`
    position: absolute;
    background-color: ${theme.palette.secondary.darker};
    height: 3px;
    width: 100%;
    top: 33px;
    z-index: 5;
`;

interface IHeaderItem {
    isDesktop?: boolean;
}

const HeaderItem = styled(H4)<IHeaderItem>`
    font-size: ${(props) => (props.isDesktop ? "21px" : "40px")};
    line-height: ${(props) => (props.isDesktop ? "21px" : "40px")};
    user-select: none;
    text-align: center;

    &.selected-item,
    &:hover {
        text-decoration: underline;
        text-decoration-color: ${(props) => (props.isDesktop ? theme.palette.common.primary : theme.palette.common.secondary)};
        text-decoration-thickness: 1px;
        text-underline-offset: 5px;
    }
`;

interface ISubHeader {
    isDesktop?: boolean;
}

const SubHeader = styled(Box)<ISubHeader>`
    position: ${(props) => (props.isDesktop ? "absolute" : "block")};
    padding: ${(props) => (props.isDesktop ? "10px 20px" : "10px")};
    background-color: ${theme.palette.common.secondary};
    height: auto;
    top: 48px;
    transition: all 0.5s ease;
`;

interface ISubHeadingH4 {
    isDesktop?: boolean;
}

const SubHeadingH4 = styled(H4)<ISubHeadingH4>`
    font-size: ${(props) => (props.isDesktop ? "18px" : "22px")};
    cursor: pointer;
    user-select: none;
    &:hover {
        text-decoration: underline;
        text-decoration-color: ${theme.palette.common.primary};
        text-decoration-thickness: 1px;
        text-underline-offset: 5px;
    }
`;

export interface subMenuItem {
    text: string;
    path: string;
}
