import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { Validator } from "Application/Validation";

export class BuyerLoginModel extends ModelBase<BuyerLoginModel> {
    public id: string = "";

    constructor() {
        super();
        makeObservable(this, {
            id: observable,
        });
    }
}

export class BuyerLoginModelValidator extends Validator<BuyerLoginModel> {
    constructor() {
        super();
        //this.ruleFor("id").notEmpty();
    }
}
