import { makeObservable, observable, runInAction } from "mobx";
import { ViewModelBase } from "@shoothill/core";
import { APIClient, ICommand, IKeyState, RelayCommand } from "Application";
import { HomesModel, HomesModelValidator } from "./HomesModel";
import { useNavigate } from "react-router";
import { AppUrls } from "AppUrls";

export class HomesViewModel extends ViewModelBase<HomesModel> {
    public apiClient = new APIClient();
    public navigate = useNavigate();
    public isVideoMuted: boolean = true;

    public index: number = 0;

    constructor() {
        super(new HomesModel());
        this.setValidator(new HomesModelValidator());
        makeObservable(this, {
            index: observable,
            isVideoMuted: observable,
        });
    }
    public navTo: ICommand = new RelayCommand((value: string) => {
        this.navigate(value);
    });

    doSomething: ICommand = new RelayCommand((value: string) => {});

    updateIndex = (index: number) => {
        this.index = index;
    };

    public toggleMute: ICommand = new RelayCommand(() => {
        this.isVideoMuted = !this.isVideoMuted;
    });
}
