import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { Validator } from "Application/Validation";

import { HouseTypeModel } from "./HouseTypeModel";

export class PropertyRoomModel extends ModelBase<PropertyRoomModel> {
    public id: string = "";
    public sqft: string = "";
    public name: string = "";
    public houseTypeId: string = "";

    constructor() {
        super();
        makeObservable(this, {
            id: observable,
            sqft: observable,
            name: observable,
            houseTypeId: observable,
        });
    }

    public featureList: string[] = ["item 1", "item 2"];
}

export class PropertyRoomModelValidator extends Validator<PropertyRoomModel> {
    constructor() {
        super();
        //this.ruleFor("id").notEmpty();
    }
}
