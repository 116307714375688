import React from "react";
import { UnsupportedBrowserView } from "../UnsupportedBrowser/UnsupportedBrowserView";
import { Footer } from "../Footer/FooterView";
import { InfoBar } from "../../Components/InfoBar";
import { Outlet } from "react-router";
import { Box } from "../../Components";
import { pxToRem } from "../../Application";
import laptop from "Assets/laptop.jpg";
import { observer } from "mobx-react-lite";
import { Header } from "../Header/Header";
import styled from "@emotion/styled";

export const Root = styled.div`
    display: flex;
    height: 100%;
`;

const Content = styled.div`
    box-shadow: 3px 0px 6px #00000029;
    display: flex;
    flex: 1.66;
    overflow: hidden;
`;

const Image = styled.div`
    background-image: url(${laptop});
    background-position: left center;
    background-repeat: no-repeat;
    background-size: cover;
    flex: 1;
`;
//EN: use empty generic type to get default react props
export const SplitLayout: React.FC<{}> = observer((props) => {
    if (!props) return <>Props undefined</>;
    return (
        <>
            {window.IE11 ? (
                <UnsupportedBrowserView />
            ) : (
                <>
                    <Header />
                    {/*EN: Make sure you apply overflowContainer classname to all your body content*/}
                    <Box className={"overflowContainer"}>
                        <InfoBar />
                        <Root>
                            <Content>
                                <Outlet />
                            </Content>
                            <Image id={"laptop"}></Image>
                        </Root>
                        <Footer />
                    </Box>
                </>
            )}
        </>
    );
});
