import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { Validator } from "Application/Validation";

import { PropertyListItemModel } from "./PropertyListItemModel";

export class HouseTypeModel extends ModelBase<HouseTypeModel> {
    public id: string = "";
    public name: string = "";
    public description: string = "";

    constructor() {
        super();
        makeObservable(this, {
            id: observable,
            name: observable,
            description: observable,
        });
    }
}

export class HouseTypeModelValidator extends Validator<HouseTypeModel> {
    constructor() {
        super();
        //this.ruleFor("id").notEmpty();
    }
}
