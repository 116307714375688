import { makeObservable, observable, runInAction } from "mobx";
import { ViewModelBase } from "@shoothill/core";
import { APIClient, ICommand, IKeyState, RelayCommand } from "Application";
import { OurVisionModel, OurVisionModelValidator } from "./OurVisionModel";
import { useNavigate } from "react-router";

export class OurVisionViewModel extends ViewModelBase<OurVisionModel> {
    public apiClient = new APIClient();

    public navigate = useNavigate();

    constructor() {
        super(new OurVisionModel());
        this.setValidator(new OurVisionModelValidator());
        makeObservable(this, {});
    }

    public navTo: ICommand = new RelayCommand((value: string) => {
        this.navigate(value);
    });

    doSomething: ICommand = new RelayCommand((value: string) => {});
}
