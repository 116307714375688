import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { Box, theme } from "Application";
import { FullWidthCentFlexContainer } from "Components/FullWidthCentFlexCont";
import { InnerWrapper } from "Components/InnerWrapper";
import { BannerImageFull } from "Components/BannerImageFull/BannerImageFull";

import Banner from "Assets/AboutBanner.jpg";
import Banner2 from "Assets/AboutMap.jpg";
import { TitleLeftTextRightBlock } from "Components/TitleLeftTextRightBlock/TitleLeftTextRightBlock";
import { ShorelineButton } from "Components/ShorelineButton";
import { AboutViewModel } from "./AboutViewModel";
import styled from "@emotion/styled";
import { P } from "Components/Primitives/TextElements/TextElements";

export const AboutView: React.FC = observer(() => {
    const [viewModel] = useState<AboutViewModel>(new AboutViewModel());

    return (
        <>
            <FullWidthCentFlexContainer>
                <InnerWrapper>
                    <BannerImageFull imageUrl={Banner} />

                    <TitleLeftTextRightBlock
                        title="About"
                        textBlock={`Folkestone Harbour & Seafront Development Company is leading a comprehensive project designed to regenerate the harbour area and create a vibrant new seafront for the town.`}
                        buttons={
                            <>
                                <ShorelineButton displayName="MORE ABOUT THE DEVELOPER" value="https://www.folkestoneseafront.com/about/" command={viewModel.navTo} />
                                <ShorelineButton displayName="FOLKE.LIFE" value="https://folke.life/" command={viewModel.navTo} />
                            </>
                        }
                        isMobile={viewModel.isMobile}
                        hasLargeTitle={true}
                        hasLargerBodyText={true}
                    />

                    <ContactUsBox>
                        <P pt="58px" className="contactUs" color="secondary">
                            CONTACT US
                        </P>

                        <a href="mailto:sales@folkestoneseafront.com">
                            <P className={viewModel.isMobile ? "linkTextMob" : "linkText"} pt="36px" pb="11px">
                                sales@folkestoneseafront.com
                            </P>
                        </a>
                        <a href="tel:01303 760 940">
                            <P className={viewModel.isMobile ? "linkTextMob" : "linkText"} pt="36px" pb="30px">
                                01303 760 940
                            </P>
                        </a>

                        <P p="20px 0px 36px 0px" className="contactUs" color="secondary">
                            PRESS ENQUIRIES
                        </P>

                        <a href="mailto:press@folkestoneseafront.com">
                            <P className={viewModel.isMobile ? "linkTextMobSmaller" : "linkTextSmaller"} pb="58px">
                                press@folkestoneseafront.com
                            </P>
                        </a>
                    </ContactUsBox>

                    <BannerImageFull imageUrl={Banner2} />

                    <LinksBox>
                        <Box grid dc="1fr 1fr 1fr" p="118px 0px" columnGap="130px">
                            <Box>
                                <a href="https://acme.ac/" target="_blank">
                                    <P className="smallerText">ARCHITECTS</P>
                                    <P color="primary">Acme.ac</P>
                                </a>
                            </Box>
                            <Box>
                                <a href="https://8hollandstreet.com/" target="_blank">
                                    <P className="smallerText">interior design</P>
                                    <P color="primary">8HollandStreet.com</P>
                                </a>
                            </Box>
                            <Box>
                                <a href="https://spacehubdesign.com/" target="_blank">
                                    <P className="smallerText">landscaping</P>
                                    <P color="primary">SpaceHubDesign.com</P>
                                </a>
                            </Box>
                            <Box>
                                <a href="https://www.jenner-group.co.uk/" target="_blank">
                                    <P className="smallerText">construction</P>
                                    <P color="primary">Jenner-Group.co.uk</P>
                                </a>
                            </Box>
                            <Box>
                                <a href="https://www.spiderprojects.co.uk/" target="_blank">
                                    <P className="smallerText">project management</P>
                                    <P color="primary">SpiderProjects.co.uk</P>
                                </a>
                            </Box>
                            <Box>
                                <a href="https://dennis.studio/" target="_blank">
                                    <P className="smallerText">graphic design</P>
                                    <P color="primary">Dennis.Studio</P>
                                </a>
                            </Box>
                        </Box>
                    </LinksBox>
                </InnerWrapper>
            </FullWidthCentFlexContainer>
        </>
    );
});

const ContactUsBox = styled(Box)`
    background-color: #cc6b2c;

    a {
        color: ${theme.palette.common.secondary};
    }

    .linkText {
        text-align: center;
        font-size: 38px;
        color: ${theme.palette.common.secondary};
        text-decoration-color: ${theme.palette.common.secondary};
    }

    .linkTextMob {
        text-align: center;
        font-size: 24px;
        color: ${theme.palette.common.secondary};
        text-decoration-color: ${theme.palette.common.secondary};
    }

    .linkTextSmaller {
        text-align: center;
        font-size: 20px;
        color: ${theme.palette.common.secondary};
        text-decoration-color: ${theme.palette.common.secondary};
    }

    .linkTextMobSmaller {
        text-align: center;
        font-size: 18px;
        color: ${theme.palette.common.secondary};
        text-decoration-color: ${theme.palette.common.secondary};
    }

    .contactUs {
        color: #ecdfc9;
        text-align: center;
        font-family: sohne;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 2.38px;
        text-transform: uppercase;
    }
`;

export const LinksBox = styled(Box)`
    display: flex;
    justify-content: center;
    a {
        text-decoration: none;
        text-align: center;
    }

    .smallerText {
        color: #1f2747;
        text-align: center;
        font-family: sohne;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.72px;
        text-transform: uppercase;
    }
`;
