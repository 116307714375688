import { action, makeObservable, observable, runInAction } from "mobx";
import { ViewModelBase } from "@shoothill/core";
import { APIClient, ICommand, IKeyState, RelayCommand } from "Application";
import { ContactModel, ContactModelValidator } from "./ContactModel";
import { PostcontactFormEndpoint } from "./Endpoints/PostContactFormEndPoint";
import { Logger } from "index";
import { NavigateFunction, useNavigate } from "react-router";

export class ContactViewModel extends ViewModelBase<ContactModel> {
    public apiClient = new APIClient();
    public hbpResponseSuccessful: boolean = false;
    public messageSent = false;
    public navigate: NavigateFunction | undefined = undefined;
    public isProcessing: boolean = false;
    public isModalOpen: boolean = false;
    sendEventCallback: ((eventName: string) => void) | undefined = undefined;

    constructor(navigate: any | undefined) {
        super(new ContactModel());

        if (navigate === undefined) {
            this.navigate = useNavigate();
        } else {
            this.navigate = navigate;
        }

        this.setValidator(new ContactModelValidator());
        makeObservable(this, {
            hbpResponseSuccessful: observable,
            setHbpResponseSuccessful: action,
            messageSent: observable,
            isProcessing: observable,
            isModalOpen: observable,
        });
    }

    public setHbpResponseSuccessful = (value: boolean) => (this.hbpResponseSuccessful = value);

    public updateFirstName: ICommand = new RelayCommand((value: string) => {
        this.model.firstName = value;
    });

    public updateLastName: ICommand = new RelayCommand((value: string) => {
        this.model.surname = value;
    });

    public updateAddressLine1: ICommand = new RelayCommand((value: string) => {
        this.model.addressLine1 = value;
    });

    public updateAddressLine2: ICommand = new RelayCommand((value: string) => {
        this.model.addressLine2 = value;
    });

    public updateCity: ICommand = new RelayCommand((value: string) => {
        this.model.city = value;
    });

    public updatePostcode: ICommand = new RelayCommand((value: string) => {
        this.model.postcode = value;
    });

    public updateEmail: ICommand = new RelayCommand((value: string) => {
        this.model.contactEmail = value;
    });

    public updatePhoneNumber: ICommand = new RelayCommand((value: string) => {
        this.model.primaryContactNumber = value;
    });

    public updatePrefersEmails: ICommand = new RelayCommand((value: string) => {
        this.model.preferredContactMethodEmail = !this.model.preferredContactMethodEmail;
    });

    public updatePrefersTelCalls: ICommand = new RelayCommand((value: string) => {
        this.model.preferredContactMethodPhone = !this.model.preferredContactMethodPhone;
    });

    public updateMessage: ICommand = new RelayCommand((value: string) => {
        this.model.notes = value;
    });

    public updateBudgetFrom: ICommand = new RelayCommand((value: string) => {
        this.model.budgetFrom = value;
    });

    public updateBudgetTo: ICommand = new RelayCommand((value: string) => {
        this.model.budgetTo = value;
    });

    public updateWantsUpdates: ICommand = new RelayCommand((value: string) => {
        this.model.acceptsMarketing = !this.model.acceptsMarketing;
    });

    public updateAgreesPrivacyPolicy: ICommand = new RelayCommand((value: string) => {
        this.model.agreePrivacyPolicy = !this.model.agreePrivacyPolicy;
    });

    public closeSentModal = () => {
        //  setTimeout(this.backtoHomepage, 2000);
        this.backtoHomepage;
        this.isModalOpen = true;
    };

    public onSubmit: ICommand = new RelayCommand(() => {
        this.model.firstValidate = false;

        if (this.isModelValid(true, true) === true) {
            this.PostContactFormAsync().then(() => {
                this.model = new ContactModel();

                if (this.sendEventCallback != undefined) {
                    this.sendEventCallback("generate_lead_register");
                }
            });

            this.messageSent = true;

            // this.model.clearForm();
        }
    });

    public backtoHomepage: ICommand = new RelayCommand(() => {
        if (this.navigate !== undefined) {
            this.navigate("/");
        }
    });

    public navToFormSentPage = () => {
        if (this.navigate !== undefined) {
            this.navigate("/sent");
        }
    };

    public async PostContactFormAsync(): Promise<void> {
        const postContactForm = new PostcontactFormEndpoint(this);
        this.isProcessing = true;
        await this.apiClient.sendAsync(postContactForm, this.model);

        if (this.apiClient.IsRequestSuccessful) {
            let response = this.apiClient.Response();
            runInAction(() => {
                // this.isModalOpen = true;
                this.navToFormSentPage();
                this.isProcessing = false;
            });
        }

        Logger.logInformation("Posted contact form");
    }

    public get getPreferredContactMethodValidationMessage(): string {
        let retVal = "";
        if (this.getError("preferredContactMethodEmail") || this.getError("preferredContactMethodPhone")) {
            retVal = "Please select a preferred contact method";
        }
        return retVal;
    }
}
