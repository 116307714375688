import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { Box, Button, ICommand, Input, RelayCommand } from "Application";
import { Modal } from "@fluentui/react";
import styled from "@emotion/styled";

import LoginLogo from "Assets/ShorelineLogoMk2.svg";
import { BuyerLoginViewModel } from "./BuyerLoginViewModel";
import { SignIn } from "./SignIn/SignIn";
import { SignUpOrUpdate } from "./SignUpOrUpdate/SignUpOrUpdate";
import { ForgotPassword } from "./SignIn/ForgotPassword/ForgotPassword";
import { AppUrls } from "AppUrls";

interface IBuyerLogin {
    isOpen: boolean;
    setIsLoginModalOpen: (isOpen: boolean) => void;
}

export enum BuyerPanelView {
    SIGNIN = 0,
    SIGNUP = 10,
    FORGOTPASSWORD = 20,

    UPDATE = 1000,
}

export const BuyerLogin: React.FC<IBuyerLogin> = observer(({ isOpen, setIsLoginModalOpen }) => {
    const [viewModel] = useState<BuyerLoginViewModel>(new BuyerLoginViewModel());
    const [panelView, setPanelView] = useState<BuyerPanelView>(BuyerPanelView.SIGNIN);

    const closeModal: ICommand = new RelayCommand(() => {
        shutDown();
    });

    const shutDown = () => {
        setIsLoginModalOpen(false);
        setPanelView(BuyerPanelView.SIGNIN);
    };

    const toggleView: ICommand = new RelayCommand((value: BuyerPanelView) => {
        setPanelView(value);
    });

    const onCompleteCallback = () => {
        setIsLoginModalOpen(false);
        viewModel.history.push(AppUrls.Client.BuyerLoginDashboard);
    };

    return (
        <>
            <Modal isOpen={isOpen} styles={{ main: [{ background: "#ecdfc9", overflow: "hidden", maxWidth: "650px" }], root: [{ background: "rgba(0, 0, 0, 0.75)" }] }}>
                <TitleWrapper>
                    <CloseIconButton command={closeModal} displayName="X" />
                    <img src={LoginLogo} />
                    <ButtonWrapper showIf={panelView == BuyerPanelView.SIGNIN || panelView == BuyerPanelView.SIGNUP} pb="30px">
                        <SignInButton command={toggleView} className={panelView == BuyerPanelView.SIGNIN ? "selected" : ""} value={BuyerPanelView.SIGNIN} displayName="SIGN IN" />
                        <SignUpButton command={toggleView} className={panelView == BuyerPanelView.SIGNUP ? "selected" : ""} value={BuyerPanelView.SIGNUP} displayName="SIGN UP" />
                    </ButtonWrapper>
                </TitleWrapper>
                <LoginWrapper>
                    {panelView == BuyerPanelView.SIGNIN && <SignIn setPanelView={setPanelView} onComplete={onCompleteCallback} />}
                    {panelView == BuyerPanelView.SIGNUP && <SignUpOrUpdate whereIsFormUsed={BuyerPanelView.SIGNUP} />}
                    {panelView == BuyerPanelView.FORGOTPASSWORD && <ForgotPassword setPanelView={setPanelView} closeModal={closeModal} />}
                </LoginWrapper>
            </Modal>
        </>
    );
});

const CloseIconButton = styled(Button)`
    position: absolute;
    top: 0px;
    right: 0px;
    background: transparent;
    outline: none;
`;

const TitleWrapper = styled(Box)`
    position: relative;
    text-align: center;
    padding: 20px 20px 0px 20px;
`;

const LoginWrapper = styled(Box)`
    padding: 0px 20px 20px 20px;

    label {
        font-weight: 600;
    }
`;

const SignInButton = styled(Button)`
    border-radius: 0px;
    color: black;
    background: #ffffff;

    &.selected {
        color: white;
        background: #1f2747;
    }
`;

const SignUpButton = styled(Button)`
    border-radius: 0px;
    color: black;
    background: #ffffff;

    &.selected {
        color: white;
        background: #1f2747;
    }
`;

const ButtonWrapper = styled(Box)`
    padding-top: 20px;
`;
