import styled from "@emotion/styled";
import { Box, Button, EditSelect, ICommand, Input, Label, RelayCommand, theme } from "Application";

export const MenuItem = styled(Box)`
    display: flex;
    align-items: center;
    text-decoration: underline;
    cursor: pointer;
`;

export const BathBed = styled(Box)`
    display: flex;
    align-items: center;
`;

export const RoundedGreenBox = styled(Box)`
    margin-bottom: 10px;

    a {
        text-decoration: none;
        color: white;
    }

    .editselect__control {
        border-radius: 5px;
        border: none;
    }

    label {
        color: white;
    }

    .editselect__control {
        border-radius: 5px !important;
        border: transparent !important;
    }

    .editselect__value-container {
        padding: 10px 8px !important;
    }
`;

export const RoundedLighterBox = styled(Box)`
    background-color: #ecdfc9;
    padding: 20px;
    margin-bottom: 10px;
    text-align: center;
`;

export const RoundedLighterBoxNoPadding = styled(Box)`
    margin-bottom: 10px;
    text-align: center;
`;

export const EnquiryBox = styled(Box)`
    background-color: #2b6298;
    padding: 36px 40px 20px 40px;
    width: 100%;
    .privacy-policy-link {
        text-decoration: underline;
        color: white;
    }
`;
export const EnqBoxWrapper = styled(Box)`
    width: 100%;

    a {
        color: ${theme.palette.common.primary};
    }
`;

export const EnquiryInnerBox = styled(Box)`
    background-color: #566f6a;
    border-color: white;
    border-style: solid;
    border-width: 1px;
    padding: 30px 16px 30px 16px;

    .underline-link {
        text-decoration: underline;
    }
`;

export const StampDutyInput = styled(Input)`
    height: 40px;
    border-radius: 0px;
`;

export const StampDutySelect = styled(EditSelect)`
    height: 40px;
    border-radius: 0px;
`;

export const EnquiryInput = styled(Input)`
    height: 50px;
    border-radius: 20px;
    border-style: none;
`;

export const EnquiryText = styled(Input)`
    border-radius: 20px;
    border-style: none;
`;

export const EnquiryBoxes = styled(Box)`
    padding-bottom: 15px;
`;

export const ListItem = styled.li`
    list-style-position: inside;
`;
export const ListBox = styled.div`
    //list-style-position: inside;

    li {
        margin-left: 20px;
    }

    ul {
        line-height: 1.5;
    }
`;

export const SpinnerBox = styled(Box)`
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const RightBox = styled(Box)`
    background-color: #cc6b2c;
    padding: 30px 20px;
`;
