import { Logger } from "index";
import { EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";
import { UserFavourite } from "Views/BuyerLoginDashboard/UserFavourite";

export class GetUserFavouritesResponse {
    public favourites: UserFavourite[] = [];

    public constructor() {}
}

export class GetUserFavouritesEndpoint extends EndpointWithoutRequest<GetUserFavouritesResponse> {
    constructor() {
        super();
        this.verb(Http.Get);
        this.path("/api/user/getUserFavourites");
    }

    public async HandleResponseAsync(response: GetUserFavouritesResponse): Promise<GetUserFavouritesResponse> {
        Logger.logInformation("Response", response);

        //Return your model
        return response;
    }
}
