import React from "react";
import { observer } from "mobx-react-lite";
import { Box } from "Application";

export const FormatBathroom = (bathrooms: number) => {
    let retVal: string = "";
    if (bathrooms > 1) {
        retVal = bathrooms + " bathrooms";
    } else if ((bathrooms = 1)) {
        retVal = bathrooms + " bathroom";
    }

    return retVal;
};

export const FormatBedroom = (bedrooms: number) => {
    let retVal: string = "";
    if (bedrooms > 1) {
        retVal = bedrooms + " bedrooms";
    } else if ((bedrooms = 1)) {
        retVal = bedrooms + " bedroom";
    }

    return retVal;
};
