import { Box, Label } from "Application";
import { observer } from "mobx-react-lite";
import React from "react";

interface Props {
    haveError: boolean;
    displayName?: string;
    validationMessage: () => string;
    style?: any;
}
export const ErrorExclamationView: React.FC<Props> = observer((props: Props) => {
    const errorLabel = () => {
        if (props.haveError) {
            return (
                <Box display={"flex"} pt="10px">
                    <Box>
                        <Box mb={1}>
                            {props.displayName ? (
                                <Label fontStyle="error" color={"error"}>
                                    {props.displayName}
                                </Label>
                            ) : (
                                <Label color={"error"}>{props.displayName}</Label>
                            )}
                        </Box>
                    </Box>
                    {/*<TooltipHost
                        tooltipProps={{
                            calloutProps: {
                                gapSpace: 0,
                                styles: {
                                    beak: { background: theme.palette.error.main },
                                    beakCurtain: { background: theme.palette.error.main },
                                    calloutMain: { background: theme.palette.error.main },
                                },
                            },
                        }}
                        content={
                            <Label fontStyle={"h5"} color={"white"}>
                                {(props.validationMessage() as string) || "There has been an error"}
                            </Label>
                        }
                        id={nanoid()}
                        styles={{ root: { display: "inline-block" } }}
                    >
                        <Box mt={"-3px"}>
                            <ExclamationIconSVG color={"white"} backgroundcolor={theme.palette.error.main} />
                        </Box>
                    </TooltipHost>*/}
                </Box>
            );
        } else {
            return null;
        }
    };

    return errorLabel();
});
