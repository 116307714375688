import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { Validator } from "Application/Validation";

export class SelectedPropertyModel extends ModelBase<SelectedPropertyModel> {
    public id: string = "";
    public firstValidate = true;

    // Contact Form

    public firstName: string = "";
    public surname: string = "";
    public addressLine1: string = "";
    public addressLine2: string = "";
    public city: string = "";
    public postcode: string = "";
    public contactEmail: string = "";
    public primaryContactNumber: string = "";
    public notes: string = "";
    public acceptsMarketing: boolean = false;
    public agreePrivacyPolicy: boolean = false;
    public developmentName: string = "";
    public plotNumber: number = 0;
    public companyID: string = "";
    public budgetFrom: string = "400000";
    public budgetTo: string = "1000000";

    // Temp fix for preferred contact method
    public preferredContactMethodEmail: boolean = false;
    public preferredContactMethodPhone: boolean = false;

    constructor() {
        super();
        makeObservable(this, {
            id: observable,
            firstName: observable,
            surname: observable,
            addressLine1: observable,
            addressLine2: observable,
            city: observable,
            postcode: observable,
            contactEmail: observable,
            primaryContactNumber: observable,
            notes: observable,
            acceptsMarketing: observable,
            agreePrivacyPolicy: observable,
            developmentName: observable,
            plotNumber: observable,
            companyID: observable,
            budgetFrom: observable,
            budgetTo: observable,
            preferredContactMethodEmail: observable,
            preferredContactMethodPhone: observable,
        });
    }
}

export class SelectedPropertyModelValidator extends Validator<SelectedPropertyModel> {
    constructor() {
        super();
        //this.ruleFor("id").notEmpty();
        this.ruleFor("firstName")
            .notEmpty()
            .withMessage("Please enter a first name")
            .minLength(2)
            .maxLength(50)
            .when((model) => model.firstValidate === false);
        this.ruleFor("surname")
            .notEmpty()
            .withMessage("Please enter a last name")
            .minLength(2)
            .maxLength(50)
            .when((model) => model.firstValidate === false);

        this.ruleFor("addressLine1")
            .notEmpty()
            .withMessage("Please enter your address")
            .minLength(2)
            .maxLength(1024)
            .when((model) => model.firstValidate === false);

        this.ruleFor("city")
            .notEmpty()
            .withMessage("Please enter your City / Town")
            .minLength(2)
            .maxLength(50)
            .when((model) => model.firstValidate === false);

        this.ruleFor("postcode")
            .notEmpty()
            .withMessage("Please enter your post code")
            .minLength(2)
            .maxLength(10)
            .when((model) => model.firstValidate === false);
        this.ruleFor("contactEmail")
            .notEmpty()
            .withMessage("Please enter an email address")
            .emailAddress()
            .minLength(2)
            .maxLength(50)
            .when((model) => model.firstValidate === false);
        this.ruleFor("primaryContactNumber")
            .notEmpty()
            .withMessage("Please enter a valid phone number")
            .minLength(2)
            .maxLength(15)
            .withMessage("Phone number cannot exceed 15 digits")
            .when((model) => model.firstValidate === false);
        this.ruleFor("preferredContactMethodEmail")
            .must((value, model) => model.preferredContactMethodEmail || model.preferredContactMethodPhone)
            .withMessage("Please choose a preferred contact method")
            .when((model) => model.firstValidate === false);
        this.ruleFor("notes")
            .maxLength(2048)
            .when((model) => model.firstValidate === false);
        this.ruleFor("agreePrivacyPolicy")
            .equal(true)
            .withMessage("Please accept privacy policy")
            .when((model) => model.firstValidate === false);
        this.ruleFor("budgetFrom")
            .notEqual("0")
            .minLength(1)
            .withMessage("Please choose a budget")
            .when((model) => model.firstValidate === false);

        this.ruleFor("budgetTo")
            .notEqual("0")
            .minLength(1)
            .withMessage("Please choose a budget")
            .when((model) => model.firstValidate === false);
    }
}
