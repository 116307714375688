import styled from "@emotion/styled";
import { Box } from "Components";
import { P } from "Components/Primitives/TextElements/TextElements";

export const PolicyPara = styled(P)`
    padding-bottom: 20px;
`;

export const PolicyUl = styled.ul`
    padding-bottom: 20px;
    margin: 0 0 1.5em 1.5em;
`;

export const PolicyLi = styled.li``;
export const PolicyWrapper = styled(Box)`
    background-color: #e0a752;
    a {
        color: #000;
        /* text-decoration: none; */
    }
`;
