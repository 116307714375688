import React, { ReactElement } from "react";
import { observer } from "mobx-react-lite";
import { Box } from "Application";
import { H2, P } from "Components/Primitives/TextElements/TextElements";
import { fontSize } from "styled-system";

interface IBlock {
    surTitle?: string;
    title?: string;
    textBlock?: string;
    backgroundColour?: string;
    textColour?: any;
    buttons?: ReactElement;
    isMobile?: boolean;
    isTablet?: boolean;
    hasLargeTitle?: boolean;
    hasLargerBodyText?: boolean;
    hasThreeButtons?: boolean;
    JSXElement?: ReactElement;
    paddingForBlock?: string;
}

export const TitleWithTextBlock: React.FC<IBlock> = observer((props) => {
    return (
        <>
            <Box
                flexBox
                p={props.paddingForBlock ? props.paddingForBlock : ""}
                backgroundColor={props.backgroundColour ? props.backgroundColour : ""}
                // style={{ justifyContent: "center" }}
            >
                <Box maxWidth="1400px">
                    <Box>
                        {props.surTitle && (
                            <P pb="10px" style={{ fontSize: "14px", fontStyle: "normal", fontWeight: 400, lineHeight: "normal", letterSpacing: "2.38px" }}>
                                {props.surTitle}
                            </P>
                        )}
                        <H2
                            color={props.textColour ? props.textColour : "primary"}
                            pb="30px"
                            style={{
                                whiteSpace: "pre-line",
                                fontWeight: 400,
                                lineHeight: "normal",
                                fontSize: props.isMobile ? (props.hasLargeTitle ? "62px" : "28px") : "48px",
                            }}
                        >
                            {props.title}
                        </H2>
                    </Box>
                    <Box>
                        <P
                            color={props.textColour ? props.textColour : "primary"}
                            pb="30px"
                            style={{ whiteSpace: "pre-line", fontSize: props.hasLargerBodyText ? "24px" : "18px", lineHeight: "normal" }}
                        >
                            {props.textBlock}
                        </P>
                        {props.JSXElement}
                        <Box grid style={{ gridTemplateColumns: props.isMobile || props.isTablet ? "1fr" : props.hasThreeButtons ? "1fr 1fr 1fr" : "1fr 1fr" }}>
                            {props.buttons}
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    );
});
