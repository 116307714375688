import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { Validator } from "Application/Validation";

import { HouseTypeModel } from "./HouseTypeModel";

export class PropertyMediaModel extends ModelBase<PropertyMediaModel> {
    public id: string = "";
    public houseTypeId: string = "";
    public developmentId: string = "";
    public imageUrl: string = "";
    public originalImageUrl: string = "";
    public thumbnailImageUrl: string = "";
    public ordinal: number = 0;

    constructor() {
        super();
        makeObservable(this, {
            id: observable,
            imageUrl: observable,
            originalImageUrl: observable,
            thumbnailImageUrl: observable,
            ordinal: observable,
            houseTypeId: observable,
            developmentId: observable,
        });
    }

    public featureList: string[] = ["item 1", "item 2"];
}

export class PropertyMediaModelValidator extends Validator<PropertyMediaModel> {
    constructor() {
        super();
        //this.ruleFor("id").notEmpty();
    }
}
