import styled from "@emotion/styled";
import { CoreStoreInstance } from "@shoothill/core";
import { Box, Label, theme } from "Application";
import { observer } from "mobx-react-lite";
import React, { ReactNode } from "react";
import { ExclamationIconSVG } from "../Assets/Icons/ExclamationIconSVG";

const Common = styled(Box)`
    padding: 10px;
    display: flex;
    align-items: center;
`;
const BackgroundError = styled(Common)`
    background: ${theme.palette.error.main};
`;
const BackgroundWarning = styled(Common)`
    background: ${theme.palette.warning.main};
`;
const BackgroundSuccess = styled(Common)`
    background: ${theme.palette.success.main};
`;
const BackgroundInfo = styled(Common)`
    background: ${theme.palette.info.main};
`;

export const InfoBar: React.FC = observer(() => {
    const getDialogue = (): ReactNode => {
        switch (CoreStoreInstance.infoBarSeverity) {
            case "success":
                return getsuccess();
            case "warning":
                return getWarning();
            case "info":
                return getInformation();
            case "error":
                return getError();
        }
    };

    const getsuccess = () => {
        return (
            <BackgroundSuccess>
                <ExclamationIconSVG color={theme.palette.success.main} />
                <Box pl={1}>
                    <Label fontStyle={"h5"} color={"white"}>
                        {CoreStoreInstance.infoBarMessage}
                    </Label>
                </Box>
            </BackgroundSuccess>
        );
    };

    const getWarning = () => {
        return (
            <BackgroundWarning>
                <ExclamationIconSVG color={theme.palette.warning.main} />
                <Box pl={1}>
                    <Label fontStyle={"h5"} color={"white"}>
                        {CoreStoreInstance.infoBarMessage}
                    </Label>
                </Box>
            </BackgroundWarning>
        );
    };

    const getInformation = () => {
        return (
            <BackgroundInfo>
                <ExclamationIconSVG color={"black"} />
                <Box pl={1}>
                    <Label fontStyle={"h5"} color={"white"}>
                        {CoreStoreInstance.infoBarMessage}
                    </Label>
                </Box>
            </BackgroundInfo>
        );
    };

    const getError = () => {
        return (
            <BackgroundError>
                <ExclamationIconSVG color={theme.palette.error.main} />
                <Box pl={1}>
                    <Label fontStyle={"h5"} color={"white"}>
                        {CoreStoreInstance.infoBarMessage}
                    </Label>
                </Box>
            </BackgroundError>
        );
    };

    return <Box id={"infoBar"}>{CoreStoreInstance.isInfoBarOpen && getDialogue()}</Box>;
});
