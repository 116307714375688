import styled from "@emotion/styled";
import { Box } from "Components";
import { theme } from "Application";

export const QuoteBox = styled(Box)`
    background-color: #2b6298;

    p {
        font-family: "sabon";
        font-size: 30px;
        /* line-height: 38px; */
        text-align: center;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .smallerText {
        color: #ecdfc9;
        text-align: center;
        font-family: sohne;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 2.38px;
        text-transform: uppercase;
    }
`;

export const ScrollBox = styled(Box)`
    overflow-x: scroll;
    width: 100%;
    ::-webkit-scrollbar {
        display: none;
    }
`;
