import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Box, ICommand, RelayCommand } from "Application";
import { useNavigate, useParams } from "react-router";
import { DownloadViewModel, DownloadViews } from "./DownloadViewModel";
import { MainWrapper } from "./Download.style";
import { H4, P } from "Components/Primitives/TextElements/TextElements";
import { ShorelineButton } from "Components/ShorelineButton";

export const DownloadView: React.FC = observer(() => {
    const [viewModel] = useState(new DownloadViewModel());
    let params = useParams();
    let navigate = useNavigate();

    useEffect(() => {
        if (params.type !== undefined) {
            viewModel.downloadBrochure(params.type);
        }
    }, []);

    const openPdfinNewWindow = () => {
        if (params.type !== undefined) {
            viewModel.downloadBrochure(params.type);
        }
    };

    const navigateHome: ICommand = new RelayCommand((value: string) => {
        navigate("/");
    });

    return (
        <>
            <MainWrapper>
                <Box>
                    {viewModel.downloadView == DownloadViews.Downloading && <H4 color="primary">Downloading brochure...</H4>}
                    {viewModel.downloadView == DownloadViews.Downloaded && (
                        <>
                            <H4 color="primary" pb="20px">
                                Thank you for your interest
                            </H4>
                            <ShorelineButton command={navigateHome} displayName={"GO TO HOMEPAGE"} />
                            <P pt="30px" style={{ cursor: "pointer" }} onClick={openPdfinNewWindow}>
                                Brochure not downloading? Click here
                            </P>
                        </>
                    )}
                    {viewModel.downloadView == DownloadViews.PathNotFound && (
                        <>
                            <H4 color="primary" pb="20px">
                                Sorry, the page you are looking for cannot be found
                            </H4>
                            <ShorelineButton command={navigateHome} displayName={"GO TO HOMEPAGE"} />
                        </>
                    )}
                </Box>
            </MainWrapper>
        </>
    );
});
