import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { Validator } from "Application/Validation";
import { PropertyRoomModel } from "Views/NewHomes/Subviews/PropertyRoomModel";
import { PropertyMediaViewModel } from "Views/NewHomes/Subviews/PropertyMediaViewModel";
import { PropertyMediaModel } from "Views/NewHomes/Subviews/PropertyMediaModel";

export class CompareModalModel extends ModelBase<CompareModalModel> {
    public id: string = "";
    public propertyType: string = "";
    public selectedPropertyId: string = "";

    //For the second property

    public developmentId: string = "";
    public developmentName: string = "";
    public plotNumber: number = 0;
    public addressLine1: string = "";
    public addressLine2: string = "";
    public postCode: string = "";
    public description: string = "";
    public houseTypeId: string = "";
    public basePurchasePrice: number = 0;
    public numberOfBeds: string = "";
    public actualStatus: number = 0;
    public houseType: string = "";
    public numberOfBathrooms: string = "";
    public parkingType: string = "";
    public sqft: string = "";
    public epc: string = "";
    public councilTaxBand: string = "";
    public phase: number | null = null;
    public floor: string = "";
    public block: string = "";
    public features: string = "";

    public propertyRooms = observable<PropertyRoomModel>([]);
    public floorplans = observable<PropertyMediaModel>([]);
    public images = observable<PropertyMediaModel>([]);

    constructor() {
        super();
        makeObservable(this, {
            id: observable,
            propertyType: observable,
            selectedPropertyId: observable,
        });
    }
}

export class CompareModalModelValidator extends Validator<CompareModalModel> {
    constructor() {
        super();
        //this.ruleFor("id").notEmpty();
    }
}
