import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Box, ICommand, Label, RelayCommand } from "Application";
import { motion, useAnimationControls } from "framer-motion";

import Polygon1 from "Assets/Polygon 1.svg";
import Polygon2 from "Assets/Polygon 2.svg";
import { NextButton, PrevButton } from "Components/NextPrevButton";
import { ImageModal } from "Components/ImageModal/ImageModal";
import { PropertyMediaModel } from "Views/NewHomes/Subviews/PropertyMediaModel";

import LeftArrow from "Assets/LeftBlueArrow.svg";
import RightArrow from "Assets/RightBlueArrow.svg";

interface ICarouselWithText {
    title?: string;
    model: PropertyMediaModel[];
    isMobile?: boolean;
}

export const ImageCarouselWithTextWider: React.FC<ICarouselWithText> = observer((props) => {
    const [ArticleArray, setPicArray] = useState(props.model);

    const [IndexOne, setIndexOne] = useState(0);
    const [IndexTwo, setIndexTwo] = useState(1);
    const [IndexThree, setIndexThree] = useState(2);
    const [IndexFour, setIndexFour] = useState(ArticleArray.length - 2);
    const [IndexFive, setIndexFive] = useState(ArticleArray.length - 1);

    const [iscarouselModalOpen, setIsCarouselModalOpen] = useState(false);

    const onCloseModal = () => setIsCarouselModalOpen(false);

    const doSomething: ICommand = new RelayCommand((value: string) => {});

    function NextArticle() {
        setIndexOne(IndexOne + 1 == ArticleArray.length ? 0 : IndexOne + 1);
        setIndexTwo(IndexTwo + 1 == ArticleArray.length ? 0 : IndexTwo + 1);
        setIndexThree(IndexThree + 1 == ArticleArray.length ? 0 : IndexThree + 1);
        setIndexFour(IndexFour + 1 == ArticleArray.length ? 0 : IndexFour + 1);
        setIndexFive(IndexFive + 1 == ArticleArray.length ? 0 : IndexFive + 1);
    }

    function GoNextTwoArticles() {
        NextArticle();
        NextArticle();
    }

    function PreviousArticle() {
        setIndexOne(IndexOne == 0 ? ArticleArray.length - 1 : IndexOne - 1);
        setIndexTwo(IndexTwo == 0 ? ArticleArray.length - 1 : IndexTwo - 1);
        setIndexThree(IndexThree == 0 ? ArticleArray.length - 1 : IndexThree - 1);
        setIndexFour(IndexFour == 0 ? ArticleArray.length - 1 : IndexFour - 1);
        setIndexFive(IndexFive == 0 ? ArticleArray.length - 1 : IndexFive - 1);
    }

    function GoPreviousTwoArticles() {
        PreviousArticle();
        PreviousArticle();
    }

    const controls = useAnimationControls();

    useEffect(() => {
        // controls.set({ opacity: 0 });
        // controls.start({ opacity: 1 });
    }, [IndexOne]);

    const transition = {
        type: "spring",
        damping: 25,
        stiffness: 120,
    };

    return (
        <>
            {/* <Box showIf={iscarouselModalOpen} style={{ backgroundColor: "black", width: "100%", height: "100%", top: 0, left: 0, position: "absolute", zIndex: 1 }}></Box> */}
            <ImageModal
                NextButton={NextArticle}
                PrevButton={PreviousArticle}
                onCloseModal={onCloseModal}
                open={iscarouselModalOpen}
                imgUrl={ArticleArray[IndexOne].originalImageUrl}
            ></ImageModal>
            <Box
                grid
                dc={"repeat(1, auto 26% 26% 26% auto)"}
                tc="repeat(1, auto 26% 26% 26% auto)"
                style={{ position: "relative" }}
                columnGap="10px"
                minHeight={"329px"}
                pb={props.isMobile ? "0px" : "100px"}
            >
                {/* Slide 5 */}
                <Box hidden={props.isMobile} style={{ alignSelf: "start" }} onClick={() => GoPreviousTwoArticles()}>
                    <Box height={"289px"}>
                        {ArticleArray[IndexFour] && <img src={ArticleArray[IndexFour].imageUrl} width="100%" height={"100%"} style={{ objectFit: "cover", opacity: "0.3" }}></img>}
                    </Box>
                </Box>{" "}
                {/* Slide 4 */}
                <Box hidden={props.isMobile} style={{ alignSelf: "start" }}>
                    <Box height={"289px"} onClick={() => PreviousArticle()}>
                        {ArticleArray[IndexFive] && <img src={ArticleArray[IndexFive].imageUrl} width="100%" height={"100%"} style={{ objectFit: "cover" }}></img>}
                    </Box>
                </Box>{" "}
                {/* Slide 1 */}
                <Box style={{ alignSelf: "start" }}>
                    <Box height={"289px"}>
                        <motion.div style={{ height: "100%" }} onClick={() => setIsCarouselModalOpen(!iscarouselModalOpen)} animate={{ scale: iscarouselModalOpen ? 1.2 : 1 }}>
                            {ArticleArray[IndexOne] && (
                                <motion.img animate={controls} src={ArticleArray[IndexOne].imageUrl} width="100%" height={"100%"} style={{ objectFit: "cover" }}></motion.img>
                            )}
                        </motion.div>
                    </Box>
                </Box>
                {/* Slide 2 */}
                <Box display={"flex"} flexDirection={"column"} style={{ justifyContent: "space-between" }}>
                    <Box hidden={props.isMobile} height={"289px"} onClick={() => NextArticle()}>
                        {ArticleArray[IndexTwo] && <img src={ArticleArray[IndexTwo].imageUrl} width="100%" height={"100%"} style={{ objectFit: "cover" }}></img>}
                    </Box>
                    <Box display={"flex"} justifyContent={props.isMobile ? "center" : "left"}></Box>
                </Box>
                {/* Slide 3 */}
                <Box hidden={props.isMobile} style={{ alignSelf: "start" }}>
                    <Box height={"289px"} onClick={() => GoNextTwoArticles()}>
                        {ArticleArray[IndexThree] && (
                            <img src={ArticleArray[IndexThree].imageUrl} width="100%" height={"100%"} style={{ objectFit: "cover", opacity: "0.3" }}></img>
                        )}
                    </Box>
                </Box>
                <Box></Box>
                <Box></Box>
                {/* 
                <Box>
                    <Box minHeight={"110px"}>
                        <Label fontStyle="p" color="primary" pb="20px">
                            {ArticleArray[IndexOne].articleText.length > 180 && ArticleArray[IndexOne].articleText.substring(0, 180) + "..."}
                            {ArticleArray[IndexOne].articleText.length < 180 && ArticleArray[IndexOne].articleText}
                        </Label>
                    </Box>
                </Box> */}
                <Box></Box>
                <PrevButton pr={"4px"} mr={"15px"} onClick={PreviousArticle} style={{ position: "absolute", left: 20, top: 120 }}>
                    <img src={LeftArrow}></img>
                </PrevButton>
                <NextButton pl={"4px"} onClick={NextArticle} style={{ position: "absolute", right: 20, top: 120 }}>
                    <img src={RightArrow}></img>
                </NextButton>
            </Box>
        </>
    );
});
