import styled from "@emotion/styled";
import { Box } from "Components";

interface HomeWrapperProps {
    maxWidth?: string;
}

export const InnerWrapper = styled(Box)<HomeWrapperProps>`
    /* max-width: 1246px; */
    max-width: ${(props) => props.maxWidth};
    text-align: left;
    align-items: left;
    width: 100%;
    /* display: flex;
    align-items: center;
    width: 100%;
    margin-left: 20px;
    margin-right: 20px; */

    .centered-text {
        text-align: center;
        row-gap: 60px;
    }

    .hidden {
        opacity: 0;
        transition: opacity 0.5s ease-in-out;
    }

    .visible {
        opacity: 1;
    }
`;
