import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { Box, EditDate, Input, MaskedOptions, NullCommand } from "Application";
import styled from "@emotion/styled";
import { Button } from "../Components/Primitives/Buttons/Button";
import { Label } from "../Components/Primitives/Labels/Label";
import { CheckBox } from "../Components/Primitives/Checkboxes/CheckBox";
import { TestViewModel } from "../Application/Examples/TestViewModel";

const StyledBox = styled(Box)`
    border: 2px solid rgb(233, 171, 88);
    border-radius: 5px;
    background-color: rgba(233, 171, 88, 0.5);
    padding: 1em;
    color: #d9480f;
`;
const StyledCell = styled(Box)`
    border: 2px solid rgb(233, 171, 88);
    border-radius: 5px;
    background-color: rgba(233, 171, 88, 0.5);
    padding: 1em;
    color: #d9480f;
`;
export const BlankView: React.FC = observer(() => {
    const [viewModel] = useState(() => new TestViewModel());
    return (
        <>
            <EditDate displayName={"Time only"} value={() => viewModel.getValue<any>("dob")} command={viewModel.updateDOBCommand} />
            {/* <Input
                displayName="Currency"
                command={viewModel.updateAmountCommand}
                value={() => viewModel.getValue("amount")}
                validationMessage={() => "Validation Error Message Sample"}
                maskedOptions={MaskedOptions.Currency()}
            />*/}
        </>
    );
});
