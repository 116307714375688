import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { Box, theme } from "Application";
import { TitleLeftTextRightBlock } from "Components/TitleLeftTextRightBlock/TitleLeftTextRightBlock";
import { FullWidthCentFlexContainer } from "Components/FullWidthCentFlexCont";
import { InnerWrapper } from "Components/InnerWrapper";
import { SemiCircle } from "Components/SemiCircle";
import { H1, H2, H4, P } from "Components/Primitives/TextElements/TextElements";
import { BuyingwithshorelineViewModel } from "./BuyingWithshorelineViewModel";
import { BannerImageFull } from "Components/BannerImageFull/BannerImageFull";
import { Navigate, Route, Routes } from "react-router-dom";
import { AssistedMoveView } from "./AssistedMove/AssistedMoveView";
import { HomeView } from "Views/Home/HomeView";
import Banner from "Assets/HomeCarouselVidFallback.jpg";
import { PartExchangeView } from "./PartExchange/PartExchangeView";
import { ShorelineButton } from "Components/ShorelineButton";

export const BuyingWithShorelineView: React.FC = observer(() => {
    const [viewModel] = useState(new BuyingwithshorelineViewModel());

    return (
        <>
            {/* <FullWidthCentFlexContainer p={viewModel.isDesktop ? "0px 40px" : "0px 20px"} backgroundColor="white">
                <InnerWrapper height={"100%"} maxWidth="1400px">
                    <H2
                        color="primary"
                        p="40px 0px"
                        style={{
                            whiteSpace: "pre-line",
                            fontWeight: 400,
                            lineHeight: "normal",
                            fontSize: viewModel.isMobile ? "62px" : "48px",
                        }}
                    >
                        Buying With shoreline
                    </H2>
                </InnerWrapper>
            </FullWidthCentFlexContainer> */}
            {/* <Box grid dc="1fr 1fr" p={viewModel.isDesktop ? "0px 20px 40px 20px" : "10px"}>
                <AssistedMoveView />
                <PartExchangeView />
            </Box> */}

            <Routes>
                <Route path="*" element={<Navigate to="assistedmove" replace />} />
                <Route
                    path={"assistedmove/"}
                    element={
                        <React.Suspense fallback={<HomeView />}>
                            <AssistedMoveView />
                        </React.Suspense>
                    }
                />
                <Route
                    path={"partexchange/"}
                    element={
                        <React.Suspense fallback={<HomeView />}>
                            <PartExchangeView />
                        </React.Suspense>
                    }
                />
            </Routes>
        </>
    );
});
