import styled from "@emotion/styled";
import { isEmptyOrWhitespace, isNullOrUndefined, KeyValuePair } from "@shoothill/core";
import { Box, ErrorExclamationView, ICommand, theme } from "Application";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { useEffect, useState } from "react";
import Select, { FormatOptionLabelMeta, MenuPosition } from "react-select";
import { FieldLabel } from "../CommonStyles";
// #region View Props

const SelectContainer = styled.div`
    .editselect__placeholder {
        font-size: ${theme.fontStyles.label.fontSize ?? theme.defaultFontStyle.fontSize};
    }
    .editselect__control {
        border: 1px solid ${theme.palette.field.light};
        border-radius: 0 !important;
    }
    .editselect__control--is-focused {
        border: 1px solid ${theme.palette.common.focus} !important;
        box-shadow: none !important;
    }
    .editselect__value-container {
        padding: 3px 8px;
        white-space: nowrap !important;
    }
    .virtualized-select-validation {
        flex-grow: 1;
    }
`;

interface IProps {
    canClear?: boolean;
    command: ICommand;
    className?: string;
    displayName?: string;
    optionRenderer?: (item: any) => void;
    options: KeyValuePair[];
    placeholder?: string;
    style?: any;
    styles?: any;
    value: () => string;
    isMulti?: boolean;
    validationMessage?: () => string;
    noHeader?: boolean;
    ref?: React.Ref<any>;
    cy?: string;
    formatOptionLabel?: ((data: any, formatOptionLabelMeta: FormatOptionLabelMeta<any>) => React.ReactNode) | undefined;
    displayNameSuffix?: JSX.Element;
    menuPosition?: MenuPosition;
}

// #endregion View Props

export const EditSelect: React.FC<IProps> = observer((props) => {
    const [selectedOption, setSelectedOption] = useState<any>(null);
    const haveError = !isEmptyOrWhitespace(props.validationMessage?.() as string);
    // #region Code Behind
    useEffect(() => {
        //EN: Have to do this because react-select stores the entire key value pair in the value property
        if (!props.isMulti) {
            const initValue = props.options.find((option: KeyValuePair) => option.key === props.value());
            if (initValue) {
                setSelectedOption({ value: initValue.key, label: initValue.text });
            }
        } else {
            if (props.value()) {
                const values = props.value() as any as string[];
                let arr = [];
                for (const item of values) {
                    const initValue = props.options.find((option: KeyValuePair) => option.key === item);
                    if (initValue) {
                        arr.push({ value: initValue.key, label: initValue.text });
                    }
                }
                setSelectedOption(arr);
            }
        }
    }, []);

    useEffect(() => {
        if (!props.isMulti) {
            const initValue = props.options.find((option: KeyValuePair) => option.key === props.value()) as KeyValuePair;
            if (initValue) {
                setSelectedOption({ value: initValue.key, label: initValue.text });
            }
        } else {
            const values = props.value() as any as string[];
            let arr = [];
            for (const item of values) {
                const initValue = props.options.find((option: KeyValuePair) => option.key === item);
                if (initValue) {
                    arr.push({ value: initValue.key, label: initValue.text });
                }
            }
            setSelectedOption(arr);
        }
    }, [props.value()]);

    const canDisplayName = (): boolean => {
        return !isEmptyOrWhitespace(props.displayName);
    };

    const getCanClear = (): boolean => {
        return isNullOrUndefined(props.canClear) ? false : props.canClear!;
    };

    const getClassName = (): string => {
        return clsx({
            editselect: true,
            [`editselect-${props.className}`]: !isEmptyOrWhitespace(props.className),
            "editselect-emptylabel": props.displayName === "EMPTY_LABEL",
        });
    };

    const getPlaceholder = (): string => {
        return isEmptyOrWhitespace(props.placeholder) ? "Select or type" : props.placeholder!;
    };

    const onChange = (option: any): void => {
        setSelectedOption(option);
        if (!props.isMulti) {
            props.command.execute({ key: option.value, text: option.label } as KeyValuePair);
        } else {
            const selected = option.map((a: any) => {
                return { key: a.value, text: a.label } as KeyValuePair;
            });
            props.command.execute(selected);
        }
    };
    const isDisabled = (): boolean => {
        return isNullOrUndefined(props.command.canExecute) ? false : !props.command.canExecute;
    };
    const getValidationMessage = (): string => {
        return isEmptyOrWhitespace(props.validationMessage?.() as string) ? "" : (props.validationMessage?.() as string);
    };
    const isValidationMessageNotEmpty = (): boolean => {
        return true;
        //?return !isEmptyOrWhitespace(props.validationMessage!());
    };

    const getOptions = (): any => {
        return props.options.map((item: KeyValuePair) => {
            return {
                label: item.text,
                value: item.key,
            };
        });
    };
    const getDataCY = (): string => {
        let regex = /[^a-z]/gi;
        let result = (props.displayName || "").replace(regex, "");
        return props.cy || result;
    };
    // #endregion Code Behind
    return (
        <SelectContainer className={getClassName()}>
            {!props.noHeader && (
                <Box flexBox style={{ gap: "5px" }}>
                    <FieldLabel className="placeholder">{props.displayName}</FieldLabel>
                    {props.displayNameSuffix}
                    {/*<ErrorExclamationView haveError={haveError} validationMessage={() => getValidationMessage()} />*/}
                </Box>
            )}
            <Box flexBox style={{ ...props.style }}>
                {/*<Select options={options} />*/}
                <Select
                    menuPortalTarget={document.body}
                    styles={props.styles}
                    ref={props.ref}
                    isClearable={getCanClear()}
                    className="virtualized-select-validation"
                    classNamePrefix={"editselect"}
                    isDisabled={isDisabled()}
                    onChange={onChange}
                    isMulti={props.isMulti}
                    options={getOptions()}
                    placeholder={getPlaceholder()}
                    value={selectedOption}
                    formatOptionLabel={props.formatOptionLabel}
                    data-cy={getDataCY()}
                    menuPosition={props.menuPosition === undefined ? undefined : props.menuPosition}
                />
                <ErrorExclamationView haveError={haveError} validationMessage={() => getValidationMessage()} />

                {/* {isValidationMessageNotEmpty() && <p>{props.validationMessage!()}</p>}*/}
            </Box>
        </SelectContainer>
    );
    // #endregion Public Interface
});
EditSelect.defaultProps = {
    displayName: "",
};
