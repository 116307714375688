import { makeObservable, observable, runInAction } from "mobx";
import { KeyValuePair, ViewModelBase } from "@shoothill/core";
import { PropertyListItemModel, PropertyListItemModelValidator } from "./PropertyListItemModel";
import { APIClient, ICommand, RelayCommand } from "Application";
import { HouseTypeViewModel } from "./HouseTypeViewModel";

import { uriString } from "Application/Helpers/UriString";
import { PropertyRoomViewModel } from "./PropertyRoomViewModel";
import { PropertyMediaViewModel } from "./PropertyMediaViewModel";
export class PropertyListItemViewModel extends ViewModelBase<PropertyListItemModel> {
    public apiClient = new APIClient();

    public housePrice: number = this.model.basePurchasePrice;
    public buyerOption: number = -1;
    public houseTypesViewModel = observable<HouseTypeViewModel>(new HouseTypeViewModel());
    public stampDutyAmount: number = 0;

    public stampDutyDisplayToggle: boolean = false;

    public propertyRoomsViewModels = observable<PropertyRoomViewModel>([]);
    public imagesViewModels = observable<PropertyMediaViewModel>([]);
    public floorplansViewModels = observable<PropertyMediaViewModel>([]);

    constructor(model = new PropertyListItemModel()) {
        super(model);
        this.setValidator(new PropertyListItemModelValidator());
        makeObservable(this, { stampDutyDisplayToggle: observable, stampDutyAmount: observable });
    }

    public getStatus = (val: number) => {
        let retVal = "";
        switch (val) {
            case 2:
                retVal = "reserved";
                break;
            case 3:
                retVal = "reserved";
                break;
            case 4:
                retVal = "sold";
                break;
            case 5:
                retVal = "sold";
                break;
            case 7:
                retVal = "unreleased";
                break;
            default:
                retVal = "view";
                break;
        }
        return retVal;
    };

    public getAvailableStatus = (val: number) => {
        let retVal = "";
        switch (val) {
            case 2:
                retVal = "Reserved";
                break;
            case 3:
                retVal = "Reserved";
                break;
            case 4:
                retVal = "Sold";
                break;
            case 5:
                retVal = "Sold";
                break;
            case 7:
                retVal = "Unreleased";
                break;
            default:
                retVal = "For sale";
                break;
        }
        return retVal;
    };

    public generatePermalink = () => {
        let val = uriString(this.model.developmentName) + "/";

        if (this.model.phase) {
            val = val + uriString("phase" + this.model.phase) + "-";
        }

        if (this.model.block) {
            val = val + uriString("block" + this.model.block) + "-";
        }

        if (this.model.floor) {
            val = val + uriString("floor" + this.model.floor) + "-";
        }

        val = val + uriString("plot" + this.model.plotNumber);

        return val;
    };

    public get getBuyerOptions(): KeyValuePair<any>[] {
        let retVal: KeyValuePair<any>[] = [
            { key: 0, text: "first-Time buyer" },
            { key: 1, text: "buying my next home" },
            { key: 2, text: "buying an additional property or second home" },
        ];

        return retVal;
    }

    public updateHousePrice: ICommand = new RelayCommand((value: number) => {
        this.housePrice = value;
    });

    public updateBuyerOption: ICommand = new RelayCommand((value: KeyValuePair) => {
        this.buyerOption = value.key;
    });

    public get updateFeatureList() {
        return this.model.featureList;
    }

    public getStampDuty: ICommand = new RelayCommand((value: number) => {
        if (this.stampDutyDisplayToggle == false && this.buyerOption > -1) {
            this.stampDutyDisplayToggle = true;
        }
        if (this.buyerOption == 0) {
            if (this.housePrice < 425000) {
                this.stampDutyAmount = 0;
            } else if (this.housePrice > 425000 && this.housePrice <= 625000) {
                this.stampDutyAmount = (this.housePrice - 425000) * 0.05;
            } else if (this.housePrice > 625000) {
                if (this.housePrice > 625000 && this.housePrice <= 925000) {
                    this.stampDutyAmount = (this.housePrice - 250000) * 0.05;
                } else if (this.housePrice > 925000 && this.housePrice <= 1500000) {
                    this.stampDutyAmount = (this.housePrice - 925000) * 0.1;
                    this.stampDutyAmount += 33750;
                } else if (this.housePrice > 1500000) {
                    this.stampDutyAmount = (this.housePrice - 1500000) * 0.12;
                    this.stampDutyAmount += 91250;
                }
            }
        } else if (this.buyerOption == 1) {
            if (this.housePrice > 1500000) {
                this.stampDutyAmount = (this.housePrice - 1500000) * 0.12;
                this.stampDutyAmount += 91250;
            } else if (this.housePrice > 925000 && this.housePrice <= 1500000) {
                this.stampDutyAmount = (this.housePrice - 925000) * 0.1;
                this.stampDutyAmount += 33750;
            } else if (this.housePrice > 250000 && this.housePrice <= 925000) {
                this.stampDutyAmount = (this.housePrice - 250000) * 0.05;
            } else if (this.housePrice <= 250000) {
                this.stampDutyAmount = 0;
            }
        } else if (this.buyerOption == 2) {
            if (this.housePrice > 1500000) {
                this.stampDutyAmount = (this.housePrice - 1500000) * 0.15;
                this.stampDutyAmount += 136250;
            } else if (this.housePrice > 925000 && this.housePrice <= 1500000) {
                this.stampDutyAmount = (this.housePrice - 925000) * 0.13;
                this.stampDutyAmount += 61500;
            } else if (this.housePrice > 250000 && this.housePrice <= 925000) {
                this.stampDutyAmount = (this.housePrice - 250000) * 0.08;
                this.stampDutyAmount += 7500;
            } else if (this.housePrice <= 250000) {
                this.stampDutyAmount = this.housePrice * 0.03;
            }
        }

        /* console.log("Your stamp duty would be... " + this.stampDutyAmount); */
    });
}
