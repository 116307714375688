import React from "react";
import { observer } from "mobx-react-lite";
import { Box } from "Application";
import { P } from "Components/Primitives/TextElements/TextElements";

export interface IQuoteBlock {
    imageUrl: string;
    quoteText: string;
    quoteAuthor: string;
    isMobile: boolean;
    imageLeft?: boolean;
    isTablet?: boolean;
}

export const QuoteBlock: React.FC<IQuoteBlock> = observer((props) => {
    return (
        <>
            <Box flexBox style={{ justifyContent: "center" }}>
                <Box grid tc={props.isMobile ? "1fr" : "1fr 1fr"} maxWidth="1400px">
                    <Box order={props.imageLeft ? (props.isMobile ? 1 : 2) : 1} flexBox style={{ alignItems: "center", justifyContent: "center", lineHeight: "0px" }}>
                        <Box>
                            <P
                                color="primary"
                                style={{
                                    textAlign: "center",
                                    whiteSpace: props.isMobile || props.isTablet ? "normal" : "pre-line",
                                    fontSize: props.isMobile || props.isTablet ? "20px" : "30px",
                                    fontFamily: "sabon",
                                    lineHeight: "38px",
                                }}
                                p={props.isMobile ? "30px 40px 0px 40px" : ""}
                            >
                                {props.quoteText}
                            </P>
                            <P color="primary" pt="15px" style={{ textAlign: "center" }}>
                                {props.quoteAuthor}
                            </P>
                        </Box>
                    </Box>
                    <Box
                        order={props.imageLeft ? (props.isMobile ? 2 : 1) : 1}
                        p={props.isMobile ? "46px 18px 0px 18px" : props.imageLeft ? "100px 0px 0px 40px" : "100px 40px 0px 0px"}
                        style={{ lineHeight: "0px" }}
                    >
                        <img src={props.imageUrl} width="100%" />
                    </Box>
                </Box>
            </Box>
        </>
    );
});
