import { Logger } from "index";
import { EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";
import { CurrentPosition, InterestedIn, SourceOfEnquiry } from "Models/StaticData";

export interface UserRelatedResponse {
    currentPositions: CurrentPosition[];
    interestedIn: InterestedIn[];
    sourceOfEnquiry: SourceOfEnquiry[];
}

export class GetUserRelatedEndpoint extends EndpointWithoutRequest<UserRelatedResponse> {
    constructor() {
        super();
        this.verb(Http.Get);
        this.path("/api/user/getuserrelated");
    }

    public async HandleResponseAsync(response: UserRelatedResponse): Promise<UserRelatedResponse> {
        Logger.logInformation("GetUserRelatedEndpoint Response", response);

        //Return your model
        return response;
    }
}
