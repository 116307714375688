import { isNullOrEmpty } from "@shoothill/core";
import { config } from "../config";
import { Lambda, observe } from "mobx";
import { Logger } from "../index";

export const setPageTitle = (title: string): void => {
    const currentPageTitle = document.getElementsByTagName("title")[0].innerHTML as string;
    if (!isNullOrEmpty(title)) {
        const newPageTitle = title + " | " + config.rootPageTitle;
        if (currentPageTitle !== newPageTitle) {
            document.title = newPageTitle;
        }
    }
};

/**
 * This helper method is used to link a mobx observable array to a mobx observable array of viewmodels
 * @param observableModelArray The observable array to link to that exists in your model
 * @param observableViewModelArray The observable array of viewmodels to link to that exists in your viewmodel
 * @param viewModelClass The type of viewmodel to create. IE TodoItemViewModel
 */
export const viewModelModelLink = (observableModelArray: any, observableViewModelArray: any, viewModelClass: any): Lambda => {
    const disposable: Lambda = observe(observableModelArray, (childModelChanges: any) => {
        try {
            for (const addedChildModel of childModelChanges.added) {
                observableViewModelArray.push(new viewModelClass(addedChildModel));
            }

            for (const removedChildModel of childModelChanges.removed) {
                const childViewModelToRemove = observableViewModelArray.find((vm: any) => vm.model.KEY === removedChildModel.KEY);

                if (childViewModelToRemove) {
                    observableViewModelArray.remove(childViewModelToRemove);
                }
            }
        } catch (e: any) {
            Logger.logError(`${viewModelClass.constructor.name} viewModelModelLink`, e);
        }
    });
    return () => {
        Logger.logInformation(`${viewModelClass.constructor.name} viewModelModelLink`, "Disposing of observer");
        disposable();
    };
};
