import { makeObservable, observable, runInAction } from "mobx";
import { ViewModelBase } from "@shoothill/core";
import { APIClient, IKeyState } from "Application";
import { PolicyModel, PolicyModelValidator } from "./PolicyModel";

export class PolicyViewModel extends ViewModelBase<PolicyModel> {
    public apiClient = new APIClient();

    constructor() {
        super(new PolicyModel());
        this.setValidator(new PolicyModelValidator());
        makeObservable(this, {});
    }
}
