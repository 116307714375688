import { Stores } from "./Stores";

export class GlobalAppUrls {
    public Client = {
        Home: "/",
        About: "/about",
        OurVision: "/vision",
        Download: "/download/:type",
        NewHomes: "/newhomes/",
        Homes: "/homes/",
        Property: "/property/",
        WestWing: "/newhomes/westwing/",
        EastWing: "newhomes/eastwing/",
        Amenities: "/amenities",
        News: "/news/",
        Register: "/register/",
        Living: "/living/",
        Buying: "/buying/*",
        PartExchange: "/buying/partexchange/",
        AssistedMove: "/buying/assistedmove/",
        // NewsArticle: "/news/newsarticle",
        Folklife: "/folklife",
        HarbourArm: "/harbourarm",
        Contact: "/contact",
        FormSent: "/sent",
        BuyingANewHome: "/buying-a-new-home",
        BuyerLogin: "/buyerlogin",
        BuyerLoginDashboard: "/buyerdashboard",
        Theme: "/theme",
        StyleSheet: "/stylesheet",
        ColoursMUI: "/coloursmui",
        Samples: "/samples/text",
        Error: "/error",
        NotFound: "/notfound",
        PrivacyPolicy: "/privacy-policy",
        CookiePolicy: "/cookie-policy",
        Terms: "/terms",
        Test: "/test",
        PageBuilder: "/pagebuilder",
        Todo: "/todo",
        Blank: "/blank",
        Demo: "/application/personalinfo",

        ConfirmPassword: "/confirmpassword/:token/*",
        ForgotPassword: "/forgotpassword/:token/*",
        ResetPassword: "/resetpassword/:token/*",
        Account: {
            Login: "/account/login",
            Logout: "/logout",
            ForgotPassword: "/account/forgotpassword",
            ForgotPasswordSuccess: "/account/forgotpassword-success",
            ResetPassword: "/account/resetpassword/:token",
            Register: "/account/register",
            SessionExpired: "/sessionexpired",
        },
        Admin: {
            AdminHome: "/admin/dashboard/userslist",
            LogViewer: "/admin/logviewer",
            AdminCMS: "/admin/",
            AdminNews: {
                rootInAdmin: "/news",
                root: "/admin/news",
                addArticle: "/addarticle",
                editArticle: "/editarticle",
            },
        },
    };

    public Server = {
        Account: {
            Login: "/api/account/login",
            Logout: "/api/account/logout",
            ResetPassword: {
                Reset: "/api/account/resetpassword",
                VerifyToken: "/api/account/resetpasswordtoken",
            },
            SetPassword: "/api/account/setpassword",
            ForgotPassword: "/api/account/forgotpassword",
            Register: "/api/account/register",
            User: {
                DeleteUser: "/api/user/delete",
                DeleteRole: "/api/role/delete",
            },
        },
        Admin: {
            ResetFailedLoginAttemptsCount: "/api/user/ResetLoginAttempts",
            GetRoles: "/api/roles/getall",
        },
        Developments: {
            GetDevelopmentsList: "/api/development/getalldevelopmentswithmedia",
        },
        Properties: {
            GetTownHouses: "/api/property/gettownhouses/{id}",
            GetPropertyByDevelopmentId: "/api/property/getbydevelopmentid/{id}",
            GetPropertyById: "/api/property/getpropertybyid/{id}",
            GetPropertyByTitle: "/api/property/getpropertybytitle/{title}",
        },
        News: {
            GetAllNewsArticles: "/api/news/getallnews",
            GetNewsArticleById: "/api/news/getnewsbyid/{id}",
            DeleteNewsArticleById: "/api/news/delete",
        },
        Instagram: {
            //GetAllInstagramArticles: "https://www.instagram.com/shoreline_folkestone/?__a=1&__d=dis",
            GetAllInstagramArticles: "/api/instagram/getinstagramposts",
        },
        Images: {
            CropAndResize: "/api/images/CropAndResize",
        },
    };
}
export const AppUrls = new GlobalAppUrls();
