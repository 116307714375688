import { makeObservable, observable, runInAction, action } from "mobx";
import { ViewModelBase } from "@shoothill/core";
import { APIClient, ICommand, IKeyState, RelayCommand } from "Application";
import { SelectedPropertyModel, SelectedPropertyModelValidator } from "./SelectedPropertyModel";
import { PostcontactFormEndpoint } from "Views/Contact/Endpoints/PostContactFormEndPoint";
import { Logger } from "index";
import { ContactViewModel } from "Views/Contact/ContactViewModel";
import { useNavigate } from "react-router";

export class SelectedPropertyViewModel extends ViewModelBase<SelectedPropertyModel> {
    public apiClient = new APIClient();

    public navigate = useNavigate();

    public isProcessing: boolean = false;
    public isModalOpen: boolean = false;

    constructor() {
        super(new SelectedPropertyModel());
        this.setValidator(new SelectedPropertyModelValidator());
        makeObservable(this, { isProcessing: observable, isModalOpen: observable, PostContactFormAsync: action });
    }

    public updateFirstName: ICommand = new RelayCommand((value: string) => {
        this.model.firstName = value;
    });

    public updateLastName: ICommand = new RelayCommand((value: string) => {
        this.model.surname = value;
    });

    public updateAddressLine1: ICommand = new RelayCommand((value: string) => {
        this.model.addressLine1 = value;
    });

    public updateAddressLine2: ICommand = new RelayCommand((value: string) => {
        this.model.addressLine2 = value;
    });

    public updateCity: ICommand = new RelayCommand((value: string) => {
        this.model.city = value;
    });

    public updatePostcode: ICommand = new RelayCommand((value: string) => {
        this.model.postcode = value;
    });

    public updateEmail: ICommand = new RelayCommand((value: string) => {
        this.model.contactEmail = value;
    });

    public updatePhoneNumber: ICommand = new RelayCommand((value: string) => {
        this.model.primaryContactNumber = value;
    });

    public updatePrefersEmails: ICommand = new RelayCommand((value: string) => {
        this.model.preferredContactMethodEmail = !this.model.preferredContactMethodEmail;
    });

    public updatePrefersTelCalls: ICommand = new RelayCommand((value: string) => {
        this.model.preferredContactMethodPhone = !this.model.preferredContactMethodPhone;
    });

    public updateMessage: ICommand = new RelayCommand((value: string) => {
        this.model.notes = value;
    });

    public updateBudgetFrom: ICommand = new RelayCommand((value: string) => {
        this.model.budgetFrom = value;
    });

    public updateBudgetTo: ICommand = new RelayCommand((value: string) => {
        this.model.budgetTo = value;
    });

    public updateWantsUpdates: ICommand = new RelayCommand((value: string) => {
        this.model.acceptsMarketing = !this.model.acceptsMarketing;
    });

    public updateAgreesPrivacyPolicy: ICommand = new RelayCommand((value: string) => {
        this.model.agreePrivacyPolicy = !this.model.agreePrivacyPolicy;
    });

    public messageSent = false;

    public closeSentModal = () => {
        //  setTimeout(this.backtoHomepage, 2000);
        this.backtoHomepage;
        this.isModalOpen = true;
    };

    public backtoHomepage: ICommand = new RelayCommand((value: string) => {
        this.navigate("/");
    });

    public onSubmit: ICommand = new RelayCommand((value: string) => {
        this.model.firstValidate = false;
        this.model.plotNumber = Number(value);

        if (this.isModelValid()) {
            console.log("True");
            this.PostContactFormAsync();
        }
        // this.messageSent = true;
        // setTimeout(this.refreshPage, 2000);
    });

    public refreshPage: ICommand = new RelayCommand((value: string) => {
        window.location.reload();
    });

    public async PostContactFormAsync(): Promise<void> {
        let contactViewModel = new ContactViewModel(this.navigate);
        this.isProcessing = true;
        const postContactForm = new PostcontactFormEndpoint(contactViewModel);
        await this.apiClient.sendAsync(postContactForm, this.model);

        if (this.apiClient.IsRequestSuccessful) {
            let response = this.apiClient.Response();
            runInAction(() => {
                this.isModalOpen = true;
                this.isProcessing = false;
            });
        }

        this.messageSent = true;

        Logger.logInformation("Posted contact form");
    }

    public get getPreferredContactMethodValidationMessage(): string {
        let retVal = "";
        if (this.getError("preferredContactMethodEmail") || this.getError("preferredContactMethodPhone")) {
            retVal = "Please select a preferred contact method";
        }
        return retVal;
    }
}
