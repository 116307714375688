import React from "react";
import { SessionExpiredView } from "./Views/SessionExpired/SessionExpiredView";
// import { PrivacyPolicyView } from "./Views/Legal/PrivacyPolicy/PrivacyPolicyView";
import { StyleSheetView } from "Application/Examples/StyleSheetView";
import { ConfirmPasswordView } from "Components/BuyerLogin/ConfirmPassword/ConfirmPasswordView";
import { AccountStore } from "Stores/Domain";
import { DomainStore } from "Stores/Domain/DomainStore";
import { AboutView } from "Views/About/About";
import { AdminCMS } from "Views/AdminCMS/AdminCMS";
import { AmenitiesView } from "Views/Amenities/Amenities";
import { BuyerLoginDashboard } from "Views/BuyerLoginDashboard/BuyerLoginDashboard";
import { BuyingANewHomeView } from "Views/BuyingANewHome/BuyingANewHome";
import { ContactView } from "Views/Contact/Contact";
import { Error } from "Views/Error/Error";
import { FolkelifeView } from "Views/Folkelife/Folkelife";
import { HarbourArmView } from "Views/HarbourArm/HarbourArm";
import { LayoutNoHeaderOrFooter } from "Views/Layouts/LayoutNoHeaderOrFooter";
import { NewHomesView } from "Views/NewHomes/NewHomes";
import { OurVisionView } from "Views/OurVision/OurVision";
import { CookiePolicyView } from "Views/Policies/CookiePolicy";
import { PrivacyPolicyView } from "Views/Policies/PrivacyPolicy";
import { Navigate, Route as ReactRoute, Route, Routes } from "react-router-dom";
import { container } from "tsyringe";
import { AppUrls } from "./AppUrls";
import { CommonInjects } from "./Application/CommonInjects";
import { PrivateRoute, ThemedLoader } from "./Components";
import { DebugRouter } from "./Components/DebugRouter";
import { BlankView } from "./Views/BlankView";
import { HomeView } from "./Views/Home/HomeView";
import { DefaultLayout, DefaultLayoutNoHeader } from "./Views/Layouts";
import { SplitLayout } from "./Views/Layouts/SplitLayout";
import LoginHomeView from "./Views/Login/LoginHomeView";
import { Todo } from "./Views/Todos/Todo";
import { NewsView } from "Views/News/News";
import { Header } from "Views/Header/Header";
import { HomesView } from "Views/Homes/Homes";
import { LivingView } from "Views/Living/Living";
import { FormSentView } from "Views/Contact/FormSent";
import { DownloadView } from "Views/BrochureAddendums/Download";
import { AssistedMoveView } from "Views/BuyingWithShoreline/AssistedMove/AssistedMoveView";
import { BuyingWithShorelineView } from "Views/BuyingWithShoreline/BuyingWithShorelineView";
const LogoutView = React.lazy(() => import("Views/Logout/LogoutView"));
const PageBuilderView = React.lazy(() => import("Views/PageBuilder/PageBuilderView"));
const AdminRoutes = React.lazy(() => import("./Views/Admin/AdminRoutes"));

export const AppRoutes: React.FC = () => {
    const accountStore = container.resolve(AccountStore);
    const domainStore = container.resolve(DomainStore);

    const showSignIn = () => {
        domainStore.setIsLoginModalOpen(true);
    };

    return (
        <>
            <DebugRouter>
                <Routes>
                    {/*                     <Route path={"/"} element={<DefaultLayoutNoHeader />}>
                        <Route
                            path={AppUrls.Client.NewHomes + "*"}
                            element={
                                <React.Suspense fallback={<ThemedLoader isOpen={true} />}>
                                    <NewHomesView />
                                </React.Suspense>
                            }
                        />
                    </Route> */}

                    {/* <Route path={"/"} element={<LayoutNoHeaderOrFooter />}>
                        <Route
                            path={AppUrls.Client.Admin.AdminCMS + "*"}
                            element={
                                <React.Suspense fallback={<ThemedLoader isOpen={true} />}>
                                    <AdminCMS />
                                </React.Suspense>
                            }
                        ></Route>
                    </Route> */}

                    <Route path={"/"} element={<DefaultLayout />}>
                        <Route
                            path={AppUrls.Client.NewHomes + "*"}
                            element={
                                <React.Suspense fallback={<ThemedLoader isOpen={true} />}>
                                    <NewHomesView />
                                </React.Suspense>
                            }
                        />
                    </Route>
                    <Route path={"/"} element={<DefaultLayout />}>
                        {/* ** Change back to Home view ** */}
                        {/* <Route index element={<Navigate to="/newhomes" />} /> */}
                        <Route index element={<HomeView />} />
                        <Route
                            path={AppUrls.Client.ConfirmPassword}
                            element={
                                <React.Suspense fallback={<ThemedLoader isOpen={true} />}>
                                    <ConfirmPasswordView />
                                </React.Suspense>
                            }
                        />

                        <Route
                            path={AppUrls.Client.ForgotPassword}
                            element={
                                <React.Suspense fallback={<ThemedLoader isOpen={true} />}>
                                    <ConfirmPasswordView />
                                </React.Suspense>
                            }
                        />

                        <Route
                            path={AppUrls.Client.About}
                            element={
                                <React.Suspense fallback={<ThemedLoader isOpen={true} />}>
                                    <AboutView />
                                </React.Suspense>
                            }
                        />

                        <Route
                            path={"/homes"}
                            element={
                                <React.Suspense fallback={<ThemedLoader isOpen={true} />}>
                                    <HomesView />
                                </React.Suspense>
                            }
                        />
                        <Route
                            path={AppUrls.Client.OurVision}
                            element={
                                <React.Suspense fallback={<ThemedLoader isOpen={true} />}>
                                    <OurVisionView />
                                </React.Suspense>
                            }
                        />

                        {/* <Route
                            path={AppUrls.Client.Amenities}
                            element={
                                <React.Suspense fallback={<ThemedLoader isOpen={true} />}>
                                    <AmenitiesView />
                                </React.Suspense>
                            }
                        /> */}

                        <Route element={<PrivateRoute isAllowed={accountStore.isLoggedInUser} redirectFunction={showSignIn} redirectPath={AppUrls.Client.Home} />}>
                            {/* ONLY PUT ROUTES IN HERE IF YOU WANT TO REDIRECT AND TURN ON THE SIGN IN ELSE CREATE ANOTHER PRIVATE ROUTE*/}
                            {/* <Route
                                path={AppUrls.Client.BuyerLoginDashboard}
                                element={
                                    <React.Suspense fallback={<ThemedLoader isOpen={true} />}>
                                        <BuyerLoginDashboard />
                                    </React.Suspense>
                                }
                            /> */}
                        </Route>

                        {/* <Route
                            path={AppUrls.Client.News + "*"}
                            element={
                                <React.Suspense fallback={<ThemedLoader isOpen={true} />}>
                                    <NewsView />
                                </React.Suspense>
                            }
                        /> */}

                        <Route
                            path={AppUrls.Client.Living}
                            element={
                                <React.Suspense fallback={<ThemedLoader isOpen={true} />}>
                                    <LivingView />
                                </React.Suspense>
                            }
                        />

                        <Route
                            path={AppUrls.Client.Buying}
                            element={
                                <React.Suspense fallback={<ThemedLoader isOpen={true} />}>
                                    <BuyingWithShorelineView />
                                </React.Suspense>
                            }
                        />

                        <Route
                            path={AppUrls.Client.Register}
                            element={
                                <React.Suspense fallback={<ThemedLoader isOpen={true} />}>
                                    <ContactView />
                                </React.Suspense>
                            }
                        />

                        <Route
                            path={AppUrls.Client.FormSent}
                            element={
                                <React.Suspense fallback={<ThemedLoader isOpen={true} />}>
                                    <FormSentView />
                                </React.Suspense>
                            }
                        />

                        <Route
                            path={AppUrls.Client.PrivacyPolicy}
                            element={
                                <React.Suspense fallback={<ThemedLoader isOpen={true} />}>
                                    <PrivacyPolicyView />
                                </React.Suspense>
                            }
                        />

                        <Route
                            path={AppUrls.Client.CookiePolicy}
                            element={
                                <React.Suspense fallback={<ThemedLoader isOpen={true} />}>
                                    <CookiePolicyView />
                                </React.Suspense>
                            }
                        />

                        <Route
                            path={AppUrls.Client.Download}
                            element={
                                <React.Suspense fallback={<ThemedLoader isOpen={true} />}>
                                    <DownloadView />
                                </React.Suspense>
                            }
                        />

                        {/* <Route
                            path={AppUrls.Client.BuyingANewHome}
                            element={
                                <React.Suspense fallback={<ThemedLoader isOpen={true} />}>
                                    <BuyingANewHomeView />
                                </React.Suspense>
                            }
                        /> */}

                        {/* <Route
                            path={AppUrls.Client.News}
                            element={
                                <React.Suspense fallback={<ThemedLoader isOpen={true} />}>
                                    <NewsView />
                                </React.Suspense>
                            }
                        /> */}

                        {/* <Route
                            path={AppUrls.Client.Admin.AdminCMS + "*"}
                            element={
                                <React.Suspense fallback={<ThemedLoader isOpen={true} />}>
                                    <AdminCMS />
                                </React.Suspense>
                            }
                        > */}

                        {/* <Route
                            path={AppUrls.Client.PrivacyPolicy}
                            element={
                                <React.Suspense fallback={<ThemedLoader isOpen={true} />}>
                                    <PrivacyPolicyView />
                                </React.Suspense>
                            }
                        />
                        <Route
                            path={AppUrls.Client.Terms}
                            element={
                                <React.Suspense fallback={<ThemedLoader isOpen={true} />}>
                                    <TermsAndConditions />
                                </React.Suspense>
                            }
                        />
                        */}

                        {/* <Route path={AppUrls.Client.Todo} element={<Todo />} /> */}
                        <Route path={AppUrls.Client.Account.SessionExpired} element={<SessionExpiredView />} />
                        <Route path={AppUrls.Client.Error} element={<Error supportEmail={"support@shoothill.com"} />} />
                        <Route path={"/stylesheet"} element={<StyleSheetView />} />
                        <Route path={AppUrls.Client.PageBuilder} element={<PageBuilderView />} />

                        {/* <Route
                            path={"/admin/*"}
                            element={
                                <React.Suspense fallback={<ThemedLoader isOpen={true} />}>
                                    <AdminRoutes />
                                </React.Suspense>
                            }
                        /> */}
                    </Route>
                    <Route element={<SplitLayout />}>
                        <Route path={"/account/*"} element={<LoginHomeView />} />
                        <Route
                            path={AppUrls.Client.Account.Logout}
                            element={
                                <React.Suspense fallback={<ThemedLoader isOpen={true} />}>
                                    <LogoutView />
                                </React.Suspense>
                            }
                        />
                    </Route>

                    <Route element={<LayoutNoHeaderOrFooter />}>
                        {/* <Route path="preview/*" element={<PreviewView />} /> */}
                        <Route path="blank" element={<BlankView />} />
                    </Route>

                    {/*404 Route*/}
                    <ReactRoute
                        path={"*"}
                        element={
                            <main style={{ padding: "1rem" }}>
                                <p>
                                    There's nothing here!{" "}
                                    <a href="/" title="home">
                                        Click to return to the home page
                                    </a>
                                </p>
                            </main>
                        }
                    />
                </Routes>
            </DebugRouter>

            <CommonInjects />
        </>
    );
};
