import { observer } from "mobx-react-lite";
import React from "react";

interface ButtonIconWrapperProps {
    imgUrl: string;
}

export const ButtonIconWrapper: React.FC<ButtonIconWrapperProps> = observer((props) => {
    return (
        <>
            <img src={props.imgUrl} style={{ verticalAlign: "center", paddingRight: "10px" }}></img>
        </>
    );
});
