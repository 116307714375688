import { observer } from "mobx-react-lite";
import React from "react";

import { Helmet, HelmetData } from "react-helmet-async";

interface IMetaHeader {
    title: string;
    metaDescription: string;
}

export const MetaHeader: React.FC<IMetaHeader> = observer((props) => {
    const helmetData = new HelmetData({});

    let siteUrl: string = "https://shorelinefolkestone.co.uk/";

    return (
        <>
            <Helmet helmetData={helmetData}>
                <title>{props.title}</title>
                <link rel="canonical" href={siteUrl} />
                <meta name="description" content={props.metaDescription} />
            </Helmet>
        </>
    );
});
