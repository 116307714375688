import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { Validator } from "Application/Validation";

export class ForgotPasswordModel extends ModelBase<ForgotPasswordModel> {
    public id: string = "";

    public emailAddress: string = "";

    constructor() {
        super();
        makeObservable(this, {
            id: observable,

            emailAddress: observable,
        });
    }
}

export class ForgotPasswordModelValidator extends Validator<ForgotPasswordModel> {
    constructor() {
        super();
        //this.ruleFor("id").notEmpty();
        this.ruleFor("emailAddress").notEmpty().emailAddress().maxLength(100);
    }
}
