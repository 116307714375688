import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";

import { Box, pxToRem, Button, Input, Label, Show, Else, ThemedLoader } from "Application";
import { LoginHomeViewModel } from "../../LoginHomeViewModel";
import { ForgotPasswordViewModel } from "./ForgotPasswordViewModel";
import { useParams } from "react-router";

/**
 * Login page view.
 */
export const ForgotPasswordView = observer(({ loginHomeViewModel }: { loginHomeViewModel: LoginHomeViewModel }) => {
    // #region Code Behind
    const [viewModel] = useState(() => new ForgotPasswordViewModel());
    let params = useParams();
    let token = params.token || null;

    useEffect(() => {
        if (token) {
            viewModel.showResetPasswordCommand.execute(token);
        }
    }, []);
    // #endregion Code Behind

    const renderForm = () => (
        <>
            <Box grid alignContent={"center"} dc="1fr" rowGap={"30px"} minWidth={"300px"}>
                <Label fontStyle={"h3"}>Forgot your password?</Label>
                <Show if={!viewModel.showSuccess}>
                    <Label fontStyle={"h4"} style={{ fontWeight: 400 }}>
                        Tell us your email address, and we'll send you an email to reset your details.
                    </Label>
                    <Input
                        displayName="Email Address *"
                        command={viewModel.updateEmailAddressCommand}
                        value={() => viewModel.getValue("emailAddress")}
                        validationMessage={() => viewModel.getError("emailAddress")}
                    />
                    <Button displayName="Continue" command={viewModel.forgotPasswordCommand} paletteColor="primary" type="submit" />
                    <Else>
                        <Label fontStyle={"h4"} style={{ fontWeight: 400 }}>
                            We have sent you an email to reset your password.
                        </Label>
                    </Else>
                </Show>
                <Box onClick={() => loginHomeViewModel.navigateToSignInCommand.execute()}>
                    <Label fontStyle={"h7"}>Back to sign in</Label>
                </Box>
            </Box>
        </>
    );

    const renderResetPassword = () => {
        return (
            <Box grid alignContent={"center"} dc="1fr" rowGap={"30px"} minWidth={"300px"}>
                <Show if={!viewModel.resetPasswordViewModel.showSuccessMessage}>
                    <Label fontStyle={"h3"}>Reset your password?</Label>
                    <Input
                        displayName={"New Password"}
                        command={viewModel.resetPasswordViewModel.updateNewPasswordCommand}
                        value={() => viewModel.resetPasswordViewModel.getValue("newPassword")}
                        validationMessage={() => viewModel.resetPasswordViewModel.getError("newPassword")}
                        type={"password"}
                    />
                    <Input
                        displayName={"Confirm Password"}
                        command={viewModel.resetPasswordViewModel.updateConfirmPasswordCommand}
                        value={() => viewModel.resetPasswordViewModel.getValue("confirmPassword")}
                        validationMessage={() => viewModel.resetPasswordViewModel.getError("confirmPassword")}
                        type={"password"}
                    />
                    <Button displayName="Reset Password" command={viewModel.resetPasswordViewModel.resetPasswordCommand} paletteColor="primary" type="submit" />
                    <Else>
                        <Label fontStyle={"h4"}>You have successfully reset your password</Label>
                        <Label fontStyle={"h4"}>You can now go back to the login page</Label>
                        <Box onClick={() => loginHomeViewModel.navigateToSignInCommand.execute()}>
                            <Label fontStyle={"h7"}>Back to sign in</Label>
                        </Box>
                    </Else>
                </Show>
            </Box>
        );
    };

    const renderBusy = () => <ThemedLoader isOpen={viewModel.apiClient.IsBusy} />;
    /*const renderBusy = () => <span>Busy</span>;*/

    return (
        <React.Fragment>
            <Show if={!viewModel.showResetPassword}>
                {renderForm()}
                {renderBusy()}
                <Else>{renderResetPassword()}</Else>
            </Show>
        </React.Fragment>
    );
});
