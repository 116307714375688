import { computed, makeObservable, observable, runInAction } from "mobx";
import { FieldType, KeyValuePair, ViewModelBase } from "@shoothill/core";
import { APIClient, IKeyState, RelayCommand } from "Application";
import { CompareModalModel, CompareModalModelValidator } from "./CompareModalModel";
import { NewHomeListViewModel } from "Views/NewHomes/NewHomeList/NewHomeListViewModel";
import { NewHomesViewModel, loadingStatus } from "Views/NewHomes/NewHomesViewModel";
import { PropertyListItemModel } from "Views/NewHomes/Subviews/PropertyListItemModel";
import { PropertyListItemViewModel } from "Views/NewHomes/Subviews/PropertyListItemViewModel";
import { uriString } from "Application/Helpers/UriString";
import { property } from "lodash";

export class CompareModalViewModel extends ViewModelBase<CompareModalModel> {
    public apiClient = new APIClient();

    public properties: NewHomesViewModel | undefined = undefined;

    constructor(properties: NewHomesViewModel) {
        super(new CompareModalModel());
        this.setValidator(new CompareModalModelValidator());
        makeObservable(this, { getAddressSecondProperty: computed });

        this.properties = properties;
    }

    public updatePropertyTypeCommand = new RelayCommand((kvp: KeyValuePair) => {
        this.updateField("propertyType", kvp.text);
        console.log(this.model.propertyType);
    });

    public updateChosenProperty = new RelayCommand((key: KeyValuePair) => {
        this.updateField("selectedPropertyId", key.key);

        this.updateSecondProperty();
    });

    public get getSelectedPropertyId(): string {
        return this.model.selectedPropertyId;
    }

    // public generatePermalink = () => {
    //     let val = uriString(this.model.developmentName) + "/";

    //     if (this.model.phase) {
    //         val = val + uriString("phase" + this.model.phase);
    //     }

    //     if (this.model.block) {
    //         val = val + uriString("block" + this.model.block);
    //     }

    //     if (this.model.floor) {
    //         val = val + uriString("floor" + this.model.floor);
    //     }

    //     val = val + uriString("plot" + this.model.plotNumber);

    //     return val;
    // };

    public generatePermalink = () => {
        let val = uriString(this.model.developmentName) + "/";

        if (this.model.phase) {
            val = val + uriString("phase" + this.model.phase) + "-";
        }

        if (this.model.block) {
            val = val + uriString("block" + this.model.block) + "-";
        }

        if (this.model.floor) {
            val = val + uriString("floor" + this.model.floor) + "-";
        }

        val = val + uriString("plot" + this.model.plotNumber);

        return val;
    };

    public updateSecondProperty = () => {
        if (this.properties !== undefined) {
            this.properties.propertyListItemViewModels.map((property: PropertyListItemViewModel) => {
                if (property.model.id == this.model.selectedPropertyId) {
                    this.model.plotNumber = property.model.plotNumber;
                    this.model.addressLine1 = property.model.addressLine1;
                    // public addressLine1: string = "";
                    // public addressLine2: string = "";
                    // public postCode: string = "";
                    this.model.developmentName = property.model.developmentName;
                    this.model.description = property.model.description;
                    // public houseTypeId: string = "";
                    this.model.basePurchasePrice = property.model.basePurchasePrice;
                    this.model.numberOfBeds = property.model.numberOfBeds;
                    // public actualStatus: number = 0;
                    // public houseType: string = "";
                    this.model.numberOfBathrooms = property.model.numberOfBathrooms;
                    // public parkingType: string = "";
                    // public sqft: string = "";
                    // public epc: string = "";
                    // public councilTaxBand: string = "";
                    this.model.phase = property.model.phase;
                    this.model.floor = property.model.floor;
                    this.model.block = property.model.block;
                    this.model.features = property.model.features;
                    this.model.propertyRooms = property.model.propertyRooms;
                    this.model.floorplans = property.model.floorplans;
                    this.model.images = property.model.images;
                }
            });
        }
    };

    public getProperties(properties: NewHomesViewModel): KeyValuePair<any>[] {
        let retVal: KeyValuePair<any>[] = [];

        // let notselected: KeyValuePair<any> = {
        //     key: "",
        //     text: "Not Listed",
        // };
        // retVal.push(notselected);

        if (this.model.propertyType == "East Apartments") {
            properties.getEastWing;
            properties.model.eastWingViewModels.map((property) => {
                if (property.getValue("actualStatus") == "1") {
                    let kv: KeyValuePair<any> = {
                        key: property.model.id,
                        text: property.model.addressLine1 + "  -  " + "Plot " + property.model.plotNumber,
                    };
                    retVal.push(kv);
                }
            });
        }

        if (this.model.propertyType == "West Apartments") {
            properties.getWestWing;
            properties.model.westWingViewModels.map((property) => {
                if (property.getValue("actualStatus") == "1") {
                    let kv: KeyValuePair<any> = {
                        key: property.model.id,
                        text: property.model.addressLine1,
                    };
                    retVal.push(kv);
                }
            });
        }

        if (this.model.propertyType == "Beach Houses") {
            properties.getTownhouses;
            properties.model.townHouseViewModels.map((property) => {
                if (property.getValue("actualStatus") == "1") {
                    let kv: KeyValuePair<any> = {
                        key: property.model.id,
                        text: property.model.addressLine1,
                    };
                    retVal.push(kv);
                }
            });
        }

        if (this.model.propertyType == "Penthouses") {
            properties.getPenthouses;
            properties.model.penthouseViewModels.map((property) => {
                if (property.getValue("actualStatus") == "1") {
                    let kv: KeyValuePair<any> = {
                        key: property.model.id,
                        text: property.model.addressLine1,
                    };
                    retVal.push(kv);
                }
            });
        }

        if (this.model.propertyType == "Duplexes") {
            properties.getDuplexes;
            properties.model.duplexViewModels.map((property) => {
                if (property.getValue("actualStatus") == "1") {
                    let kv: KeyValuePair<any> = {
                        key: property.model.id,
                        text: property.model.addressLine1,
                    };
                    retVal.push(kv);
                }
            });
        }

        return retVal;
    }

    public getPropertyTypes(properties: NewHomesViewModel) {
        let PropertyTypeArray: KeyValuePair[] = [];
        let index = 0;

        if (properties.areThereAnyEastWingApartments == loadingStatus.YesDisplay) {
            PropertyTypeArray.push({ key: String(index), text: "East Apartments" });
            index++;
        }
        if (properties.areThereAnyWestWingApartments == loadingStatus.YesDisplay) {
            PropertyTypeArray.push({ key: String(index), text: "West Apartments" });
            index++;
        }
        if (properties.areThereAnyTownHouses == loadingStatus.YesDisplay) {
            PropertyTypeArray.push({ key: String(index), text: "Beach Houses" });
            index++;
        }
        if (properties.areThereAnyPenthouses == loadingStatus.YesDisplay) {
            PropertyTypeArray.push({ key: String(index), text: "Penthouses" });
            index++;
        }
        if (properties.areThereAnyDuplexes == loadingStatus.YesDisplay) {
            PropertyTypeArray.push({ key: String(index), text: "Duplexes" });
            index++;
        }

        return PropertyTypeArray;
    }

    public get getAddressSecondProperty(): string {
        return this.model.addressLine1;
    }

    private updateField(fieldName: keyof FieldType<CompareModalModel>, value: any) {
        this.setValue(fieldName, value);
        this.isFieldValid(fieldName);
    }
}
