import React from "react";
import { observer } from "mobx-react-lite";
import { Box } from "Application";
import { RoundedGreenBox } from "../SelectedPropertyStyles";
import { H3, P } from "Components/Primitives/TextElements/TextElements";
import { HorizontalGradLineDarkLightDark } from "Components/HorizontalGradLineDarkLightDark";

import PhoneThumb from "Assets/PhoneThumb.svg";

export const MarketingSuiteBox: React.FC = observer(() => {
    return (
        <>
            <RoundedGreenBox>
                <Box>
                    <H3 color="quarternary" pb="20px">
                        Marketing suite
                    </H3>
                    <P color="quarternary" style={{ fontSize: "14px" }}>
                        Open by appointment
                    </P>
                    <P color="quarternary" style={{ fontSize: "14px" }}>
                        Thursday - Monday 10:00 - 17:00
                    </P>
                </Box>
                <Box grid dc="auto 1fr" mc="auto 1fr" columnGap="10px" alignContent="center">
                    <Box>
                        <img src={PhoneThumb}></img>
                    </Box>
                    <a href="tel:01303 760940">
                        <P color="quarternary" style={{ fontSize: "14px" }}>
                            01303 760940
                        </P>
                    </a>
                </Box>
            </RoundedGreenBox>
        </>
    );
});
