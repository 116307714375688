import React from "react";
import { observer } from "mobx-react-lite";
import { Box } from "Application";

// Import Swiper React components
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Navigation, Pagination } from "swiper";

import Image1 from "Assets/HomeCarouselMulti1.jpg";
import Image2 from "Assets/HomeCarouselMulti2.jpg";
import Image3 from "Assets/HomeCarouselMulti3.jpg";
import Image4 from "Assets/HomeCarouselMulti4.jpg";
import Image5 from "Assets/HomeCarouselMulti5.jpg";
import Image6 from "Assets/HomeCarouselMulti6.jpg";
import Image7 from "Assets/HomeCarouselMulti7.jpg";
import Image8 from "Assets/HomeCarouselMulti8.jpg";
import Image9 from "Assets/HomeCarouselMulti9.jpg";
import Image10 from "Assets/HomeCarouselMulti10.jpg";
import LeftArrow from "Assets/LeftSandyArrow.svg";
import RightArrow from "Assets/RightSandyArrow.svg";

import styled from "@emotion/styled";

export interface ICarousel {
    isMobile: boolean;
}

export const CarouselMultiMk2: React.FC<ICarousel> = observer(({ isMobile }) => {
    return (
        <>
            <WrapperBox>
                <Swiper
                    navigation={true}
                    modules={[Navigation]}
                    className="mySwiper"
                    spaceBetween={isMobile ? 0 : 25}
                    slidesPerView={isMobile ? 1 : 3.4}
                    centeredSlides={true}
                    loop={true}
                    // loopedSlides={10}
                    // watchSlidesVisibility={true}
                >
                    <SwiperSlide>
                        <img src={Image1} width="100%" height="100%" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={Image2} width="100%" height="100%" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={Image3} width="100%" height="100%" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={Image4} width="100%" height="100%" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={Image5} width="100%" height="100%" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={Image6} width="100%" height="100%" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={Image7} width="100%" height="100%" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={Image8} width="100%" height="100%" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={Image9} width="100%" height="100%" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={Image10} width="100%" height="100%" style={{ objectFit: "cover", cursor: "grab" }} />
                    </SwiperSlide>
                </Swiper>
            </WrapperBox>
        </>
    );
});

export const WrapperBox = styled(Box)`
    width: 100%;
    height: 650px;
    background-color: #2b6298;
    .swiper-button-next {
        background-image: url(${RightArrow});
        background-repeat: no-repeat;
        background-size: 100% auto;
        background-position: center;
    }
    .swiper-button-next::after {
        display: none;
    }
    .swiper-button-prev {
        background-image: url(${LeftArrow});
        background-repeat: no-repeat;
        background-size: 100% auto;
        background-position: center;
    }
    .swiper-button-prev::after {
        display: none;
    }
    .swiper-container {
        height: auto;
    }

    .swiper-slide {
        /* height: auto; */
        height: 650px;
    }

    img {
        object-fit: cover;
        cursor: grab;
        user-select: none;
    }
`;
