import styled from "@emotion/styled";
import { Box } from "./General";

export const NextButtonOld = styled(Box)`
    border-radius: 50%;
    border: none;
    /* background-color: #eda765; */
    background: linear-gradient(270deg, #eda765 0%, #7f5433 100%) 0% 0% no-repeat padding-box;
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 3px 6px #00000029;
`;

export const PrevButtonOld = styled(Box)`
    border-radius: 50%;
    border: none;
    /* background-color: #eda765; */
    background: linear-gradient(90deg, #eda765 0%, #7f5433 100%) 0% 0% no-repeat padding-box;
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 3px 6px #00000029;
`;

export const NextButton = styled(Box)`
    border-radius: 50%;
    border: none;
    /* background-color: #eda765; */
    background: transparent;
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`;

export const PrevButton = styled(Box)`
    border-radius: 50%;
    border: none;
    /* background-color: #eda765; */
    background: transparent;
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`;
export const CloseButton = (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
        <defs>
            <linearGradient id="linear-gradient" x1="0.981" y1="0.5" x2="0.018" y2="0.5" gradientUnits="objectBoundingBox">
                <stop offset="0" stopColor="#eda765" />
                <stop offset="1" stopColor="#7f5433" />
            </linearGradient>
        </defs>
        <g id="Group_12362" data-name="Group 12362" transform="translate(14485 24116)">
            <rect id="Rectangle_3078" data-name="Rectangle 3078" width="30" height="30" rx="15" transform="translate(-14485 -24116)" fill="url(#linear-gradient)" />
            <g id="Group_12362-2" data-name="Group 12362" transform="translate(-14475.5 -24106)">
                <line id="Line_2" data-name="Line 2" y1="9" x2="9" transform="translate(1 1)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="2" />
                <line id="Line_3" data-name="Line 3" x2="9" y2="9" transform="translate(1 1)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="2" />
            </g>
        </g>
    </svg>
);
