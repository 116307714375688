import React from "react";
import { UnsupportedBrowserView } from "../UnsupportedBrowser/UnsupportedBrowserView";
import { InfoBar } from "../../Components/InfoBar";
import { observer } from "mobx-react-lite";
import { Outlet } from "react-router";
import { Box } from "../../Components";
import { Header } from "../Header/Header";

//EN: use empty generic type to get default react props
export const DefaultLayoutNoFooter: React.FC<{}> = observer((props) => {
    if (!props) return <>Props undefined</>;
    return (
        <>
            {window.IE11 ? (
                <UnsupportedBrowserView />
            ) : (
                <Box className={"overflowContainer"}>
                    <Header />
                    <Box overflow={"auto"} height={"100vh"}>
                        <InfoBar />
                        <Outlet />
                    </Box>
                </Box>
            )}
        </>
    );
});
