import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { Box, ICommand, RelayCommand } from "Application";
import { FullWidthCentFlexContainer } from "Components/FullWidthCentFlexCont";
import { InnerWrapper } from "Components/InnerWrapper";
import styled from "@emotion/styled";
import { H1, H2, H3, H4, P } from "Components/Primitives/TextElements/TextElements";
import { container } from "tsyringe";
import { DomainStore } from "Stores/Domain/DomainStore";
import { BannerImageFull } from "Components/BannerImageFull/BannerImageFull";
import Banner1 from "Assets/HomesBanner1.jpg";
import Banner1Mob from "Assets/HomesBanner1Mob.png";
import Banner2 from "Assets/HomesBanner2.jpg";
import Banner2Mob from "Assets/HomesBanner2Mob.png";
import { TitleLeftTextRightBlock } from "Components/TitleLeftTextRightBlock/TitleLeftTextRightBlock";
import Apartment from "Assets/HomesApartments.png";
import Townhouse from "Assets/HomesTownhouses.png";
import Duplex from "Assets/HomesDuplexes.png";
import Penthouse from "Assets/HomesPenthouses.png";
import Plan from "Assets/ShorelineCrescentPlan.jpg";

import HomesEastWest from "Assets/Homes_EastWest.jpg";
import HomesTownhouse from "Assets/Homes_Townhouse_2.jpg";
import HomesDuplex from "Assets/Homes_Duplex_2.jpg";
import HomesPenthouse from "Assets/Homes_Penthouse_2.jpg";
import HomesEastWestMobile from "Assets/Homes_EastWest_Mobile.png";
import HomesTownhouseMobile from "Assets/Homes_Townhouse_2.jpg";
import HomesDuplexMobile from "Assets/Homes_Duplex_2.jpg";
import HomesPenthouseMobile from "Assets/Homes_Penthouse_2.jpg";

import { motion } from "framer-motion";
import { HomesViewModel } from "./HomesViewModel";
import { ShorelineButton } from "Components/ShorelineButton";
import { BannerImageFullMobile } from "Components/BannerImageFull/BannerImageFullMobile";
import { DownloadBrochureModal } from "Components/DownloadBrochure/DownloadBrochureModal/DownloadBrochureModal";
import { AppUrls } from "AppUrls";

import { useInView } from "react-intersection-observer";
import { BaseCard } from "Components/BaseCard";
import UnMute from "Assets/Icons/UnMute.svg";
import Mute from "Assets/Icons/Mute.svg";
import BannerVideo from "Assets/Videos/Shoreline Crescent Video Snippet_1.mp4";
import BannerVideoMobile from "Assets/Videos/Shoreline_Crescent_Video Snippet_1_MOBILE.mp4";
import BannerImagePlaceholder from "Assets/HomesBannerPlaceholder.png";

export const HomesView: React.FC = observer(() => {
    const domainStore = container.resolve(DomainStore);
    const [viewModel] = useState<HomesViewModel>(new HomesViewModel());
    const videoRef = useRef(null);

    const [index, setIndex] = useState(0);
    const [currentOpacity, setCurrentOpacity] = useState(1);
    const [isVisible, setIsVisible] = useState(true);

    const { ref: RefInitial, inView: InitialInView } = useInView({ threshold: 0.25 });
    const RefApartmentsScrollTo = useRef<null | HTMLDivElement>(null);
    const { ref: RefTownhouse, inView: TownhouseInView } = useInView({ threshold: 0.25 });
    const RefTownhousesScrollTo = useRef<null | HTMLDivElement>(null);
    const { ref: RefDuplex, inView: DuplexInView } = useInView({ threshold: 0.25 });
    const RefDuplexesScrollTo = useRef<null | HTMLDivElement>(null);
    const { ref: RefPenthouse, inView: PenthouseInView } = useInView({ threshold: 0.5 });
    const RefPenthousesScrollTo = useRef<null | HTMLDivElement>(null);

    useEffect(() => {
        if (InitialInView == true) {
            setIndex(0);
        }
    }, [InitialInView]);

    useEffect(() => {
        if (TownhouseInView == true) {
            setIndex(1);
        }
    }, [TownhouseInView]);

    useEffect(() => {
        if (DuplexInView == true) {
            setIndex(2);
        }
    }, [DuplexInView]);

    useEffect(() => {
        if (PenthouseInView == true) {
            setIndex(3);
        }
    }, [PenthouseInView]);

    const openBrochureModal: ICommand = new RelayCommand(() => {
        domainStore.openBrochureModal();
    });

    const ViewOptions = [
        {
            backgroundColor: "#FAEEDD",
            bodyText: "Fifty-four one and two bedroom apartments across the development with private balconies or terraces, all with glorious views.",
            imageUrl: Apartment,
            title: "Apartment",
        },
        {
            backgroundColor: "#D1DFEA",
            bodyText:
                "Twenty luxury 3 and 4 bedroom Beach Houses with expansive roof terraces, gardens, beach front entrances, cellars, laundry spaces, parking for two cars and some with lifts.",
            imageUrl: Townhouse,
            title: "Beach House",
        },
        {
            backgroundColor: "#A3C0D6",
            bodyText:
                "Four two-bedroom duplexes each with its own beach front entrance, podium garden, generous cellar and utility spaces, as well as private parking at your door.",
            imageUrl: Duplex,
            title: "Duplex",
        },
        {
            backgroundColor: "#F4DDBB",
            bodyText: "Six luxurious penthouse apartments, each with their own balconies and sprawling rooftop terraces with sea views.",
            imageUrl: Penthouse,
            title: "Penthouse",
        },
    ];

    const navtoApartments = () => {
        RefApartmentsScrollTo.current?.scrollIntoView({ behavior: "instant", block: "center" });
    };

    const navtoTownhouses = () => {
        RefTownhousesScrollTo.current?.scrollIntoView({ behavior: "instant", block: "end" });
    };

    const navtoDuplexes = () => {
        RefDuplexesScrollTo.current?.scrollIntoView({ behavior: "instant", block: "end" });
    };

    const navtoPenthouses = () => {
        RefPenthousesScrollTo.current?.scrollIntoView({ behavior: "instant", block: "end" });
    };

    return (
        <>
            <DownloadBrochureModal open={domainStore.isBrochureModalOpen} closeModal={domainStore.closeBrochureModal} />

            <FullWidthCentFlexContainer>
                <InnerWrapper height={"100%"} style={{ backgroundColor: ViewOptions[index].backgroundColor }}>
                    {/* **** Banner Image - swapped out for video **** */}
                    {/* {viewModel.isMobile ? <BannerImageFullMobile imageUrl={Banner1Mob} /> : <BannerImageFull imageUrl={Banner1} />} */}

                    <Box style={{ position: "relative", lineHeight: "0px" }}>
                        <video autoPlay muted={viewModel.isVideoMuted} playsInline ref={videoRef} loop width="100%" poster={BannerImagePlaceholder}>
                            <source src={viewModel.isMobile ? BannerVideoMobile : BannerVideo} type="video/mp4" />
                            {/* <img src={BannerImage} width="100%" /> */}
                        </video>
                        <Box style={{ position: "absolute", left: 20, top: 20 }}>
                            <ShorelineButton
                                command={viewModel.toggleMute}
                                icon={<img src={viewModel.isVideoMuted ? Mute : UnMute} height="14px" style={{ paddingRight: viewModel.isMobile ? "0px" : "10px" }} />}
                                displayName={viewModel.isMobile ? "" : viewModel.isVideoMuted ? "  Unmute" : "  Mute"}
                            />
                        </Box>
                    </Box>

                    <div ref={RefInitial}>
                        <TitleLeftTextRightBlock
                            title="Shoreline Crescent"
                            textBlock={`20 Beach Houses, 4 Duplexes, 54 Apartments and 6 Penthouses, each with outside space and access to communal gardens.\n
With uninterrupted views out to sea and situated on the beach’s edge, Shoreline Crescent is one of the finest beachside developments in the country.`}
                            backgroundColour="#2B6298"
                            textColour={"secondary"}
                            buttons={
                                <>
                                    <ShorelineButton displayName="VIEW AVAILABLE PROPERTIES" value={AppUrls.Client.NewHomes} command={viewModel.navTo} />
                                    <ShorelineButton displayName="BOOK AN APPOINTMENT" value={AppUrls.Client.Register} command={viewModel.navTo} />
                                    <ShorelineButton displayName="DOWNLOAD BROCHURE" command={openBrochureModal} />
                                </>
                            }
                            isMobile={viewModel.isMobile}
                            isTablet={viewModel.isTablet}
                            hasLargerBodyText={true}
                            hasThreeButtons={true}
                        />
                    </div>
                </InnerWrapper>
            </FullWidthCentFlexContainer>

            <FullWidthCentFlexContainer>
                <InnerWrapper maxWidth="1400px" m={viewModel.isMobile ? "0px 20px" : "0px 40px"}>
                    <HomesBox>
                        <H1 color="primary" pb="40px" style={{ fontStyle: "48px", fontWeight: 400 }}>
                            Homes
                        </H1>
                        <Box grid dc="1fr 1fr">
                            <BaseCard
                                imgUrl={viewModel.isMobile ? HomesEastWestMobile : HomesEastWest}
                                title="Apartments"
                                description="Fifty-four one and two bedroom apartments across the development with private balconies or terrace. Elegant open-plan living areas frame spectacular views and rooms are drenched in sunlight."
                            />
                            <BaseCard
                                imgUrl={viewModel.isMobile ? HomesTownhouseMobile : HomesTownhouse}
                                title="Beach Houses"
                                description="Twenty luxury 3 and 4 bedroom Beach Houses with expansive roof terraces, gardens, beach front entrances, cellars, laundry spaces, parking for two cars and some with lifts. A warm palette of materials is inviting to the touch and spaces are carefully planned."
                            />
                            <BaseCard
                                imgUrl={viewModel.isMobile ? HomesDuplexMobile : HomesDuplex}
                                title="Duplexes"
                                description="Four two-bedroom duplexes each with its own beach front entrance, podium garden, cellar and utility spaces, as well as private parking. Natural light, clean lines and woven carpets continue the sense of the beach, while triple glazed windows protect from sound."
                            />
                            <BaseCard
                                imgUrl={viewModel.isMobile ? HomesPenthouseMobile : HomesPenthouse}
                                title="Penthouses"
                                description="Six luxurious penthouse apartments, each with their own balconies and sprawling rooftop terraces. Layouts are devised to maximise dramatic views out to sea, over gardens and of the Folkestone townscape, while remaining inherently private."
                            />
                        </Box>
                    </HomesBox>
                    <Box pb="80px" style={{ display: "flex", justifyContent: "center" }}>
                        <ShorelineButton displayName="VIEW AVAILABLE PROPERTIES" value={AppUrls.Client.NewHomes} command={viewModel.navTo} />
                    </Box>
                    {!viewModel.isMobile && (
                        <>
                            {/* OLD SCROLL SECTION */}
                            {/* <ScrollBox hidden={viewModel.isMobile} style={{ opacity: currentOpacity }}>
                                <Box flexBox maxWidth="1400px" style={{ justifyContent: "space-between", flexDirection: "column" }}>
                                    <Box grid dc="1fr 1fr">
                                        <MenuBox>
                                            <H3ScrollMenuItem color="primary" pb="10px" className={index === 0 ? "selected" : ""} onClick={navtoApartments}>
                                                Apartments
                                            </H3ScrollMenuItem>
                                            <H3ScrollMenuItem color="primary" pb="10px" className={index === 1 ? "selected" : ""} onClick={navtoTownhouses}>
                                                Townhouses
                                            </H3ScrollMenuItem>
                                            <H3ScrollMenuItem color="primary" pb="10px" className={index === 2 ? "selected" : ""} onClick={navtoDuplexes}>
                                                Duplexes
                                            </H3ScrollMenuItem>
                                            <H3ScrollMenuItem color="primary" pb="10px" className={index === 3 ? "selected" : ""} onClick={navtoPenthouses}>
                                                Penthouses
                                            </H3ScrollMenuItem>
                                        </MenuBox>

                                        <BodyP>{ViewOptions[index].bodyText}</BodyP>
                                    </Box>
                                    <Box grid dc="1fr 2fr">
                                        <Box />
                                        <Box>
                                            <img src={ViewOptions[index].imageUrl} width="100%" height="auto" style={{ objectFit: "cover", display: "block", maxHeight: "50vh" }} />
                                        </Box>
                                    </Box>
                                </Box>
                            </ScrollBox> */}
                        </>
                    )}
                    {/* ****DO NOT REMOVE - These are the divs that are scrolled to**** */}
                    {/* {!viewModel.isMobile && (
                        <>
                            <div style={{ height: "100vh" }} ref={RefInitial}>
                                <div ref={RefApartmentsScrollTo} />
                            </div>

                            <div style={{ height: "100vh" }} ref={RefTownhouse}>
                                <div ref={RefTownhousesScrollTo} style={{ height: "100vh" }} />
                            </div>
                            <div style={{ height: "100vh" }} ref={RefDuplex}>
                                <div ref={RefDuplexesScrollTo} style={{ height: "100vh" }} />
                            </div>
                            <div style={{ height: "100vh" }} ref={RefPenthouse}>
                                <div ref={RefPenthousesScrollTo} style={{ height: "100vh" }} />
                            </div>
                        </>
                    )} */}
                    {/* {viewModel.isMobile && (
                        <>
                            {ViewOptions.map((houseType) => {
                                return (
                                    <Box p="30px 20px" style={{ backgroundColor: houseType.backgroundColor }}>
                                        <H3StyledMobile pb="22px">{houseType.title}</H3StyledMobile>
                                        <PStyledMobile pb="28px">{houseType.bodyText}</PStyledMobile>

                                        <img src={houseType.imageUrl} width="100%" height="100%" />
                                    </Box>
                                );
                            })}
                        </>
                    )} */}
                </InnerWrapper>
            </FullWidthCentFlexContainer>
            <FullWidthCentFlexContainer>
                <InnerWrapper>
                    {viewModel.isMobile ? <BannerImageFullMobile imageUrl={Banner2Mob} /> : <BannerImageFull imageUrl={Banner2} />}
                    <BannerImageFull imageUrl={Plan} />

                    <FullWidthCentFlexContainer p={viewModel.isMobile ? "30px 20px" : "50px 40px"}>
                        <BuyingProcessBox maxWidth="1400px" grid dc="1fr 1fr" mc="1fr">
                            <H2 color="primary">The Buying Process</H2>
                            <P color="primary">
                                From the day of your first appointment with our Shoreline Sales Consultant, you will receive a personalised service. We know how important it is to
                                make your home-buying journey enjoyable, exciting and tailored to you. Our Sales team can put you in touch with recommended solicitors and
                                independent financial advisors to assist you throughout the buying process.
                            </P>

                            <OrangeHr isMobile={viewModel.isMobile} />
                            <H3Styled style={{ fontSize: "32px" }}>1. Making a reservation</H3Styled>
                            <P color="primary">
                                When you have chosen your dream home, and are ready to make a reservation, we ask that you bring two forms of identification with you.<br></br>
                                <br></br> This can be your passport, your driver’s licence, a bank statement or in some cases, a utility bill.<br></br>
                                <br></br> At the same time, you will pay an initial reservation fee.
                            </P>
                            <OrangeHr isMobile={viewModel.isMobile} />

                            <H3Styled style={{ fontSize: "32px" }}>2. Payable reservation fees</H3Styled>
                            <P color="primary">
                                Under £499k (Two thousand pounds)<br></br>
                                <br></br> From £500k to £799k (Five thousand pounds)<br></br>
                                <br></br> Over £800k (Ten thousand pounds)
                            </P>

                            <OrangeHr isMobile={viewModel.isMobile} />
                            <H3Styled style={{ fontSize: "32px" }}>3. Instruct your solicitors</H3Styled>
                            <P color="primary">
                                The next step is to instruct your solicitor to act on your behalf. We suggest you do this within 48 hours of making your reservation, to avoid any
                                delays and to ensure the process runs as smoothly as possible. If you do not have a solicitor, our Sales Consultants can provide you with
                                independent solicitors that we work with to make your move easier. On receipt of the draft legal contracts, we aim to exchange contracts within 28
                                days.
                            </P>

                            <OrangeHr isMobile={viewModel.isMobile} />
                            <H3Styled style={{ fontSize: "32px" }}>4. Searches</H3Styled>
                            <P color="primary">
                                Once your solicitor has been instructed, you will need to transfer across your search monies to prevent any delays. Your solicitor will advise you
                                which searches need to take place, and ensure they are carried out in a timely manner.
                            </P>

                            <OrangeHr isMobile={viewModel.isMobile} />
                            <H3Styled style={{ fontSize: "32px" }}>5. Mortgage application</H3Styled>
                            <P color="primary">
                                If you require a mortgage, you will need to liaise with your mortgage advisor who will be able to submit a full application with your preferred
                                lender. Your advisor should submit a full application with your preferred lender within three days of making your reservation. If applicable, your
                                survey fee will also need to be paid at this point.
                            </P>

                            <OrangeHr isMobile={viewModel.isMobile} />
                            <H3Styled style={{ fontSize: "32px" }}>6. Enquiries</H3Styled>
                            <P color="primary">
                                Once the searches have been carried out, your solicitor may raise enquiries about your property. These will be answered by our legal team and a
                                report will be sent to you. You should read this report and confirm to your solicitor that you are happy with the content.
                            </P>

                            <OrangeHr isMobile={viewModel.isMobile} />
                            <H3Styled style={{ fontSize: "32px" }}>7. Survey</H3Styled>
                            <P color="primary">A survey will be carried out on your new home on behalf of your mortgage provider.</P>

                            <OrangeHr isMobile={viewModel.isMobile} />
                            <H3Styled style={{ fontSize: "32px" }}>8. Mortgage offer</H3Styled>
                            <P color="primary">At this point, your mortgage offer will be issued to you and your solicitor for sign off within a week of a survey.</P>

                            <OrangeHr isMobile={viewModel.isMobile} />
                            <H3Styled style={{ fontSize: "32px" }}>9. Deposits and exchanging contracts</H3Styled>
                            <P color="primary">
                                Prior to exchanging contracts, you will need to sign your contract and pay your deposit monies. Usually, 10% of the final purchase is required.
                                <br></br>
                                <br></br> We would recommend transferring your funds to your solicitor at least 48 hours prior to the exchange deadline. Once the contracts are
                                exchanged, we will update you on the completion date.
                            </P>

                            <OrangeHr isMobile={viewModel.isMobile} />
                            <H3Styled style={{ fontSize: "32px" }}>10. Home demonstration</H3Styled>
                            <P color="primary">You will be invited to attend a home demonstration to explain to you how your home works and the building’s features.</P>

                            <OrangeHr isMobile={viewModel.isMobile} />
                            <H3Styled style={{ fontSize: "32px" }}>11. Completion day</H3Styled>
                            <P color="primary">
                                When your home is ready and has passed all our quality checks, our team will serve your solicitors with a legal notice to complete.<br></br>
                                <br></br> This will give you 14 days to complete on the property. On the day of completion, your keys will be released once our solicitor has
                                received all the completion funds.
                            </P>

                            <OrangeHr isMobile={viewModel.isMobile} />
                            <H3Styled style={{ fontSize: "32px" }}>12. Aftercare</H3Styled>
                            <P color="primary">
                                To make sure our homes are of a high standard they have been inspected and signed off by different people at different stages of the build process.
                            </P>

                            <Box grid dc="1fr" style={{ justifyContent: "center", justifyItems: "center", gridColumn: !viewModel.isMobile ? "span 2" : "span 1" }}>
                                <Box grid tc="1fr 1fr" p="40px 0px">
                                    <ShorelineButton displayName="BOOK AN APPOINTMENT" value={AppUrls.Client.Register} command={viewModel.navTo} />
                                    <ShorelineButton displayName={"DOWNLOAD BROCHURE"} command={openBrochureModal} />
                                </Box>
                            </Box>
                        </BuyingProcessBox>
                    </FullWidthCentFlexContainer>
                </InnerWrapper>
            </FullWidthCentFlexContainer>
        </>
    );
});

const ScrollBox = styled.div`
    height: 94vh;
    width: 100%;
    position: sticky;
    left: 0;
    top: 48px;
    padding: 40px 0px;
    display: flex;
    justify-content: center;

    /* max-height: 100vh;
    max-width: 100vw;
    transform: translate(-50%, -50%); */

    .hidden {
        opacity: 0;
        transition: opacity 0.5s ease-in-out;
    }

    .visible {
        opacity: 1;
    }
`;

const H3ScrollMenuItem = styled(H3)`
    cursor: pointer;
    font-family: sohne;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    color: #212745;
    cursor: pointer;
`;

const MenuBox = styled(Box)`
    .selected {
        text-decoration: underline;
        text-underline-offset: 6px;
    }
`;
const BodyP = styled(P)`
    color: #1f2747;
    font-family: Sabon;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;

const BuyingProcessBox = styled(Box)`
    .white-space {
        white-space: pre-line;
    }
`;

interface IOrangeHR {
    isMobile?: boolean;
}

const OrangeHr = styled.hr<IOrangeHR>`
    border: 1px solid #e0a752;
    margin: 20px 0px;
    grid-column: ${(props) => (props.isMobile ? "span 1" : "span 2")};
`;

const H3Styled = styled(H3)`
    color: #1f2747;
    font-family: sohne;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 48px;
    cursor: pointer;
`;

const H3StyledMobile = styled(H3)`
    color: #212745;
    font-family: sohne;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 48px; /* 160% */
`;

const PStyledMobile = styled(P)`
    color: #1f2747;
    font-family: sabon;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;

const HomesBox = styled(Box)`
    padding: 67px 0px 40px 0px;
`;
