import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { observer } from "mobx-react-lite";
import { Box, CheckBox, ICommand, Input, Label, RelayCommand, theme } from "Application";
import { FullWidthCentFlexContainer } from "Components/FullWidthCentFlexCont";
import { InnerWrapper } from "Components/InnerWrapper";
import { ImageCarouselWithTextWider } from "Components/ImageCarouselWithText/ImageCarouselWithTextWider";

// Image Imports
import X from "Assets/x.svg";
import EnquiryThumb from "Assets/Enquiry.svg";
import EnquiryThumbWhite from "Assets/EnquiryWhite.svg";
import CompareThumb from "Assets/Compare.svg";
import FavsThumb from "Assets/Favs.svg";
import FavsThumbWhite from "Assets/FavesWhite.svg";
import DownloadThumb from "Assets/Download.svg";

import ShareThumb from "Assets/Share.svg";
import BackThumb from "Assets/Back.svg";
import BathThumb from "Assets/BathThumb.svg";
import BedThumb from "Assets/BedThumb.svg";
import Banner from "Assets/SelectedApartmentBanner2.jpg";
import loadingSpinner from "Assets/LoadingSpinner.svg";

import { ShorelineButton } from "Components/ShorelineButton";

import { PropertyListItemViewModel } from "../Subviews/PropertyListItemViewModel";
import { CarouselForImagesDimensions } from "Components/CarouselForImages/CarouselForImagesDimensions";
import { GradLabel, H3Grad, H4Grad } from "Components/GradHeadings";
import { CompareModalView } from "./CompareModal/CompareModal";
import { BathBed, EnqBoxWrapper, EnquiryBox, EnquiryBoxes, EnquiryInput, EnquiryText, ListBox, ListItem, MenuItem, RightBox, SpinnerBox } from "./SelectedPropertyStyles";
import { AppUrls } from "AppUrls";
import { CoreStoreInstance, formatCurrency } from "@shoothill/core";
import { NewHomesViewModel } from "../NewHomesViewModel";
import { SelectedPropertyViewModel } from "./SelectedPropertyViewModel";
import { ShareLinksModal } from "Components/ShareLinksModal/ShareLinksModal";
import { Link } from "react-router-dom";
import { H2, H3, H4, H5, P } from "Components/Primitives/TextElements/TextElements";
import { MarketingSuiteBox } from "./MarketingSuiteBox/MarketingSuiteBox";
import { MortgagesBox } from "./MortgagesBox/MortgagesBox";
import { BuyingANewHomeBox } from "./BuyingANewHomeBox/BuyingANewHomeBox";
import { StampDutyCalculatorBox } from "./StampDutyCalculatorBox/StampDutyCalculator";
import { Buttons } from "./Buttons/Buttons";
import { CompareModalMobile } from "./CompareModal/CompareModalMobile";
import { DownloadBrochureModal } from "Components/DownloadBrochure/DownloadBrochureModal/DownloadBrochureModal";
import { RoundUp } from "Application/Helpers/RoundUp";
import { AccountStore } from "Stores/Domain";
import { container } from "tsyringe";
import { DomainStore } from "Stores/Domain/DomainStore";
import { Modal } from "@fluentui/react";
import { ModalBanner } from "./CompareModal/CompareModalMobile.styles";
import { FormatBathroom, FormatBedroom } from "Utils/BedroomBathroomFormat";
import { CarouselFloorPlansMk2 } from "Components/CarouselFloorPlansMk2/CarouselFloorPlansMk2";
import { currencyFormat } from "Utils/CurrencyFormatting";
interface ISelectedProperty {
    viewModel: PropertyListItemViewModel;
    propertiesViewModel: NewHomesViewModel;
}

export const SelectedProperty: React.FC<ISelectedProperty> = observer((props) => {
    const accountStore = container.resolve(AccountStore);
    const domainStore = container.resolve(DomainStore);
    const [viewModel] = useState<SelectedPropertyViewModel>(new SelectedPropertyViewModel());
    const [imageModalToggled, setImageModalToggled] = useState(false);
    const [shareLinksModalToggled, setShareLinksModalToggled] = useState(false);
    const [brochureDownloadModalToggled, setBochureDownloadModalToggled] = useState(false);
    const [showNotLoggedIn, setShowNotLoggedIn] = useState(false);
    let { link } = useParams();

    CoreStoreInstance.CoreOptions.mobileBreakPoint = 600;

    const doSomething: ICommand = new RelayCommand((value: string) => {});

    const propertyId: string = props.viewModel.getValue("id");
    const isFavourite: boolean = domainStore.isFavourite(propertyId);

    const toggleModal: ICommand = new RelayCommand((value: string) => {
        switch (value) {
            case "compare":
                setImageModalToggled(true);
                break;
            case "download":
                setBochureDownloadModalToggled(true);
                break;
            default:
        }
    });

    const toggleFavourite: ICommand = new RelayCommand((value: string) => {
        if (accountStore.IsLoggedIn === false) {
            setShowNotLoggedIn(true);
        } else {
            const propertyId: string = props.viewModel.getValue("id");
            const isFavourite: boolean = domainStore.isFavourite(propertyId);
            // if favourite is true, we want to send isdeleted = true to toggle its state
            // if favourite is false, we want to send isdeleted = false to add it.
            domainStore.setFavouriteState(accountStore.UserName, propertyId, isFavourite);
        }
    });

    const closeNotLoggedDialog = () => {
        setShowNotLoggedIn(false);
    };

    const acceptNotLoggedDialog: ICommand = new RelayCommand((value: string) => {
        setShowNotLoggedIn(false);
        domainStore.setIsLoginModalOpen(true);
    });

    const toggleModal2 = () => {
        setImageModalToggled(true);
    };

    const toggleShareLinksModal = () => {
        setShareLinksModalToggled(true);
    };

    const toggleDownloadBrochureModal = () => {
        setBochureDownloadModalToggled(true);
    };

    const onClose = (): void => {
        setShareLinksModalToggled(false);
        setImageModalToggled(false);
        setBochureDownloadModalToggled(false);
        console.log("closed");
    };

    const handleBackClick = () => {
        props.viewModel.history.push(AppUrls.Client.NewHomes);
    };

    // useEffect(() => {
    //     if (viewModel.isModelValid(true, true) === true) {
    //         CoreStoreInstance.HideInfoBar();
    //     }
    // }, [viewModel.isModelValid(true, true)]);

    const EnquiryForm = (
        <>
            <EnqBoxWrapper>
                <EnquiryBox>
                    <H3 color="quarternary" pt="40px" pb="20px">
                        Make an enquiry
                    </H3>
                    <EnquiryBoxes grid dc="1fr 1fr">
                        <Box style={{ alignSelf: "start" }}>
                            <EnquiryInput
                                command={viewModel.updateFirstName}
                                type="text"
                                displayName="First name*"
                                placeholder="First name"
                                labelColor="quarternary"
                                value={() => viewModel.getValue("firstName")}
                                validationMessage={() => viewModel.getError("firstName")}
                            ></EnquiryInput>
                        </Box>
                        <Box style={{ alignSelf: "start" }}>
                            <EnquiryInput
                                command={viewModel.updateLastName}
                                type="text"
                                displayName="Last name*"
                                placeholder="Last name"
                                labelColor="quarternary"
                                value={() => viewModel.getValue("surname")}
                                validationMessage={() => viewModel.getError("surname")}
                            ></EnquiryInput>
                        </Box>
                    </EnquiryBoxes>
                    <EnquiryBoxes>
                        <EnquiryInput
                            command={viewModel.updateAddressLine1}
                            type="text"
                            displayName="Address line 1*"
                            placeholder="Address line 1"
                            labelColor="quarternary"
                            value={() => viewModel.getValue("addressLine1")}
                            validationMessage={() => viewModel.getError("addressLine1")}
                        />
                    </EnquiryBoxes>
                    <EnquiryBoxes>
                        <EnquiryInput
                            command={viewModel.updateAddressLine2}
                            type="text"
                            displayName="Address line 2*"
                            placeholder="Address line 2"
                            labelColor="quarternary"
                            value={() => viewModel.getValue("addressLine2")}
                            /* validationMessage={() => viewModel.getError("addressLine2")} */
                        />
                    </EnquiryBoxes>
                    <EnquiryBoxes grid dc="1fr 1fr">
                        <Box style={{ alignSelf: "start" }}>
                            <EnquiryInput
                                command={viewModel.updateCity}
                                type="text"
                                displayName="City / Town*"
                                placeholder="City / Town"
                                labelColor="quarternary"
                                value={() => viewModel.getValue("city")}
                                validationMessage={() => viewModel.getError("city")}
                            ></EnquiryInput>
                        </Box>
                        <Box style={{ alignSelf: "start" }}>
                            <EnquiryInput
                                command={viewModel.updatePostcode}
                                type="text"
                                displayName="Post code*"
                                placeholder="Post code"
                                labelColor="quarternary"
                                value={() => viewModel.getValue("postcode")}
                                validationMessage={() => viewModel.getError("postcode")}
                            ></EnquiryInput>
                        </Box>
                    </EnquiryBoxes>
                    <EnquiryBoxes>
                        <EnquiryInput
                            command={viewModel.updateEmail}
                            type="email"
                            displayName="Email address*"
                            placeholder="Email address"
                            labelColor="quarternary"
                            value={() => viewModel.getValue("contactEmail")}
                            validationMessage={() => viewModel.getError("contactEmail")}
                        ></EnquiryInput>
                    </EnquiryBoxes>
                    <EnquiryBoxes>
                        <EnquiryInput
                            command={viewModel.updatePhoneNumber}
                            type="number"
                            displayName="Phone number*"
                            placeholder="Phone number"
                            labelColor="quarternary"
                            value={() => viewModel.getValue("primaryContactNumber")}
                            // maskedOptions={MaskedOptions.MobilePhoneNumber()}
                            validationMessage={() => viewModel.getError("primaryContactNumber")}
                        ></EnquiryInput>
                    </EnquiryBoxes>

                    <EnquiryBoxes>
                        <Label color="secondary">Preferred method of contact*</Label>
                    </EnquiryBoxes>

                    <EnquiryBoxes grid dc="1fr 1fr">
                        <CheckBox
                            className="inputConsent"
                            displayName="&nbsp;&nbsp;Email"
                            command={viewModel.updatePrefersEmails}
                            labelColor="secondary"
                            value={() => viewModel.getValue("preferredContactMethodEmail")}
                            validationMessage={() => viewModel.getError("preferredContactMethodEmail")}
                        ></CheckBox>
                        <CheckBox
                            className="inputConsent"
                            displayName="&nbsp;&nbsp;Phone"
                            command={viewModel.updatePrefersTelCalls}
                            labelColor="secondary"
                            value={() => viewModel.getValue("preferredContactMethodPhone")}
                            validationMessage={() => viewModel.getError("preferredContactMethodPhone")}
                        ></CheckBox>
                    </EnquiryBoxes>

                    {viewModel.getPreferredContactMethodValidationMessage !== "" && (
                        <Box className={"fullWidth"}>
                            <Label fontStyle="error" color="error">
                                {viewModel.getPreferredContactMethodValidationMessage}
                            </Label>
                        </Box>
                    )}

                    <EnquiryBoxes pb="30px">
                        <EnquiryText
                            command={viewModel.updateMessage}
                            type="text"
                            multiline={true}
                            displayName="Message"
                            placeholder="Any notes..."
                            labelColor="quarternary"
                            value={() => viewModel.getValue("notes")}
                            style={{ resize: "none" }}
                            rows={6}
                            validationMessage={() => viewModel.getError("notes")}
                        />
                    </EnquiryBoxes>
                    <EnquiryBoxes grid dc="1fr 1fr">
                        <Box style={{ alignSelf: "start" }}>
                            <EnquiryInput
                                command={viewModel.updateBudgetFrom}
                                type="text"
                                displayName="Budget from*"
                                placeholder="Budget from"
                                labelColor="quarternary"
                                value={() => currencyFormat(viewModel.getValue("budgetFrom"))}
                                validationMessage={() => viewModel.getError("budgetFrom")}
                                prefix={<p>£</p>}
                            ></EnquiryInput>
                        </Box>
                        <Box style={{ alignSelf: "start" }}>
                            <EnquiryInput
                                command={viewModel.updateBudgetTo}
                                type="text"
                                displayName="Budget to*"
                                placeholder="Budget to"
                                labelColor="quarternary"
                                value={() => currencyFormat(viewModel.getValue("budgetTo"))}
                                validationMessage={() => viewModel.getError("budgetTo")}
                                prefix={<p>£</p>}
                            ></EnquiryInput>
                        </Box>
                    </EnquiryBoxes>
                    <Label fontStyle="p" color="white" pb="20px">
                        <CheckBox
                            className="inputConsent"
                            displayName="&nbsp;&nbsp;Yes, I want to know about updates from Shoreline"
                            labelColor="quarternary"
                            command={viewModel.updateWantsUpdates}
                            value={() => viewModel.getValue("acceptsMarketing")}
                        ></CheckBox>
                    </Label>
                    <Label fontStyle="p" color="white" pb="20px">
                        <CheckBox
                            className="inputConsent"
                            displayName="&nbsp;&nbsp;I agree to the "
                            labelColor="quarternary"
                            command={viewModel.updateAgreesPrivacyPolicy}
                            value={() => viewModel.getValue("agreePrivacyPolicy")}
                            validationMessage={() => viewModel.getError("agreePrivacyPolicy")}
                            hrefLink={
                                <Link className="privacy-policy-link" to="/privacy-policy">
                                    privacy policy
                                </Link>
                            }
                        ></CheckBox>
                    </Label>
                    <Label fontStyle="error" color="error">
                        {viewModel.getError("agreePrivacyPolicy")}
                    </Label>
                    {/* <Box maxWidth="228px" pt="20px" mb="20px">
                        <ShorelineButton command={viewModel.onSubmit} displayName={!viewModel.messageSent ? "SEND" : "SENT"}></ShorelineButton>
                    </Box> */}

                    <Box flexBox mb="30px" dc="1fr" style={{ gridColumn: "span 2", justifyContent: "center" }}>
                        <Box maxWidth="228px">
                            {viewModel.isProcessing ? (
                                <img src={loadingSpinner} />
                            ) : (
                                <ShorelineButton
                                    command={viewModel.onSubmit}
                                    value={props.viewModel.getValue("plotNumber")}
                                    displayName={!viewModel.messageSent ? "SEND" : "SENT"}
                                ></ShorelineButton>
                            )}
                        </Box>
                    </Box>
                </EnquiryBox>
            </EnqBoxWrapper>
        </>
    );

    const mainPage = (
        <>
            <Modal isOpen={viewModel.isModalOpen} onDismiss={viewModel.closeSentModal}>
                <P p="40px">Thank you for registering your interest</P>
                <Box p="20px">
                    <ShorelineButton mb="20px" command={viewModel.backtoHomepage} displayName="BACK TO HOME PAGE" />
                    <ShorelineButton command={viewModel.refreshPage} displayName="CLOSE" />
                </Box>
            </Modal>

            {!viewModel.isMobile && (
                <CompareModalView propertiesViewModel={props.propertiesViewModel} viewModelFromParent={props.viewModel} open={imageModalToggled} onClose={onClose} />
            )}
            {viewModel.isMobile && (
                <CompareModalMobile propertiesViewModel={props.propertiesViewModel} viewModelFromParent={props.viewModel} open={imageModalToggled} onClose={onClose} />
            )}
            <Modal
                isOpen={showNotLoggedIn}
                onDismiss={closeNotLoggedDialog}
                styles={{
                    main: { backgroundColor: theme.palette.common.secondary, color: theme.palette.common.primary },
                    scrollableContent: { maxHeight: "200vh" },
                }}
            >
                <ModalBanner pl="20px" pr="20px" mt="15px" style={{ height: "24px", maxWidth: "500px" }}>
                    <Box style={{ width: "100%", position: "relative", textAlign: "right" }}>
                        <img src={X} onClick={() => closeNotLoggedDialog()}></img>
                    </Box>
                </ModalBanner>
                <Box pt="5px" style={{ textAlign: "center", color: theme.palette.common.primary }} maxWidth="500px">
                    {/* <Box p="24px" style={{ textAlign: "center", fontWeight: "bold", fontSize: "24px" }}>
                        HOLD UP WE REQUIRE ONE MORE STEP FROM YOU FIRST
                    </Box> */}
                    <P p="16px" style={{ textAlign: "center", color: theme.palette.common.primary }}>
                        Before you can add the property as a favourite, you need to create an account. Click on the button below to log in or register.
                    </P>
                    <Box p="30px">
                        <ShorelineButton command={acceptNotLoggedDialog} displayName="CREATE ACCOUNT" />
                    </Box>
                </Box>
            </Modal>
            <ShareLinksModal open={shareLinksModalToggled} onCloseModal={onClose} />
            <DownloadBrochureModal open={brochureDownloadModalToggled} closeModal={onClose} />
            <FullWidthCentFlexContainer>
                <InnerWrapper maxWidth={"1400px"} height={"50px"} width="100%" padding="0px 40px">
                    <Box grid dc="auto auto auto auto auto" mc="auto auto auto auto auto" columnGap={"20px"} alignContent={"center"} justifyContent="start" height="100%">
                        <MenuItem onClick={() => handleBackClick()}>
                            <img src={BackThumb}></img>
                            <H5 color="primary" pl="5px">
                                BACK TO NEW HOMES
                            </H5>
                        </MenuItem>

                        <MenuItem>
                            {/* <img src={EnquiryThumb}></img> */}
                            {/* <div
                                onClick={() => {
                                    contactFormRef.current ? contactFormRef.current.scrollIntoView() : "";
                                }}
                            > */}
                            {/* <Label fontStyle="h5" color="primary" pl="5px">
                                MAKE ENQUIRY
                            </Label> */}
                            {/* </div> */}
                        </MenuItem>

                        <MenuItem showIf={!viewModel.isMobile} onClick={toggleModal2}>
                            <img src={CompareThumb}></img>
                            <H5 color="primary" pl="5px">
                                COMPARE PLOT
                            </H5>
                        </MenuItem>
                        {/* <MenuItem showIf={!viewModel.isMobile} onClick={toggleFavourite}>
                            <img src={FavsThumbWhite}></img>
                            <Label fontStyle="h5" color="primary" pl="5px">
                                ADD TO FAVOURITES
                            </Label>
                        </MenuItem> */}
                        <MenuItem showIf={!viewModel.isMobile} onClick={toggleDownloadBrochureModal}>
                            <img src={DownloadThumb}></img>
                            <H5 color="primary" pl="5px">
                                DOWNLOAD BROCHURE
                            </H5>
                        </MenuItem>
                        <MenuItem showIf={!viewModel.isMobile} onClick={toggleShareLinksModal}>
                            <img src={ShareThumb}></img>
                            <H5 color="primary" pl="5px">
                                SHARE
                            </H5>
                        </MenuItem>
                    </Box>
                </InnerWrapper>
            </FullWidthCentFlexContainer>
            <FullWidthCentFlexContainer>
                <InnerWrapper
                    maxWidth="1400px"
                    pt="30px"
                    padding="30px 40px 0px 40px"
                    pl={props.viewModel.isTablet || props.viewModel.isMobile ? "20px" : ""}
                    pr={props.viewModel.isTablet || props.viewModel.isMobile ? "20px" : ""}
                >
                    <Box grid dc="1fr 1fr" rowGap={"0px"} width="100%">
                        <Box>
                            <P pb="20px">Plot No. {props.viewModel.getValue("plotNumber")}</P>
                            <H2 color="primary" pb="20px" style={{ fontWeight: 600 }}>
                                {props.viewModel.getValue("addressLine1")}
                            </H2>
                            {/* <GradLabel fontStyle="h2" color="primary" pb="15px">
                                {props.viewModel.getValue("houseType")}
                            </GradLabel> */}
                            <H3 color="primary" pb="20px">
                                {new Intl.NumberFormat("en-GB", { style: "currency", currency: "GBP", maximumFractionDigits: 0 }).format(
                                    props.viewModel.getValue("basePurchasePrice"),
                                )}
                            </H3>
                            <Box grid dc="auto auto" mc="auto auto" width={"100%"} pb="20px">
                                <P color="default">Floor {props.viewModel.getValue("floor")}</P>
                                {/* <Label fontStyle="p" color="default">
                                    319.7 sq m.
                                </Label> */}
                                <P color="default">{RoundUp(props.viewModel.getValue("sqft"))} sq ft.</P>
                            </Box>
                        </Box>
                        <Box grid dc="1fr 1fr" mc="1fr 1fr" alignItems="start" pb={"20px"}>
                            <BathBed>
                                <img src={BedThumb}></img>
                                <P color="primary" ml="10px">
                                    {FormatBedroom(props.viewModel.getValue("numberOfBeds"))}
                                </P>
                            </BathBed>
                            <BathBed>
                                <img src={BathThumb}></img>
                                <P color="primary" ml="10px">
                                    {FormatBathroom(Number(props.viewModel.getValue("numberOfBathrooms")) + Number(props.viewModel.getValue("numberOfEnsuites")))}
                                </P>
                            </BathBed>
                        </Box>
                    </Box>
                </InnerWrapper>
            </FullWidthCentFlexContainer>

            {props.viewModel.model.images.length > 0 && (
                <>
                    <FullWidthCentFlexContainer>
                        <InnerWrapper maxWidth="1400px">
                            <ImageCarouselWithTextWider isMobile={viewModel.isMobile} model={props.viewModel.model.images}></ImageCarouselWithTextWider>
                        </InnerWrapper>
                    </FullWidthCentFlexContainer>
                </>
            )}
            <Box p="20px" showIf={props.viewModel.isMobile} style={{ backgroundColor: "#cc6b2c" }}>
                <MarketingSuiteBox />
                <Buttons toggleModal={toggleModal} toggleFavourite={toggleFavourite} isFavourite={isFavourite} />
            </Box>

            <FullWidthCentFlexContainer pb="68px">
                <InnerWrapper
                    maxWidth={"1400px"}
                    // pl={props.viewModel.isTablet || props.viewModel.isMobile ? "20px" : ""}
                    // pr={props.viewModel.isTablet || props.viewModel.isMobile ? "20px" : ""}
                    p={props.viewModel.isTablet || props.viewModel.isMobile ? "0px 20px" : "0px 40px"}
                >
                    <Box grid dc="auto minmax(auto, 313px)" columnGap={60}>
                        <Box>
                            <H3 color="primary" pb="20px" pt={props.viewModel.isMobile ? "20px" : "0px"}>
                                Feature list
                            </H3>

                            <P color="primary" pb="40px">
                                <ListBox
                                    dangerouslySetInnerHTML={{
                                        __html: props.viewModel.getValue<string>("features"),
                                    }}
                                />
                            </P>
                            <Box>
                                <H3 color="primary" pb="20px">
                                    Description
                                </H3>

                                <P
                                    color="primary"
                                    pb="40px"
                                    style={{ whiteSpace: "pre-line" }}
                                    dangerouslySetInnerHTML={{
                                        __html: props.viewModel.getValue<string>("description"),
                                    }}
                                ></P>
                            </Box>

                            {props.viewModel.model.floorplans.length > 0 && (
                                <>
                                    <H3 color="primary" pb="20px">
                                        Floorplans
                                    </H3>
                                    <CarouselForImagesDimensions isMobile={viewModel.isMobile} model={props.viewModel.model.floorplans}></CarouselForImagesDimensions>

                                    {/* <CarouselFloorPlansMk2 floorPlans={props.viewModel.model.floorplans} /> */}
                                </>
                            )}

                            <H3 color="primary" p="40px 0px">
                                Room dimensions
                            </H3>

                            <Box grid dc="1fr 1fr" tc="1fr" rowGap={viewModel.isMobile || viewModel.isTablet ? "0px" : "20px"}>
                                <Box
                                    grid
                                    dc="1fr 1fr"
                                    mc="1fr"
                                    rowGap="20px"
                                    pb="20px"
                                    pt="20px"
                                    style={{
                                        alignContent: "baseline",
                                        borderTop: "2px solid #1F2747",
                                        borderBottom: viewModel.isMobile || viewModel.isTablet ? "" : "2px solid #1F2747",
                                    }}
                                >
                                    {props.viewModel.model.propertyRooms.map((room, index) => {
                                        if (
                                            !room.name.toLocaleLowerCase().includes("bedroom") &&
                                            !room.name.toLocaleLowerCase().includes("en-suite") &&
                                            !room.name.toLocaleLowerCase().includes("balcony") &&
                                            !room.name.toLocaleLowerCase().includes("dressing room") &&
                                            !room.name.toLocaleLowerCase().includes("terrace")
                                        ) {
                                            return (
                                                <>
                                                    <P key={index + "a"} color="primary">
                                                        {room.name}
                                                    </P>
                                                    <P
                                                        key={index + "b"}
                                                        color="primary"
                                                        pb={viewModel.isMobile ? "20px" : "0px"}
                                                        style={{ textAlign: viewModel.isDesktop ? "center" : "left" }}
                                                    >
                                                        {room.sqft}
                                                    </P>
                                                </>
                                            );
                                        }
                                    })}
                                </Box>

                                <Box
                                    grid
                                    dc="1fr 1fr"
                                    mc="1fr"
                                    rowGap="20px"
                                    pb={"20px"}
                                    pt="20px"
                                    style={{ alignContent: "baseline", borderTop: "2px solid #1F2747", borderBottom: "2px solid #1F2747" }}
                                >
                                    {props.viewModel.model.propertyRooms.map((room, index) => {
                                        if (
                                            room.name.toLocaleLowerCase().includes("bedroom") ||
                                            room.name.toLocaleLowerCase().includes("en-suite") ||
                                            room.name.toLocaleLowerCase().includes("balcony") ||
                                            room.name.toLocaleLowerCase().includes("dressing room") ||
                                            room.name.toLocaleLowerCase().includes("terrace")
                                        ) {
                                            return (
                                                <>
                                                    <P color="primary" key={index + "c"}>
                                                        {room.name}
                                                    </P>
                                                    <P
                                                        color="primary"
                                                        key={index + "d"}
                                                        pb={viewModel.isMobile ? "20px" : "0px"}
                                                        style={{ textAlign: viewModel.isDesktop ? "center" : "left" }}
                                                    >
                                                        {room.sqft}
                                                    </P>
                                                </>
                                            );
                                        }
                                    })}
                                </Box>
                            </Box>
                        </Box>
                        <Box>
                            <FullWidthCentFlexContainer showIf={viewModel.isMobile} mb="20px">
                                {EnquiryForm}
                            </FullWidthCentFlexContainer>

                            {!props.viewModel.isMobile && (
                                <RightBox>
                                    <MarketingSuiteBox />

                                    <Buttons toggleModal={toggleModal} toggleFavourite={toggleFavourite} isFavourite={isFavourite} />

                                    <StampDutyCalculatorBox viewModel={props.viewModel} />

                                    <MortgagesBox />

                                    {/* <BuyingANewHomeBox /> */}
                                </RightBox>
                            )}
                        </Box>
                    </Box>
                </InnerWrapper>
            </FullWidthCentFlexContainer>
            {/* <div id="contact"> */}
            <FullWidthCentFlexContainer style={{ backgroundColor: "#2b6298" }}>
                <InnerWrapper maxWidth="1400px" showIf={!viewModel.isMobile}>
                    {EnquiryForm}
                </InnerWrapper>
            </FullWidthCentFlexContainer>
            <img hidden={viewModel.isMobile} src={Banner} width="100%" />
            {/* </div> */}
        </>
    );

    return (
        <>
            {!props.propertiesViewModel.hasLoaded ? (
                <SpinnerBox>
                    <img src={loadingSpinner} />
                </SpinnerBox>
            ) : (
                mainPage
            )}
        </>
    );
});
