import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { Box, EditSelect, ICommand, Label, RelayCommand, theme } from "Application";
import { Modal } from "@fluentui/react";
import { InnerWrapper } from "Components/InnerWrapper";
import styled from "@emotion/styled";

//Image imports

import X from "Assets/x.svg";
import { HorizontalGradLineDarkLightDark } from "Components/HorizontalGradLineDarkLightDark";
import { ShorelineButton } from "Components/ShorelineButton";
import { CompareModalViewModel } from "./CompareModalViewModel";

import { PropertyListItemViewModel } from "Views/NewHomes/Subviews/PropertyListItemViewModel";
import { ListBox } from "../SelectedPropertyStyles";
import { NewHomesViewModel } from "Views/NewHomes/NewHomesViewModel";
import { CarouselForImagesDimensions } from "Components/CarouselForImages/CarouselForImagesDimensions";
import { CarouselForImagesDimensionsModal } from "Components/CarouselForImages/CarouselForImagesDimensionsModal";
import { H4, P } from "Components/Primitives/TextElements/TextElements";
import { FormatBathroom, FormatBedroom } from "Utils/BedroomBathroomFormat";

export interface ICompareModal {
    open: boolean | undefined;
    viewModelFromParent: PropertyListItemViewModel;
    propertiesViewModel: NewHomesViewModel;
    onClose: () => void;
}

export const CompareModalView: React.FC<ICompareModal> = observer(({ open, onClose, viewModelFromParent, propertiesViewModel }) => {
    const [viewModel] = useState(() => new CompareModalViewModel(propertiesViewModel));
    const [choosePropertyView, isChoosePropertyView] = useState(true);
    const doSomething: ICommand = new RelayCommand((value: string) => {});
    const goToPropertyOne: ICommand = new RelayCommand((value: string) => {
        onClose();
    });
    const goToPropertyTwo: ICommand = new RelayCommand((value: string) => {});

    const ToggleChoosePropertyPanel: ICommand = new RelayCommand((value: string) => {
        isChoosePropertyView(!choosePropertyView);
    });

    return (
        <>
            <Box width="100%">
                <Modal
                    isOpen={open}
                    onDismiss={() => onClose}
                    styles={{ main: [{ background: "transparent", overflow: "hidden" }], root: [{ background: "rgba(0, 0, 0, 0.75)" }] }}
                >
                    <InnerWrapper maxWidth="1000px">
                        <ModalBanner pl="20px" pr="20px" mb="10px">
                            <Box pr="30px" style={{ cursor: "pointer" }}>
                                <img src={X} onClick={() => onClose()}></img>
                            </Box>
                            <H4 color="primary">Compare properties</H4>
                        </ModalBanner>
                        <ModalOuterGrid>
                            <Box>
                                {viewModelFromParent.model.images.length > 0 ? (
                                    <img src={viewModelFromParent.model.images[1].imageUrl} height="259px" width="100%" style={{ objectFit: "cover" }}></img>
                                ) : (
                                    <Box style={{ backgroundColor: "white" }}>
                                        <P p="20px" style={{ textAlign: "center" }}>
                                            <i>No image</i>
                                        </P>
                                    </Box>
                                )}
                            </Box>
                            {choosePropertyView == false && (
                                <Box style={{ position: "relative" }}>
                                    {viewModelFromParent.model.images.length > 0 ? (
                                        <img src={viewModel.model.images[1].imageUrl} height="259px" width="100%" style={{ objectFit: "cover" }}></img>
                                    ) : (
                                        <Box style={{ backgroundColor: "white" }}>
                                            <P p="20px" style={{ textAlign: "center" }}>
                                                <i>No image</i>
                                            </P>
                                        </Box>
                                    )}
                                    {/* <img src={viewModel.model.images[1].imageUrl} height="259px" width="100%" style={{ objectFit: "cover" }}></img> */}
                                    <ChangePropertyBox>
                                        <ShorelineButton command={ToggleChoosePropertyPanel} displayName="CHANGE PROPERTY"></ShorelineButton>
                                    </ChangePropertyBox>
                                </Box>
                            )}

                            <GrayBox>
                                <H4 color="primary" pt="36px">
                                    {viewModelFromParent.getValue("addressLine1")}&nbsp; &nbsp;
                                    {new Intl.NumberFormat("en-GB", { style: "currency", currency: "GBP", maximumFractionDigits: 0 }).format(
                                        viewModelFromParent.getValue("basePurchasePrice"),
                                    )}
                                </H4>
                            </GrayBox>
                            {choosePropertyView == true && (
                                <GrayBoxTrans gridColumn="2" gridRow="1/21">
                                    <H4 pt="20px" pb="20px" color="quarternary">
                                        Compare
                                    </H4>

                                    <EditSelect
                                        command={viewModel.updatePropertyTypeCommand}
                                        displayName="Select property type"
                                        placeholder="Please select"
                                        options={viewModel.getPropertyTypes(propertiesViewModel)}
                                        value={() => viewModel.getValue("propertyType")}
                                        styles={{
                                            // Dropdown menu z-index fix
                                            menuPortal: (provided: any) => ({ ...provided, zIndex: 1000001 }),
                                        }}
                                    ></EditSelect>
                                    {viewModel.model.propertyType !== "" && (
                                        <EditSelect
                                            command={viewModel.updateChosenProperty}
                                            displayName="Select plot"
                                            placeholder="Please select"
                                            options={viewModel.getProperties(propertiesViewModel)}
                                            value={() => viewModel.getSelectedPropertyId}
                                            styles={{
                                                // Dropdown menu z-index fix
                                                menuPortal: (provided: any) => ({ ...provided, zIndex: 1000001 }),
                                            }}
                                        ></EditSelect>
                                    )}
                                    {viewModel.getValue("selectedPropertyId") != "" && (
                                        <ShorelineButton mt="30px" mb="30px" command={ToggleChoosePropertyPanel} displayName="ADD"></ShorelineButton>
                                    )}
                                </GrayBoxTrans>
                            )}
                            {choosePropertyView == false && (
                                <>
                                    <GrayBox>
                                        <H4 color="primary" pt="36px">
                                            {viewModel.getAddressSecondProperty}&nbsp; &nbsp;
                                            {new Intl.NumberFormat("en-GB", { style: "currency", currency: "GBP", maximumFractionDigits: 0 }).format(
                                                viewModel.getValue("basePurchasePrice"),
                                            )}
                                        </H4>
                                    </GrayBox>
                                </>
                            )}

                            <GrayBox>
                                <H4 color="primary">{viewModelFromParent.getValue("numberOfBeds") == 1 ? "One bedroom apartment" : "Two bedroom apartment"} </H4>
                            </GrayBox>
                            {choosePropertyView == false && (
                                <>
                                    <GrayBox>
                                        <H4 color="primary">{viewModel.getValue("numberOfBeds") == 1 ? "One bedroom apartment" : "Two bedroom apartment"} </H4>
                                    </GrayBox>
                                </>
                            )}

                            <GrayBox>
                                <H4 color="primary" pb="22px">
                                    {FormatBedroom(viewModelFromParent.getValue("numberOfBeds"))} &nbsp; &nbsp;
                                    {FormatBathroom(viewModelFromParent.getValue("numberOfBathrooms"))}
                                </H4>
                            </GrayBox>
                            {choosePropertyView == false && (
                                <>
                                    <GrayBox>
                                        <H4 color="primary" pb="22px">
                                            {FormatBedroom(viewModel.getValue("numberOfBeds"))} &nbsp; &nbsp; {FormatBathroom(viewModel.getValue("numberOfBathrooms"))}
                                        </H4>
                                    </GrayBox>
                                </>
                            )}

                            <GrayBox>
                                <H4 color="primary" pb="15px">
                                    Description:
                                </H4>
                                <P
                                    color="primary"
                                    style={{ whiteSpace: "pre-line" }}
                                    pb="30px"
                                    dangerouslySetInnerHTML={{
                                        __html: viewModelFromParent.getValue("description"),
                                    }}
                                >
                                    {/* {viewModelFromParent.getValue("description")} */}
                                </P>
                            </GrayBox>
                            {choosePropertyView == false && (
                                <>
                                    <GrayBox>
                                        <H4 color="primary" pb="15px">
                                            Description:
                                        </H4>
                                        <P
                                            color="primary"
                                            style={{ whiteSpace: "pre-line" }}
                                            pb="30px"
                                            dangerouslySetInnerHTML={{
                                                __html: viewModel.getValue("description"),
                                            }}
                                        >
                                            {/* {viewModel.getValue("description")} */}
                                        </P>
                                    </GrayBox>
                                </>
                            )}
                            <GrayBox>
                                <H4 color="primary" pb="15px">
                                    Feature list:
                                </H4>
                                <P color="primary" pb="40px">
                                    <ListBox
                                        dangerouslySetInnerHTML={{
                                            __html: viewModelFromParent.getValue<string>("features"),
                                        }}
                                    />
                                </P>
                            </GrayBox>
                            {choosePropertyView == false && (
                                <>
                                    <GrayBox>
                                        <H4 color="primary" pb="15px">
                                            Feature list:
                                        </H4>
                                        <P color="primary" pb="40px">
                                            <ListBox
                                                dangerouslySetInnerHTML={{
                                                    __html: viewModel.getValue<string>("features"),
                                                }}
                                            />
                                        </P>
                                    </GrayBox>
                                </>
                            )}
                            <GrayBox>
                                <H4 color="primary" pb="30px">
                                    Floorplans:
                                </H4>
                                <CarouselForImagesDimensionsModal model={viewModelFromParent.model.floorplans}></CarouselForImagesDimensionsModal>
                            </GrayBox>
                            {choosePropertyView == false && (
                                <>
                                    <GrayBox>
                                        <H4 color="primary" pb="30px">
                                            Floorplans:
                                        </H4>
                                        <CarouselForImagesDimensionsModal model={viewModel.model.floorplans}></CarouselForImagesDimensionsModal>
                                    </GrayBox>
                                </>
                            )}
                            <GrayBox>
                                <H4 color="primary" pb="30px">
                                    Room dimensions:
                                </H4>

                                <Box grid dc="repeat(auto-fit, minmax(200px, 1fr))" rowGap="20px" pb="60px">
                                    {viewModelFromParent.model.propertyRooms.map((room) => {
                                        return (
                                            <>
                                                <P color="primary">{room.name}</P>
                                                <P color="primary">{room.sqft}</P>
                                            </>
                                        );
                                    })}
                                </Box>
                                <P pb="60px"></P>
                            </GrayBox>
                            {choosePropertyView == false && (
                                <>
                                    <GrayBox>
                                        <H4 color="primary" pb="30px">
                                            Room dimensions:
                                        </H4>

                                        <Box grid dc="repeat(auto-fit, minmax(200px, 1fr))" rowGap="20px" pb="60px">
                                            {viewModel.model.propertyRooms.map((room) => {
                                                return (
                                                    <>
                                                        <P color="primary">{room.name}</P>
                                                        <P color="primary">{room.sqft}</P>
                                                    </>
                                                );
                                            })}
                                        </Box>
                                    </GrayBox>
                                </>
                            )}
                            {/* <GrayBox>
                                <ShorelineButton command={goToPropertyOne} displayName="VIEW APARTMENT" />
                            </GrayBox>
                            {choosePropertyView == false && (
                                <GrayBox>
                                    <ShorelineButton command={goToPropertyTwo} displayName="VIEW APARTMENT" />
                                </GrayBox>
                            )} */}
                        </ModalOuterGrid>
                    </InnerWrapper>
                </Modal>
            </Box>
        </>
    );
});

const ModalBanner = styled(Box)`
    height: 102px;
    background-color: ${theme.palette.common.quarternary};
    display: flex;
    align-items: center;
    /* display: grid; */
    /* grid-template-columns: 485px 485px; */
    /* justify-content: space-between; */
    /* align-items: center; */
`;

const ModalOuterGrid = styled(Box)`
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 4px;
    padding-bottom: 120px;

    .fluent-default-layer-host {
        z-index: 1 !important;
    }
`;

const GrayBox = styled(Box)`
    background-color: #ecdfc9;
    padding: 0px 20px 5px 20px;
`;

const GrayBoxTrans = styled(Box)`
    background-color: #2b6298;
    padding: 20px;

    .editselect__control {
        border-radius: 4px;
        border: none;
    }
    .css-1tuglhw-FieldLabel {
        color: #ecdfc9;
    }
`;

const WhiteBox = styled(Box)`
    background-color: #f3f2ee;
    padding: 13px 20px 13px 20px;
`;

const ChangePropertyBox = styled(Box)`
    position: absolute;
    top: 10px;
    right: 10px;
`;
