import React from "react";
import { observer } from "mobx-react-lite";
import { Box, ICommand, RelayCommand } from "Application";
import { RoundedLighterBox } from "../SelectedPropertyStyles";

import Mortgages from "Assets/MortgagesFirst.png";
import { ShorelineButton } from "Components/ShorelineButton";
import { H6, P } from "Components/Primitives/TextElements/TextElements";

export const MortgagesBox: React.FC = observer(() => {
    const doSomething: ICommand = new RelayCommand((value: string) => {});

    return (
        <>
            <RoundedLighterBox>
                <Box pt="20px" pb="20px" style={{ textAlign: "center" }}>
                    <img src={Mortgages} width="70%"></img>
                </Box>
                <P color="black" pb="30px" style={{ textAlign: "center" }}>
                    Find out what you can afford with our trusted partner.
                </P>
                <a
                    href="https://my.digi.mortgage/mortgagesfirst/mortgage-calculator/start?utm_source=Folkstone%20Harbour&utm_medium=Website%20Referral&utm_campaign=Shoreline&utm_content=General"
                    target="_blank"
                >
                    <ShorelineButton command={doSomething} displayName="WHAT CAN I AFFORD" mb="30px"></ShorelineButton>
                </a>
                <H6 color="black" style={{ textAlign: "center" }}>
                    Clicking the button above will link you to a website external to shorelinefolkestone.co.uk
                </H6>
            </RoundedLighterBox>
        </>
    );
});
