import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { Validator } from "Application/Validation";

export class BuyingWithshorelineModel extends ModelBase<BuyingWithshorelineModel> {
    // public id: string = "";

    constructor() {
        super();
        makeObservable(this, {
            // id: observable
        });
    }
}

export class BuyingWithshorelineModelValidator extends Validator<BuyingWithshorelineModel> {
    constructor() {
        super();
        //this.ruleFor("id").notEmpty();
    }
}
