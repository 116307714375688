import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { Box, ICommand, Label, RelayCommand, theme } from "Application";
import { motion } from "framer-motion";
import { FullWidthCentFlexContainer } from "Components/FullWidthCentFlexCont";
import { HorizontalGradLineDarkLightDark } from "Components/HorizontalGradLineDarkLightDark";
import { InnerWrapper } from "Components/InnerWrapper";
import { BlockImageTextView } from "Components/BlockImageText/BlockImageText";

// Image Imports Mk2

import Banner1 from "Assets/VisionBanner1.jpg";
import Banner1Mob from "Assets/VisionBanner1Mob.png";
import Banner2 from "Assets/23053_201_Leas_Lift_Square.jpg";
import Banner3 from "Assets/VisionBanner3.jpg";
import Banner4 from "Assets/VisionBanner4.jpg";
import Banner4Mob from "Assets/VisionBanner4Mob.png";
import Banner5 from "Assets/23053_205_Entrance.jpg";
import Banner5Mob from "Assets/23053_205_Entrance_Mobile.jpg";

import { ShorelineButton } from "Components/ShorelineButton";
import { OurVisionViewModel } from "./OurVisionViewModel";
import { CoreStoreInstance } from "@shoothill/core";
import { BannerImageFull } from "Components/BannerImageFull/BannerImageFull";
import { TitleLeftTextRightBlock } from "Components/TitleLeftTextRightBlock/TitleLeftTextRightBlock";
import { BannerImageFullMobile } from "Components/BannerImageFull/BannerImageFullMobile";
import { AppUrls } from "AppUrls";
import { container } from "tsyringe";
import { DomainStore } from "Stores/Domain/DomainStore";
import { DownloadBrochureModal } from "Components/DownloadBrochure/DownloadBrochureModal/DownloadBrochureModal";
import { CarouselFullWidthMk2 } from "Components/CarouselFullWidthMk2/CarouselFullWidthMk2";
import { BannerImageFullCover } from "Components/BannerImageFull/BannerImageFullCover";

export const OurVisionView: React.FC = observer(() => {
    const [viewModel] = useState<OurVisionViewModel>(new OurVisionViewModel());
    CoreStoreInstance.CoreOptions.mobileBreakPoint = 600;

    const doSomething: ICommand = new RelayCommand((value: string) => {});

    const domainStore = container.resolve(DomainStore);

    const openBrochureModal: ICommand = new RelayCommand(() => {
        domainStore.openBrochureModal();
    });

    const tabletMargin = "20px";
    const tabletPadding = "20px";

    return (
        <>
            <DownloadBrochureModal open={domainStore.isBrochureModalOpen} closeModal={domainStore.closeBrochureModal} />
            <FullWidthCentFlexContainer>
                <InnerWrapper>
                    {/* {!viewModel.isMobile && <BannerImageFull imageUrl={Banner1} />}
                    {viewModel.isMobile && <BannerImageFullMobile imageUrl={Banner1Mob} />} */}

                    {viewModel.isMobile ? <BannerImageFullMobile imageUrl={Banner1Mob} /> : <CarouselFullWidthMk2 />}

                    <TitleLeftTextRightBlock
                        title="The Masterplan"
                        textBlock={`Shoreline Crescent is the first residential phase a project that aims to build a vibrant new community along the historic seafront of Folkestone, inspired by the legacy of Victorian Folkestone.`}
                        backgroundColour="#E0A752"
                        textColour={"primary"}
                        buttons={
                            <>
                                <ShorelineButton displayName="BOOK AN APPOINTMENT" value={AppUrls.Client.Register} command={viewModel.navTo} />
                                <ShorelineButton displayName="DOWNLOAD BROCHURE" command={openBrochureModal} />
                            </>
                        }
                        isMobile={viewModel.isMobile}
                        isTablet={viewModel.isTablet}
                        hasLargerBodyText={true}
                    />

                    <TitleLeftTextRightBlock
                        title="A New Era"
                        textBlock={`With carefully designed spaces for both indoor and outdoor living, this Harbour & Seafront Development aims to maximize the stunning views and preserve the harbour’s heritage. \n
The project marks the beginning of a new era for the coastline, offering locals and visitors an opportunity to be a part of this exciting new chapter in Folkestone’s story.`}
                        backgroundColour="#ECDFC9"
                        textColour={"primary"}
                        isMobile={viewModel.isMobile}
                    />

                    <Box flexBox style={{ justifyContent: "center" }}>
                        <Box maxWidth="1400px" width="100%" grid dc="1fr 1fr" p={viewModel.isMobile ? "0px 20px 100px 20px" : "0px 0px 100px 0px"} columnGap="40px" rowGap="40px">
                            <Box>
                                <BannerImageFullCover imageUrl={Banner3} caption="A render of the Shoreline Crescent from Lower Sandgate Road." />
                            </Box>
                            <Box>
                                <BannerImageFullCover imageUrl={Banner2} caption="A render of Leas Lift Square." />
                            </Box>
                        </Box>
                    </Box>

                    <TitleLeftTextRightBlock
                        title="Environmental Awareness"
                        textBlock={`The significant renovations have been authentic and in sympathy with the historical narrative of the town. There is a keen interest in keeping the area protected from the sea and salt-water by using rust-proof fixtures and fittings, and extensive protection against high tides and sea-level rises.\n
The designs for the new developments show the introduction of numerous trees and planting. The vision of a tree-lined boulevard is not only aesthetic but contributes to the design's plans of creating protection from the elements and microclimates for diversity. The landscaped shingle gardens with coastal planting are also part of this vision.\n
The residents' podium gardens offer a mixture of open biodiverse lawns surrounded by swathes of coastal planting, a sheltered tree grove and plenty of seating. Harvesting of rainwater in the podium gardens, which, combined with water-efficient fittings, will greatly reduce the development's demand for portable water.`}
                        backgroundColour="#E0A752"
                        textColour={"primary"}
                        isMobile={viewModel.isMobile}
                    />

                    {!viewModel.isMobile && <BannerImageFull imageUrl={Banner4} />}
                    {viewModel.isMobile && <BannerImageFullMobile imageUrl={Banner4Mob} />}

                    <TitleLeftTextRightBlock
                        title="Community"
                        textBlock={`The Masterplan of the Seafront Development provides many benefits to the local community, in addition to the functioning Leas Lift. The planned 10,000 sqm of commercial space on the site will create hundreds of new jobs for the residents of Folkestone. There are many opportunities now for work in construction, hospitality and creative sector jobs. Young people in the town are already benefiting from apprenticeship opportunities as part of the development.\n 
As well as this, up to £3 million has been set aside to support primary schools and educational settings in the town. The Masterplan also includes up to £1.2 million to invest in new local GP and health and social care provision in the area. In addition, more than £500,000 has been allocated to supporting the local transport network.\n
The Leas Lift has been a key method of transport between the upper Leas and the seafront since Victorian times, until it stopped working in 2016.  The Leas Lift Square will soon be developed into a vibrant pedestrian area at the base of the lift, designed with native planting, walkways and refreshment area. The Folkestone Harbour and Seafront Development team have supported the Leas Lift CIC with funds, engineering expertise and in applying for Heritage Lottery funding to bring the lift back into working order.`}
                        backgroundColour="#E0A752"
                        textColour={"primary"}
                        buttons={
                            <>
                                <ShorelineButton
                                    displayName="FIND OUT MORE ABOUT THE DEVELOPER"
                                    style={{ whiteSpace: viewModel.isMobile ? "normal" : "nowrap" }}
                                    value={AppUrls.Client.About}
                                    command={viewModel.navTo}
                                />
                            </>
                        }
                        isMobile={viewModel.isMobile}
                    />

                    {!viewModel.isMobile && <BannerImageFull imageUrl={Banner5} />}
                    {viewModel.isMobile && <BannerImageFullMobile imageUrl={Banner5Mob} />}
                </InnerWrapper>
            </FullWidthCentFlexContainer>
        </>
    );
});
