import React from "react";
import { observer } from "mobx-react-lite";
import { Box, ICommand, RelayCommand } from "Application";
import { ShorelineButton } from "Components/ShorelineButton";
import { ButtonIconWrapper } from "Components/ButtonIconPropWrapper";

import CompareThumbWhite from "Assets/CompareWhite.svg";
import DownloadThumbWhite from "Assets/DownloadWhite.svg";
//import FavsThumb from "Assets/Favs.svg";
import FavsThumbWhite from "Assets/FavesWhite.svg";

interface IButtons {
    isFavourite: boolean;
    toggleModal: ICommand<any>;
    toggleFavourite: ICommand<any>;
}

export const Buttons: React.FC<IButtons> = observer(({ isFavourite, toggleModal, toggleFavourite }) => {
    const favText: string = isFavourite === false ? "ADD TO FAVOURITES" : "REMOVE FAVOURITE";

    return (
        <>
            {/* <ShorelineButton
                                command={doSomething}
                                icon={<ButtonIconWrapper imgUrl={EnquiryThumbWhite}></ButtonIconWrapper>}
                                mb="15px"
                                displayName="MAKE ENQUIRY"
                            ></ShorelineButton> */}
            <ShorelineButton
                command={toggleModal}
                value="compare"
                icon={<ButtonIconWrapper imgUrl={CompareThumbWhite}></ButtonIconWrapper>}
                mb="15px"
                displayName="COMPARE PLOT"
            ></ShorelineButton>
            {/* TURN BACK ON IF USER LOGIN IS REINSTATED */}
            {/* <ShorelineButton command={toggleFavourite} icon={<ButtonIconWrapper imgUrl={FavsThumbWhite}></ButtonIconWrapper>} mb="15px" displayName={favText}></ShorelineButton> */}
            <ShorelineButton
                command={toggleModal}
                value="download"
                icon={<ButtonIconWrapper imgUrl={DownloadThumbWhite}></ButtonIconWrapper>}
                mb="15px"
                displayName="DOWNLOAD BROCHURE"
            ></ShorelineButton>
        </>
    );
});
