import styled from "@emotion/styled";
import { Box } from "Application";

export const SemiCircle = styled(Box)<{ $bgColor?: string }>`
    /* margin: 1rem auto; */
    background-color: ${(props) => (props.$bgColor ? props.$bgColor : "#48abe0")};
    width: 8rem;
    height: 4rem;
    border-radius: 10rem 10rem 0 0;
`;
