import styled from "@emotion/styled";
import { Box } from "Components";

export const VerticalGradLine = styled(Box)`
    width: 1.5px;
    margin-right: 5px;
    margin-left: 5px;

    background-image: linear-gradient(180deg, #7d5232 0%, #bc834f 25%, #f0aa66 47%, #eba664 56%, #db9a5c 66%, #c18651 78%, #9d6a40 91%, #7d5232 100%);
`;

export const VerticalGradLineNoMargin = styled(Box)`
    width: 1.5px;

    background-image: linear-gradient(180deg, #7d5232 0%, #bc834f 25%, #f0aa66 47%, #eba664 56%, #db9a5c 66%, #c18651 78%, #9d6a40 91%, #7d5232 100%);
`;

export const VerticalGradLineThicker = styled(Box)`
    width: 8px;

    background-image: linear-gradient(180deg, #7d5232 0%, #bc834f 25%, #f0aa66 47%, #eba664 56%, #db9a5c 66%, #c18651 78%, #9d6a40 91%, #7d5232 100%);
`;
