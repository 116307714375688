import { makeObservable, observable, runInAction } from "mobx";
import { CoreStoreInstance, ViewModelBase } from "@shoothill/core";
import { APIClient, ICommand, IKeyState, RelayCommand } from "Application";
import { ForgotPasswordModel, ForgotPasswordModelValidator } from "./ForgotPasswordModel";
import { ForgetPasswordEndpoint } from "./Endpoints/ForgetPasswordEndPoint";

export class ForgotPasswordViewModel extends ViewModelBase<ForgotPasswordModel> {
    public apiClient = new APIClient();

    public PanelView: string = "EnterEmail";

    constructor() {
        super(new ForgotPasswordModel());
        this.setValidator(new ForgotPasswordModelValidator());
        makeObservable(this, {
            PanelView: observable,
        });
    }

    public updateEmail: ICommand = new RelayCommand((value: string) => {
        this.model.emailAddress = value;
    });

    public submit: ICommand = new RelayCommand(async (value: string) => {
        if (this.isModelValid()) {
            // TODO - check validation, send request

            const register = new ForgetPasswordEndpoint();
            await this.apiClient.sendAsync(register, this.model);

            if (this.apiClient.IsRequestSuccessful === true) {
                this.PanelView = "ResetRequestSent";
            } else {
                // TODO CMR How do we show errors?
            }
        }
        CoreStoreInstance.HideInfoBar();
    });
}
