import styled from "@emotion/styled";
import { Box, Button, ICommand, Label, RelayCommand, theme } from "Application";

export const BuildingSticky = styled(Box)`
    scroll-snap-align: start;
    position: sticky;
    top: 0;
`;

export const NewHomesButton = styled(Button)`
    text-align: center;
    /* margin-bottom: 20px; */
    /* background-color: #eda765; */
    background: #1f2747;
    color: #ecdfc9;
    width: 100%;
    min-height: 50px;
    border-radius: 0px;
    white-space: normal;
    font-family: "open sans";
    font-weight: 300;
    /* box-shadow: 0px 3px 6px #00000029; */
    &.selected {
        /* background: #27684e; */
    }
`;

export const Triangle = styled(Box)`
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 15px 12px 0 12px;
    border-color: #1f2747 transparent transparent transparent;
    z-index: 98;
`;

export const TriangleMobile = styled(Box)`
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 0 15px 20px 15px;
    border-color: transparent transparent white transparent;
`;

export const HeadingStyle = styled(Label)`
    line-height: 1.1;
`;

export const BackBox = styled(Box)`
    height: 50px;
    background-color: white;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    padding-left: 20px;
`;

export const BackBoxInner = styled(Box)`
    display: grid;
    grid-template-columns: auto auto;
    gap: 10px;
    align-items: center;
    .back-text {
        font-weight: 600;
        font-size: 12px;
        cursor: pointer;
    }
`;
