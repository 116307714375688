import { ModelBase } from "@shoothill/core";
import { action, makeObservable, observable } from "mobx";
import { Validator } from "Application/Validation";

export class ConfirmPasswordModel extends ModelBase<ConfirmPasswordModel> {
    public password: string = "";
    public confirmPassword: string = "";
    public token: string = "";
    public isForgotPassword: boolean = false;

    constructor() {
        super();
        makeObservable(this, {
            confirmPassword: observable,
            password: observable,
            token: observable,
            isForgotPassword: observable,
            clear: action,
        });
    }

    public clear = () => {
        this.password = "";
        this.confirmPassword = "";
        this.token = "";
        this.isForgotPassword = false;
    };
}

export class ConfirmPaswordValidator extends Validator<ConfirmPasswordModel> {
    constructor() {
        super();
        this.ruleFor("token").notEmpty();
        this.ruleFor("password")
            .notEmpty()
            .minLength(8)
            .withMessage("Password must be at least 8 characters long")
            .maxLength(15)
            .matches(new RegExp("^(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"))
            .withMessage("Password must contain at least one number and one special character");
        this.ruleFor("confirmPassword")
            .notEmpty()
            .maxLength(15)
            .withMessage("Please enter a password")
            .must({ predicate: (confirmPassword, model) => confirmPassword === model.password, message: (value, model) => "Passwords must match" })
            .withMessage("Passwords must match");
    }
}
