import { makeObservable, observable, runInAction } from "mobx";
import { ViewModelBase } from "@shoothill/core";
import { APIClient, ICommand, IKeyState, RelayCommand } from "Application";
import { BuyingWithshorelineModel, BuyingWithshorelineModelValidator } from "./BuyingWithShorelineModel";
import { useNavigate } from "react-router-dom";

export class BuyingwithshorelineViewModel extends ViewModelBase<BuyingWithshorelineModel> {
    public apiClient = new APIClient();
    public navigate = useNavigate();

    constructor() {
        super(new BuyingWithshorelineModel());
        this.setValidator(new BuyingWithshorelineModelValidator());
        makeObservable(this, {});
    }

    public navTo: ICommand = new RelayCommand((value: string) => {
        this.navigate(value);
    });
}
