import { Box, ICommand, Label, RelayCommand } from "Application";
import { ButtonIconWrapper } from "Components/ButtonIconPropWrapper";
import { ShorelineButton } from "Components/ShorelineButton";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import Modal from "react-responsive-modal";

//Image Imports

import CopyIcon from "Assets/CopyIcon.svg";
import EmailIcon from "Assets/EmailIcon.svg";
import FacebookIcon from "Assets/FacebookIcon.svg";
import TwitterIcon from "Assets/TwitterIcon.svg";
import WhatsAppIcon from "Assets/WhatsAppIcon.svg";
import { CloseButton } from "Components/NextPrevButton";

interface IModalProps {
    onCloseModal?: any;
    open: boolean;
}

export const ShareLinksModal: React.FC<IModalProps> = observer((props) => {
    const doSomething: ICommand = new RelayCommand((value: string) => {});

    const [copyLinkText, setCopyLinkText] = useState("");

    const copyToClipboard: ICommand = new RelayCommand((value: string) => {
        const url = location.href;
        if ("clipboard" in navigator) {
            navigator.clipboard.writeText(url);
        }
        setCopyLinkText(`Link copied to clipboard`);
        setTimeout(() => setCopyLinkText(""), 2000);
    });

    return (
        <>
            <Modal
                open={props.open}
                onClose={props.onCloseModal}
                closeIcon={CloseButton}
                styles={{
                    modal: { width: "100%", maxWidth: "333.33px", background: "none" },
                    closeButton: { marginBottom: "30px" },
                    modalContainer: { backgroundColor: "rgba(0, 0, 0, 0.7)" },
                }}
            >
                <Box>
                    <Box maxWidth="333px" minHeight="60px">
                        <Box p="15px" showIf={copyLinkText !== ""} style={{ wordWrap: "break-word", borderRadius: "5px", backgroundColor: "white" }}>
                            <Label fontStyle="p" color="primary" style={{ textAlign: "center" }}>
                                {copyLinkText}
                            </Label>
                        </Box>
                    </Box>
                    <ShorelineButton
                        command={copyToClipboard}
                        icon={<ButtonIconWrapper imgUrl={CopyIcon}></ButtonIconWrapper>}
                        mb="15px"
                        displayName="Copy link to property"
                    ></ShorelineButton>
                    <a href={`mailto:?subject=Check out this property!&body=${location.href}`}>
                        <ShorelineButton command={doSomething} icon={<ButtonIconWrapper imgUrl={EmailIcon}></ButtonIconWrapper>} mb="15px" displayName="Email"></ShorelineButton>
                    </a>

                    <a href={`whatsapp://send?text= Check out this property:${location.href}`} rel="nofollow noopener" target="_blank">
                        <ShorelineButton
                            command={doSomething}
                            icon={<ButtonIconWrapper imgUrl={WhatsAppIcon}></ButtonIconWrapper>}
                            mb="15px"
                            displayName="WhatsApp"
                        ></ShorelineButton>
                    </a>

                    <div data-href="http://www.google.com" data-layout="" data-size="">
                        <a target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=http%3A%2F%2Fwww.google.com%2F&amp;src=sdkpreparse">
                            <ShorelineButton
                                command={doSomething}
                                icon={<ButtonIconWrapper imgUrl={FacebookIcon}></ButtonIconWrapper>}
                                mb="15px"
                                displayName="Facebook"
                            ></ShorelineButton>
                        </a>
                    </div>

                    <a href={`https://twitter.com/intent/tweet?text=Check out this property: ${location.href}`} target="_blank">
                        <ShorelineButton
                            command={doSomething}
                            icon={<ButtonIconWrapper imgUrl={TwitterIcon}></ButtonIconWrapper>}
                            mb="15px"
                            displayName="Twitter"
                        ></ShorelineButton>
                    </a>

                    {/* <ShorelineButton
                        command={doSomething}
                        icon={<ButtonIconWrapper imgUrl={PinterestIcon}></ButtonIconWrapper>}
                        mb="15px"
                        displayName="Pinterest"
                    ></ShorelineButton> */}
                </Box>
            </Modal>
        </>
    );
});
