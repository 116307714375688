import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Route, Routes } from "react-router-dom";
import { SelectedProperty } from "./SelectedProperty/SelectedProperty";
import { NewHomesViewModel } from "./NewHomesViewModel";
import { uniqueId } from "lodash-es";
import { NewHomesSubView } from "./Subviews/NewHomesSubview";
import { NewHomesLogin } from "./NewHomesLogin/NewHomesLogin";
import { Box, ICommand, RelayCommand } from "Application";

export const NewHomesView: React.FC = observer(() => {
    const [viewModel] = useState<NewHomesViewModel>(new NewHomesViewModel());

    const [passwordAccepted, setPasswordAccepted] = useState(false);

    useEffect(() => {
        let validation = sessionStorage.getItem("validated");
        viewModel.loadPropertiesForHardCodedDevelopmentId();

        if (validation == "true") {
            setPasswordAccepted(true);
        }

        return () => {
            viewModel.clear();
        };
    }, []);

    const switchView = () => {
        setPasswordAccepted(true);
    };

    return (
        <>
            {/* {passwordAccepted == false && <NewHomesLogin switchView={switchView} />}

            {passwordAccepted == true && ( */}
            <Routes>
                <Route index element={<NewHomesSubView viewModel={viewModel} />} />
                {viewModel.propertyListItemViewModels.map((row, index) => (
                    <Route key={uniqueId()} path={row.generatePermalink()} element={<SelectedProperty viewModel={row} propertiesViewModel={viewModel} />} />
                ))}
            </Routes>
            {/* )} */}

            {/* padding */}
            <Box p="50px" backgroundColor="transparent"></Box>
        </>
    );
});
