import React from "react";
import { observer } from "mobx-react-lite";
import { Box } from "Application";

// Import Swiper React components
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Navigation, Pagination, Autoplay } from "swiper";

import Image1 from "Assets/VisionBanner1.jpg";
import Image2 from "Assets/23053_202_Cafe_Extension.jpg";
import Image3 from "Assets/23053_203_Marine_Parade.jpg";
import Image4 from "Assets/23053_205_Entrance.jpg";
import LeftArrow from "Assets/LeftSandyArrow.svg";
import RightArrow from "Assets/RightSandyArrow.svg";

import styled from "@emotion/styled";

export const CarouselFullWidthMk2: React.FC = observer(() => {
    return (
        <>
            <WrapperBox>
                <Swiper
                    navigation={true}
                    autoplay={{
                        delay: 3000,
                        disableOnInteraction: false,
                    }}
                    modules={[Navigation, Autoplay]}
                    className="mySwiper"
                    spaceBetween={0}
                    loop={true}
                >
                    <SwiperSlide>
                        <img src={Image1} width="100%" height="100%" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={Image2} width="100%" height="100%" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={Image3} width="100%" height="100%" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={Image4} width="100%" height="100%" />
                    </SwiperSlide>
                </Swiper>
            </WrapperBox>
        </>
    );
});

export const WrapperBox = styled(Box)`
    width: 100%;
    height: 100vh;
    .swiper-button-next {
        background-image: url(${RightArrow});
        background-repeat: no-repeat;
        background-size: 100% auto;
        background-position: center;
    }
    .swiper-button-next::after {
        display: none;
    }
    .swiper-button-prev {
        background-image: url(${LeftArrow});
        background-repeat: no-repeat;
        background-size: 100% auto;
        background-position: center;
    }
    .swiper-button-prev::after {
        display: none;
    }

    img {
        object-fit: cover;
        cursor: grab;
        user-select: none;
    }
    .swiper {
        width: 100%;
        height: 100%;
    }

    .swiper .swiper-slide {
        height: auto;
    }

    .swiper-slide img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`;
