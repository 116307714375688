import styled from "@emotion/styled";
import {
    shadow,
    ShadowProps,
    position,
    PositionProps,
    border,
    BorderProps,
    background,
    BackgroundProps,
    grid,
    GridProps,
    color,
    ColorProps,
    flexbox,
    FlexboxProps,
    layout,
    LayoutProps,
    space,
    SpaceProps,
    typography,
    TypographyProps,
    gridArea,
} from "styled-system";
import React, { DOMAttributes, PropsWithChildren, useEffect, useState } from "react";
import { action, autorun, computed, makeObservable, observable } from "mobx";
import { CoreStoreInstance, isNullOrUndefined, ViewModelBase } from "@shoothill/core";
import { Logger } from "../../index";
import { debounce, throttle } from "lodash-es";
import { motion, MotionProps } from "framer-motion";

interface AutoBoxGridProps {
    mc: string; //Mobile columns
    tc: string; //Tablet columns
    dc: string; //Desktop columns
    mro: string; //Mobile rows
    tr: string; //Tablet rows
    dr: string; //Desktop rows
    gridAutoColumns: string; //Grid auto columns
    ma: string[]; //Mobile areas
    ta: string[]; //Tablet areas
    da: string[]; //Desktop areas
    areaName: string;
    columnGap: string | number;
    rowGap: string | number;
    dcs: string; //Desktop column span
    drs: string;
    tcs: string;
    trs: string;
    mcs: string;
    mrs: string;
    gap: string | number;
    man: string; //Mobile area name
    tan: string; //Tablet area name
    dan: string; //Desktop area name
}
export type BoxProps =
    | BorderProps
    | ShadowProps
    | PositionProps
    | BackgroundProps
    | GridProps
    | DOMAttributes<any>
    | (ColorProps & LayoutProps & SpaceProps & TypographyProps & FlexboxProps & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>);
export const InternalBox = styled(motion.div)<BoxProps>`
    ${color};
    ${layout};
    ${space};
    ${typography};
    ${flexbox};
    ${grid};
    ${background};
    ${border};
    ${position};
    ${shadow};
`;

type Props = {
    id?: string;
    center?: boolean;
    flexBox?: boolean;
    grid?: boolean;
    showIf?: boolean | undefined | null;
    style?: React.DetailedHTMLProps<React.StyleHTMLAttributes<HTMLStyleElement>, HTMLStyleElement>;
} & BoxProps &
    Partial<AutoBoxGridProps> &
    Partial<MotionProps>;

const formatAreas = (areas: string[]) => {
    if (areas && areas.length > 0) {
        return areas.map((area) => `"${area}"`).join(" ");
    }
    return "";
};

const getFlexStyles = (props: Props): {} => {
    return { display: "flex", columnGap: props.columnGap || "24px", rowGap: props.rowGap || "24px", ...props.style };
};

const getGridStyles = (props: Props): {} => {
    let columns = "";
    let rows = "";
    let area = "";
    let view = "";
    if (CoreStoreInstance.isMobile) {
        columns = props.mc || "1fr";
        rows = props.mro!;
        area = formatAreas(props.ma as []);
        view = "mobile";
    } else if (CoreStoreInstance.isTablet) {
        columns = props.tc || props.dc || "1fr 1fr";
        rows = props.tr!;
        area = formatAreas(props.ta as []);
        view = "tablet";
    } else if (CoreStoreInstance.isDesktop) {
        columns = props.dc! || "1fr 1fr";
        rows = props.dr!;
        area = formatAreas(props.da as []);
        view = "desktop";
    } else {
        columns = props.dc! || "1fr 1fr";
        rows = props.dr!;
        area = formatAreas(props.da as []);
        view = "desktop";
    }
    return { display: "grid", gridTemplateColumns: columns, gridTemplateRows: rows, gridTemplateAreas: area, columnGap: props.columnGap || "24px", rowGap: props.rowGap || "24px" };
};

const getGridSpanStyles = (props: Props): {} => {
    let retval = {};
    if (props.dan) {
        retval = { gridArea: props.dan, ...retval };
    }
    if (props.tan) {
        retval = { gridArea: props.tan, ...retval };
    }
    if (props.man) {
        retval = { gridArea: props.man, ...retval };
    }
    if (props.dcs) {
        retval = { gridColumn: `span ${props.dcs}`, ...retval };
    }
    if (props.drs) {
        retval = { gridRow: `span ${props.drs}`, ...retval };
    }
    if (props.tcs) {
        retval = { gridColumn: `span ${props.tcs}`, ...retval };
    }
    if (props.trs) {
        retval = { gridRow: `span ${props.trs}`, ...retval };
    }
    if (props.mcs) {
        retval = { gridColumn: `span ${props.mcs}`, ...retval };
    }
    if (props.mrs) {
        retval = { gridRow: `span ${props.mrs}`, ...retval };
    }
    return retval;
};

export const Box: React.FC<PropsWithChildren<Props>> = (props: PropsWithChildren<Props>) => {
    //let { children,  ...rest } = props;
    let { children, showIf, flexBox, style, dc, tc, mc, dr, tr, mro, da, ta, ma, dan, tan, man, dcs, drs, tcs, trs, mcs, mrs, ...rest } = props;
    let appliedStyles = { ...props.style };

    //EN: Used to renrender the component when the view changes
    const [dimensions, setDimensions] = React.useState({
        height: window.innerHeight,
        width: window.innerWidth,
    });

    useEffect(() => {
        const debouncedHandleResize = throttle(function handleResize() {
            setDimensions({
                height: window.innerHeight,
                width: window.innerWidth,
            });
        }, 1000);
        window.addEventListener("resize", debouncedHandleResize);

        return () => {
            window.removeEventListener("resize", debouncedHandleResize);
        };
    }, []);

    if (flexBox) {
        appliedStyles = getFlexStyles(props);
    } else if (props.grid) {
        appliedStyles = getGridStyles(props);
    } else if (props.center) {
        appliedStyles = { display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", textAlign: "center", ...props.style };
    }

    appliedStyles = { ...appliedStyles, ...getGridSpanStyles(props), ...style };

    //Logger.logDebug("Box", props.id, props);
    //Logger.logDebug("BoxStyle", appliedStyles);
    return (
        <>
            {showIf ? (
                // @ts-ignore
                <InternalBox style={appliedStyles} {...rest}>
                    {children}
                </InternalBox>
            ) : null}
        </>
    );
};
Box.defaultProps = {
    showIf: true,
};
