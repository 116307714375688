import React, { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Box, CheckBox, EditSelect, ICommand, Input, RelayCommand } from "Application";
import { SignUpOrUpdateViewModel } from "./SignUpOrUpdateViewModel";
import styled from "@emotion/styled";
import { P } from "Components/Primitives/TextElements/TextElements";
import { ShorelineButton } from "Components/ShorelineButton";
import { BuyerPanelView } from "../BuyerLogin";
import { ErrorModal } from "Components/Modals/ErrorModal";

interface ISignUpOrUpdate {
    whereIsFormUsed?: BuyerPanelView;
}

export const SignUpOrUpdate: React.FC<ISignUpOrUpdate> = observer((props) => {
    const [viewModel] = useState<SignUpOrUpdateViewModel>(new SignUpOrUpdateViewModel());
    const [showErrorOpen, setShowErrorOpen] = useState<boolean>(false);
    const cancelSaveChanges: ICommand = new RelayCommand((value: string) => {});

    useEffect(() => {
        viewModel.load();

        return () => {
            viewModel.clear();
        };
    }, []);

    useEffect(() => {
        if (viewModel.errorMessage.length > 0) {
            setShowErrorOpen(true);
        } else {
            setShowErrorOpen(false);
        }
    }, [viewModel.getErrorMessage]);

    const closeErrorWindow: ICommand = new RelayCommand(() => {
        setShowErrorOpen(false);
    });

    return (
        <>
            <ErrorModal errorMessage={viewModel.errorMessage} showErrorOpen={showErrorOpen} closeErrorWindow={closeErrorWindow} />
            <DetailsBox grid dc="1fr 1fr 1fr 1fr" tc="1fr 1fr 1fr 1fr" pb="30px">
                <SpanTwoBox isMobile={viewModel.isMobile}>
                    <Input
                        command={viewModel.updateFirstName}
                        style={{ fontSize: "40px" }}
                        type="text"
                        displayName="First name"
                        placeholder="First name"
                        labelColor="black"
                        value={() => viewModel.getValue("firstName")}
                        validationMessage={() => viewModel.getError("firstName")}
                        displayNameSuffix={RedAsterisk}
                    ></Input>
                </SpanTwoBox>
                <SpanTwoBox isMobile={viewModel.isMobile}>
                    <Input
                        command={viewModel.updateLastName}
                        type="text"
                        displayName="Last name"
                        placeholder="Last name"
                        labelColor="black"
                        value={() => viewModel.getValue("lastName")}
                        validationMessage={() => viewModel.getError("lastName")}
                        displayNameSuffix={RedAsterisk}
                    ></Input>
                </SpanTwoBox>
                <SpanTwoBox isMobile={viewModel.isMobile}>
                    <Input
                        command={viewModel.updateEmail}
                        type="text"
                        displayName="Email address"
                        placeholder="Email address"
                        labelColor="black"
                        value={() => viewModel.getValue("emailAddress")}
                        validationMessage={() => viewModel.getError("emailAddress")}
                        displayNameSuffix={RedAsterisk}
                    ></Input>
                </SpanTwoBox>
                <SpanTwoBox isMobile={viewModel.isMobile}>
                    <Input
                        command={viewModel.updatePhoneNumber}
                        type="text"
                        displayName="Phone number"
                        placeholder="Phone number"
                        labelColor="black"
                        value={() => viewModel.getValue("phoneNumber")}
                        validationMessage={() => viewModel.getError("phoneNumber")}
                        displayNameSuffix={RedAsterisk}
                        maxLength={15}
                    ></Input>
                </SpanTwoBox>
                <SpanTwoBox isMobile={viewModel.isMobile}>
                    <Input
                        command={viewModel.updateAddressLine1}
                        type="text"
                        displayName="Address line 1"
                        placeholder="Address line 1"
                        labelColor="black"
                        value={() => viewModel.getValue("addressLine1")}
                        validationMessage={() => viewModel.getError("addressLine1")}
                        displayNameSuffix={RedAsterisk}
                    ></Input>
                </SpanTwoBox>
                <SpanTwoBox isMobile={viewModel.isMobile}>
                    <Input
                        command={viewModel.updateAddressLine2}
                        type="text"
                        displayName="Address line 2"
                        placeholder="Address line 2"
                        labelColor="black"
                        value={() => viewModel.getValue("addressLine2")}
                        validationMessage={() => viewModel.getError("addressLine2")}
                    ></Input>
                </SpanTwoBox>
                <SpanOneBox>
                    <Input
                        command={viewModel.updateTownCity}
                        type="text"
                        displayName="Town/City"
                        placeholder="Town/City"
                        labelColor="black"
                        value={() => viewModel.getValue("city")}
                        validationMessage={() => viewModel.getError("city")}
                        displayNameSuffix={RedAsterisk}
                    ></Input>
                </SpanOneBox>
                <SpanOneBox>
                    <Input
                        command={viewModel.updatePostcode}
                        type="text"
                        displayName="Postcode"
                        placeholder="Postcode"
                        labelColor="black"
                        value={() => viewModel.getValue("postCode")}
                        validationMessage={() => viewModel.getError("postCode")}
                        displayNameSuffix={RedAsterisk}
                    ></Input>
                </SpanOneBox>
                <SpanTwoBox hidden={props.whereIsFormUsed == BuyerPanelView.SIGNUP} isMobile={viewModel.isMobile} />
                <SpanTwoBox isMobile={viewModel.isMobile}>
                    <EditSelect
                        styles={{
                            control: (base: any) => ({
                                ...base,
                                // override border radius to match the box
                                borderRadius: 5,
                                border: "none",
                            }),
                            menuPortal: (base: any) => ({ ...base, zIndex: 99999999 }),
                        }}
                        displayName="I am interested in"
                        command={viewModel.updateInterestedIn}
                        options={viewModel.getInterestedInTypes}
                        value={() => viewModel.getValue("interestedInId")}
                        validationMessage={() => viewModel.getError("interestedInId")}
                        displayNameSuffix={RedAsterisk}
                    />
                    <EditSelectErrorMsg> {viewModel.getError("interestedInId")}</EditSelectErrorMsg>
                </SpanTwoBox>
                <SpanTwoBox isMobile={viewModel.isMobile}>
                    <EditSelect
                        styles={{
                            control: (base: any) => ({
                                ...base,
                                // override border radius to match the box
                                borderRadius: 5,
                                border: "none",
                            }),
                            menuPortal: (base: any) => ({ ...base, zIndex: 99999999 }),
                        }}
                        displayName="Current position"
                        command={viewModel.updateCurrentPosition}
                        options={viewModel.getCurrentPositionTypes}
                        value={() => viewModel.getValue("currentPositionId")}
                        validationMessage={() => viewModel.getError("currentPositionId")}
                        displayNameSuffix={RedAsterisk}
                    />
                    <EditSelectErrorMsg> {viewModel.getError("currentPositionId")}</EditSelectErrorMsg>
                </SpanTwoBox>
                <SpanTwoBox isMobile={viewModel.isMobile}>
                    <EditSelect
                        styles={{
                            control: (base: any) => ({
                                ...base,
                                // override border radius to match the box
                                borderRadius: 5,
                                border: "none",
                            }),
                            menuPortal: (base: any) => ({ ...base, zIndex: 99999999 }),
                        }}
                        displayName="Where did you hear about Shoreline?"
                        command={viewModel.updateSourceOfEnquiry}
                        options={viewModel.getSourceOfEnquiryTypes}
                        value={() => viewModel.getValue("sourceOfEnquiryId")}
                        validationMessage={() => viewModel.getError("sourceOfEnquiryId")}
                        displayNameSuffix={RedAsterisk}
                    />
                    <EditSelectErrorMsg> {viewModel.getError("sourceOfEnquiryId")}</EditSelectErrorMsg>
                </SpanTwoBox>
                <SpanTwoBox hidden={props.whereIsFormUsed == BuyerPanelView.SIGNUP} isMobile={viewModel.isMobile} />
                <SpanTwoBox isMobile={viewModel.isMobile}>
                    <P pb="20px" style={{ fontWeight: 600, fontSize: "12px", textAlign: "left" }}>
                        How would like to hear about news from us?
                    </P>
                    <TickBoxes>
                        <Box>
                            <CheckBox command={viewModel.updateAcceptsEmail} displayName="Email" value={() => viewModel.getValue("marketingEmail")} />
                        </Box>
                        <Box>
                            <CheckBox command={viewModel.updateAcceptsText} displayName="Text" value={() => viewModel.getValue("marketingText")} />
                        </Box>
                        <Box>
                            <CheckBox command={viewModel.updateAcceptsPost} displayName="Post" value={() => viewModel.getValue("marketingPost")} />
                        </Box>
                    </TickBoxes>
                </SpanTwoBox>
                <SpanTwoBox isMobile={viewModel.isMobile} />

                {props.whereIsFormUsed == BuyerPanelView.UPDATE && (
                    <>
                        <SpanOneBox>
                            <ShorelineButton command={viewModel.submitUpdatedContactDetails} displayName="SAVE CHANGES" />
                        </SpanOneBox>
                        <SpanOneBox>
                            <ShorelineButton command={cancelSaveChanges} displayName="CANCEL" style={CancelButton} />
                        </SpanOneBox>
                    </>
                )}

                {props.whereIsFormUsed == BuyerPanelView.SIGNUP && (
                    <>
                        <CenteredButtonBox isMobile={viewModel.isMobile}>
                            <ShorelineButton command={viewModel.submitUpdatedContactDetails} displayName="CREATE ACCOUNT" />
                        </CenteredButtonBox>
                    </>
                )}
            </DetailsBox>
        </>
    );
});

const DetailsBox = styled(Box)`
    label {
        font-weight: 600;
        font-size: 12px;
    }

    .toolTip {
        background: #8a8a8a;
        color: white;
        max-width: 190px;
        font-size: 9px;
    }
`;
const SpanOneBox = styled(Box)`
    grid-column: span 1;
`;

interface ISpanBox {
    isMobile?: boolean;
}

const SpanTwoBox = styled(Box)<ISpanBox>`
    grid-column: ${(props) => (props.isMobile ? "span 1" : "span 2")};

    .editselect__control {
        border-radius: 0.125rem !important;
        border: transparent !important;
    }
`;

const SpanFourBox = styled(Box)<ISpanBox>`
    grid-column: ${(props) => (props.isMobile ? "span 1" : "span 4")};
`;

const CenteredButtonBox = styled(Box)<ISpanBox>`
    grid-column: ${(props) => (props.isMobile ? "span 1" : "span 4")};
`;

const TickBoxes = styled(Box)`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
`;

const CancelButton = {
    background: "#8A8A8A",
};

const EditSelectErrorMsg = styled(P)`
    color: #c52f3e;
    font-size: 12px;
    font-weight: 600;
    padding-top: 10px;
`;

const RedAsterisk = <P style={{ padding: "5px 0px", color: "#F06F56" }}>*</P>;
