import { ModelBase } from "@shoothill/core";
import { action, makeObservable, observable } from "mobx";
import { Validator } from "Application/Validation";
import { Guid } from "Application/Helpers";

export class SignUpOrUpdateModel extends ModelBase<SignUpOrUpdateModel> {
    public id: Guid | null = null;

    public firstValidate = true;

    public firstName: string = "";
    public lastName: string = "";
    public emailAddress: string = "";
    public phoneNumber: string = "";
    public addressLine1: string = "";
    public addressLine2: string = "";
    public city: string = "";
    public postCode: string = "";
    public interestedInId: number | null = null;
    public currentPositionId: number | null = null;
    public sourceOfEnquiryId: number | null = null;
    public marketingEmail: boolean = false;
    public marketingText: boolean = false;
    public marketingPost: boolean = false;

    constructor() {
        super();
        makeObservable(this, {
            id: observable,
            firstValidate: observable,

            firstName: observable,
            lastName: observable,
            emailAddress: observable,
            phoneNumber: observable,
            addressLine1: observable,
            addressLine2: observable,
            city: observable,
            postCode: observable,
            interestedInId: observable,
            currentPositionId: observable,
            sourceOfEnquiryId: observable,
            marketingEmail: observable,
            marketingText: observable,
            marketingPost: observable,

            clear: action,
        });
    }

    public clear() {
        this.id = null;
        this.firstValidate = true;
        this.firstName = "";
        this.lastName = "";
        this.emailAddress = "";
        this.phoneNumber = "";
        this.addressLine1 = "";
        this.addressLine2 = "";
        this.city = "";
        this.postCode = "";
        this.interestedInId = null;
        this.currentPositionId = null;
        this.sourceOfEnquiryId = null;
        this.marketingEmail = false;
        this.marketingText = false;
        this.marketingPost = false;
    }
}

export class SignUpOrUpdateModelValidator extends Validator<SignUpOrUpdateModel> {
    constructor() {
        super();
        //this.ruleFor("id").notEmpty();

        this.ruleFor("firstName")
            .notEmpty()
            .withMessage("Please enter your first name")
            .maxLength(50)
            .withMessage("Please enter your first name")
            .when((model) => model.firstValidate === false);
        this.ruleFor("lastName")
            .notEmpty()
            .withMessage("Please enter your last name")
            .maxLength(50)
            .withMessage("Please enter your last name")
            .when((model) => model.firstValidate === false);
        this.ruleFor("emailAddress")
            .notEmpty()
            .withMessage("Please enter a valid email address")
            .emailAddress()
            .maxLength(100)
            .withMessage("Please enter a valid email address")
            .when((model) => model.firstValidate === false);
        this.ruleFor("phoneNumber")
            .notEmpty()
            .withMessage("Please enter a valid phone number")
            .maxLength(15)
            .withMessage("Please enter a valid phone number")
            .when((model) => model.firstValidate === false);
        this.ruleFor("addressLine1")
            .notEmpty()
            .withMessage("Please enter your address")
            .minLength(2)
            .maxLength(1024)
            .withMessage("Please enter your address")
            .when((model) => model.firstValidate === false);
        this.ruleFor("addressLine2")
            .maxLength(1024)
            .withMessage("Address Line 2 has a max length of 1024 characters")
            .when((model) => model.firstValidate === false);
        this.ruleFor("city")
            .notEmpty()
            .withMessage("Please enter your town/city")
            .minLength(2)
            .maxLength(50)
            .when((model) => model.firstValidate === false);
        this.ruleFor("postCode")
            .notEmpty()
            .withMessage("Please enter your post code")
            .minLength(2)
            .maxLength(10)
            .when((model) => model.firstValidate === false);
        this.ruleFor("interestedInId")
            .notNull()
            .withMessage("Please select")
            .when((model) => model.firstValidate === false);
        this.ruleFor("currentPositionId")
            .notNull()
            .withMessage("Please select")
            .when((model) => model.firstValidate === false);
        this.ruleFor("sourceOfEnquiryId")
            .notNull()
            .withMessage("Please select")
            .when((model) => model.firstValidate === false);
    }
}
