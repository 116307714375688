import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { Validator } from "Application/Validation";

export class HomeModel extends ModelBase<HomeModel> {
    public id: string = "";

    constructor() {
        super();
        makeObservable(this, {
            id: observable,
        });
    }
}

export class HomeModelValidator extends Validator<HomeModel> {
    constructor() {
        super();
        //this.ruleFor("id").notEmpty();
    }
}
