import React from "react";
import { observer } from "mobx-react-lite";
import { EditableCopyStore } from "../Stores";
import { container } from "tsyringe";

export const CommonInjects = observer(() => {
    const editableCopyStore = container.resolve(EditableCopyStore);

    return <></>;
});
