import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { Validator } from "Application/Validation";

export class FooterModel extends ModelBase<FooterModel> {
    public id: string = "";

    public firstValidate = true;

    public firstName: string = "";
    public lastName: string = "";
    public contactEmail: string = "";
    public agreePrivacyPolicy: boolean = false;

    constructor() {
        super();
        makeObservable(this, {
            id: observable,

            firstName: observable,
            lastName: observable,
            contactEmail: observable,

            agreePrivacyPolicy: observable,
        });
    }
}

export class FooterModelValidator extends Validator<FooterModel> {
    constructor() {
        super();
        //this.ruleFor("id").notEmpty();
        this.ruleFor("firstName")
            .notEmpty()
            .withMessage("Please enter a first name")
            .minLength(2)
            .maxLength(50)
            .when((model) => model.firstValidate === false);
        this.ruleFor("lastName")
            .notEmpty()
            .withMessage("Please enter a last name")
            .minLength(2)
            .maxLength(50)
            .when((model) => model.firstValidate === false);
        this.ruleFor("contactEmail")
            .notEmpty()
            .withMessage("Please enter an email address")
            .emailAddress()
            .minLength(2)
            .maxLength(50)
            .when((model) => model.firstValidate === false);
        this.ruleFor("agreePrivacyPolicy")
            .equal(true)
            .withMessage("Please accept privacy policy")
            .when((model) => model.firstValidate === false);
    }
}
