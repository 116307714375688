import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { Box, Input } from "Application";
import styled from "@emotion/styled";
import { P } from "Components/Primitives/TextElements/TextElements";
import { ShorelineButton } from "Components/ShorelineButton";
import { SignInViewModel } from "./SignInViewModel";
import { BuyerPanelView } from "../BuyerLogin";

interface ISignIn {
    setPanelView: React.Dispatch<React.SetStateAction<BuyerPanelView>>;
    onComplete: () => void;
}

export const SignIn: React.FC<ISignIn> = observer((props) => {
    const [viewModel] = useState<SignInViewModel>(new SignInViewModel(props.onComplete));

    return (
        <>
            <SignInWrapper pb="30px">
                <Box pb="20px">
                    <Input
                        command={viewModel.updateEmail}
                        style={{ fontSize: "9px" }}
                        type="text"
                        displayName="Login"
                        placeholder="Email address"
                        labelColor="black"
                        value={() => viewModel.getValue("emailAddress")}
                        validationMessage={() => viewModel.getError("emailAddress")}
                        displayNameSuffix={RedAsterisk}
                    ></Input>
                </Box>

                <Box>
                    <Input
                        command={viewModel.updatePassword}
                        type="password"
                        displayName="Password"
                        placeholder="Password"
                        labelColor="black"
                        value={() => viewModel.getValue("password")}
                        validationMessage={() => viewModel.getError("password")}
                        displayNameSuffix={RedAsterisk}
                    ></Input>
                </Box>

                <Box onClick={() => props.setPanelView(BuyerPanelView.FORGOTPASSWORD)} style={{ cursor: "pointer" }}>
                    <ForgottonPasswordText>Oops, I've forgotten my password</ForgottonPasswordText>
                </Box>

                <ShorelineButton mt="30px" command={viewModel.signIn} displayName="SIGN IN" />
            </SignInWrapper>
        </>
    );
});

const ForgottonPasswordText = styled(P)`
    font-size: 10px;
    text-align: left;
    color: #2d91ff;
    text-decoration: underline;
`;

const SignInWrapper = styled(Box)`
    label {
        font-size: 12px;
        font-weight: 600;
    }
`;

const RedAsterisk = <P style={{ padding: "5px 0px", color: "#F06F56" }}>*</P>;
