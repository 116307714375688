import { Logger } from "../../../../index";
import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "../../../../AppUrls";
import { SignUpOrUpdateModel } from "Components/BuyerLogin/SignUpOrUpdate/SignUpOrUpdateModel";
import { AccountStore } from "Stores/Domain";
import { container } from "tsyringe";
export class RegisterRequest {
    public lastName: string = "";
    public firstName: string = "";
    public phoneNumber: number | string = "";
    public emailAddress: string = "";
    public password: string = "";
    public confirmPassword: string = "";
    public userDetails: UserDetailsRequest = new UserDetailsRequest();
    public terms: boolean = false;
    public tod: Date = new Date();
}
export class UserDetailsRequest {
    public companyName: string = "";
    public address1: string = "";
    public address2: string = "";
    public city: string = "";
    public county: string = "";
    public postCode: string = "";
    public phoneNumber: string = "";
    public interestedInId: number = 0; // don't send this up as 0 it will fail
    public sourceOfEnquiryId: number = 0; // don't send this up as 0 it will fail
    public currentPositionId: number = 0; // don't send this up as 0 it will fail
    public marketingEmail: boolean = false;
    public marketingSMS: boolean = false;
    public marketingPost: boolean = false;
}
export class RegisterResponse {
    public jwt: string = "";

    public emailConfirmationRequired: boolean = false;
    public id: string = "";

    public constructor() {}
}

export class RegisterEndpointPOST extends Endpoint<RegisterRequest, RegisterResponse> {
    private accountStore: AccountStore;
    ///
    /// constructor
    ///
    constructor() {
        super();
        this.verb(Http.Post);
        this.path(AppUrls.Server.Account.Register);
        this.accountStore = container.resolve(AccountStore);
    }

    public async HandleRequestAsync(model: SignUpOrUpdateModel): Promise<any> {
        //EN: We have a direct one to one name mapping so we can just call fromModel
        //which will strip out the model base and give us a clean request object back
        let request = new RegisterRequest();
        model.toRequest<RegisterRequest>(request);

        // toRequest isn't expecting subobjects.
        request.userDetails = new UserDetailsRequest();

        request.userDetails.companyName = "";
        request.userDetails.address1 = model.addressLine1;
        request.userDetails.address2 = model.addressLine2;
        request.userDetails.city = model.city;
        request.userDetails.county = "";
        request.userDetails.postCode = model.postCode;
        request.userDetails.phoneNumber = model.phoneNumber;
        request.userDetails.interestedInId = model.interestedInId!; // don't send this up as 0 it will fail
        request.userDetails.sourceOfEnquiryId = model.sourceOfEnquiryId!; // don't send this up as 0 it will fail
        request.userDetails.currentPositionId = model.currentPositionId!; // don't send this up as 0 it will fail
        request.userDetails.marketingEmail = model.marketingEmail;
        request.userDetails.marketingSMS = model.marketingText;
        request.userDetails.marketingPost = model.marketingPost;

        return request;
    }

    public async HandleResponseAsync(response: RegisterResponse): Promise<RegisterResponse> {
        Logger.logInformation("Response", response);

        if (response !== null && response !== undefined && response.jwt !== null && response.jwt !== undefined && response.jwt.length > 0) {
            await this.accountStore.getLoginState(response.jwt as string);
        }

        return response;
    }
}
