import React, { useEffect, useState } from "react";
import { Box } from "../../Components";
import { HeaderViewModel } from "./HeaderViewModel";
import { observer } from "mobx-react-lite";
import { HamburgerButton, Sidebar } from "./SideBar";
import { AppUrls } from "../../AppUrls";
import { container } from "tsyringe";
import { AccountStore } from "../../Stores/Domain";
import { MenuItem } from "./MenuItem";
import logo from "Assets/ShorelineLogoMk2.svg";
import logoMobile from "Assets/ShorelineLogoMk2Mobile.svg";
import { NavLink, useNavigate } from "react-router-dom";
import { CoreStoreInstance } from "@shoothill/core";
import { DomainStore } from "Stores/Domain/DomainStore";
import { HeaderBar, HeaderWrapper, MenuBox, MobileMenuBox, RegisterYourInterestButton } from "./Header.Styles";
import { InnerWrapper } from "Components/InnerWrapper";
import { BuyerLogin } from "Components/BuyerLogin/BuyerLogin";
import { HeaderButton } from "Components/Buttons/HeaderButton";
import { H3, H5 } from "Components/Primitives/TextElements/TextElements";
import { ICommand, RelayCommand } from "Application";
import { head } from "lodash";
import { useSpring } from "framer-motion";
import InstagramLogo from "Assets/InstagramLogoMk2.svg";
import FacebookLogo from "Assets/FacebookLogoMk2.svg";
import PhoneIcon from "Assets/PhoneThumbBlue.svg";
import PhoneIconLight from "Assets/PhoneThumb.svg";

const domainStore = container.resolve(DomainStore);

export const Header: React.FC = observer(() => {
    CoreStoreInstance.CoreOptions.mobileBreakPoint = 600;
    let navigate = useNavigate();
    const accountStore = container.resolve(AccountStore);
    const domainStore = container.resolve(DomainStore);

    const [headerViewModel] = useState(() => new HeaderViewModel());
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    const myBuyerLoginClicked = (e: any) => {
        domainStore.setIsLoginModalOpen(true);
    };

    const myAccountClicked = (e: any) => {
        navigate(AppUrls.Client.BuyerLoginDashboard);
    };

    useEffect(() => {}, [accountStore.IsLoggedIn]);

    const navToRegisterPage: ICommand = new RelayCommand(() => {
        domainStore.setSideBarClosed(!domainStore.isSideBarClosed);
        headerViewModel.setActiveMenuItem("Register");
        navigate(AppUrls.Client.Register);
    });

    const navToHomePage = () => {
        domainStore.setSideBarClosed(true);
        headerViewModel.setActiveMenuItem("Home");
        navigate("/");
    };

    const x = useSpring(0, { stiffness: 400, damping: 40 });

    const MenuItems = (
        <MenuBox
            rowGap={headerViewModel.isDesktop ? "24px" : "0px"}
            isDesktop={headerViewModel.isDesktop}
            min-height="48px"
            flexBox
            flexDirection={headerViewModel.isDesktop ? "row" : "column"}
            p={domainStore.isSideBarClosed ? "0px" : "30px 0px"}
            style={{ textAlign: "center" }}
        >
            <MenuItem text={"Homes"} viewModel={headerViewModel} path={AppUrls.Client.Homes} />
            <MenuItem text={"Vision"} viewModel={headerViewModel} path={AppUrls.Client.OurVision} />
            <MenuItem text={"About"} viewModel={headerViewModel} path={AppUrls.Client.About} />
            <MenuItem text={"Living"} viewModel={headerViewModel} path={AppUrls.Client.Living} />
            <MenuItem
                text={"Buying With Shoreline"}
                viewModel={headerViewModel}
                path={AppUrls.Client.Buying}
                hasSubMenu={true}
                subMenuItems={[
                    { text: "Part Exchange", path: AppUrls.Client.PartExchange },
                    { text: "Assisted Move", path: AppUrls.Client.AssistedMove },
                ]}
            />
            {/* <MenuItem text={"BUYING A NEW HOME"} viewModel={headerViewModel} path={AppUrls.Client.BuyingANewHome} /> */}
            {/* <MenuItem text={"AMENITIES"} viewModel={headerViewModel} path={AppUrls.Client.Amenities} /> */}
            {/* <MenuItem text={"NEWS"} viewModel={headerViewModel} path={AppUrls.Client.News} /> */}
            {/* <MenuItem text={"FOLKELIFE"} viewModel={headerViewModel} path={AppUrls.Client.Folklife} />
            <MenuItem text={"HARBOUR ARM"} viewModel={headerViewModel} path={AppUrls.Client.HarbourArm} /> */}
            {/* <MenuItem text={"CONTACT"} viewModel={headerViewModel} path={AppUrls.Client.Contact} /> */}

            {/* <HeaderButton title="REGISTER YOUR INTEREST" isVisble={true} onClickCallback={myBuyerLoginClicked} /> */}
            {/* <HeaderButton title="LOGIN" isVisble={!accountStore.IsLoggedIn} onClickCallback={myBuyerLoginClicked} />
            <HeaderButton title="MY ACCOUNT" isVisble={accountStore.IsLoggedIn} onClickCallback={myAccountClicked} /> */}
            <RegisterYourInterestButton displayName="BOOK AN APPOINTMENT" command={navToRegisterPage} isDesktop={headerViewModel.isDesktop} />
            <Box showIf={!headerViewModel.isDesktop} columnGap="10px" p="20px 0px" flexBox>
                <img src={InstagramLogo} style={{ cursor: "pointer" }} onClick={() => window.open("https://www.instagram.com/shoreline_folkestone/")} />
                <img src={FacebookLogo} style={{ cursor: "pointer" }} onClick={() => window.open("https://www.facebook.com/shorelinecrescent")} />
            </Box>
            <Box showIf={!headerViewModel.isDesktop} flexBox pt="7px" columnGap="10px">
                <img src={PhoneIconLight} height="10px" />
                <a href="tel:01303 760 940" style={{ textDecoration: "none" }}>
                    <H5 hidden={headerViewModel.isDesktop} color="secondary">
                        01303 760 940
                    </H5>
                </a>
            </Box>
        </MenuBox>
    );

    return (
        <>
            <BuyerLogin isOpen={domainStore.isLoginModalOpen} setIsLoginModalOpen={domainStore.setIsLoginModalOpen} />
            <HeaderWrapper viewModel={headerViewModel} style={{ position: "sticky", top: 0, left: 0, zIndex: 100 }}>
                <InnerWrapper>
                    <HeaderBar viewModel={headerViewModel}>
                        <Box flexBox style={{ height: "100%" }} columnGap={headerViewModel.isMobile ? "10px" : "25px"}>
                            <Box style={{ cursor: "pointer" }} onClick={navToHomePage}>
                                <img style={{ height: "100%" }} src={headerViewModel.isMobile ? logoMobile : logo} alt="Go to home" />
                            </Box>
                            <Box flexBox style={{ alignItems: "end", cursor: "pointer" }} columnGap="20px" onClick={navToHomePage}>
                                <H3 color="primary" style={{ alignSelf: "center", fontSize: "20px" }}>
                                    Shoreline Crescent
                                </H3>
                                <Box showIf={headerViewModel.isDesktop} flexBox style={{ alignSelf: "center", alignItems: "end" }} columnGap="10px">
                                    <H5 hidden={headerViewModel.isMobile || headerViewModel.isTablet} color="primary" pt="7px" pr="15px">
                                        FOLKESTONE
                                    </H5>

                                    <img src={PhoneIcon} height="10px" />
                                    <a href="tel:01303 760 940" style={{ textDecoration: "none" }}>
                                        <H5 hidden={headerViewModel.isMobile} color="primary" pt="7px">
                                            01303 760 940
                                        </H5>
                                    </a>
                                </Box>
                            </Box>
                        </Box>

                        <Box showIf={headerViewModel.isDesktop}>{MenuItems}</Box>
                        <Box showIf={!headerViewModel.isDesktop}>
                            <HamburgerButton x={x as any} width={320} />
                        </Box>
                        {/* <Box className="fakesidebar" showIf={headerViewModel.isMobile}>
                            <Sidebar width={300} color={"white"} left={true}>
                                <Box>Sidebar</Box>
                            </Sidebar>
                        </Box> */}
                    </HeaderBar>
                    <MobileMenuBox width="100%" height="100%" showIf={!headerViewModel.isDesktop && !domainStore.isSideBarClosed}>
                        {!domainStore.isSideBarClosed && !headerViewModel.isDesktop && MenuItems}
                    </MobileMenuBox>
                </InnerWrapper>
            </HeaderWrapper>
            {/* <HorizontalGradLineDarkLightDark widthOfLine="100%" /> */}
        </>
    );
});
