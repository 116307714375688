import { makeObservable, observable, runInAction } from "mobx";
import { ViewModelBase } from "@shoothill/core";
import { APIClient, IKeyState } from "Application";
import { AssistedMoveModel, AssistedMoveModelValidator } from "./AssistedMoveModel";

export class AssistedMoveViewModel extends ViewModelBase<AssistedMoveModel> {
    public apiClient = new APIClient();

    constructor() {
        super(new AssistedMoveModel());
        this.setValidator(new AssistedMoveModelValidator());
        makeObservable(this, {});
    }
}
