import * as React from "react";
import { SVGProps } from "react";

export const PersonSVG = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={28} height={28} {...props}>
        <g data-name="Group 5169">
            <path data-name="Rectangle 1752" fill="#7f7f7f" d="M0 0h28v28H0z" />
            <g data-name="Group 5168">
                <g data-name="Group 5171" fill="#fff">
                    <path
                        data-name="Path 5653"
                        d="M14.083 13.963a4.037 4.037 0 0 0 2.967-1.224 4.037 4.037 0 0 0 1.224-2.967 4.037 4.037 0 0 0-1.224-2.967 3.989 3.989 0 0 0-2.967-1.242 4.037 4.037 0 0 0-2.967 1.224 4.037 4.037 0 0 0-1.224 2.967 4.037 4.037 0 0 0 1.224 2.967 4.053 4.053 0 0 0 2.967 1.242Zm0 0"
                    />
                    <path
                        data-name="Path 5654"
                        d="M21.727 18.387a18.123 18.123 0 0 0-.151-1.19 11.268 11.268 0 0 0-.285-1.19 5.489 5.489 0 0 0-.469-1.073 4.114 4.114 0 0 0-.671-.888 2.8 2.8 0 0 0-.939-.587 3.182 3.182 0 0 0-1.224-.219 1.382 1.382 0 0 0-.453.235c-.235.151-.52.335-.821.52a4.814 4.814 0 0 1-1.173.52 4.91 4.91 0 0 1-1.458.235 4.91 4.91 0 0 1-1.458-.235 5.19 5.19 0 0 1-1.173-.52c-.3-.184-.57-.369-.821-.52a1.382 1.382 0 0 0-.453-.235 3.241 3.241 0 0 0-1.224.218 2.8 2.8 0 0 0-.939.587 3.638 3.638 0 0 0-.671.888 5.489 5.489 0 0 0-.469 1.073 7.742 7.742 0 0 0-.287 1.19 11.557 11.557 0 0 0-.151 1.19c-.034.369-.05.738-.05 1.123a2.753 2.753 0 0 0 .8 2.079 2.963 2.963 0 0 0 2.112.754h9.555a2.909 2.909 0 0 0 2.112-.754 2.7 2.7 0 0 0 .8-2.079c0-.386-.017-.754-.05-1.123Zm0 0"
                    />
                </g>
            </g>
        </g>
    </svg>
);
