import { makeObservable, observable, runInAction } from "mobx";
import { ViewModelBase } from "@shoothill/core";
import { APIClient, ICommand, IKeyState, RelayCommand } from "Application";
import { LivingModel, LivingModelValidator } from "./LivingModel";
import { useNavigate } from "react-router";

export class LivingViewModel extends ViewModelBase<LivingModel> {
    public apiClient = new APIClient();

    public navigate = useNavigate();

    public navTo: ICommand = new RelayCommand((value: string) => {
        if (value === "https://folke.life/") {
            window.open("https://folke.life/");
        } else {
            this.navigate(value);
        }
    });

    constructor() {
        super(new LivingModel());
        this.setValidator(new LivingModelValidator());
        makeObservable(this, {});
    }
}
