import styled from "@emotion/styled";
import { Box, Button } from "Application";
import { H3, P } from "Components/Primitives/TextElements/TextElements";
import { motion } from "framer-motion";

export const CookieWrapper = styled(motion.div)`
    min-height: 230px;
    max-width: 650px;
    background-color: #c6b59f;
    position: absolute;
    bottom: -200px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    box-shadow: 0px 0px 6px #00000029;
    padding: 30px;
    z-index: 100;
`;

export const CookiePolicyTitle = styled(H3)`
    font-family: "roboto";
    padding-bottom: 26px;
    text-align: center;
    color: white;
`;

export const CookiePolicyText = styled(P)`
    font-family: "roboto";
    font-size: 14px;
    color: white;
`;

export const CookiePolicyButton = styled(Button)`
    background-color: #1f2747;
    color: #ecdfc9;
`;

export const linkStyle = {
    color: "#17A2FF",
    textDecoration: "underline",
    cursor: "pointer",
};

export const PolicyPara = styled(P)`
    padding-bottom: 20px;
    font-size: 14px;
    color: white;
`;

export const ScrollBox = styled(Box)`
    overflow-y: scroll;
    max-height: 60vh;
    margin-bottom: 20px;
    padding: 15px;
    position: relative;
    z-index: 1000001;

    ::-webkit-scrollbar {
        width: 5px;
    }

    ::-webkit-scrollbar-thumb {
        background: transparent linear-gradient(180deg, #7f5433 0%, #eda765 50%, #7d5232 100%) 0% 0% no-repeat padding-box;
    }
`;

export const CloseButton = styled(Button)`
    position: absolute;
    left: 0;
    top: 10px;
    background: transparent;
    color: white;
    border: 0;
    font-size: 24px;
    outline: none;
`;
