import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { Box, EditSelect, ICommand, RelayCommand, theme } from "Application";
import { CompareModalViewModel } from "./CompareModalViewModel";
import { PropertyListItemViewModel } from "Views/NewHomes/Subviews/PropertyListItemViewModel";
import { NewHomesViewModel } from "Views/NewHomes/NewHomesViewModel";
// import { Modal } from "@fluentui/react";

import Modal from "react-responsive-modal";

import X from "Assets/x.svg";

// Import Swiper React components
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Navigation, Pagination } from "swiper";
import styled from "@emotion/styled";
import { H4, P } from "Components/Primitives/TextElements/TextElements";
import { HorizontalGradLineDarkLightDark } from "Components/HorizontalGradLineDarkLightDark";
import { ShorelineButton } from "Components/ShorelineButton";
import { backgroundColor, height, zIndex } from "styled-system";
import { ListBox } from "../SelectedPropertyStyles";
import { WhiteBox, ModalBanner, SliderWrapper, OuterBox, BoxWrapper, ScrollingDiv, GrayBox } from "./CompareModalMobile.styles";

export interface ICompareModal {
    open: boolean;
    viewModelFromParent: PropertyListItemViewModel;
    propertiesViewModel: NewHomesViewModel;
    onClose: () => void;
}

export const CompareModalMobile: React.FC<ICompareModal> = observer(({ open, onClose, viewModelFromParent, propertiesViewModel }) => {
    const [viewModel] = useState(() => new CompareModalViewModel(propertiesViewModel));
    const [choosePropertyView, isChoosePropertyView] = useState(true);
    const doSomething: ICommand = new RelayCommand((value: string) => {});

    const ToggleChoosePropertyPanel: ICommand = new RelayCommand((value: string) => {
        isChoosePropertyView(!choosePropertyView);
    });

    const backToChosenProperty: ICommand = new RelayCommand(() => {
        onClose();
    });

    const ChoosePropertyPanel = (
        <>
            <WhiteBox>
                <EditSelect
                    styles={{
                        // Dropdown menu z-index fix
                        menuPortal: (provided: any) => ({ ...provided, zIndex: 1000001 }),
                    }}
                    command={viewModel.updatePropertyTypeCommand}
                    placeholder="Select property type"
                    options={viewModel.getPropertyTypes(propertiesViewModel)}
                    value={() => viewModel.getValue("propertyType")}
                ></EditSelect>

                {viewModel.model.propertyType !== "" && (
                    <EditSelect
                        styles={{ menuPortal: (base: any) => ({ ...base, zIndex: 99999 }), option: (base: any) => ({ ...base, zIndex: 99999 }) }}
                        command={viewModel.updateChosenProperty}
                        placeholder="Select plot"
                        options={viewModel.getProperties(propertiesViewModel)}
                        value={() => viewModel.getSelectedPropertyId}
                    ></EditSelect>
                )}
            </WhiteBox>
            <Box style={{ backgroundColor: "#f3f2ee" }}>
                <HorizontalGradLineDarkLightDark widthOfLine="100%" heightOfLine="2px" />
                <Box p="20px 10px 20px 10px">
                    {/* {viewModel.getValue("selectedPropertyId") != "" && ( */}
                    <ShorelineButton
                        isDisabled={viewModel.getValue("selectedPropertyId") == ""}
                        command={ToggleChoosePropertyPanel}
                        style={{ background: viewModel.getValue("selectedPropertyId") == "" ? "grey" : "" }}
                        displayName="ADD"
                    ></ShorelineButton>
                    {/* )} */}
                </Box>
            </Box>
        </>
    );

    return (
        <>
            <Modal
                open={open}
                onClose={() => onClose}
                styles={{
                    modal: { width: "90%", backgroundColor: "transparent", padding: "0px", overflowY: "hidden" },
                    root: { backgroundColor: "rgba(0, 0, 0, 0.75)" },
                }}
                showCloseIcon={false}
            >
                <Box>
                    <ModalBanner pl="20px" pr="20px" mb="20px">
                        <Box pr="30px">
                            <img src={X} onClick={() => onClose()}></img>
                        </Box>
                        <H4 color="white">COMPARE PROPERTIES</H4>
                    </ModalBanner>
                    <SliderWrapper>
                        <Swiper
                            pagination={{
                                el: ".swiper-custom-pagination",
                            }}
                            modules={[Pagination]}
                            className="mySwiper"
                            preventClicks={true}
                            preventClicksPropagation={true}
                        >
                            <SwiperSlide>
                                <ScrollingDiv>
                                    <WhiteBox>
                                        <P>{viewModelFromParent.getValue("addressLine1")}</P>
                                    </WhiteBox>
                                    <HorizontalGradLineDarkLightDark widthOfLine="100%" heightOfLine="2px" />
                                    <GrayBox>
                                        <P>
                                            {new Intl.NumberFormat("en-GB", { style: "currency", currency: "GBP", maximumFractionDigits: 0 }).format(
                                                viewModelFromParent.getValue("basePurchasePrice"),
                                            )}
                                        </P>
                                    </GrayBox>
                                    <HorizontalGradLineDarkLightDark widthOfLine="100%" heightOfLine="2px" />
                                    <WhiteBox>
                                        <P> {viewModelFromParent.getValue("numberOfBeds")} bedrooms</P>
                                    </WhiteBox>
                                    <HorizontalGradLineDarkLightDark widthOfLine="100%" heightOfLine="2px" />
                                    <GrayBox>
                                        <P> {viewModelFromParent.getValue("numberOfBathrooms")} bathrooms</P>
                                    </GrayBox>

                                    <HorizontalGradLineDarkLightDark widthOfLine="100%" heightOfLine="2px" />
                                </ScrollingDiv>

                                <Box mb="20px" style={{ backgroundColor: "#f3f2ee" }}>
                                    <HorizontalGradLineDarkLightDark widthOfLine="100%" heightOfLine="2px" />
                                    <Box p="15px 10px 15px 10px">
                                        <ShorelineButton command={backToChosenProperty} displayName="VIEW"></ShorelineButton>
                                    </Box>
                                </Box>

                                {choosePropertyView ? (
                                    <>{ChoosePropertyPanel}</>
                                ) : (
                                    <>
                                        <ScrollingDiv>
                                            <WhiteBox>
                                                <P>{viewModel.getValue("addressLine1")}</P>
                                            </WhiteBox>
                                            <HorizontalGradLineDarkLightDark widthOfLine="100%" heightOfLine="2px" />
                                            <GrayBox>
                                                <P>
                                                    {new Intl.NumberFormat("en-GB", { style: "currency", currency: "GBP", maximumFractionDigits: 0 }).format(
                                                        viewModel.getValue("basePurchasePrice"),
                                                    )}
                                                </P>
                                            </GrayBox>
                                            <HorizontalGradLineDarkLightDark widthOfLine="100%" heightOfLine="2px" />
                                            <WhiteBox>
                                                <P> {viewModel.getValue("numberOfBeds")} bedrooms</P>
                                            </WhiteBox>
                                            <HorizontalGradLineDarkLightDark widthOfLine="100%" heightOfLine="2px" />
                                            <GrayBox>
                                                <P> {viewModel.getValue("numberOfBathrooms")} bathrooms</P>
                                            </GrayBox>
                                            <HorizontalGradLineDarkLightDark widthOfLine="100%" heightOfLine="2px" />
                                        </ScrollingDiv>

                                        <Box style={{ backgroundColor: "#f3f2ee" }}>
                                            <HorizontalGradLineDarkLightDark widthOfLine="100%" heightOfLine="2px" />
                                            <Box grid mc="1fr 1fr" p="15px 10px 15px 10px">
                                                <ShorelineButton command={doSomething} displayName="VIEW"></ShorelineButton>
                                                <ShorelineButton className="grey-button" command={ToggleChoosePropertyPanel} displayName="CHANGE"></ShorelineButton>
                                            </Box>
                                        </Box>
                                    </>
                                )}
                            </SwiperSlide>

                            <SwiperSlide>
                                <ScrollingDiv p="10px">
                                    <P pb="15px" style={{ fontWeight: 600 }}>
                                        Description:
                                    </P>
                                    <P
                                        dangerouslySetInnerHTML={{
                                            __html: viewModelFromParent.getValue("description"),
                                        }}
                                    >
                                        {/* {viewModelFromParent.getValue("description")} */}
                                    </P>
                                </ScrollingDiv>

                                <Box mb="20px" style={{ backgroundColor: "#f3f2ee" }}>
                                    <HorizontalGradLineDarkLightDark widthOfLine="100%" heightOfLine="2px" />
                                    <Box p="15px 10px 15px 10px">
                                        <ShorelineButton command={backToChosenProperty} displayName="VIEW"></ShorelineButton>
                                    </Box>
                                </Box>

                                {choosePropertyView ? (
                                    <>{ChoosePropertyPanel}</>
                                ) : (
                                    <>
                                        <ScrollingDiv p="10px">
                                            <P pb="15px" style={{ fontWeight: 600 }}>
                                                Description:
                                            </P>
                                            <P
                                                dangerouslySetInnerHTML={{
                                                    __html: viewModel.getValue("description"),
                                                }}
                                            >
                                                {/* {viewModel.getValue("description")} */}
                                            </P>
                                        </ScrollingDiv>

                                        <Box style={{ backgroundColor: "#f3f2ee" }}>
                                            <HorizontalGradLineDarkLightDark widthOfLine="100%" heightOfLine="2px" />
                                            <Box grid mc="1fr 1fr" p="15px 10px 15px 10px">
                                                <ShorelineButton command={doSomething} displayName="VIEW"></ShorelineButton>
                                                <ShorelineButton className="grey-button" command={ToggleChoosePropertyPanel} displayName="CHANGE"></ShorelineButton>
                                            </Box>
                                        </Box>
                                    </>
                                )}
                            </SwiperSlide>

                            <SwiperSlide>
                                <ScrollingDiv p="10px">
                                    <P pb="15px" style={{ fontWeight: 600 }}>
                                        Feature list:
                                    </P>
                                    <ListBox
                                        dangerouslySetInnerHTML={{
                                            __html: viewModelFromParent.getValue<string>("features"),
                                        }}
                                    />
                                </ScrollingDiv>

                                <Box mb="20px" style={{ backgroundColor: "#f3f2ee" }}>
                                    <HorizontalGradLineDarkLightDark widthOfLine="100%" heightOfLine="2px" />
                                    <Box p="15px 10px 15px 10px">
                                        <ShorelineButton command={backToChosenProperty} displayName="VIEW"></ShorelineButton>
                                    </Box>
                                </Box>

                                {choosePropertyView ? (
                                    <>{ChoosePropertyPanel}</>
                                ) : (
                                    <>
                                        <ScrollingDiv p="10px">
                                            <P pb="15px" style={{ fontWeight: 600 }}>
                                                Feature list:
                                            </P>
                                            <ListBox
                                                dangerouslySetInnerHTML={{
                                                    __html: viewModel.getValue<string>("features"),
                                                }}
                                            />
                                        </ScrollingDiv>
                                        <Box style={{ backgroundColor: "#f3f2ee" }}>
                                            <HorizontalGradLineDarkLightDark widthOfLine="100%" heightOfLine="2px" />
                                            <Box grid mc="1fr 1fr" p="15px 10px 15px 10px">
                                                <ShorelineButton command={doSomething} displayName="VIEW"></ShorelineButton>
                                                <ShorelineButton className="grey-button" command={ToggleChoosePropertyPanel} displayName="CHANGE"></ShorelineButton>
                                            </Box>
                                        </Box>
                                    </>
                                )}
                            </SwiperSlide>

                            <SwiperSlide>
                                <ScrollingDiv p="10px">
                                    <>
                                        {viewModelFromParent.model.floorplans.map((floorplanMap) => {
                                            return <img src={floorplanMap.imageUrl} width="100%" />;
                                        })}
                                    </>
                                </ScrollingDiv>

                                <Box mb="20px" style={{ backgroundColor: "#f3f2ee" }}>
                                    <HorizontalGradLineDarkLightDark widthOfLine="100%" heightOfLine="2px" />
                                    <Box p="15px 10px 15px 10px">
                                        <ShorelineButton command={backToChosenProperty} displayName="VIEW"></ShorelineButton>
                                    </Box>
                                </Box>

                                {choosePropertyView ? (
                                    <>{ChoosePropertyPanel}</>
                                ) : (
                                    <>
                                        <ScrollingDiv p="10px">
                                            <>
                                                {viewModel.model.floorplans.map((floorplanMap) => {
                                                    return <img src={floorplanMap.imageUrl} width="100%" />;
                                                })}
                                            </>
                                        </ScrollingDiv>
                                        <Box style={{ backgroundColor: "#f3f2ee" }}>
                                            <HorizontalGradLineDarkLightDark widthOfLine="100%" heightOfLine="2px" />
                                            <Box grid mc="1fr 1fr" p="15px 10px 15px 10px">
                                                <ShorelineButton command={doSomething} displayName="VIEW"></ShorelineButton>
                                                <ShorelineButton className="grey-button" command={ToggleChoosePropertyPanel} displayName="CHANGE"></ShorelineButton>
                                            </Box>
                                        </Box>
                                    </>
                                )}
                            </SwiperSlide>

                            {/* <SwiperSlide>Slide 2</SwiperSlide>
                            <SwiperSlide>Slide 3</SwiperSlide>
                            <SwiperSlide>Slide 4</SwiperSlide> */}
                        </Swiper>
                        <div className="swiper-custom-pagination" style={{ textAlign: "center" }} />
                    </SliderWrapper>
                </Box>
            </Modal>
        </>
    );
});
