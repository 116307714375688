import { Logger } from "index";
import { EndpointWithoutRequest, Http } from "Application";
import { PropertyWithRelatedResponse } from "./PropertyResponseModel";

/* import {  } */

import { AppUrls } from "AppUrls";

class GETPropertiesByDevelopmentIdEndpointResponse {
    public propertiesWithRelated: PropertyWithRelatedResponse[] = [];
}
export class GETPropertiesByDevelopmentIdEndpoint extends EndpointWithoutRequest<GETPropertiesByDevelopmentIdEndpointResponse> {
    constructor(id: string) {
        super();
        this.verb(Http.Get);
        this.path(AppUrls.Server.Properties.GetPropertyByDevelopmentId.replace("{id}", id));
    }

    public async HandleResponseAsync(response: GETPropertiesByDevelopmentIdEndpointResponse): Promise<PropertyWithRelatedResponse[]> {
        Logger.logInformation("Response", response);
        return response.propertiesWithRelated;
    }
}
