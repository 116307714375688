import styled from "@emotion/styled";
import { Box } from "Application";

interface IResponsiveProps {
    isMobile?: boolean;
    isTablet?: boolean;
}

export const ConfirmPasswordWrapper = styled(Box)<IResponsiveProps>`
    display: grid;
    grid-template-columns: ${(props) => (props.isMobile ? "1fr" : props.isTablet ? "50% 50%" : "30% 70%")};
`;
export const FormOuterWrapper = styled(Box)`
    display: flex;
    justify-content: center;
    text-align: center;
    box-shadow: 3px 0px 6px #00000029;
    z-index: 10;
    background-color: #e3e3de;
    padding-top: 20px;
    padding-bottom: 20px;
    label {
        font-weight: 600;
    }

    .toolTip {
        background: white;
        color: black;
        max-width: 190px;
        font-size: 9px;
    }
`;
