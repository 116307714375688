import { brochureTypes } from "Views/BrochureAddendums/DownloadViewModel";

export const getBrochureType = (brochureType: brochureTypes) => {
    switch (brochureType) {
        case 0:
            return "Host Brochure Downloaded";
        case 1:
            return "Duplex Brochure Downloaded";
        case 2:
            return "East Apartment Brochure Downloaded";
        case 3:
            return "Penthouse Brochure Downloaded";
        case 4:
            return "Beach House Brochure Downloaded"; // Was previously Townhouse
        case 5:
            return "West Apartment Brochure Downloaded";
        default:
            return "Invalid brochure Brochure Downloaded";
    }
};

// export enum brochureTypes {
//     "FullBrochure" = 0,
//     "Duplex" = 1,
//     "EastApartment" = 2,
//     "Penthouse" = 3,
//     "Townhouse" = 4,
//     "WestApartment" = 5,
// }
