import { ModelBase } from "@shoothill/core";
import { action, makeObservable, observable } from "mobx";
import { Validator } from "Application/Validation";
import { PropertyRoomModel } from "./PropertyRoomModel";
import { PropertyMediaModel } from "./PropertyMediaModel";
import { PropertyWithRelatedResponse } from "../Endpoints/PropertyResponseModel";

export class PropertyListItemModel extends ModelBase<PropertyListItemModel> {
    public id: string = "";
    public developmentId: string = "";
    public developmentName: string = "";
    public plotNumber: number = 0;
    public addressLine1: string = "";
    public addressLine2: string = "";
    public postCode: string = "";
    public description: string = "";
    public houseTypeId: string = "";
    public basePurchasePrice: number = 0;
    public numberOfBeds: string = "";
    public actualStatus: number = 0;
    public houseType: string = "";
    public numberOfBathrooms: string = "";
    public numberOfEnsuites: string = "";
    public parkingType: string = "";
    public sqft: string = "";
    public epc: string = "";
    public councilTaxBand: string = "";
    public phase: number | null = null;
    public floor: string = "";
    public block: string = "";
    public features: string = "";
    public buildingType: string = "";

    public floorplans = observable<PropertyMediaModel>([]);
    public images = observable<PropertyMediaModel>([]);
    public propertyRooms = observable<PropertyRoomModel>([]);

    constructor() {
        super();
        makeObservable(this, {
            id: observable,
            developmentId: observable,
            developmentName: observable,
            plotNumber: observable,
            addressLine1: observable,
            addressLine2: observable,
            postCode: observable,
            description: observable,
            houseTypeId: observable,
            basePurchasePrice: observable,
            numberOfBeds: observable,
            actualStatus: observable,
            houseType: observable,
            numberOfBathrooms: observable,
            numberOfEnsuites: observable,
            parkingType: observable,
            sqft: observable,
            epc: observable,
            councilTaxBand: observable,
            floor: observable,
            block: observable,
            features: observable,
            fromPropertyWithRelated: action,
        });
    }

    public featureList: string[] = ["item 1", "item 2"];

    public fromPropertyWithRelated(property: PropertyWithRelatedResponse) {
        this.id = property.id;
        this.developmentId = property.developmentId;
        this.developmentName = property.developmentName;
        this.plotNumber = property.plotNumber;
        this.addressLine1 = property.addressLine1;
        this.addressLine2 = property.addressLine2;
        this.postCode = property.postCode;
        this.description = property.description;
        this.houseTypeId = property.houseTypeId;
        this.basePurchasePrice = property.basePurchasePrice;
        this.numberOfBeds = property.numberOfBeds;
        this.actualStatus = property.actualStatus;
        this.houseType = property.houseType;
        this.numberOfBathrooms = property.numberOfBathrooms;
        this.numberOfEnsuites = property.numberOfEnsuites;
        this.parkingType = property.parkingType;
        this.sqft = property.sqft;
        this.epc = property.epc;
        this.councilTaxBand = property.councilTaxBand;
        this.floor = property.floor;
        this.block = property.block;
        this.features = property.features;
        this.buildingType = property.buildingType;

        this.images.clear();
        if (property.images) {
            for (const image of property.images) {
                let imgModel = new PropertyMediaModel();
                imgModel.id = image.id;
                imgModel.imageUrl = image.imageUrl;
                imgModel.originalImageUrl = image.originalImageUrl;
                imgModel.thumbnailImageUrl = image.thumbnailImageUrl;
                imgModel.ordinal = image.ordinal;
                imgModel.houseTypeId = image.houseTypeId;
                imgModel.developmentId = image.developmentId;

                this.images.push(imgModel);
            }
        }

        this.floorplans.clear();
        if (property.floorplans) {
            for (const floorplan of property.floorplans) {
                let floorplanModel = new PropertyMediaModel();
                floorplanModel.id = floorplan.id;
                floorplanModel.imageUrl = floorplan.imageUrl;
                floorplanModel.originalImageUrl = floorplan.originalImageUrl;
                floorplanModel.thumbnailImageUrl = floorplan.thumbnailImageUrl;
                floorplanModel.ordinal = floorplan.ordinal;
                floorplanModel.houseTypeId = floorplan.houseTypeId;
                floorplanModel.developmentId = floorplan.developmentId;

                this.floorplans.push(floorplanModel);
            }
        }

        this.propertyRooms.clear();
        if (property.propertyRooms) {
            for (const room of property.propertyRooms) {
                let roomModel = new PropertyRoomModel();
                roomModel.id = room.id;
                roomModel.sqft = room.sqft;
                roomModel.name = room.name;
                roomModel.houseTypeId = room.houseTypeId;
                this.propertyRooms.push(roomModel);
            }
        }
    }
}

export class PropertyListItemModelValidator extends Validator<PropertyListItemModel> {
    constructor() {
        super();
        //this.ruleFor("id").notEmpty();
    }
}
