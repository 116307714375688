import { RegisterUserModel, RegisterUserValidator } from "Views/Login/SignUp/RegisterUserModel";
import { FieldType, ViewModelBase } from "@shoothill/core";
import { container } from "tsyringe";
import { AccountStore } from "Stores/Domain";
import { RelayCommandAsync, ICommandAsync } from "../../../Application/Commands";
import { RegisterEndpointPOST } from "./Endpoints/RegisterEndpointPOST";
import { APIClient, IKeyState } from "../../../Application";
import { makeObservable, observable } from "mobx";
import { ConfirmEmailEndpoint } from "./Endpoints/ConfirmEmailEndpoint";

export class RegisterUserViewModel extends ViewModelBase<RegisterUserModel> {
    public apiClient = new APIClient();
    public accountStore = container.resolve(AccountStore);
    public showSuccess: boolean = false;
    public showConfirmEmailSuccess: boolean = false;
    public showError: boolean = false;

    constructor() {
        super(new RegisterUserModel());
        this.setValidator(new RegisterUserValidator());
        makeObservable(this, {
            showSuccess: observable,
        });
    }

    public updateFirstNameCommand: ICommandAsync = new RelayCommandAsync(async (value: string, keyState: IKeyState) => {
        await this.updateField("firstName", value, keyState);
    });
    public updateLastNameCommand: ICommandAsync = new RelayCommandAsync(async (value: string, keyState: IKeyState) => {
        await this.updateField("lastName", value, keyState);
    });
    public updateEmailAddressCommand: ICommandAsync = new RelayCommandAsync(async (value: string, keyState: IKeyState) => {
        await this.updateField("emailAddress", value, keyState);
    });
    public updatePhoneNumber: ICommandAsync = new RelayCommandAsync(async (value: string, keyState: IKeyState) => {
        await this.updateField("phoneNumber", value, keyState);
    });
    public updatePasswordCommand: ICommandAsync = new RelayCommandAsync(async (value: string, keyState: IKeyState) => {
        await this.updateField("password", value, keyState);
    });
    public updateConfirmPasswordCommand: ICommandAsync = new RelayCommandAsync(async (value: string, keyState: IKeyState) => {
        await this.updateField("confirmPassword", value, keyState);
    });
    public updateTermsCommand: ICommandAsync = new RelayCommandAsync(async (value: string) => {
        await this.updateField("terms", value);
    });

    private async updateField(fieldName: keyof FieldType<RegisterUserModel>, value: any, keyState?: IKeyState) {
        this.setValue(fieldName, value);
    }

    public registerCommand: ICommandAsync = new RelayCommandAsync(async () => {
        if (this.isModelValid()) {
            const registerEndpoint = new RegisterEndpointPOST();
            await this.apiClient.sendAsync(registerEndpoint, this.model);
            if (this.apiClient.IsRequestSuccessful) {
                this.history.replace("/account/register/success");
            } else {
                this.history.replace("/account/register/error");
            }
        }
    });

    public confirmEmailCommand: ICommandAsync = new RelayCommandAsync(async (token: string) => {
        const confirmEmailEndpoint = new ConfirmEmailEndpoint(token);
        await this.apiClient.sendAsync(confirmEmailEndpoint, this.model);
        if (this.apiClient.IsRequestSuccessful) {
            this.showConfirmEmailSuccess = true;
            this.history.push("/account/confirmemail/register/success");
        } else {
            this.showConfirmEmailSuccess = false;
            this.showError = true;
            this.history.push("/account/confirmemail/register/error");
        }
    });
}
