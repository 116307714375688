import * as React from "react";

import styled from "@emotion/styled";
import { isEmptyOrWhitespace, isNullOrUndefined, KeyValuePair } from "@shoothill/core";
import clsx from "clsx";
import { uniqueId } from "lodash-es";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { ICommand, theme } from "../../../Application";
import { Label } from "../Labels/Label";

interface Props {
    horizontal: boolean;
}
const RadioContainer = styled.div<Props>`
    display: ${(props) => (props.horizontal ? "flex" : "block")};
    align-items: flex-end;
    .radio {
        margin-right: ${(props) => (props.horizontal ? "30px" : 0)};
        margin-top: 0.5rem;
        margin-bottom: ${(props) => (props.horizontal ? "0" : "0.5rem")};
        input[type="radio"] {
            position: absolute;
            opacity: 0;
            white-space: nowrap;
            + .radio-label {
                &:before {
                    content: "";
                    border-radius: 100%;
                    border: 1px solid ${theme.palette.field.light};
                    display: inline-block;
                    width: 2em;
                    height: 2em;
                    position: relative;
                    top: -0.2em;
                    margin-right: 1em;
                    vertical-align: top;
                    cursor: pointer;
                    text-align: center;
                    transition: all 500ms ease-out;
                    box-shadow: inset 0 0 0 1px ${theme.palette.field.light};
                }
            }
            &:checked {
                + .radio-label {
                    &:before {
                        background-color: ${theme.palette.secondary.main};
                        box-shadow: inset 0 0 0 3px white;
                    }
                }
            }
            &:hover {
                + .radio-label {
                    &:before {
                        outline: none;
                        border: 1px solid black;
                    }
                }
            }
            &:focus {
                + .radio-label {
                    &:before {
                        outline: none;
                        border-color: ${theme.palette.secondary.main};
                    }
                }
            }
            &:disabled {
                + .radio-label {
                    &:before {
                        box-shadow: inset 0 0 0 4px ${theme.palette.field.light};
                        border-color: darken(${theme.palette.field.light}, 25%);
                        background: darken(${theme.palette.field.light}, 25%);
                    }
                }
            }
            + .radio-label {
                &:empty {
                    &:before {
                        margin-right: 0;
                    }
                }
            }
        }
    }
`;

// #region View Props
interface IProps {
    className?: string;
    displayName?: string;
    command: ICommand;
    options: KeyValuePair[];
    value: () => string;
    horizontal?: boolean;
    validationMessage?: () => string;
    ref?: React.Ref<HTMLInputElement>;
    cy?: string;
}
// #endregion View Props

export const RadioButton: React.FC<IProps> = observer((props) => {
    let nameId: string = uniqueId("radio-");

    // #region Public Interface
    useEffect(() => {}, []);

    // #region Code Behind

    const getClassName = (): string => {
        return clsx({
            radiobutton: true,
            [`radiobutton-${props.className}`]: !isEmptyOrWhitespace(props.className),
        });
    };

    const isDisabled = (): boolean => {
        return isNullOrUndefined(props.command.canExecute) ? false : !props.command.canExecute;
    };

    const isChecked = (key: string): boolean => {
        return key === props.value();
    };

    const onChange = (key: string): void => {
        props.command.execute(key);
    };
    const getDataCY = (): string => {
        let regex = /[^a-z]/gi;
        let result = (props.displayName || "").replace(regex, "");
        return props.cy || result;
    };
    // #endregion Code Behind

    return (
        <RadioContainer horizontal={props.horizontal || false}>
            {props.options.map((option: KeyValuePair) => {
                const htmlForId = `radio-${option.key}`;
                return (
                    <div className={"radio"} key={htmlForId}>
                        <input
                            ref={props.ref}
                            name={nameId}
                            checked={isChecked(option.key)}
                            disabled={isDisabled()}
                            id={htmlForId}
                            onChange={() => onChange(option.key)}
                            type="radio"
                            data-cy={getDataCY()}
                        />
                        <Label fontStyle={"radioButtonLabel"} htmlFor={htmlForId} style={{ display: "inline", whiteSpace: "nowrap" }} className={"radio-label"}>
                            {option.text}
                        </Label>
                    </div>
                );
            })}
        </RadioContainer>
    );
    // #endregion Public Interface
});
RadioButton.defaultProps = {
    horizontal: true,
};
