import { Logger } from "index";
import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import _ from "lodash";
import { ContactModel } from "Views/Contact/ContactModel";
import { brochureTypes } from "Views/BrochureAddendums/DownloadViewModel";

class DownloadBrochureRequest {
    public companyId: string = "";
    public title: string = "";
    public firstName: string = "";
    public middleName: string = "";
    public surname: string = "";
    public addressLine1: string = "";
    public addressLine2: string = "";
    public addressLine3: string = "";
    public county: string = "";
    public city: string = "";
    public postcode: string = "";
    public primaryContactNumber: string = "";
    public secondaryContactNumber: string = "";
    public contactEmail: string = "";
    public followUpDate: string = "";
    public developmentName: string = "";
    public plotNumber: number = -1;
    public numberOfBeds: number | null = null;
    public notes: string = "";
    public acceptsMarketing: boolean | null = null;
    public helpToBuy: boolean | null = null;
    public budgetFrom: string = "";
    public budgetTo: string = "";
    public hbpResponse: boolean = false;

    public brochureType: brochureTypes = brochureTypes.FullBrochure;
}

class DownloadBrochureResponse {}

export class DownloadBrochureEndpoint extends Endpoint<DownloadBrochureRequest, DownloadBrochureResponse> {
    constructor() {
        super();
        this.verb(Http.Post);
        this.path("/api/downloadbrochure/downloadbrochure");
        this.responseIsBlob(true);
    }

    public async HandleRequestAsync(model: ContactModel): Promise<any> {
        let request = new DownloadBrochureRequest();
        request.firstName = model.firstName;
        request.surname = model.surname;
        request.addressLine1 = model.addressLine1;
        request.addressLine2 = model.addressLine2;
        request.city = model.city;
        request.postcode = model.postcode;
        request.contactEmail = model.contactEmail;
        request.primaryContactNumber = model.primaryContactNumber;

        let notesWithContactPrefs: string = "Preferred contact method: ";

        if (model.preferredContactMethodEmail === true) {
            notesWithContactPrefs += "Email - Yes, ";
        } else if (model.preferredContactMethodEmail === false) {
            notesWithContactPrefs += "Email - No, ";
        }

        if (model.preferredContactMethodPhone === true) {
            notesWithContactPrefs += "Phone - Yes.";
        } else if (model.preferredContactMethodPhone === false) {
            notesWithContactPrefs += "Phone - No.";
        }

        request.notes = model.notes + " | " + notesWithContactPrefs;

        request.acceptsMarketing = model.acceptsMarketing;
        //request.agreePrivacyPolicy = model.agreePrivacyPolicy;
        request.developmentName = model.developmentName;
        request.plotNumber = model.plotNumber;
        request.companyId = model.companyId;
        request.budgetFrom = model.budgetFrom;
        request.budgetTo = model.budgetTo;

        request.brochureType = model.brochureType;

        return request;
    }

    public async HandleResponseAsync(response: any): Promise<any> {
        const contentDisposition = response.headers["content-disposition"];
        const filename = contentDisposition.split("filename=")[1];

        // Create a link element for the file and use the filename provided.
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(new Blob([response]));
        link.setAttribute("download", filename);

        document.body.appendChild(link);

        // Download.
        link.click();

        // Having clicked the link, delete the element otherwise it will
        // remain attached to the document.
        document.body.removeChild(link);

        return Promise.resolve();
    }
}
