import { Box, Button, Label } from "Components";
import { FullWidthCentFlexContainer } from "Components/FullWidthCentFlexCont";
import { InnerWrapper } from "Components/InnerWrapper";
import React, { useEffect, useRef, useState } from "react";

// Images Mk 2

import Banner1 from "Assets/HomeBannerImage1aMK2.jpg";
import Banner2 from "Assets/HomeBannerImage2aMK2.jpg";
import Banner3 from "Assets/HomeBannerImage3Mk2.jpg";
import Banner4 from "Assets/HomeBannerImage4Mk2.jpg";
import Banner5 from "Assets/HomeBannerImage5Mk2.jpg";
import QuoteImage from "Assets/QuoteEllipse1.png";
import BannerVideo from "Assets/Videos/Website_Banner_MP4.mp4";
import BannerVideoMobile from "Assets/Videos/Website_Banner_MP4_Mobile.mp4";

// Images Mobile Mk 2

import Banner0Mob from "Assets/HomeBannerImageMob0Mk2.png";
import Banner1Mob from "Assets/HomeBannerImage1aMK2.jpg";
import Banner2Mob from "Assets/HomeBannerImage2aMK2.jpg";
import Banner3Mob from "Assets/HomeBannerImage2aMK2.jpg";

import styled from "@emotion/styled";
import { ICommand, RelayCommand, theme } from "Application";
import { CoreStoreInstance } from "@shoothill/core";
import { HomeViewModel } from "./HomeViewModel";
import { observer } from "mobx-react-lite";
import { H1, P } from "Components/Primitives/TextElements/TextElements";
import { HomePageButton, SubHeadingBox } from "Views/Header/Header.Styles";
import { BrochureButton } from "Components/DownloadBrochure/DownloadBrochureButton/DownloadBrochureButton";
import { DownloadBrochureModal } from "Components/DownloadBrochure/DownloadBrochureModal/DownloadBrochureModal";
import { CarouselForImagesInstagram } from "Components/CarouselForImages/Instagram/CarouselForImagesInstagram";
import { CookieConsent } from "Components/CookieConsent/CookieConsent";
import { MetaHeader } from "Components/SEOComponents/MetaHeader";
import { CarouselFullWidthMk2 } from "Components/CarouselFullWidthMk2/CarouselFullWidthMk2";
import { TitleLeftTextRightBlock } from "Components/TitleLeftTextRightBlock/TitleLeftTextRightBlock";
import { ShorelineButton } from "Components/ShorelineButton";
import { BannerImageFull } from "Components/BannerImageFull/BannerImageFull";
import { QuoteBlock } from "Components/QuoteBlock/QuoteBlock";
import { CarouselMultiMk2 } from "Components/CarouselMultiMk2/CarouselMultiMk2";
import { QuoteBox, ScrollBox } from "./Home.styles";
import { BannerImageFullMobile } from "Components/BannerImageFull/BannerImageFullMobile";
import { AppUrls } from "AppUrls";
import { container } from "tsyringe";
import { DomainStore } from "Stores/Domain/DomainStore";
import BannerImageFallback from "Assets/HomeCarouselVidFallback.jpg";
import UnMute from "Assets/Icons/UnMute.svg";
import Mute from "Assets/Icons/Mute.svg";
import { NotificationModal } from "Components/NotificationModal/NotificationModal";

export const HomeView: React.FC = observer(() => {
    const [viewModel] = useState<HomeViewModel>(new HomeViewModel());
    const domainStore = container.resolve(DomainStore);

    CoreStoreInstance.CoreOptions.mobileBreakPoint = 600;

    const openBrochureModal: ICommand = new RelayCommand(() => {
        domainStore.openBrochureModal();
    });

    const videoRef = useRef(null);
    useEffect(() => {
        if (videoRef.current !== undefined && videoRef.current !== null) {
            videoRef.current.defaultMuted = true;
        }
    });

    const tabletMargin = "20px";
    const tabletPadding = "20px";

    return (
        <>
            <DownloadBrochureModal open={domainStore.isBrochureModalOpen} closeModal={domainStore.closeBrochureModal} />
            {/* <NotificationModal open={viewModel.isNotificationModalOpen} closeModal={viewModel.closeNotificationModal} /> */}
            <MetaHeader
                title="Shoreline"
                metaDescription="A contemporary development of 84 apartments, beach houses, duplexes and penthouses situated on Folkestone's beautiful coast with unrivalled views across the Channel."
            />

            <FullWidthCentFlexContainer>
                <InnerWrapper>
                    {/* **** Carousel - swapped out for video **** */}
                    {/* {viewModel.isMobile ? <BannerImageFullMobile imageUrl={Banner0Mob} /> : <CarouselFullWidthMk2 />} */}

                    <Box style={{ position: "relative" }}>
                        <video autoPlay muted={viewModel.isVideoMuted} playsInline ref={videoRef} loop width="100%" poster={BannerImageFallback}>
                            <source src={viewModel.isMobile ? BannerVideoMobile : BannerVideo} type="video/mp4" />
                            {/* <img src={BannerImage} width="100%" /> */}
                        </video>
                        <Box style={{ position: "absolute", left: 20, top: 20 }}>
                            <ShorelineButton
                                command={viewModel.toggleMute}
                                icon={<img src={viewModel.isVideoMuted ? Mute : UnMute} height="14px" style={{ paddingRight: viewModel.isMobile ? "0px" : "10px" }} />}
                                displayName={viewModel.isMobile ? "" : viewModel.isVideoMuted ? "  Unmute" : "  Mute"}
                            />
                        </Box>
                    </Box>
                    <TitleLeftTextRightBlock
                        title="Sea. Sky. Shoreline."
                        textBlock={`Designed and delivered by internationally acclaimed, award-winning architects ACME, Shoreline Crescent represents the best in coastal living and is the first opportunity to buy into this exciting new scheme.\n\n Situated on the beach’s edge but just an hour from the capital and the continent.  In a world where location is everything, Shoreline Crescent has it all.`}
                        buttons={
                            <>
                                <ShorelineButton displayName="VIEW AVAILABLE PROPERTIES" value={AppUrls.Client.NewHomes} command={viewModel.navTo} />
                                <ShorelineButton displayName="BOOK AN APPOINTMENT" value={AppUrls.Client.Register} command={viewModel.navTo} />
                                <ShorelineButton displayName="DOWNLOAD BROCHURE" command={openBrochureModal} />
                            </>
                        }
                        isMobile={viewModel.isMobile}
                        hasLargeTitle={true}
                        hasLargerBodyText={true}
                        hasThreeButtons={true}
                        isTablet={viewModel.isTablet}
                    />

                    {viewModel.isMobile ? <BannerImageFullMobile imageUrl={Banner1Mob} /> : <BannerImageFull imageUrl={Banner1} />}

                    <TitleLeftTextRightBlock
                        title={`Thousands of Ever\n Changing Colours`}
                        textBlock={`Shoreline Crescent’s white glazed bricks reflect the changing colours of the sea and sky according to the weather and the time of day.`}
                        buttons={
                            <>
                                <ShorelineButton displayName="VIEW AVAILABLE PROPERTIES" value={AppUrls.Client.NewHomes} command={viewModel.navTo} />
                            </>
                        }
                        isMobile={viewModel.isMobile}
                    />

                    {/* {viewModel.isMobile && <BannerImageFullMobile imageUrl={Banner2Mob} />} */}

                    {!viewModel.isMobile && (
                        <TitleLeftTextRightBlock
                            title="Built to Last"
                            textBlock={`Designed by RIBA award-winning ACME, every detail of Shoreline Crescent has been meticulously considered. Materials used on the exterior of the building are rust-proof. Behind the façade, wall ties are made from the same marine grade materials found on oil rigs, ensuring longevity and sustainability. Shoreline Crescent is a building designed and built to endure.`}
                            backgroundColour="#2B6298"
                            textColour={"secondary"}
                            isMobile={viewModel.isMobile}
                        />
                    )}

                    {!viewModel.isMobile && <BannerImageFull imageUrl={Banner2} />}

                    <QuoteBlock
                        quoteAuthor="ACME"
                        quoteText={`“The flowing south facade of the building \n with its organic curved forms echoes the \n shape of the waves, pebbles and coast.” `}
                        imageUrl={QuoteImage}
                        isMobile={viewModel.isMobile}
                        isTablet={viewModel.isTablet}
                        imageLeft={false}
                    />

                    {viewModel.isMobile && (
                        <TitleLeftTextRightBlock
                            title="Built to Last"
                            textBlock={`Designed by RIBA award-winning ACME, every detail of Shoreline Crescent has been meticulously considered. Materials used on the exterior of the building are rust-proof. Behind the façade, wall ties are made from the same marine grade materials found on oil rigs, ensuring longevity and sustainability. Shoreline Crescent is a building designed and built to endure.`}
                            backgroundColour="#2B6298"
                            textColour={"secondary"}
                            isMobile={viewModel.isMobile}
                        />
                    )}

                    {viewModel.isMobile && <BannerImageFullMobile imageUrl={Banner3Mob} />}

                    <TitleLeftTextRightBlock
                        title="Refined Interiors"
                        textBlock={`With show-homes designed by 8 Holland Street, elegant open-plan living areas frame spectacular views and rooms are drenched in sunlight. Natural wood floors and full-height windows form a contemporary backdrop for beachside living.`}
                        backgroundColour="#2B6298"
                        textColour={"secondary"}
                        buttons={
                            <>
                                <ShorelineButton displayName="VIEW AVAILABLE PROPERTIES" value={AppUrls.Client.NewHomes} command={viewModel.navTo} />
                            </>
                        }
                        isMobile={viewModel.isMobile}
                    />

                    <CarouselMultiMk2 isMobile={viewModel.isMobile} />

                    <QuoteBox flexBox justifyContent="center">
                        <Box margin={viewModel.isMobile ? "40px 20px" : "95px"}>
                            <P hidden={viewModel.isMobile} color="secondary" style={{ fontSize: viewModel.isMobile ? "20px" : "30px" }}>
                                “We have responded to the dynamism of Folkestone and this spectacular <br></br> beachfront location to create sophisticated and comfortable living
                                spaces.<br></br>
                                Materials and visual references draw on the local landscape, the building’s<br></br> architectural detailing and wider Scandinavian design
                                references.”
                            </P>
                            <P hidden={!viewModel.isMobile} color="secondary" style={{ fontSize: viewModel.isMobile ? "20px" : "30px" }}>
                                “We have responded to the dynamism of Folkestone and this spectacular beachfront location to create sophisticated and comfortable living spaces.
                                Materials and visual references draw on the local landscape, the building’s architectural detailing and wider Scandinavian design references.”
                            </P>
                            <P mt="20px" className="smallerText">
                                8 HOLLAND STREET
                            </P>
                            <Box grid tc="1fr 1fr" pt="82px">
                                <ShorelineButton displayName="BOOK AN APPOINTMENT" value={AppUrls.Client.Register} command={viewModel.navTo} />
                                <ShorelineButton displayName="DOWNLOAD BROCHURE" command={openBrochureModal} />
                            </Box>
                        </Box>
                    </QuoteBox>

                    <BannerImageFull imageUrl={Banner3} />

                    <TitleLeftTextRightBlock
                        title="Outdoor Spaces"
                        textBlock={`Spacehub, award-winning landscape architects, have designed shingle gardens around the building connecting Shoreline Crescent with its beachside environment. To the rear of the building there is a lush and vibrant communal garden with year-round local vegetation, just metres away from the Lower Leas Coastal Park.`}
                        backgroundColour="#CC6B2C"
                        textColour={"secondary"}
                        isMobile={viewModel.isMobile}
                    />

                    <BannerImageFull imageUrl={Banner4} />

                    <TitleLeftTextRightBlock
                        surTitle="FOLKESTONE"
                        title={`“One of the best places to \n live in 2023”  - The Times`}
                        textBlock={`Folkestone is a vibrant coastal community with creativity at its heart. Eclectic independent shops, cafés and restaurants, a thriving arts scene, world-class sports facilities, and proximity to the sea and rolling countryside make Folkestone “one of the best places to live” in the UK. `}
                        buttons={
                            <>
                                <ShorelineButton displayName="LIVING IN FOLKESTONE" value={AppUrls.Client.Living} command={viewModel.navTo} />
                            </>
                        }
                        isMobile={viewModel.isMobile}
                    />

                    {!viewModel.isMobile && <BannerImageFull imageUrl={Banner5} />}

                    {viewModel.isMobile && (
                        <ScrollBox>
                            <img src={Banner5} style={{ width: "250%", height: "500px", overflowX: "scroll", objectFit: "cover" }} />
                        </ScrollBox>
                    )}

                    <TitleLeftTextRightBlock
                        title="The Masterplan"
                        textBlock={`Shoreline Crescent is the first residential phase a project that aims to build a vibrant new community along the historic seafront of Folkestone, inspired by the legacy of Edwardian Folkestone.`}
                        backgroundColour="#E0A752"
                        textColour={"primary"}
                        buttons={
                            <>
                                <ShorelineButton displayName="OUR VISION" value={AppUrls.Client.OurVision} command={viewModel.navTo} />
                            </>
                        }
                        isMobile={viewModel.isMobile}
                    />
                </InnerWrapper>
            </FullWidthCentFlexContainer>

            {/* Swap out for Twitter carousel below once wired into DB */}
            {/* <FullWidthCentFlexContainer>
                <InnerWrapper
                    maxWidth={"1000px"}
                    pl={viewModel.isTablet || viewModel.isMobile ? tabletPadding : ""}
                    pr={viewModel.isTablet || viewModel.isMobile ? tabletPadding : ""}
                    pb={"40px"}
                >
                    <Box textAlign={"center"}>
                        <H2Grad pb={"30px"}>WHERE THE LAND MEETS THE SEA</H2Grad>
                        <Box display={"flex"} justifyContent={"center"}>
                            <HorizontalGradLineDarkLightDark mb={"25px"}></HorizontalGradLineDarkLightDark>
                        </Box>
                    </Box>
                    <CarouselForImages pagination={viewModel.isMobile ? true : false} slidesPerView={viewModel.isMobile ? 1 : 3}></CarouselForImages>
                </InnerWrapper>
            </FullWidthCentFlexContainer> */}

            {/* <FullWidthCentFlexContainer>
                <InnerWrapper
                    maxWidth={"1000px"}
                    pl={viewModel.isTablet || viewModel.isMobile ? tabletPadding : ""}
                    pr={viewModel.isTablet || viewModel.isMobile ? tabletPadding : ""}
                    pb={"40px"}
                >
                    <Box textAlign={"center"}>
                        <H2Grad pb={"30px"}>
                            WHERE THE LAND MEETS THE SEA &nbsp;&nbsp;
                            <a href="https://www.instagram.com/shoreline_folkestone/" target="_blank">
                                <img src={InstagramGradLogo}></img>
                            </a>
                        </H2Grad>
                        <Box display={"flex"} justifyContent={"center"}>
                            <HorizontalGradLineDarkLightDark mb={"25px"}></HorizontalGradLineDarkLightDark>
                        </Box>
                    </Box>
                    <CarouselForImagesInstagram pagination={viewModel.isMobile ? true : false} slidesPerView={viewModel.isMobile ? 1 : 3}></CarouselForImagesInstagram>
                </InnerWrapper>
            </FullWidthCentFlexContainer> */}

            {/* <FullWidthCentFlexContainer pb="50px">
                <BrochureButton whileHover={{ scale: 1.1 }} onClick={() => setIsBrochureModalOpen(true)}>
                    DOWNLOAD <br />
                    BROCHURE
                </BrochureButton>
            </FullWidthCentFlexContainer> */}

            {/* <ImageCarouselWithTextNews></ImageCarouselWithTextNews> */}
            {/* <Link to="/contact">
                <Box showIf={viewModel.isDesktop || viewModel.isTablet}>
                    <InterestButton></InterestButton>
                </Box>
            </Link> */}
        </>
    );
});
