import styled from "@emotion/styled";
import { theme } from "Application";
import { Box } from "Components";

export const SliderWrapper = styled(Box)`
    height: 100%;
    .swiper {
        width: 100%;
        height: 100%;
    }

    .swiper-slide {
        display: grid;
        grid-template-rows: 25vh auto 25vh auto;
    }
    .swiper-pagination-bullet {
        background: white;
        opacity: 1;
    }
    .swiper-pagination-bullet-active {
        background: #eda765;
    }
    .swiper-pagination {
        margin-bottom: -20px;
    }

    .grey-button {
        background: #8a8a8a !important;
    }
    .swiper-container {
        position: relative !important;
        z-index: 0;
    }
`;

export const ModalBanner = styled(Box)`
    height: 50px;
    background-color: ${theme.palette.common.secondary};
    display: flex;
    align-items: center;
    /* display: grid; */
    /* grid-template-columns: 485px 485px; */
    /* justify-content: space-between; */
    /* align-items: center; */
`;

export const GrayBox = styled(Box)`
    background-color: #e3e3de;
    padding: 13px 20px 13px 20px;
`;

export const WhiteBox = styled(Box)`
    background-color: #f3f2ee;
    padding: 13px 20px 13px 20px;

    .editselect__control {
        border-radius: 4px;
        border: none;
        z-index: 10000000;
    }
`;

export const OuterBox = styled(Box)`
    background-color: #f3f2ee;
`;

export const ScrollingDiv = styled(Box)`
    overflow-y: auto;
    height: 100%;
    background-color: #f3f2ee;
`;

export const BoxWrapper = styled(Box)`
    background-color: #f3f2ee;
`;
export const ListBox = styled.div`
    list-style-position: inside;

    ul {
        line-height: 1.5;
    }
`;

export const GrayBoxTrans = styled(Box)`
    background-color: rgba(227, 227, 222, 0.75);
    padding: 13px 20px 13px 20px;
    height: 100%;
`;

export const EditSelectModal = styled.select`
    padding: 8px;
    width: 100%;
    font-family: "open sans";
    font-size: 14px;
`;
