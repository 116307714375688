import { makeObservable, observable, runInAction } from "mobx";
import { KeyValuePair, ViewModelBase } from "@shoothill/core";
import { HouseTypeModel, HouseTypeModelValidator } from "./HouseTypeModel";
import { APIClient, ICommand, RelayCommand } from "Application";

export class HouseTypeViewModel extends ViewModelBase<HouseTypeModel> {
    public apiClient = new APIClient();

    public housePrice: number = 0;
    public buyerOption: number = 0;
    public stampDutyAmount: number = 0;

    public stampDutyDisplayToggle: boolean = false;

    constructor(model = new HouseTypeModel()) {
        super(model);
        this.setValidator(new HouseTypeModelValidator());
        makeObservable(this, {});
    }
}
