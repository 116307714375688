import React from "react";
import { observer } from "mobx-react-lite";
import { Box } from "Application";

export interface IBannerImageFull {
    imageUrl: string;
}

export const BannerImageFullMobile: React.FC<IBannerImageFull> = observer((props) => {
    return (
        <>
            <Box style={{ lineHeight: "0px" }}>
                <img src={props.imageUrl} height="500px" width="100%" style={{ objectFit: "cover", objectPosition: "bottom center" }} />
            </Box>
        </>
    );
});
