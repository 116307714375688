import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { NewHomesViewModel } from "../NewHomesViewModel";

import { NewHomeDefaultView } from "./NewHomeDefaultView";
import { NewHomeWingView } from "./NewHomeWingView";
import { AppUrls } from "AppUrls";

interface INewHomeDefault {
    viewModel: NewHomesViewModel;
}

export const NewHomesSubView: React.FC<INewHomeDefault> = observer((props) => {
    const [panelView, setPanelView] = useState<string>("");

    // Note: as of 20/03/23 now displaying all East Wing properties on New Homes Page!

    return (
        <>
            {/* {panelView.length === 0 &&  */}
            <NewHomeDefaultView viewModel={props.viewModel} path={AppUrls.Client.NewHomes} clickValue={(val) => setPanelView(val)} />
            {/* } */}

            {/* {panelView.length > 0 && <NewHomeWingView viewModel={props.viewModel} path={AppUrls.Client.NewHomes} clickValue={(val) => setPanelView(val)} />} */}
        </>
    );
});
