import styled from "@emotion/styled";
import { theme } from "Styles";
import { Box } from "./General";

export const FullWidthCentFlexContainer = styled(Box)`
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: ${theme.palette.common.secondary}; */
`;
