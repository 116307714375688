import React from "react";
import { observer } from "mobx-react-lite";
import { Box } from "Application";
import { InnerWrapper } from "Components/InnerWrapper";
import { PropertyListItem } from "./PropertyListItem";
import { BuildingType, NewHomesViewModel } from "../NewHomesViewModel";
import styled from "@emotion/styled";
import { P } from "Components/Primitives/TextElements/TextElements";
import { PropertyListItemViewModel } from "./PropertyListItemViewModel";
import { TriangleMobile } from "./NewHomeStyling";

interface INewHomesList {
    viewModel: NewHomesViewModel;
    path: string;
    buildingType: string;
}

export const NewHomesListView: React.FC<INewHomesList> = observer((props) => {
    return (
        <>
            <InnerWrapper maxWidth={"1400px"} width="auto" p={props.viewModel.isDesktop ? "0px 40px" : "0px"}>
                <Box
                    style={{
                        backgroundColor: "quarternary",
                        width: "auto",
                    }}
                >
                    <Box
                        grid
                        dc={props.buildingType == BuildingType.Townhouse ? "2fr 1fr 1fr 1fr 1fr 1.2fr 1.5fr 1.5fr" : "2fr 1fr 1fr 1fr 1fr 1fr 1fr 1.2fr 1.5fr 1.5fr"}
                        pb={props.viewModel.isMobile ? "0px" : "20px"}
                        mb="50px"
                        columnGap="0px"
                        width="auto"
                        style={{ backgroundColor: "white" }}
                    >
                        <HeaderBox showIf={!props.viewModel.isMobile}>
                            <Box width="100%">
                                <P pl="10px" color="quarternary">
                                    Address
                                </P>
                            </Box>
                            <Pipe color="quarternary">|</Pipe>
                        </HeaderBox>
                        <HeaderBox showIf={!props.viewModel.isMobile}>
                            <Box width="100%">
                                <PHeaderCentered color="quarternary">Plot No.</PHeaderCentered>
                            </Box>
                            <Pipe color="quarternary">|</Pipe>
                        </HeaderBox>
                        <HeaderBox showIf={!props.viewModel.isMobile && props.buildingType !== BuildingType.Townhouse}>
                            <Box width="100%">
                                <PHeaderCentered color="quarternary">Block</PHeaderCentered>
                            </Box>
                            <Pipe color="quarternary">|</Pipe>
                        </HeaderBox>
                        <HeaderBox showIf={!props.viewModel.isMobile}>
                            <Box width="100%">
                                <PHeaderCentered color="quarternary">Beds</PHeaderCentered>
                            </Box>
                            <Pipe color="quarternary">|</Pipe>
                        </HeaderBox>
                        <HeaderBox showIf={!props.viewModel.isMobile && props.buildingType !== BuildingType.Townhouse}>
                            <Box width="100%">
                                <PHeaderCentered color="quarternary">Level</PHeaderCentered>
                            </Box>
                            <Pipe color="quarternary">|</Pipe>
                        </HeaderBox>
                        <HeaderBox showIf={!props.viewModel.isMobile}>
                            <Box width="100%">
                                <PHeaderCentered color="quarternary">Baths</PHeaderCentered>
                            </Box>
                            <Pipe color="quarternary">|</Pipe>
                        </HeaderBox>
                        {/* <HeaderBox showIf={!props.viewModel.isMobile}>
                            <Box width="100%">
                                <PHeaderCentered color="quarternary">Ensuites</PHeaderCentered>
                            </Box>
                            <Pipe color="quarternary">|</Pipe>
                        </HeaderBox> */}
                        <HeaderBox showIf={!props.viewModel.isMobile}>
                            <Box width="100%">
                                <PHeaderCentered color="quarternary">SqFt</PHeaderCentered>
                            </Box>
                            <Pipe color="quarternary">|</Pipe>
                        </HeaderBox>
                        <HeaderBox showIf={!props.viewModel.isMobile}>
                            <Box width="100%">
                                <PHeaderCentered color="quarternary">Available</PHeaderCentered>
                            </Box>
                            <Pipe color="quarternary">|</Pipe>
                        </HeaderBox>
                        <HeaderBox showIf={!props.viewModel.isMobile}>
                            <Box width="100%">
                                <PHeaderCentered color="quarternary">Price</PHeaderCentered>
                            </Box>
                            <Pipe color="quarternary">|</Pipe>
                        </HeaderBox>
                        <HeaderBox pr="10px" showIf={!props.viewModel.isMobile}>
                            <Box width="100%">
                                <PHeaderCentered color="quarternary">View</PHeaderCentered>
                            </Box>
                        </HeaderBox>
                        {/* {props.viewModel.propertyListItemViewModels
                            // .filter((row, index) => row.getValue("numberOfBeds") === "1")
                            .filter((row, index) => row.getValue<string>("houseTypeId").toUpperCase() != "123D2C76-F1A5-4108-8590-F980C2BECADD")
                            .map(
                                (row, index) => row.getStatus(row.getValue("actualStatus")) != "unreleased" && <PropertyListItem path={props.path} viewModel={row} index={index} />,
                            )}   */}

                        {/* **** Using filtered gets rather than filtering here **** */}
                        <Box width={"100%"} showIf={props.viewModel.isMobile} display={"flex"} style={{ backgroundColor: "#ecdfc9" }} justifyContent={"center"}>
                            <TriangleMobile />
                        </Box>
                        {props.buildingType == BuildingType.EastWing &&
                            props.viewModel.getEastWing
                                .filter((row, index) => row.getValue<string>("houseTypeId").toUpperCase() != "123D2C76-F1A5-4108-8590-F980C2BECADD")
                                .map((row, index) => (
                                    // row.getStatus(row.getValue("actualStatus")) != "unreleased" &&
                                    <PropertyListItem path={props.path} viewModel={row} key={index} index={index} />
                                ))}

                        {props.buildingType == BuildingType.WestWing &&
                            props.viewModel.getWestWing
                                .filter((row, index) => row.getValue<string>("houseTypeId").toUpperCase() != "123D2C76-F1A5-4108-8590-F980C2BECADD")
                                .map((row, index) => (
                                    // row.getStatus(row.getValue("actualStatus")) != "unreleased" &&
                                    <PropertyListItem path={props.path} viewModel={row} key={index} index={index} />
                                ))}

                        {props.buildingType == BuildingType.Townhouse &&
                            props.viewModel.getTownhouses
                                .filter((row, index) => row.getValue<string>("houseTypeId").toUpperCase() != "123D2C76-F1A5-4108-8590-F980C2BECADD")
                                .map((row, index) => (
                                    // row.getStatus(row.getValue("actualStatus")) != "unreleased" &&
                                    <PropertyListItem path={props.path} viewModel={row} key={index} index={index} buildingType={BuildingType.Townhouse} />
                                ))}

                        {props.buildingType == BuildingType.Duplex &&
                            props.viewModel.getDuplexes
                                .filter((row, index) => row.getValue<string>("houseTypeId").toUpperCase() != "123D2C76-F1A5-4108-8590-F980C2BECADD")
                                .map((row, index) => (
                                    // row.getStatus(row.getValue("actualStatus")) != "unreleased" &&
                                    <PropertyListItem path={props.path} viewModel={row} key={index} index={index} />
                                ))}

                        {props.buildingType == BuildingType.Penthouse &&
                            props.viewModel.getPenthouses
                                .filter((row, index) => row.getValue<string>("houseTypeId").toUpperCase() != "123D2C76-F1A5-4108-8590-F980C2BECADD")
                                .map((row, index) => (
                                    // row.getStatus(row.getValue("actualStatus")) != "unreleased" &&
                                    <PropertyListItem path={props.path} viewModel={row} key={index} index={index} />
                                ))}
                    </Box>
                </Box>
                <Box></Box>
            </InnerWrapper>
        </>
    );
});

const HeaderBox = styled(Box)`
    background-color: #cc6b2c;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #ecdfc9;

    display: flex;
    justify-content: space-between;
`;

const PHeaderCentered = styled(P)`
    text-align: center;
`;

const Pipe = styled(P)`
    font-size: 22px;
    font-weight: 100;
    letter-spacing: -5px;
`;
