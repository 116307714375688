import { Logger } from "index";
import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { ForgotPasswordModel } from "../ForgotPasswordModel";

class ForgetPasswordRequest {
    public emailAddress: string = "";

    public constructor() {}
}

export class ForgetPasswordResponse {}

export class ForgetPasswordEndpoint extends Endpoint<ForgetPasswordRequest, ForgetPasswordResponse> {
    constructor() {
        super();
        this.verb(Http.Post);
        this.path("/api/account/forgotpassword");
    }

    public async HandleRequestAsync(model: ForgotPasswordModel): Promise<any> {
        let request = new ForgetPasswordRequest();
        request.emailAddress = model.emailAddress;
        return request;
    }

    public async HandleResponseAsync(response: ForgetPasswordResponse): Promise<ForgetPasswordResponse> {
        Logger.logInformation("Response", response);

        //Return your model
        return response;
    }
}
