import { ModelBase } from "@shoothill/core";
import { action, makeObservable, observable } from "mobx";
import { Validator } from "Application/Validation";

import { PropertyListItemModel } from "./Subviews/PropertyListItemModel";
import { PropertyListItemViewModel } from "./Subviews/PropertyListItemViewModel";

export class NewHomesModel extends ModelBase<NewHomesModel> {
    public id: string = "";
    public properties = observable<PropertyListItemModel>([]);

    public eastWingViewModels: PropertyListItemViewModel[] = [];
    public westWingViewModels: PropertyListItemViewModel[] = [];
    public townHouseViewModels: PropertyListItemViewModel[] = [];
    public duplexViewModels: PropertyListItemViewModel[] = [];
    public penthouseViewModels: PropertyListItemViewModel[] = [];

    constructor() {
        super();
        makeObservable(this, {
            id: observable,

            eastWingViewModels: observable,
            westWingViewModels: observable,
            townHouseViewModels: observable,
            duplexViewModels: observable,
            penthouseViewModels: observable,
            clear: action,
        });
    }

    public clear() {
        this.eastWingViewModels = [];
        this.westWingViewModels = [];
        this.townHouseViewModels = [];
        this.duplexViewModels = [];
        this.penthouseViewModels = [];
        this.id = "";
        this.properties.clear();
    }
}
export class NewHomesModelValidator extends Validator<NewHomesModel> {
    constructor() {
        super();
        //this.ruleFor("id").notEmpty();
    }
}
