import { Logger } from "index";
import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { ContactModel } from "../ContactModel";
import { ContactViewModel } from "../ContactViewModel";
import { currencyFormatForUpsert } from "Utils/CurrencyFormatting";

// Use this one
export class RequestResponse {
    public companyId: string = "";
    public title: string = "";
    public firstName: string = "";
    public middleName: string = "";
    public surname: string = "";
    public addressLine1: string = "";
    public addressLine2: string = "";
    public addressLine3: string = "";
    public county: string = "";
    public city: string = "";
    public postcode: string = "";
    public primaryContactNumber: string = "";
    public secondaryContactNumber: string = "";
    public contactEmail: string = "";
    public followUpDate: string = "";
    public developmentName: string = "";
    public plotNumber: number = -1;
    public numberOfBeds: number | null = null;
    public notes: string = "";
    public acceptsMarketing: boolean | null = null;
    public helpToBuy: boolean | null = null;
    public budgetFrom: string = "";
    public budgetTo: string = "";
    public hbpResponse: boolean = false;
    // Removed for now as some confusion
    // public acceptsEmail: boolean = false;
    // public acceptsTelCall: boolean = false;
}

export class PostcontactFormEndpoint extends Endpoint<RequestResponse, RequestResponse> {
    private readonly viewModel: ContactViewModel;
    constructor(viewModel: ContactViewModel) {
        super();
        this.verb(Http.Post);
        this.path("/api/contact/postcontacttopublicapi");
        this.viewModel = viewModel;
    }

    public async HandleRequestAsync(model: ContactModel): Promise<RequestResponse> {
        let request = new RequestResponse();
        request.firstName = model.firstName;
        request.surname = model.surname;
        request.addressLine1 = model.addressLine1;
        request.addressLine2 = model.addressLine2;
        request.city = model.city;
        request.postcode = model.postcode;
        request.contactEmail = model.contactEmail;
        request.primaryContactNumber = model.primaryContactNumber;

        let notesWithContactPrefs: string = "Preferred contact method: ";

        if (model.preferredContactMethodEmail === true) {
            notesWithContactPrefs += "Email - Yes, ";
        } else if (model.preferredContactMethodEmail === false) {
            notesWithContactPrefs += "Email - No, ";
        }

        if (model.preferredContactMethodPhone === true) {
            notesWithContactPrefs += "Phone - Yes.";
        } else if (model.preferredContactMethodPhone === false) {
            notesWithContactPrefs += "Phone - No.";
        }

        request.notes = "Message: " + model.notes + " | " + notesWithContactPrefs;
        request.acceptsMarketing = model.acceptsMarketing;
        //request.agreePrivacyPolicy = model.agreePrivacyPolicy;
        request.developmentName = model.developmentName;
        request.plotNumber = model.plotNumber;
        request.companyId = model.companyId;
        request.budgetFrom = currencyFormatForUpsert(model.budgetFrom);
        request.budgetTo = currencyFormatForUpsert(model.budgetTo);
        // request.acceptsEmail = model.acceptsEmail;
        // request.acceptsTelCall = model.acceptsTelCall;

        return request;
    }

    public async HandleResponseAsync(response: RequestResponse): Promise<RequestResponse> {
        Logger.logInformation("Response", response);

        //Return your model
        return response;
    }
}
