import { makeObservable, observable, runInAction } from "mobx";
import { ViewModelBase } from "@shoothill/core";
import { APIClient, ICommand, IKeyState, RelayCommand } from "Application";
import { PartExchangeModel, PartExchangeModelValidator } from "./PartExchangeModel";
import { useNavigate } from "react-router-dom";

export class PartExchangeViewModel extends ViewModelBase<PartExchangeModel> {
    public apiClient = new APIClient();
    public navigate = useNavigate();

    constructor() {
        super(new PartExchangeModel());
        this.setValidator(new PartExchangeModelValidator());
        makeObservable(this, {});
    }

    public navTo: ICommand = new RelayCommand((value: string) => {
        this.navigate(value);
    });
}
