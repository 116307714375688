import React from "react";
import { observer } from "mobx-react-lite";
import { Box, ICommand } from "Application";
import { Modal } from "@fluentui/react";
import ShorelineLogo from "Assets/ShorelineLogo.svg";
import { P } from "Components/Primitives/TextElements/TextElements";
import { ShorelineButton } from "Components/ShorelineButton";

export interface IErrorModalProps {
    showErrorOpen: boolean;
    errorMessage: string;
    closeErrorWindow: ICommand;
}

export const ErrorModal: React.FC<IErrorModalProps> = observer(({ closeErrorWindow, errorMessage, showErrorOpen }) => {
    return (
        <Modal allowTouchBodyScroll={true} isOpen={showErrorOpen} styles={{ main: [{ background: "#C6B59F", maxWidth: "550px" }], root: [{ background: "rgba(0, 0, 0, 0.75)" }] }}>
            <Box pt="20px" style={{ textAlign: "center", color: "#ffffff", fontSize: "16px" }} maxWidth="320px">
                <Box p="20px">
                    <img src={ShorelineLogo} height="20px" />
                </Box>
                <p style={{ padding: "20px" }}>{errorMessage}</p>
                <p style={{ padding: "20px" }}>Please try again later, or use the contact form to contact Shoreline.</p>
                <Box p="0px 30px 20px 30px">
                    <ShorelineButton command={closeErrorWindow} displayName="OK" style={{ background: "#575756" }} />
                </Box>
            </Box>
        </Modal>
    );
});
