import { ResetPasswordModel, ResetPasswordValidator } from "./ResetPasswordModel";
import { makeObservable, observable, runInAction } from "mobx";
import { ViewModelBase, FieldType } from "@shoothill/core";
import { ICommandAsync, RelayCommandAsync } from "../../../../Application/Commands";
import { ResetPasswordEndpoint } from "./Endpoints/ResetPasswordEndpoint";
import { APIClient } from "../../../../Application";
import { IKeyState } from "../../../../Application";

export class ResetPasswordViewModel extends ViewModelBase<ResetPasswordModel> {
    public tokenValid: boolean = false;
    public errorMessage: string = "";
    public showSuccessMessage: boolean = false;
    public showErrorMessage: boolean = false;
    public apiClient = new APIClient();
    private resetPasswordEndpoint = new ResetPasswordEndpoint();

    constructor() {
        super(new ResetPasswordModel());
        this.setValidator(new ResetPasswordValidator());
        makeObservable(this, {
            tokenValid: observable,
            errorMessage: observable,
            showSuccessMessage: observable,
            showErrorMessage: observable,
        });
    }
    public updateNewPasswordCommand: ICommandAsync = new RelayCommandAsync(async (value: string, keyState: IKeyState) => {
        await this.updateField("newPassword", value, keyState);
        this.isFieldValid("confirmPassword");
    });
    public updateConfirmPasswordCommand: ICommandAsync = new RelayCommandAsync(async (value: string, keyState: IKeyState) => {
        await this.updateField("confirmPassword", value, keyState);
    });
    private updateField(fieldName: keyof FieldType<ResetPasswordModel>, value: any, keyState: IKeyState) {
        this.setValue(fieldName, value);
        this.isFieldValid(fieldName);
    }

    public resetPasswordCommand: ICommandAsync = new RelayCommandAsync(async (token: string) => {
        await this.apiClient.sendAsync(this.resetPasswordEndpoint, this.model);
        if (this.apiClient.IsRequestSuccessful) {
            this.showSuccessMessage = true;
        } else {
            this.showErrorMessage = true;
        }
    });
}
