import { css, Global } from "@emotion/react";
import React from "react";
import { theme } from "./AppTheme";

export const GlobalStyles = () => {
    const scrollbar_backgroundcolor = "#db9a5c";
    const scrollbar_backgroundbordercolor = "#DCDCDC";

    return (
        <Global
            styles={css`
                html,
                body {
                    margin: 0;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                    text-rendering: optimizeLegibility;
                    background-color: #ecdfc9 !important;
                    height: 100%;

                    font-family: ${theme.defaultFontStyle.fontFamily};
                    font-size: ${theme.defaultFontStyle.fontSize};
                    line-height: ${theme.defaultFontStyle.lineHeight};

                    overflow: hidden;
                }
                .overflowContainer {
                    overflow: auto;
                    /* height: 95vh; */
                    height: 100vh;
                }

                code {
                    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
                }

                /////////////////////////////////////////
                //Custom scroll bar
                * {
                    scrollbar-width: thin;
                    scrollbar-color: ${scrollbar_backgroundcolor} ${scrollbar_backgroundbordercolor};
                }

                // Chrome, Edge, and Safari - more overrides available, including radius, width and thumb borders.
                *::-webkit-scrollbar {
                    height: 9px;
                    width: 9px;
                }

                *::-webkit-scrollbar-track {
                    background: ${scrollbar_backgroundbordercolor};
                }

                *::-webkit-scrollbar-thumb {
                    background-color: ${scrollbar_backgroundcolor};
                    border-radius: 9px;
                    border: 1px solid ${scrollbar_backgroundbordercolor};
                }
                //End of custom scroll bar
                /////////////////////////////////////////
            `}
        />
    );
};
