import React from "react";
import { observer } from "mobx-react-lite";
import { Box, ICommand, Label, RelayCommand } from "Application";

import BedThumbTiny from "Assets/BedroomTinyThumb.png";
import BathroomThumbTiny from "Assets/BathroomTinyThumb.png";
import styled from "@emotion/styled";
import { ShorelineButton } from "Components/ShorelineButton";

import { PropertyListItemViewModel } from "./PropertyListItemViewModel";
import { P } from "Components/Primitives/TextElements/TextElements";
import { HorizontalGradLineDarkLightDark } from "Components/HorizontalGradLineDarkLightDark";
import { RoundUp } from "Application/Helpers/RoundUp";
import { BuildingType } from "../NewHomesViewModel";

interface IPropertyListItemProps {
    viewModel: PropertyListItemViewModel;
    path: string;
    index: number;
    buildingType?: BuildingType;
}

export const PropertyListItem: React.FC<IPropertyListItemProps> = observer((props) => {
    const isDisabled = () => {
        let retVal = false;

        if (props.viewModel.getStatus(props.viewModel.getValue("actualStatus")) == "sold" || props.viewModel.getStatus(props.viewModel.getValue("actualStatus")) == "reserved") {
            retVal = true;
        }

        return retVal;
    };

    const goToSelectedProperty: ICommand = new RelayCommand((value: string) => {
        handleClick();
    });

    const handleClick = () => {
        props.viewModel.history.push(props.path + props.viewModel.generatePermalink() + "/");
    };

    return (
        <>
            <Box style={{ opacity: isDisabled() ? "0.5" : "1" }}>
                <Label fontStyle="p" pl={props.viewModel.isMobile ? "20px" : "10px"} pt={props.viewModel.isMobile ? "30px" : ""} color="primary" style={{ fontWeight: 600 }}>
                    {props.viewModel.getValue("addressLine1")}
                </Label>
            </Box>
            <PCentered hidden={props.viewModel.isMobile} style={{ opacity: isDisabled() ? "0.5" : "1" }}>
                {props.viewModel.getValue("plotNumber")}
            </PCentered>
            <PCentered hidden={props.viewModel.isMobile || props.buildingType === BuildingType.Townhouse} style={{ opacity: isDisabled() ? "0.5" : "1" }}>
                {props.viewModel.getValue("block")}
            </PCentered>

            <Box showIf={props.viewModel.isMobile} style={{ opacity: isDisabled() ? "0.5" : "1" }}>
                <P pl="20px">
                    Plot {props.viewModel.getValue("plotNumber")}, Block {props.viewModel.getValue("block")}, Level {props.viewModel.getValue("floor")}
                </P>
            </Box>
            <Box showIf={props.viewModel.isMobile} style={{ opacity: isDisabled() ? "0.5" : "1" }}>
                <Box pl="20px" pr="20px" display={"flex"} style={{ justifyContent: "space-between" }}>
                    <P>
                        <img src={BedThumbTiny}></img>&nbsp;
                        {props.viewModel.getValue("numberOfBeds")}
                    </P>
                    <P>
                        <img src={BathroomThumbTiny}></img>&nbsp;
                        {Number(props.viewModel.model.numberOfBathrooms) + Number(props.viewModel.model.numberOfEnsuites)}
                    </P>
                    <P>{props.viewModel.getValue("sqft")} SqFt</P>
                </Box>
            </Box>

            <Box showIf={!props.viewModel.isMobile} display={"flex"} width="100%" justifyContent="center" style={{ opacity: isDisabled() ? "0.5" : "1" }}>
                <P>
                    <img src={BedThumbTiny}></img>&nbsp;
                    {props.viewModel.getValue("numberOfBeds")}
                </P>
            </Box>
            <PCentered hidden={props.viewModel.isMobile || props.buildingType === BuildingType.Townhouse} style={{ opacity: isDisabled() ? "0.5" : "1" }}>
                {props.viewModel.getValue("floor")}
            </PCentered>
            <Box showIf={!props.viewModel.isMobile} display={"flex"} justifyContent="center" style={{ opacity: isDisabled() ? "0.5" : "1" }}>
                <P>
                    <img src={BathroomThumbTiny}></img>&nbsp;
                    {Number(props.viewModel.model.numberOfBathrooms) + Number(props.viewModel.model.numberOfEnsuites)}
                </P>
            </Box>
            {/* <Box showIf={!props.viewModel.isMobile} display={"flex"} justifyContent="center" style={{ opacity: isDisabled() ? "0.5" : "1" }}>
                {Number(props.viewModel.getValue("numberOfEnsuites")) > 0 && (
                    <P>
                        <img src={BathroomThumbTiny}></img>&nbsp;
                        {props.viewModel.getValue("numberOfEnsuites")}
                    </P>
                )}
            </Box> */}
            <PCentered hidden={props.viewModel.isMobile} style={{ opacity: isDisabled() ? "0.5" : "1" }}>
                {RoundUp(props.viewModel.getValue("sqft"))}
            </PCentered>
            <PCentered hidden={props.viewModel.isMobile} style={{ opacity: isDisabled() ? "0.5" : "1" }}>
                {/* {props.viewModel.getStatus(props.viewModel.getValue("actualStatus")) !== "reserved" || "sold" ? "For Sale" : ""} */}
                {props.viewModel.getAvailableStatus(props.viewModel.getValue("actualStatus"))}
            </PCentered>

            <PCentered hidden={props.viewModel.isMobile} style={{ opacity: isDisabled() ? "0.5" : "1" }}>
                {props.viewModel.getValue("actualStatus") == 1 &&
                    new Intl.NumberFormat("en-GB", { style: "currency", currency: "GBP", maximumFractionDigits: 0 }).format(props.viewModel.getValue("basePurchasePrice"))}
            </PCentered>

            <Box flexBox showIf={props.viewModel.isMobile} padding={props.viewModel.isMobile ? "0px 20px" : "0px"}>
                <PCentered style={{ opacity: isDisabled() ? "0.5" : "1" }}>
                    {/* {props.viewModel.getStatus(props.viewModel.getValue("actualStatus")) !== "reserved" || "sold" ? "For Sale" : ""} */}
                    {props.viewModel.getAvailableStatus(props.viewModel.getValue("actualStatus"))}
                </PCentered>

                <PCentered style={{ opacity: isDisabled() ? "0.5" : "1" }}>
                    {props.viewModel.getValue("actualStatus") == 1 &&
                        new Intl.NumberFormat("en-GB", { style: "currency", currency: "GBP", maximumFractionDigits: 0 }).format(props.viewModel.getValue("basePurchasePrice"))}
                </PCentered>
            </Box>

            <Box justifySelf={props.viewModel.isMobile ? "left" : "center"} padding={props.viewModel.isMobile ? "0px 20px" : "0px"}>
                <ListingButton
                    isDisabled={isDisabled()}
                    command={goToSelectedProperty}
                    displayName={props.viewModel.getStatus(props.viewModel.getValue("actualStatus"))?.toLocaleUpperCase()}
                    className={
                        props.viewModel.getStatus(props.viewModel.getValue("actualStatus")) == "sold"
                            ? "sold"
                            : props.viewModel.getStatus(props.viewModel.getValue("actualStatus")) == "reserved"
                            ? "reserved"
                            : ""
                    }
                    style={{ opacity: isDisabled() ? "0.5" : "1", cursor: isDisabled() ? "default" : "pointer" }}
                />
            </Box>
            <HorizontalGradLineDarkLightDark showIf={props.viewModel.isMobile} widthOfLine="100%" heightOfLine="3px"></HorizontalGradLineDarkLightDark>
        </>
    );
});

export const ListingButton = styled(ShorelineButton)`
    text-align: center;
    width: 96px;
    padding: 0;
    background-color: #1f2747;
    height: 19px;
    font-family: "open sans";
    font-weight: 300;
    /* box-shadow: 0px 3px 6px #00000029; */
    &.selected {
        background-color: #27684e;
    }
    &.sold {
        background: #f06f56;
    }
    &.reserved {
        background: #e0a752;
    }
`;

const PCentered = styled(P)`
    text-align: center;
`;
