import { useEffect, useState } from "react";

import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { useTimeout } from "../../../Application/Hooks";
import { theme } from "../../../Styles/AppTheme";
import loadingSpinner from "Assets/LoadingSpinner.svg";
import { H2 } from "../TextElements/TextElements";
import Logo from "Assets/ShorelineLogoMk2.svg";

export const Wrapper = styled.div`
    .loading-page {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 100vh;
        background-color: #ecdfc9;
    }

    .loading-icon {
        border: 5px solid #f3f3f3;
        border-top: 5px solid #3498db;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        animation: spin 1s linear infinite;
    }

    /* .loading-text {
        font-size: 2em;
        margin-left: 1em;
    } */

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;

/**
 * WARNING - If your styled components needs to change style because of change in the state of the
 * model (via the props), wrap the component in a mobx observer.
 */
interface Props {
    isOpen: boolean;
    delayBeforeOpen?: number;
}
export const ThemedLoader: React.FC<Props> = observer((props: Props) => {
    const [show, setShow] = useState(false);
    const { isOpen, delayBeforeOpen, ...rest } = props;

    const myEffect = keyframes({
        "0%": {
            backgroundColor: "transparent",
            opacity: "0",
        },
        "100%": {
            backgroundColor: `${theme.palette.common.default}B2`,
            opacity: "1",
        },
    });

    const { start, clear } = useTimeout(() => {
        setShow(true);
    }, delayBeforeOpen);

    useEffect(() => {
        if (isOpen) {
            start();
        }
        return () => {
            clear();
        };
    }, []);

    return (
        <Wrapper>
            {show && (
                <div className="loading-page">
                    <img src={Logo} />
                    <H2 className="loading-text" p="40px 10px" color="primary">
                        Shoreline Crescent
                    </H2>
                    <img src={loadingSpinner} height="40px" />
                    {/* <div className="loading-icon"></div> */}
                </div>
            )}
        </Wrapper>
    );
});
