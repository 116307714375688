import React, { useEffect, useState } from "react";
import "index.scss";
import * as serviceWorker from "serviceWorker";
import "reflect-metadata";
import { App } from "./App";
import { CoreStoreInstance, getBaseUrl, createLogger, LoggerType, LogLevel } from "@shoothill/core";
import { AppErrorBoundary } from "AppErrorBoundary";
import * as MobX from "mobx";
import * as History from "history";
import { Startup } from "./Startup";
import { config } from "./config";
import ReactGA from "react-ga4";
import TagManager from "react-gtm-module";

MobX.configure({ enforceActions: "observed" });

const baseUrl: string = getBaseUrl();
export const GlobalHistory: History.History = History.createBrowserHistory({
    //basename: baseUrl,
});

export const SendQAEvent = (eventName: string) => {
    if (localStorage.getItem("acceptsCookies") == "true") {
        ReactGA.event(eventName);
    }
};

export const Logger = createLogger(LoggerType.Console);
//(window as any).GlobalHistory = GlobalHistory;

GlobalHistory.listen((location: any) => {
    if (CoreStoreInstance) {
        CoreStoreInstance.PageView(location.pathname);
    }
    const pathname: string = location.location.pathname;
    let title: string = "Shoreline";

    switch (pathname) {
        case "/vision": {
            title = "Our Vision";
            break;
        }
        case "/homes": {
            title = "Homes";
            break;
        }
        case "/about": {
            title = "About";
            break;
        }
        case "/living": {
            title = "Living";
            break;
        }
        case "/newhomes": {
            title = "NewHomes";
            break;
        }
        case "/register": {
            title = "Register";
            break;
        }
        default: {
            break;
        }
    }

    if (localStorage.getItem("acceptsCookies") == "true") {
        ReactGA.send({ hitType: "pageview", page: pathname, title: title });
    }
    // Use setTimeout to make sure this runs after React Router's own listener
    setTimeout(() => {
        // Keep default behavior of restoring scroll position when user:
        // - clicked back button
        // - clicked on a link that programmatically calls `history.goBack()`
        // - manually changed the URL in the address bar (here we might want
        // to scroll to top, but we can't differentiate it from the others)
        if (location.action === "POP") {
            return;
        }
        //Hide info bar when we are navigating between pages
        CoreStoreInstance.HideInfoBar();
        // In all other cases, check fragment/scroll to top
        let hash = window.location.hash;
        if (hash) {
            let element = document.querySelector(hash);
            if (element) {
                element.scrollIntoView({ block: "start", behavior: "smooth" });
            }
        } else {
            window.scrollTo(0, 0);
        }
    });
});

export const IsDevMode: boolean = process.env.NODE_ENV === "development";
window.IsDev = IsDevMode;
window.CoreOptions = {
    useSessionStorage: true,
};

const tagManagerArgs = {
    gtmId: "GTM-TCFKKL6",
};

export const Init: React.FunctionComponent = () => {
    const [isLoading, setIsLoading] = useState(true);
    const queryParams = new URLSearchParams(window.location.search);
    /*const logLevelQuery = new URLSearchParams(search).get("debug");*/
    if (queryParams.get("debug")) {
        config.logLevel = LogLevel.Debug;
    }
    Logger.logLevel = config.logLevel;
    Logger.logInformation("Starting up", Logger.logLevel);

    if (localStorage.getItem("acceptsCookies") == "true") {
        ReactGA.initialize("G-F3YH30PQ7S");
        TagManager.initialize(tagManagerArgs);
    }
    useEffect(() => {
        //let _ = viewModel.getInitialState();
        const startup = new Startup();
        const doAsync = async () => {
            await startup.init();
            setIsLoading(false);
        };
        const _ = doAsync();
    }, []);

    const getRender = () => {
        if (isLoading) {
            //Display loading screen so all the stores, globals etc are ready before loading the main app
            return <ThemedLoader delayBeforeOpen={0} isOpen={true} />;
        } else {
            return (
                <AppErrorBoundary>
                    <ThemedLoader delayBeforeOpen={0} isOpen={isLoading} />
                    <CookieConsent />

                    <App />
                </AppErrorBoundary>
            );
        }
    };

    return getRender();
};

//ReactDOM.render(<Init />, document.getElementById("root"));
const container: HTMLElement = document.getElementById("root") as HTMLElement;
const root = createRoot(container);
root.render(<Init />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
import { render } from "react-dom";
import { createRoot } from "react-dom/client";
import { ThemedLoader } from "./Components/Primitives/Loaders/ThemedLoader";
import { CookieConsent } from "Components/CookieConsent/CookieConsent";
import { constants } from "crypto";
