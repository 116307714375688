import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { Validator } from "Application/Validation";

export class SignInModel extends ModelBase<SignInModel> {
    public id: string = "";

    public emailAddress: string = "";
    public password: string = "";

    constructor() {
        super();
        makeObservable(this, {
            id: observable,

            emailAddress: observable,
            password: observable,
        });
    }
}

export class SignInModelValidator extends Validator<SignInModel> {
    constructor() {
        super();
        //this.ruleFor("id").notEmpty();
        this.ruleFor("emailAddress").emailAddress().maxLength(100);
        this.ruleFor("password").notEmpty().withMessage("Password cannot be empty").maxLength(100).withMessage("Password cannot exceed 100 characters");
    }
}
