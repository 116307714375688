import { singleton } from "tsyringe";
import { IObservableArray, action, computed, makeObservable, observable } from "mobx";
import { UserFavourite, UserFavouriteResponse } from "Views/BuyerLoginDashboard/UserFavourite";
import { GetUserFavouritesEndpoint, GetUserFavouritesResponse } from "Endpoints/User/GetUserFavourites";
import { APIClient } from "Application/Helpers/APIClient";
import { UpsertUserFavouriteEndpoint, UserFavouriteRequest } from "Endpoints/User/UpsertUserFavourite";
import { BuildingType } from "Views/NewHomes/NewHomesViewModel";
import { brochureTypes } from "Views/BrochureAddendums/DownloadViewModel";
@singleton()
export class DomainStore {
    public isSideBarClosed: boolean = true;
    public isLoginModalOpen: boolean = false;
    public isBrochureModalOpen: boolean = false;
    public brochureType: brochureTypes = brochureTypes.FullBrochure;

    public userFavourites: IObservableArray<UserFavourite> = observable([]);

    public scrollPosition: number = 0;

    public scrollToPosition: number = 0;

    public buildingTypeSelected: BuildingType = BuildingType.NoneSelected;

    constructor() {
        makeObservable(this, {
            isLoginModalOpen: observable,
            isSideBarClosed: observable,
            isBrochureModalOpen: observable,
            userFavourites: observable,
            scrollPosition: observable,
            buildingTypeSelected: observable,
            scrollToPosition: observable,
            brochureType: observable,
            loadUserFavourites: action,
            reset: action,
            setFavouriteState: action,
            setIsLoginModalOpen: action,
            setSideBarClosed: action,
            getUserFavourites: computed,
            setScrollPosition: action,
            setBuildingTypeSelected: action,
            setScrollToPosition: action,
        });
        (window as any).DomainStore = this;
    }

    public get getUserFavourites() {
        return this.userFavourites.slice().filter((a) => a.isDeleted === false);
    }

    public setUserFavourites = (value: UserFavourite[]) => {
        this.userFavourites.replace(value);
    };

    public setSideBarClosed = (isOpen: boolean) => {
        this.isSideBarClosed = isOpen;
    };

    public setIsLoginModalOpen = (isOpen: boolean) => {
        this.isLoginModalOpen = isOpen;
    };

    public openBrochureModal = () => {
        this.isBrochureModalOpen = true;
    };

    public closeBrochureModal = () => {
        this.isBrochureModalOpen = false;
    };

    public reset() {
        // user has logged out so scrap the favourite list
        this.userFavourites.clear();
    }

    public loadUserFavourites = async () => {
        const apiClient = new APIClient();
        const endpoint: GetUserFavouritesEndpoint = new GetUserFavouritesEndpoint();

        if (apiClient.IsBusy === false) {
            this.userFavourites.clear();
            await apiClient.sendAsync(endpoint);

            if (apiClient.IsRequestSuccessful === true) {
                let response = apiClient.Response<GetUserFavouritesResponse>();
                this.userFavourites.replace(response);
            } else {
                // TODO CMR Not much we can do;
            }
        }
    };

    public setFavouriteState = async (userId: string, propertyId: string, isDeleted: boolean) => {
        const apiClient = new APIClient();
        const endpoint: UpsertUserFavouriteEndpoint = new UpsertUserFavouriteEndpoint();

        if (apiClient.IsBusy === false) {
            let request: UserFavouriteRequest = new UserFavouriteRequest();
            request.isDeleted = isDeleted;
            request.propertyId = propertyId;
            request.userId = userId;

            await apiClient.sendAsync(endpoint, request);

            if (apiClient.IsRequestSuccessful === true) {
                let response = apiClient.Response<UserFavouriteResponse>();
                this.userFavourites.replace(response);
            } else {
                // TODO CMR Not much we can do;
            }
        }
    };

    public isFavourite(propertyId: string): boolean {
        let retVal: boolean = false; // it is either deleted or doesn't exist

        if (this.userFavourites.length > 0) {
            const propertyFav: UserFavourite | undefined = this.userFavourites.find((a) => a.propertyId === propertyId);

            if (propertyFav !== undefined && propertyFav.isDeleted === false) {
                retVal = true;
            }
        }
        return retVal;
    }

    public setScrollPosition = (scroll: number) => {
        this.scrollPosition = scroll * 100;
    };

    public setScrollToPosition = (scroll: number) => {
        this.scrollToPosition = scroll;
    };

    public setBuildingTypeSelected = (buildingType: BuildingType) => {
        this.buildingTypeSelected = buildingType;
    };
}
