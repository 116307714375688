import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Box, CheckBox, ICommand, Input, Label, MaskedOptions, RelayCommand, theme } from "Application";
import { FullWidthCentFlexContainer } from "Components/FullWidthCentFlexCont";
import { InnerWrapper } from "Components/InnerWrapper";
import styled from "@emotion/styled";
import { ShorelineButton } from "Components/ShorelineButton";
import { ContactViewModel } from "./ContactViewModel";
import { CoreStoreInstance } from "@shoothill/core";
import { ContactDetailsBox, ContactFormBox, MarketP, OurDetailsBox, OurDetailsBoxInner } from "./Contact.styles";
import { H2, H3, P } from "Components/Primitives/TextElements/TextElements";
import { Link } from "react-router-dom";
import { GoogleMap, useLoadScript, Marker, InfoBox } from "@react-google-maps/api";
import { SendQAEvent } from "index";
import loadingSpinner from "Assets/LoadingSpinner.svg";
import { Modal } from "@fluentui/react";
import { currencyFormat } from "Utils/CurrencyFormatting";

const apikey = "AIzaSyD_NyHXj6sSgemvuz3evRTzHXzMd-ZCWLM";

export const ContactView: React.FC = observer(() => {
    const [viewModel] = useState<ContactViewModel>(new ContactViewModel(undefined));
    const doSomething: ICommand = new RelayCommand((value: string) => {});
    const [GoogleMapComponent, setGoogleMapComponent] = useState(<div>Map loading...</div>);

    CoreStoreInstance.CoreOptions.mobileBreakPoint = 600;

    const tabletPadding = "20px";

    useEffect(() => {
        if (viewModel.isModelValid(true, true) === true) {
            CoreStoreInstance.HideInfoBar();
        }
    }, [viewModel.isModelValid(true, true)]);

    useEffect(() => {
        viewModel.sendEventCallback = SendQAEvent;
        setGoogleMapComponent(<GoogleMapRender />);
    }, []);

    function GoogleMapRender() {
        const { isLoaded } = useLoadScript({ googleMapsApiKey: apikey });
        if (!isLoaded) return <div>Map Loading...</div>;
        return <Map></Map>;
    }

    function Map() {
        return (
            <>
                <GoogleMap
                    zoom={15}
                    mapTypeId={"roadmap"}
                    tilt={0}
                    options={{
                        streetViewControl: false,
                        mapTypeId: "roadmap",
                        keyboardShortcuts: false,
                        gestureHandling: "cooperative",
                        styles: [
                            {
                                featureType: "all",
                                stylers: [
                                    { saturation: -100 }, // Set saturation to -100 for black and white
                                    { lightness: 30 }, // Adjust lightness to change the overall brightness
                                ],
                            },
                        ],
                    }}
                    center={{
                        lat: 51.07767572860049,
                        lng: 1.183196654076703,
                    }}
                    mapContainerStyle={{ height: "734px", width: "100%" }}
                >
                    <Marker
                        position={{
                            lat: 51.07767572860049,
                            lng: 1.183196654076703,
                        }}
                        onClick={() => {
                            window.open("https://goo.gl/maps/yjjLKdV26bwv4BTKA");
                        }}
                    />
                </GoogleMap>
            </>
        );
    }

    return (
        <>
            <Modal isOpen={viewModel.isModalOpen} onDismiss={viewModel.closeSentModal}>
                <P p="40px">Thank you for registering your interest</P>
                <Box p="20px">
                    <ShorelineButton command={viewModel.backtoHomepage} displayName="BACK TO HOME PAGE" />
                </Box>
            </Modal>

            <FullWidthCentFlexContainer>
                <InnerWrapper
                    maxWidth="690px"
                    pl={viewModel.isTablet || viewModel.isMobile ? tabletPadding : ""}
                    pr={viewModel.isTablet || viewModel.isMobile ? tabletPadding : ""}
                >
                    <Box grid dc="auto minmax(auto, 345px)" tc="1fr" columnGap={60}>
                        <Box>
                            <H2 color="primary" pt={40} pb={20}>
                                BOOK AN APPOINTMENT
                            </H2>
                        </Box>
                        <Box></Box>
                    </Box>

                    <Box>
                        <ContactFormBox grid dc="1fr 1fr" mc="1fr 1fr" order={viewModel.isTablet || viewModel.isMobile ? 1 : 0}>
                            <Box className="fullWidth"></Box>
                            <Box className={viewModel.isMobile ? "fullWidth" : ""} style={{ alignSelf: "start" }}>
                                <Input
                                    command={viewModel.updateFirstName}
                                    type="text"
                                    displayName="First name*"
                                    placeholder="First name"
                                    labelColor="primary"
                                    value={() => viewModel.getValue("firstName")}
                                    validationMessage={() => viewModel.getError("firstName")}
                                ></Input>
                            </Box>
                            <Box className={viewModel.isMobile ? "fullWidth" : ""} style={{ alignSelf: "start" }}>
                                <Input
                                    command={viewModel.updateLastName}
                                    type="text"
                                    displayName="Last name*"
                                    placeholder="Last name"
                                    labelColor="primary"
                                    value={() => viewModel.getValue("surname")}
                                    validationMessage={() => viewModel.getError("surname")}
                                ></Input>
                            </Box>

                            <Box className={"fullWidth"}>
                                <Input
                                    command={viewModel.updateAddressLine1}
                                    type="text"
                                    displayName="Address line 1*"
                                    placeholder="Address line 1"
                                    labelColor="primary"
                                    value={() => viewModel.getValue("addressLine1")}
                                    validationMessage={() => viewModel.getError("addressLine1")}
                                ></Input>
                            </Box>
                            <Box className={"fullWidth"}>
                                <Input
                                    command={viewModel.updateAddressLine2}
                                    type="text"
                                    displayName="Address line 2"
                                    placeholder="Address line 2"
                                    labelColor="primary"
                                    value={() => viewModel.getValue("addressLine2")}
                                    /* validationMessage={() => viewModel.getError("addressLine2")} */
                                ></Input>
                            </Box>
                            <Box className={viewModel.isMobile ? "fullWidth" : ""} style={{ alignSelf: "start" }}>
                                <Input
                                    command={viewModel.updateCity}
                                    type="text"
                                    displayName="City / Town*"
                                    placeholder="City / Town"
                                    labelColor="primary"
                                    value={() => viewModel.getValue("city")}
                                    validationMessage={() => viewModel.getError("city")}
                                ></Input>
                            </Box>
                            <Box className={viewModel.isMobile ? "fullWidth" : ""} style={{ alignSelf: "start" }}>
                                <Input
                                    command={viewModel.updatePostcode}
                                    type="text"
                                    displayName="Post code*"
                                    placeholder="Post code"
                                    labelColor="primary"
                                    value={() => viewModel.getValue("postcode")}
                                    validationMessage={() => viewModel.getError("postcode")}
                                ></Input>
                            </Box>

                            <Box className="fullWidth">
                                <Input
                                    command={viewModel.updateEmail}
                                    type="email"
                                    displayName="Email address*"
                                    placeholder="Email address"
                                    labelColor="primary"
                                    value={() => viewModel.getValue("contactEmail")}
                                    validationMessage={() => viewModel.getError("contactEmail")}
                                ></Input>
                            </Box>
                            <Box className="fullWidth" style={{ alignSelf: "start" }}>
                                <Input
                                    command={viewModel.updatePhoneNumber}
                                    displayName="Phone number*"
                                    placeholder="Phone number"
                                    type="number"
                                    labelColor="primary"
                                    value={() => viewModel.getValue("primaryContactNumber")}
                                    // maskedOptions={MaskedOptions.MobilePhoneNumber()}
                                    validationMessage={() => viewModel.getError("primaryContactNumber")}
                                ></Input>
                            </Box>

                            <Box className={"fullWidth"}>
                                <Label>Preferred method of contact*</Label>
                            </Box>

                            <CheckBox
                                className="inputConsent"
                                displayName="&nbsp;&nbsp;Email"
                                command={viewModel.updatePrefersEmails}
                                labelColor="primary"
                                value={() => viewModel.getValue("preferredContactMethodEmail")}
                                validationMessage={() => viewModel.getError("preferredContactMethodEmail")}
                            ></CheckBox>
                            <CheckBox
                                className="inputConsent"
                                displayName="&nbsp;&nbsp;Phone"
                                command={viewModel.updatePrefersTelCalls}
                                labelColor="primary"
                                value={() => viewModel.getValue("preferredContactMethodPhone")}
                                validationMessage={() => viewModel.getError("preferredContactMethodPhone")}
                            ></CheckBox>
                            {viewModel.getPreferredContactMethodValidationMessage !== "" && (
                                <Box className={"fullWidth"}>
                                    <Label fontStyle="error" color="error">
                                        {viewModel.getPreferredContactMethodValidationMessage}
                                    </Label>
                                </Box>
                            )}
                            {/* <Box className={"fullWidth"}>
                                <Input
                                    command={viewModel.updateAddressLine1}
                                    type="text"
                                    displayName="Address line 1*"
                                    placeholder="Address line 1"
                                    labelColor="black"
                                    value={() => viewModel.getValue("addressLine1")}
                                    validationMessage={() => viewModel.getError("addressLine1")}
                                ></Input>
                            </Box> */}
                            {/* <Box className={"fullWidth"}>
                                <Input
                                    command={viewModel.updateAddressLine2}
                                    type="text"
                                    displayName="Address line 2"
                                    placeholder="Address line 2"
                                    labelColor="black"
                                    value={() => viewModel.getValue("addressLine2")}
                                ></Input>
                            </Box> */}
                            {/* <Box className={viewModel.isMobile ? "fullWidth" : ""} style={{ alignSelf: "start" }}>
                                <Input
                                    command={viewModel.updateCity}
                                    type="text"
                                    displayName="City / Town*"
                                    placeholder="City / Town"
                                    labelColor="black"
                                    value={() => viewModel.getValue("city")}
                                    validationMessage={() => viewModel.getError("city")}
                                ></Input>
                            </Box>
                            <Box className={viewModel.isMobile ? "fullWidth" : ""} style={{ alignSelf: "start" }}>
                                <Input
                                    command={viewModel.updatePostcode}
                                    type="text"
                                    displayName="Post code*"
                                    placeholder="Post code"
                                    labelColor="black"
                                    value={() => viewModel.getValue("postcode")}
                                    validationMessage={() => viewModel.getError("postcode")}
                                ></Input>
                            </Box> */}

                            <Box className="fullWidth">
                                <Input
                                    command={viewModel.updateMessage}
                                    type="text"
                                    multiline={true}
                                    displayName="Message"
                                    placeholder="Any notes..."
                                    labelColor="primary"
                                    value={() => viewModel.getValue("notes")}
                                    style={{ resize: "none" }}
                                    rows={6}
                                    validationMessage={() => viewModel.getError("notes")}
                                ></Input>
                            </Box>
                            <Box className={viewModel.isMobile ? "fullWidth" : ""} style={{ alignSelf: "start" }}>
                                <Input
                                    command={viewModel.updateBudgetFrom}
                                    type="text"
                                    displayName="Budget from*"
                                    placeholder="Budget from"
                                    labelColor="primary"
                                    value={() => currencyFormat(viewModel.getValue("budgetFrom"))}
                                    validationMessage={() => viewModel.getError("budgetFrom")}
                                    prefix={<p>£</p>}
                                ></Input>
                            </Box>
                            <Box className={viewModel.isMobile ? "fullWidth" : ""} style={{ alignSelf: "start" }}>
                                <Input
                                    command={viewModel.updateBudgetTo}
                                    type="text"
                                    displayName="Budget to*"
                                    placeholder="Budget to"
                                    labelColor="primary"
                                    value={() => currencyFormat(viewModel.getValue("budgetTo"))}
                                    validationMessage={() => viewModel.getError("budgetTo")}
                                    prefix={<p>£</p>}
                                ></Input>
                            </Box>

                            <Box className="fullWidth">
                                <P color="black" pb="20px">
                                    <CheckBox
                                        className="inputConsent"
                                        displayName="&nbsp;&nbsp;Yes, I want to know about updates from Shoreline"
                                        command={viewModel.updateWantsUpdates}
                                        value={() => viewModel.getValue("acceptsMarketing")}
                                        labelColor="primary"
                                    ></CheckBox>
                                </P>
                                <P color="black" pb="20px">
                                    <CheckBox
                                        className="inputConsent"
                                        displayName="&nbsp;&nbsp;I agree to the "
                                        command={viewModel.updateAgreesPrivacyPolicy}
                                        labelColor="primary"
                                        value={() => viewModel.getValue("agreePrivacyPolicy")}
                                        validationMessage={() => viewModel.getError("agreePrivacyPolicy")}
                                        hrefLink={
                                            <Link className="privacy-policy-link" to="/privacy-policy">
                                                privacy policy
                                            </Link>
                                        }
                                    ></CheckBox>

                                    {/* &nbsp;&nbsp; I agree to the <span className="underline">privacy policy</span> */}
                                </P>
                                <Label fontStyle="error" color="error">
                                    {viewModel.getError("agreePrivacyPolicy")}
                                </Label>
                            </Box>
                            <Box flexBox mb="30px" dc="1fr" style={{ gridColumn: "span 2", justifyContent: "center" }}>
                                <Box maxWidth="228px">
                                    {viewModel.isProcessing ? (
                                        <img src={loadingSpinner} />
                                    ) : (
                                        <ShorelineButton command={viewModel.onSubmit} displayName={!viewModel.messageSent ? "SEND" : "SENT"}></ShorelineButton>
                                    )}
                                </Box>
                            </Box>
                        </ContactFormBox>

                        <ContactDetailsBox>
                            <Box pb="100px">
                                <MarketP pb="15px">MARKETING SUITE NOW OPEN</MarketP>
                                <P p="20px 0px" color="primary">
                                    7 days a week, 10am to 5pm
                                </P>
                                <a href="tel:01303 760 940">
                                    <P color="primary">01303 760 940</P>
                                </a>
                                <a href="mailto:sales@folkestoneseafront.com" style={{ textDecoration: "none" }}>
                                    <P color="primary">sales@folkestoneseafront.com</P>
                                </a>
                            </Box>
                        </ContactDetailsBox>
                    </Box>
                </InnerWrapper>
            </FullWidthCentFlexContainer>

            <FullWidthCentFlexContainer>
                <InnerWrapper>
                    <Box>
                        {GoogleMapComponent}
                        {/* <GoogleMapRender /> */}
                    </Box>
                </InnerWrapper>
            </FullWidthCentFlexContainer>
        </>
    );
});
