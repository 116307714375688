import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";

import { LoginViewModel } from "./LoginViewModel";
import { Box, Button, Input, Label, CheckBox, setPageTitle, ThemedLoader } from "Application";

import { LoginHomeViewModel } from "../LoginHomeViewModel";
import { PersonSVG } from "../../../Assets/Icons/PersonSVG";
import { PadlockSVG } from "../../../Assets/Icons/PadlockSVG";

/**
 * Login page view.
 */
const LoginView = observer(({ loginHomeViewModel }: { loginHomeViewModel: LoginHomeViewModel }) => {
    // #region Code Behind
    const [viewModel] = useState(() => new LoginViewModel());
    useEffect(() => {
        setPageTitle("Sign In");
    }, []);
    // #endregion Code Behind

    const renderForm = () => (
        <>
            <Box grid alignContent={"center"} dc="1fr" rowGap={"30px"} minWidth={"300px"}>
                <Input
                    command={viewModel.updateEmailCommand}
                    prefix={<PersonSVG style={{ transform: "scale(1.4)", transformOrigin: "26px 6px" }} />}
                    displayName="Email address"
                    autoFocus={true}
                    value={() => viewModel.getValue("emailAddress")}
                    validationMessage={() => viewModel.getError("emailAddress")}
                    cy={"emailAddress"}
                />
                <Input
                    command={viewModel.updatePasswordCommand}
                    prefix={<PadlockSVG style={{ transform: "scale(1.4)", transformOrigin: "26px 6px" }} />}
                    displayName="Password"
                    type="password"
                    value={() => viewModel.getValue("password")}
                    validationMessage={() => viewModel.getError("password")}
                    cy={"password"}
                />
                <Box display={"flex"} justifyContent={"space-between"}>
                    <CheckBox command={viewModel.rememberMeCommand} value={() => viewModel.getValue("rememberMe")} displayName={"Remember me"} labelColor={"black"} />
                    <Box onClick={() => loginHomeViewModel.navigateToForgotPasswordCommand.execute()}>
                        <Label fontStyle={"h7"}>Forgot Password?</Label>
                    </Box>
                </Box>
                <Button cy={"login"} displayName="Continue" command={viewModel.loginCommand} paletteColor="primary" type="submit" />
            </Box>
        </>
    );

    const renderBusy = () => <ThemedLoader isOpen={viewModel.apiClient.IsBusy} />;

    return (
        <React.Fragment>
            {renderForm()}
            {renderBusy()}
        </React.Fragment>
    );
});
export default LoginView;
