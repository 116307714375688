import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { Box, ICommand, Input, RelayCommand } from "Application";
import { ForgotPasswordViewModel } from "./ForgotPasswordViewModel";
import { P } from "Components/Primitives/TextElements/TextElements";
import { ShorelineButton } from "Components/ShorelineButton";

import BackArrow from "Assets/Back.svg";
import styled from "@emotion/styled";
import { BuyerPanelView } from "Components/BuyerLogin/BuyerLogin";

interface IForgotPassword {
    setPanelView: React.Dispatch<React.SetStateAction<BuyerPanelView>>;
    closeModal: ICommand<any>;
}

export const ForgotPassword: React.FC<IForgotPassword> = observer((props) => {
    const [viewModel] = useState<ForgotPasswordViewModel>(new ForgotPasswordViewModel());

    const EnterEmail = (
        <>
            {/* <P style={{ fontSize: "18px", fontWeight: 600, textAlign: "center" }}>Forgot your password?</P> */}

            <P pt="20px">To reset your password please provide us with the email address you use on your account.</P>
            <Input
                displayName="Email Address"
                command={viewModel.updateEmail}
                value={() => viewModel.getValue("emailAddress")}
                validationMessage={() => viewModel.getError("emailAddress")}
                placeholder="Email address"
                displayNameSuffix={RedAsterisk}
            />
            <ShorelineButton displayName="SEND RESET PASSWORD LINK" command={viewModel.submit} paletteColor="primary" type="submit" />
        </>
    );

    const ResetRequestSent = (
        <>
            <P style={{ fontWeight: 400 }}>Thank you. A reset password link has been sent to your email.</P>
            <ShorelineButton command={props.closeModal} displayName="CLOSE" />
        </>
    );

    return (
        <>
            <Box grid alignContent={"center"} dc="1fr" rowGap={"30px"} minWidth={"300px"}>
                {viewModel.PanelView == "EnterEmail" && EnterEmail}
                {viewModel.PanelView == "ResetRequestSent" && ResetRequestSent}
            </Box>
        </>
    );
});

const RedAsterisk = <P style={{ padding: "5px 0px", color: "#F06F56" }}>*</P>;

const BackBox = styled(Box)`
    display: flex;
    justify-content: left;
    cursor: pointer;
`;
