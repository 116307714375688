import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Box, ICommand, Label, RelayCommand, theme } from "Application";
import { FullWidthCentFlexContainer } from "Components/FullWidthCentFlexCont";
import { InnerWrapper } from "Components/InnerWrapper";
import { NewHomesViewModel, BuildingType, loadingStatus } from "../NewHomesViewModel";

// Image Imports

import TempWestWingThumb from "Assets/TempWestWingthumb.jpg";
import TempEastWingThumb from "Assets/HomesApartments.png";
import TempTownhouseThumb from "Assets/townhouse.jpg";
import TownhouseThumb from "Assets/townhouse2.jpg";
import TempPenthouseThumb from "Assets/penthouse.jpg";
import TempDuplexThumb from "Assets/duplex.jpg";

import loadingSpinner from "Assets/LoadingSpinner.svg";

import ApartImage from "Assets/ApartmentImage.png";
/* import PenthouseImage from "Assets/PenthouseImage.png";
import TownhouseImage from "Assets/TownhouseImage2.png"; */
import BuildingTemp from "Assets/buildingTemp.png";
import { Link } from "react-router-dom";
import { NewHomesButton, Triangle, BuildingSticky, TriangleMobile } from "./NewHomeStyling";
import { PropertyListItem } from "./PropertyListItem";
import { CoreStoreInstance } from "@shoothill/core";
import { AppUrls } from "AppUrls";
import { H2, H3, P } from "Components/Primitives/TextElements/TextElements";
import styled from "@emotion/styled";
import { DataTable } from "Components/Primitives/DataTable";
import { NewHomeList } from "../NewHomeList/NewHomeList";
import { NewHomesListView } from "./NewHomesList";

import LoadingSpinner from "Assets/LoadingSpinner.svg";
import { BuildingTypeColumn } from "./BuildingTypeColumn/BuildingTypeColumn";
import { container } from "tsyringe";
import { DomainStore } from "Stores/Domain/DomainStore";
import { DownloadBrochureModal } from "Components/DownloadBrochure/DownloadBrochureModal/DownloadBrochureModal";
import { brochureTypes } from "Views/BrochureAddendums/DownloadViewModel";

interface INewHomeDefault {
    viewModel: NewHomesViewModel;
    path: string;
    clickValue(val: string): void;
}

export const NewHomeDefaultView: React.FC<INewHomeDefault> = observer((props) => {
    // const [BuildingTypeSelected, setBuildingTypeSelected] = useState<BuildingType>(BuildingType.NoneSelected);
    const domainStore = container.resolve(DomainStore);
    CoreStoreInstance.CoreOptions.mobileBreakPoint = 700;

    const [seletedPropertyId, setSelectedPropertyId] = useState<string>("");
    const [showProperty, setShowProperty] = useState<boolean>(false);

    useEffect(() => {
        if (seletedPropertyId.length > 0) {
            setShowProperty(true);
        }
    }, [seletedPropertyId]);

    useEffect(() => {}, []);

    const togglePropertyLists: ICommand = new RelayCommand((value: string) => {
        if (value === domainStore.buildingTypeSelected) {
            domainStore.setBuildingTypeSelected(BuildingType.NoneSelected);
        } else if (value.toLocaleLowerCase() === "WestWing") {
            props.clickValue("West");
            domainStore.setBuildingTypeSelected(BuildingType.WestWing);
        } else if (value.toLocaleLowerCase() === "townhouse") {
            props.clickValue("Townhouse");
            domainStore.setBuildingTypeSelected(BuildingType.Townhouse);
        } else if (value.toLocaleLowerCase() === "eastwing") {
            props.clickValue("East");
            domainStore.setBuildingTypeSelected(BuildingType.EastWing);
        } else if (value.toLocaleLowerCase() === "duplex") {
            props.clickValue("Duplex");
            domainStore.setBuildingTypeSelected(BuildingType.Duplex);
        } else if (value.toLocaleLowerCase() === "penthouse") {
            props.clickValue("Penthouse");
            domainStore.setBuildingTypeSelected(BuildingType.Penthouse);
        }
    });

    const navigateToWestWingView: ICommand = new RelayCommand((value: string) => {});

    return (
        <>
            <DownloadBrochureModal open={domainStore.isBrochureModalOpen} closeModal={domainStore.closeBrochureModal} />

            <Box style={{ minHeight: "100vh" }}>
                <FullWidthCentFlexContainer>
                    <InnerWrapper maxWidth={"1400px"} p="0px 40px">
                        <H2 color="primary" pt={50} pb={50}>
                            A place to really live
                        </H2>

                        {props.viewModel.isLoading == loadingStatus.Loading ? (
                            <Box style={{ display: "flex", justifyContent: "center" }}>
                                <img src={loadingSpinner} />
                            </Box>
                        ) : (
                            props.viewModel.isLoading == loadingStatus.YesDisplay && (
                                <Box
                                    grid
                                    dc={`repeat(${props.viewModel.numberOfColumns},1fr)`}
                                    tc={"1fr"}
                                    columnGap={props.viewModel.isMobile ? "0px" : "4px"}
                                    rowGap={props.viewModel.isMobile ? "20px" : "0px"}
                                    width={"100%"}
                                    style={{ textAlign: "left" }}
                                >
                                    {props.viewModel.areThereAnyEastWingApartments == loadingStatus.YesDisplay && (
                                        <BuildingTypeColumn
                                            title="East Apartments"
                                            imgUrl={TempEastWingThumb}
                                            buttonValue={"EastWing"}
                                            viewModel={props.viewModel}
                                            togglePropertyLists={togglePropertyLists}
                                            buildingTypeSelected={domainStore.buildingTypeSelected}
                                            backgroundColour="#FCEEDB"
                                            brochureToDownload={brochureTypes.EastApartment}
                                        />
                                    )}

                                    {domainStore.buildingTypeSelected == BuildingType.EastWing && !props.viewModel.isDesktop && (
                                        <NewHomesListView viewModel={props.viewModel} buildingType={domainStore.buildingTypeSelected} path={props.path}></NewHomesListView>
                                    )}

                                    {props.viewModel.areThereAnyWestWingApartments == loadingStatus.YesDisplay && (
                                        <BuildingTypeColumn
                                            title="West Wing"
                                            imgUrl={TempEastWingThumb}
                                            buttonValue={"WestWing"}
                                            viewModel={props.viewModel}
                                            togglePropertyLists={togglePropertyLists}
                                            buildingTypeSelected={domainStore.buildingTypeSelected}
                                            backgroundColour="#E6EFF5"
                                            brochureToDownload={brochureTypes.WestApartment}
                                        />
                                    )}

                                    {domainStore.buildingTypeSelected == BuildingType.WestWing && !props.viewModel.isDesktop && (
                                        <NewHomesListView viewModel={props.viewModel} buildingType={domainStore.buildingTypeSelected} path={props.path}></NewHomesListView>
                                    )}

                                    {/* <VerticalGradLineNoMargin></VerticalGradLineNoMargin> */}
                                    {props.viewModel.areThereAnyTownHouses == loadingStatus.YesDisplay && (
                                        <BuildingTypeColumn
                                            title="Beach Houses"
                                            imgUrl={TownhouseThumb}
                                            buttonValue={"TownHouse"}
                                            viewModel={props.viewModel}
                                            togglePropertyLists={togglePropertyLists}
                                            buildingTypeSelected={domainStore.buildingTypeSelected}
                                            backgroundColour="#CEE0EB"
                                            brochureToDownload={brochureTypes.Townhouse}
                                        />
                                    )}

                                    {domainStore.buildingTypeSelected == BuildingType.Townhouse && !props.viewModel.isDesktop && (
                                        <NewHomesListView viewModel={props.viewModel} buildingType={domainStore.buildingTypeSelected} path={props.path}></NewHomesListView>
                                    )}

                                    {props.viewModel.areThereAnyDuplexes === loadingStatus.YesDisplay && (
                                        <BuildingTypeColumn
                                            title="Duplex"
                                            imgUrl={TempDuplexThumb}
                                            buttonValue={"Duplex"}
                                            viewModel={props.viewModel}
                                            togglePropertyLists={togglePropertyLists}
                                            buildingTypeSelected={domainStore.buildingTypeSelected}
                                            backgroundColour="#9CC1D8"
                                            brochureToDownload={brochureTypes.Duplex}
                                        />
                                    )}

                                    {domainStore.buildingTypeSelected == BuildingType.Duplex && !props.viewModel.isDesktop && (
                                        <NewHomesListView viewModel={props.viewModel} buildingType={domainStore.buildingTypeSelected} path={props.path}></NewHomesListView>
                                    )}

                                    {props.viewModel.areThereAnyPenthouses === loadingStatus.YesDisplay && (
                                        <BuildingTypeColumn
                                            title="Penthouse"
                                            imgUrl={TempPenthouseThumb}
                                            buttonValue={"Penthouse"}
                                            viewModel={props.viewModel}
                                            togglePropertyLists={togglePropertyLists}
                                            buildingTypeSelected={domainStore.buildingTypeSelected}
                                            backgroundColour="#F9DCB6"
                                            brochureToDownload={brochureTypes.Penthouse}
                                        />
                                    )}

                                    {domainStore.buildingTypeSelected == BuildingType.Penthouse && !props.viewModel.isDesktop && (
                                        <NewHomesListView viewModel={props.viewModel} buildingType={domainStore.buildingTypeSelected} path={props.path}></NewHomesListView>
                                    )}
                                </Box>
                            )
                        )}
                    </InnerWrapper>
                </FullWidthCentFlexContainer>
                {/* <FullWidthCentFlexContainer>
                    <InnerWrapper maxWidth={"1000px"} display={"flex"} justifyContent={"space-between"}>
                        <Box width={"100%"} display={"flex"} justifyContent={"center"}>
                            <Triangle showIf={BuildingTypeSelected === BuildingType.EastWing && !props.viewModel.isMobile}></Triangle>
                        </Box>

                        <Box width={"100%"} display={"flex"} justifyContent={"center"}>
                            <Triangle showIf={BuildingTypeSelected === BuildingType.Townhouse}></Triangle>
                        </Box>
                    </InnerWrapper>
                </FullWidthCentFlexContainer> */}
                <FullWidthCentFlexContainer>
                    {domainStore.buildingTypeSelected == BuildingType.WestWing && (
                        <InnerWrapper maxWidth={"1000px"}>
                            <BuildingSticky>
                                <img src={BuildingTemp} width={"100%"}></img>
                            </BuildingSticky>
                        </InnerWrapper>
                    )}

                    {domainStore.buildingTypeSelected == BuildingType.EastWing && props.viewModel.isDesktop && (
                        <NewHomesListView viewModel={props.viewModel} buildingType={domainStore.buildingTypeSelected} path={props.path}></NewHomesListView>
                    )}

                    {domainStore.buildingTypeSelected == BuildingType.Townhouse && props.viewModel.isDesktop && (
                        <NewHomesListView viewModel={props.viewModel} buildingType={domainStore.buildingTypeSelected} path={props.path}></NewHomesListView>
                    )}

                    {domainStore.buildingTypeSelected == BuildingType.Duplex && props.viewModel.isDesktop && (
                        <NewHomesListView viewModel={props.viewModel} buildingType={domainStore.buildingTypeSelected} path={props.path}></NewHomesListView>
                    )}

                    {domainStore.buildingTypeSelected == BuildingType.Penthouse && props.viewModel.isDesktop && (
                        <NewHomesListView viewModel={props.viewModel} buildingType={domainStore.buildingTypeSelected} path={props.path}></NewHomesListView>
                    )}
                </FullWidthCentFlexContainer>
            </Box>
        </>
    );
});

const HeaderBox = styled(Box)`
    background-color: rgb(198, 181, 159);
    padding-top: 20px;
    padding-bottom: 20px;

    display: flex;
    justify-content: space-between;
`;

const PHeaderCentered = styled(P)`
    text-align: center;
`;

const Pipe = styled(P)`
    font-size: 22px;
    font-weight: 100;
    letter-spacing: -5px;
`;
