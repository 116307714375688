import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { Box, ICommand, RelayCommand } from "Application";
import { H2Grad } from "Components/GradHeadings";
import { HorizontalGradLineDarkLightDark } from "Components/HorizontalGradLineDarkLightDark";
import { FullWidthCentFlexContainer } from "Components/FullWidthCentFlexCont";
import { InnerWrapper } from "Components/InnerWrapper";
import { H2, P } from "Components/Primitives/TextElements/TextElements";
import { PolicyLi, PolicyPara, PolicyUl, PolicyWrapper } from "./Policy.Styles";
import { ShorelineButton } from "Components/ShorelineButton";
import { Link } from "react-router-dom";
import { PolicyViewModel } from "./PolicyViewModel";

export const PrivacyPolicyView: React.FC = observer(() => {
    const [viewModel] = useState<PolicyViewModel>(new PolicyViewModel());
    const doSomething: ICommand = new RelayCommand((value: string) => {});

    return (
        <>
            <PolicyWrapper>
                <FullWidthCentFlexContainer>
                    <InnerWrapper maxWidth="1000px" ml="20px" mr="20px">
                        <Box grid dc="1fr auto" pt="40px" pb="40px">
                            <Box>
                                <H2 pb="20px">Privacy policy</H2>
                            </Box>
                            <Box>
                                <Link to="/">
                                    <ShorelineButton command={doSomething} displayName="RETURN TO HOME PAGE"></ShorelineButton>
                                </Link>
                            </Box>
                        </Box>
                        <PolicyPara>
                            <strong>FOLKESTONE HARBOUR (GP) LTD. PRIVACY POLICY</strong>
                        </PolicyPara>
                        <PolicyPara>At Folkestone Harbour (GP) Limited, we’re committed to protecting and respecting your privacy.</PolicyPara>
                        <PolicyPara>
                            This Policy explains when and why we collect personal information about people who visit our website, how we use it, conditions under which we may
                            disclose it to others and how we keep it secure.
                        </PolicyPara>
                        <PolicyPara>
                            We may change this Policy from time to time so please check this page regularly to ensure that you’re happy with any changes. By using our website,
                            you’re agreeing to be bound by this Policy.
                        </PolicyPara>
                        <PolicyPara>
                            Any questions regarding this Policy and our privacy practices should be sent to{" "}
                            <a href="mailto:enquiries@folkestoneseafront.com">enquiries@folkestoneseafront.com</a> or by writing to Folkestone Harbour (GP) Limited, Strand House,
                            Pilgrims Way, Monks Horton, Ashford, TN25 6DR Alternatively, you can telephone <a href="tel:01303 817541">01303 817541</a>.
                        </PolicyPara>

                        <PolicyPara>
                            <strong>WHO ARE WE?</strong>
                        </PolicyPara>
                        <PolicyPara>
                            We are Folkestone Harbour & Seafront Development Co., and we are responsible for the new development at Folkestone Seafront known as Shoreline.
                            Folkestone Harbour & Seafront Development Co. is a trading name of Folkestone Shoreline Limited which is a private limited company (company no.
                            13976955). The registered address is Strand House, Pilgrims Way, Monks Horton, Ashford, TN25 6DR.
                        </PolicyPara>

                        <PolicyPara>
                            <strong>HOW DO WE COLLECT INFORMATION FROM YOU?</strong>
                        </PolicyPara>
                        <PolicyPara>
                            We obtain information about you that you give us in person, when you visit our website, when you use our guest Wi-Fi service and when you correspond
                            with us by phone, e-mail or otherwise.
                        </PolicyPara>
                        <PolicyPara>Personal data may also collected about you from third parties including credit referencing agencies.</PolicyPara>

                        <PolicyPara>
                            <strong>WHAT TYPE OF INFORMATION IS COLLECTED FROM YOU?</strong>
                        </PolicyPara>
                        <PolicyPara>We collect the following information:</PolicyPara>
                        <PolicyPara>
                            <PolicyUl>
                                <li>your name;</li>
                                <li>your contact information including your address, email address and telephone number;</li>
                                <li>when browsing our site, your IP address, your browser type and language;</li>
                                <li>information regarding what pages are accessed and when;</li>
                                <li>information related to your interest in Folkestone and the Seafront Development;</li>
                                <PolicyLi>
                                    information that you give us in person, when you visit our website, use our guest wi-fi service and and when you correspond with us by phone,
                                    e-mail or otherwise; and
                                </PolicyLi>
                                <li>CCTV/Webcam footage for monitoring and security purposes.</li>
                            </PolicyUl>
                        </PolicyPara>

                        <PolicyPara>
                            <strong>HOW IS YOUR INFORMATION USED?</strong>
                        </PolicyPara>
                        <PolicyPara>We may use your information to:</PolicyPara>
                        <PolicyPara>
                            <PolicyUl>
                                <PolicyLi>
                                    provide information about Folkestone and the Seafront Development, our activities and services, and any other activities and services that we
                                    deem relevant or think you might find interesting;
                                </PolicyLi>
                                <PolicyLi>carry out our obligations arising from any agreements entered into between you and us;</PolicyLi>
                                <PolicyLi>carry out estate management;</PolicyLi>
                                <PolicyLi>communicate with you;</PolicyLi>
                                <PolicyLi>seek your views or comments on the Seafront Development;</PolicyLi>
                                <PolicyLi>notify you of changes to the Seafront Development;</PolicyLi>
                                <PolicyLi>deal with entries into a competition;</PolicyLi>
                                <PolicyLi>process a job application;</PolicyLi>
                                <PolicyLi>
                                    administer our site and for internal operations, including troubleshooting, data analysis, testing, research, statistical and survey purposes.
                                </PolicyLi>
                                <PolicyLi>keep our site safe and secure;</PolicyLi>
                                <PolicyLi>comply with legal and regulatory obligations; and</PolicyLi>
                                <PolicyLi>enhance security and to check your identity.</PolicyLi>
                            </PolicyUl>
                        </PolicyPara>

                        <PolicyPara>
                            <strong>LEGAL BASIS FOR PROCESSING</strong>
                        </PolicyPara>
                        <PolicyPara>Our processing of your personal information is on the basis of:</PolicyPara>
                        <PolicyUl>
                            <PolicyLi>
                                performance of contracts which you will be a party to and in order to take steps at your request prior to you entering into those contracts;
                            </PolicyLi>
                            <PolicyLi>your consent (where we request it);</PolicyLi>
                            <PolicyLi>legitimate interests pursued by us; or</PolicyLi>
                            <PolicyLi>in order to comply with a legal or regulatory obligation to which we are subject.</PolicyLi>
                        </PolicyUl>
                        <PolicyPara>
                            In relation to any processing of special categories of personal data, we will generally rely on obtaining specific consent from you at the time unless
                            there is otherwise a legal requirement for us to process such information.
                        </PolicyPara>

                        <PolicyPara>
                            <strong>LEGITIMATE INTERESTS</strong>
                        </PolicyPara>
                        <PolicyPara>
                            Where our processing is based on the legitimate interests pursued by us, those legitimate interests include: (i) collecting personal information to
                            provide you with a smooth and efficient client experience; (ii) to make sure you receive updated information on various activities; (iii) to provide the
                            services you have requested; (iv) for our own marketing and research; and (v) detecting and preventing fraud and improving security and optimisation of
                            our network, sites and services.
                        </PolicyPara>
                        <PolicyPara>
                            Where we use your information for our legitimate interests, we make sure that we take into account any potential impact that such use may have on you.
                            Our legitimate interests don’t automatically override yours and we won’t use your information if we believe that your interests should override ours
                            unless we have other grounds to do so (such as consent or a legal or regulatory obligation).
                        </PolicyPara>

                        <PolicyPara>
                            <strong>WHO CAN ACCESS YOUR INFORMATION?</strong>
                        </PolicyPara>
                        <PolicyPara>
                            Except as expressly set out in this policy we will not sell, distribute or lease your personal information to third parties unless we have your
                            permission or are required by law to do so.
                        </PolicyPara>
                        <PolicyPara>
                            The personal information you provide to us may be shared with third party companies, agents, contractors, service providers or affiliated companies, or
                            by those duly authorised by any of them, if this is necessary to provide you with information or services, respond to your enquiries or for any of the
                            purposes described in this policy, and provided that we are satisfied they take appropriate measures to protect your personal data.
                        </PolicyPara>
                        <PolicyPara>
                            We may also, for business and operational purposes, share your personal data with companies within our group, including our subsidiaries, affiliated
                            companies, partners within the Folkestone Harbour Limited Partnership and subsidiaries of Folkestone Harbour Holdings Limited.
                        </PolicyPara>

                        <PolicyPara>
                            <strong>WE MAY ALSO SHARE YOUR PERSONAL INFORMATION WITH:</strong>
                        </PolicyPara>
                        <PolicyPara>
                            Law enforcement agencies, other governmental agencies or third parties if we are required by law to do so; and other business entities should we plan to
                            merge with or be acquired by that business entity, or if we undergo a re-organisation with that entity.
                        </PolicyPara>

                        <PolicyPara>
                            <strong>YOUR RIGHTS</strong>
                        </PolicyPara>
                        <PolicyPara>At any time, you have the right to:</PolicyPara>
                        <PolicyUl>
                            <PolicyLi>request access to or a copy of any personal data which we hold about you;</PolicyLi>
                            <PolicyLi>rectification of your personal data, if you consider that the information we are holding is inaccurate;</PolicyLi>
                            <PolicyLi>ask us to delete your personal data, if you consider that we do not have the right to hold it;</PolicyLi>
                            <PolicyLi>withdraw consent to our processing of your personal data (to the extent such processing is based on previously obtained consent);</PolicyLi>
                            <PolicyLi>ask us to stop or start sending you marketing communications as described below in the ‘Your Choice’ section;</PolicyLi>
                            <PolicyLi>restrict processing of your personal data;</PolicyLi>
                            <PolicyLi>data portability (moving some of your personal data elsewhere) in certain circumstances;</PolicyLi>
                            <PolicyLi>object to your personal data being processed in certain circumstances; and</PolicyLi>
                            <PolicyLi>
                                not be subject to a decision based on automated processing and to have safeguards put in place if you are being profiled based on your personal
                                data.
                            </PolicyLi>
                        </PolicyUl>
                        <PolicyPara>
                            Any request from you for access to or a copy of your personal data must be in writing. You will not have to pay a fee to access your personal data,
                            although we may charge a reasonable fee if your request is clearly unfounded, repetitive or excessive. Alternatively we may refuse to comply with your
                            request in these circumstances. We will endeavour to respond to all legitimate requests within one calendar month, although occasionally it may take us
                            longer than a month if your request is particularly complex or you have made a number of requests. In this case, we will notify you and keep you
                            updated.
                        </PolicyPara>
                        <PolicyPara>
                            We may need to request information from you to help confirm your identity and ensure your right to access your personal data (or to exercise any of your
                            other rights). This is a security measure to ensure that personal data is not disclosed to any person who has no right to receive it. We may also
                            contact you to ask you for further information in relation to your request for clarity or to speed up our response.
                        </PolicyPara>
                        <PolicyPara>
                            We will comply with our legal obligations as regards your rights as a data subject. We aim to ensure that the information we hold about you is accurate
                            at all times. To assist us in ensuring that your information is up to date, do let us know if any of your personal details change at the following email
                            address: <a href="mailto:enquiries@folkestoneseafront.com">enquiries@folkestoneseafront.com</a>.
                        </PolicyPara>

                        <PolicyPara>
                            <strong>COMPLAINTS</strong>
                        </PolicyPara>
                        <PolicyPara>
                            Questions, comments and requests regarding this policy are welcomed and should be addressed to{" "}
                            <a href="mailto:enquiries@folkestoneseafront.com">enquiries@folkestoneseafront.com</a>. If you have any concerns about our use of your information, you
                            also have the right (as a UK resident) to make a complaint to the Information Commissioner’s Office, which regulates and supervises the use of personal
                            data in the UK, who may be contacted in writing at Wycliffe House, Water Lane, Wilmslow, Cheshire, SK9 5AF; by telephone on 0303 123 1113; by fax on
                            01625 524510; or online at{" "}
                            <a href="https://ico.org.uk/" target="_blank">
                                www.ico.org.uk
                            </a>
                            .
                        </PolicyPara>

                        <PolicyPara>
                            <strong>YOUR CHOICE</strong>
                        </PolicyPara>
                        <PolicyPara>
                            You have a choice about whether or not you wish to receive information from us. If you do not wish to receive direct marketing communications from us
                            about the work we do, then you can do so at any time by emailing us (
                            <a href="mailto:enquiries@folkestoneseafront.com">enquiries@folkestoneseafront.com</a>) and requesting that we stop contacting you.
                        </PolicyPara>
                        <PolicyPara>
                            We will not contact you for any marketing purposes unless you have given your prior consent. You can change your marketing preferences at any time by
                            contacting us by email: <a href="mailto:enquiries@folkestoneseafront.com">enquiries@folkestoneseafront.com</a> or telephone on{" "}
                            <a href="tel:01303 817 541">01303 817 541</a>. We will action any opt out requests from you without delay.
                        </PolicyPara>

                        <PolicyPara>
                            <strong>HOW LONG WILL WE KEEP YOUR INFORMATION?</strong>
                        </PolicyPara>
                        <PolicyPara>
                            We will only keep the information we collect about you for as long as required for the purposes set out above or as required to comply with any legal
                            obligations to which we are subject. For further information for how long we will keep your information please contact{" "}
                            <a href="mailto:enquiries@folkestoneseafront.com">enquiries@folkestoneseafront.com</a>.
                        </PolicyPara>
                        <PolicyPara>
                            Where we are permitted to send you direct marketing communications we may retain your contact information necessary for this purpose, for as long as you
                            do not unsubscribe from receiving the same from us. If you opt out from marketing, we will retain your information to enable us to respect your wishes
                            to not be contacted for marketing purposes.
                        </PolicyPara>

                        <PolicyPara>
                            <strong>SECURITY TO PROTECT THE LOSS, MISUSE OR ALTERATION OF YOUR INFORMATION</strong>
                        </PolicyPara>
                        <PolicyPara>
                            When you give us personal information, we take steps to ensure that it’s treated securely. Non-sensitive details (your email address etc.) are
                            transmitted normally over the Internet, and this can never be guaranteed to be fully secure. As a result, while we ensure we do all we can to protect
                            your personal information, we cannot guarantee the security of any information you transmit to us, and you do so at your own risk. Once we receive your
                            information, we make our best effort to ensure its security on our systems. Where we have given (or you have chosen) a password which enables you to
                            access certain sections of our websites, you are responsible for keeping this password confidential. We ask you not to share your password with anyone.
                            We would recommend you update your password regularly.
                        </PolicyPara>

                        <PolicyPara>
                            <strong>LINKS TO THIRD PARTY WEBSITES</strong>
                        </PolicyPara>
                        <PolicyPara>
                            Our website may contain links to other sites, including via our social media buttons. While we try to link only to websites that share our high
                            standards and respect for privacy, we are not responsible for the content, security, or privacy practices employed by other websites and a link does not
                            constitute an endorsement of that website. Once you link to another website from our website you are subject to the terms and conditions of that
                            website, including, but not limited to, its internet privacy policy and practices. Please check these policies before you submit any data to these
                            websites. In addition, if you linked to our website from a third party site, we cannot be responsible for the privacy policies and practices of the
                            owners and operators of that third party site and recommend that you check the policy of that third party site.
                        </PolicyPara>
                        <PolicyPara>
                            Certain features of our site will allow for social networking. You should ensure when using these features that you do not submit any personal data that
                            you do not want to be sent, collected or used by other users, such as profile details or e-mail address.
                        </PolicyPara>

                        <PolicyPara>
                            <strong>PROFILING</strong>
                        </PolicyPara>
                        <PolicyPara>
                            We may analyse your personal information to create a profile of your interests and preferences so that we can contact you with information we think is
                            relevant to you. We may make use of additional information about you where available from external sources to help us do this effectively. We may also
                            use your personal information to detect and reduce fraud and credit risk.
                        </PolicyPara>

                        <PolicyPara>
                            <strong>16 OR UNDER</strong>
                        </PolicyPara>
                        <PolicyPara>
                            We are concerned to protect the privacy of children aged 16 or under. If you are aged 16 or under‚ please seek your parent or guardian’s permission
                            beforehand whenever you provide us with personal information. If you do not have that consent then you must not provide personal information to us.
                        </PolicyPara>

                        <PolicyPara>
                            <strong>TRANSFERRING YOUR INFORMATION OUTSIDE OF EUROPE</strong>
                        </PolicyPara>
                        <PolicyPara>
                            Your personal information may be transferred to, and stored at, a destination outside the European Economic Area (“EEA”) and may also be processed by
                            staff operating outside the EEA who work for us, or for one of our service providers.
                        </PolicyPara>
                        <PolicyPara>
                            When we, or our permitted third parties, transfer your information outside the European Economic Area, we or they will impose obligations on the
                            recipients of that data to protect your information to the standard required in the European Economic Area or otherwise require the recipient to
                            subscribe to international frameworks intended to enable secure data sharing. In the case of transfers by us, we may also transfer your information
                            where: (i) the transfer is to a country deemed to provide adequate protection of your information by the European Commission; or (ii) where you have
                            consented to the transfer.
                        </PolicyPara>

                        <PolicyPara>
                            <strong>REVIEW OF THIS POLICY</strong>
                        </PolicyPara>
                        <PolicyPara>
                            We may make changes to our privacy policy from time to time. We will post any changes to our site and where appropriate notify you by email. Please
                            check back frequently to see any updates or changes to our policy.
                        </PolicyPara>
                        <PolicyPara>
                            If you would like this policy to be supplied to you in another format (for example audio, large print, braille) please contact us at the address at
                            <a href="mailto:enquiries@folkestoneseafront.com"> enquiries@folkestoneseafront.com</a>.
                        </PolicyPara>
                        <Box maxWidth="250px" pb="60px" pt="20px">
                            <Link to="/">
                                <ShorelineButton command={doSomething} displayName="RETURN TO HOME PAGE"></ShorelineButton>
                            </Link>
                        </Box>
                    </InnerWrapper>
                </FullWidthCentFlexContainer>
            </PolicyWrapper>
        </>
    );
});
