import React, { useEffect, useRef, useState } from "react";
import { UnsupportedBrowserView } from "../UnsupportedBrowser/UnsupportedBrowserView";
import { Footer } from "../Footer/FooterView";
import { InfoBar } from "../../Components/InfoBar";
import { Outlet, useLocation } from "react-router";
import { Box } from "../../Components";
import { Header } from "../Header/Header";
import { FullWidthCentFlexContainer } from "Components/FullWidthCentFlexCont";
import { borderBottom } from "styled-system";
import styled from "@emotion/styled";
import { theme } from "Styles";

import { CoreStoreInstance } from "@shoothill/core";
import ScrollToTop from "Components/ScrollToTop";
import { CookieConsent } from "Components/CookieConsent/CookieConsent";
import { container } from "tsyringe";
import { DomainStore } from "Stores/Domain/DomainStore";
import { DownloadBrochureModal } from "Components/DownloadBrochure/DownloadBrochureModal/DownloadBrochureModal";

//EN: use empty generic type to get default react props
export const DefaultLayout: React.FC<{}> = (props) => {
    const ScrollDivRef = useRef<HTMLInputElement>(null);
    const domainStore = container.resolve(DomainStore);

    const { pathname } = useLocation();

    CoreStoreInstance.CoreOptions.mobileBreakPoint = 600;

    // Scroll to top of page each time route changes

    useEffect(() => {
        ScrollDivRef.current?.scrollTo(0, 0);
    }, [pathname]);

    const handleScroll = () => {
        if (ScrollDivRef.current) {
            const containerElement = ScrollDivRef.current;
            const scrollTop = containerElement.scrollTop;
            const maxScrollTop = containerElement.scrollHeight - containerElement.clientHeight;
            const scrollFraction = scrollTop / maxScrollTop;
            const frameCount = 60; // Change this value as needed
            const frameIndex = Math.min(frameCount - 1, Math.floor(scrollFraction * frameCount));

            // Do something with the frameIndex or scrollFraction if needed
            domainStore.setScrollPosition(scrollFraction);
            // console.log("Frame Index:", frameIndex);
            // console.log("Scroll Fraction:", scrollFraction);
        }
    };

    useEffect(() => {
        const containerElement = ScrollDivRef.current;
        containerElement?.addEventListener("scroll", handleScroll);

        return () => {
            containerElement?.removeEventListener("scroll", handleScroll);
        };
    }, []);

    useEffect(() => {
        const scrollToPosition = Math.floor(domainStore.scrollToPosition * document.body.scrollHeight);

        window.scrollTo({
            top: scrollToPosition,
            behavior: "smooth", // Add smooth scrolling effect
        });
    }, [domainStore.scrollToPosition]);

    if (!props) return <>Props undefined</>;
    return (
        <>
            {window.IE11 ? (
                <UnsupportedBrowserView />
            ) : (
                <>
                    {/*EN: Make sure you apply overflowContainer classname to all your body content*/}

                    <div ref={ScrollDivRef} className={"overflowContainer"}>
                        <Header />
                        {/* <CookieConsent /> */}
                        <InfoBar />
                        {/*{props && <Box>{props.children}</Box>}*/}
                        <Outlet />
                        <Footer />
                    </div>
                </>
            )}
        </>
    );
};
